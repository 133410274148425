import { Typography, Stack, Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../';
import useAuth from 'src/hooks/useAuth';
import { useQuery } from '@apollo/client';
import { AthleteModalTabs } from 'src/constants';
import moment from 'moment';
import GetFlags from 'src/utils/getFlags';
import CustomTabs from '../../CustomTabs';
import { MyENTERIESUPDATED } from 'src/graphql/queries';
import UserEntries from 'src/components/dashboard/TournamentPage/general/UserEntries';
import AthleteSpaceMatches from 'src/components/dashboard/TournamentPage/AthleteSpaceMatches';
import UserPendingPayments from 'src/components/dashboard/TournamentPage/general/UserPendingPayments';
import { useTournament } from '../../../dashboard/TournamentPage/TournamentPage';
import { TournamentRegistrationsQuery } from 'src/__generated__/graphql';

type Props = {
  open: boolean;
  onClose: () => void;
  initialTab?: string;
  athleteEntries: TournamentRegistrationsQuery['tournamentRegistrations'];
  entriesLoading: boolean;
};

const AthleteSpace = ({ open, onClose, initialTab, athleteEntries, entriesLoading }: Props) => {
  const { tournament, id, refetch: refetchTournament, loading } = useTournament();
  const auth = useAuth();

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  useEffect(() => {
    setCurrentTab(initialTab);
  }, [initialTab]);
  const handleTabsChange = (value: string): void => {
    setCurrentTab(value);
  };

  if (loading) return;

  return (
    <CustomModal
      contentSx={{
        minHeight: { xs: '80dvh', md: '50dvh' },
        maxHeight: { xs: '80dvh', md: '50dvh' },
        minWidth: { md: '500px' },
        maxWidth: { md: '500px' },
        overflow: 'hidden',
      }}
      title="Athlete space"
      openOvveride={open}
      onClose={onClose}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack
          sx={{
            alignItems: 'start',
            gap: '10px',
            // mb: '20px',
            width: '100%',
            flexShrink: 0,
          }}
        >
          <Stack sx={{ width: '100%', gap: '4px' }}>
            <Typography
              variant="heading4"
              sx={{
                color: '#0A2540',
                lineHeight: '20.16px',
                textTransform: 'uppercase',
                cursor: 'pointer',
                wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {tournament.title}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Box component="img" src="/images/updated/calendar.svg" sx={{ width: 16, height: 16 }} />
                <Typography
                  variant="caption"
                  sx={{
                    color: '#86909F',
                    fontFamily: 'Inter',
                    fontSize: 10,
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  {tournament.startDate.format('D MMM YYYY')}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={0.5} alignItems="center">
                <GetFlags
                  country={tournament.country}
                  style={{
                    width: 16,
                    height: 10,
                    marginTop: 1,
                    borderRadius: 1,
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: 'var(--black-500, #86909F)',
                    fontFamily: 'Inter',
                    fontSize: 10,
                    fontWeight: 400,
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 120,
                  }}
                >
                  {tournament.country}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <CustomTabs
            ref={tabsRef}
            outerSx={{
              position: 'sticky',
              top: 0,
              width: '100%',
            }}
            sx={{
              width: '100%',
              borderBottom: '1px solid var(--stroke-1, #EDF1F6)',
              padding: '0px !important',
            }}
            onChange={handleTabsChange}
            tabs={AthleteModalTabs}
            currentTab={currentTab}
            isShare={false}
          />
        </Stack>
        {currentTab === 'entries' && auth?.user && <UserEntries Enteries={athleteEntries} loading={entriesLoading} refetchTournament={refetchTournament} />}
        {currentTab === 'matches' && <AthleteSpaceMatches />}
        {currentTab === 'payments' && <UserPendingPayments refetchTournament={refetchTournament} Enteries={athleteEntries} loading={entriesLoading} />}
      </Stack>
    </CustomModal>
  );
};

export default AthleteSpace;
