import { Avatar, Box, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';
import GetFlag from 'src/utils/getFlags';
import { getFideTitle } from 'src/utils/helperFunction';

const UserNameCell = ({ user, fideTitle }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Typography
        variant="body1"
        sx={{
          width: 'max-content',
          color: '#193660',
          fontWeight: 500,
          cursor: 'pointer',
          fontSize: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: '#29ABE2' },
            }}
          >
            {user?.name && (
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                  mr: 1,
                }}
                src={user?.avatar}
              />
            )}
            <Typography
              variant="body1"
              sx={{
                display: 'flex',
                width: '-webkit-fill-available',
                color: '#193660',
                fontWeight: '500',
                mr: 0.5,
                textTransform: 'uppercase',
                fontSize: '12px',
              }}
              onClick={() => {
                if (user?.id) {
                  window.open(`/athlete/${user?.id}?tab=overview`, '_blank');
                }
              }}
            >
              {user?.name ? user?.name : ''} {user?.surname ? user?.surname : ''}{' '}
            </Typography>
            {user?.name && (
              <GetFlag
                country={user?.citizenship ? user?.citizenship : user?.nation}
                style={{
                  width: '1.4em',
                  height: '1.4em',
                  marginTop: '5px',
                  borderRadius: '1px',
                }}
              />
            )}
            <Box
              sx={{
                padding: '3px 5px',
                background: getFideTitle(fideTitle)?.color,
                color: '#FFFF',
                borderRadius: '5px',
                marginRight: '10px',
                width: 'max-content',
                marginLeft: '10px',
              }}
            >
              {getFideTitle(fideTitle)?.abbreviation}
            </Box>
          </Box>
        </Box>
      </Typography>
    </Fragment>
  );
};

export default UserNameCell;
