import { gql } from '@apollo/client';

const TOURNAMENT_CATEGORY_GROUPS = gql`
  query findAllTournamentCategoryGroup($platform: Int, $filter: ListTournamentCategoryGroupInput) {
    findAllTournamentCategoryGroup(platform: $platform, filter: $filter) {
      id
      name
    }
  }
`;

export default TOURNAMENT_CATEGORY_GROUPS;
