import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Modal, Theme, useMediaQuery } from '@material-ui/core';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import toast from 'react-hot-toast';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import { useMutation } from '@apollo/client';
import { EDITUSER } from 'src/graphql/mutations';
import useAuth from 'src/hooks/useAuth';

const DUPR_IFRAME_URL = 'https://mydupr.com/login-external-app/Y2stMmQxNzQ1ZWItMGY2ZC00YjFmLWZiNjQtZmJiNTk2MDk4Zjc5';

const DuprConnection = ({ duprData, setDuprData, isResponsive }) => {
  const { user, dispatch } = useAuth();
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const [updateUser, { loading: userUpdateLoading }] = useMutation(EDITUSER) as any;

  const handleConnectDuprClick = () => {
    setIsIframeOpen(true);
    setLoading(true);
    setIframeLoading(true);
  };

  const handleMessage = async (event: MessageEvent) => {
    if (event.origin === 'https://dashboard.dupr.com') {
      const { data } = event;
      console.log('nbmbmnbmbmn', data);
      if (data.duprId) {
        setDuprData(data);
        if (user?.id) {
          try {
            const response = await updateUser({
              variables: {
                id: parseFloat(user?.id),
                duprId: data.duprId,
              },
            });
            if (response && response.data) {
              const updatedUserData = response?.data?.updateUser;
              dispatch({
                type: 'UPDATE_USER',
                payload: {
                  user: updatedUserData,
                },
              });
              toast.success('DUPR connected successfully');
              setLoading(false);
              setIsIframeOpen(false);
            }
          } catch (error) {
            setLoading(false);
            setIsIframeOpen(false);
            console.log('error?.message', error?.message);
            toast.error(error?.message, {
              duration: 6000,
            });
            handleCloseModal();
          }
        } else {
          toast.success('DUPR connected successfully');
          setLoading(false);
          setIsIframeOpen(false);
        }
      }
    }
  };
  const handleCloseModal = () => {
    setIsIframeOpen(false);
    setLoading(false);
  };
  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Box sx={{ position: 'relative' }}>
      <CustomButton shape="default" size="medium" variant="primary" onClick={handleConnectDuprClick} disabled={loading} sx={{ background: '#3a79e0', width: '100%', fontWeight: 500 }}>
        {loading ? <CircularProgress size={25} /> : isResponsive ? 'Connect' : 'Connect DUPR'}
      </CustomButton>

      <CustomModal openOvveride={isIframeOpen} onClose={() => handleCloseModal()}>
        <Box
          sx={{
            maxWidth: { xs: 'none', sm: '400px' },
            minWidth: { xs: '100%', sm: '400px' },
            height: '90vh',
            maxHeight: '800px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {iframeLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <iframe
            src={DUPR_IFRAME_URL}
            width="100%"
            height="100%"
            style={{
                position:"absolute",
              display: 'block',
              border: 'none',
              height: '100%',
              maxHeight: '800px',
              WebkitOverflowScrolling: 'touch',
            }}
            onLoad={handleIframeLoad}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};

export default DuprConnection;
