import { useQuery } from "@apollo/client";
import { Box, CircularProgress, TableCell, makeStyles, withStyles } from "@material-ui/core";
import React, { FC, Fragment } from "react";
import { useParams } from "react-router";
import { ReactSVG } from "react-svg";
import Loading from "src/components/Loading";
import { CALCULATESCORE } from "src/graphql/queries";
import useAuth from "src/hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  text: {
    color: "#193660",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    [theme.breakpoints.down("xl")]: {
      fontSize: "10px",
    },
  },
}));

const CustomTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

const ScoresCells: FC<{
  entryID: string;
  isGroup: boolean;
  groupType: string;
  entryPlace?: number;
}> = ({ entryID, isGroup, groupType, entryPlace }) => {
  const { id } = useParams();
  const auth = useAuth();
  const { loading, data: scoreTableData } = useQuery(CALCULATESCORE, {
    variables: {
      filter: {
        tournament: parseInt(id),
      },
    },
  });
  const findEntryScore = scoreTableData?.calculateGroupScore?.find((score) => score?.entry === entryID);
  const classes = useStyles();

  return (
    <Fragment>
      <CustomTableCell align="center" sx={{ width: "75px" }} className={classes.text}>
        {loading ? <Loading height="50px" responsive={true} /> : findEntryScore?.points}
      </CustomTableCell>
      {groupType !== "king" && (
        <CustomTableCell align="center" sx={{ width: "75px" }} className={classes.text}>
          {loading ? (
            <Loading height="50px" responsive={true} />
          ) : Number.isNaN(findEntryScore?.roundWon / findEntryScore?.roundlost) ? (
            0
          ) : Number(findEntryScore?.roundWon / findEntryScore?.roundlost) === Infinity ? (
            `${findEntryScore?.roundWon}.000`
          ) : (
            Number((findEntryScore?.roundWon / findEntryScore?.roundlost).toFixed(3))
          )}
          {!loading && ` (${findEntryScore?.roundWon}/${findEntryScore?.roundlost})`}
        </CustomTableCell>
      )}
      <CustomTableCell align="center" sx={{ width: "75px" }} className={classes.text}>
        {loading ? (
          <Loading height="50px" responsive={true} />
        ) : Number.isNaN(findEntryScore?.pointsWon / findEntryScore?.pointsLose) ? (
          0
        ) : Number(findEntryScore?.pointsWon / findEntryScore?.pointsLose) === Infinity ? (
          `${findEntryScore?.pointsWon}.000`
        ) : (
          Number((findEntryScore?.pointsWon / findEntryScore?.pointsLose).toFixed(3))
        )}
        {!loading && ` (${findEntryScore?.pointsWon}/${findEntryScore?.pointsLose})`}
      </CustomTableCell>
      {isGroup && (
        <CustomTableCell align="center" sx={{ width: "75px" }} className={classes.text}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loading ? <Loading height="50px" responsive={true} /> : entryPlace ?? findEntryScore?.place}
            <ReactSVG src="/images/caretDown.svg" />
          </Box>
        </CustomTableCell>
      )}
    </Fragment>
  );
};
export default ScoresCells;
