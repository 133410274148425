import React from 'react';
import { createStyles, makeStyles, Switch, SwitchClassKey, SwitchProps, Theme, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  switchTrack: {
    '& .MuiButtonBase-root.Mui-disabled+.MuiSwitch-track': {
      backgroundColor: '#000 !important',
    },
  },
}));

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}
interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 35,
      height: 20,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: 'white',
        '& + $track': {
          backgroundColor: '#fe512d',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #edf1f6`,
      backgroundColor: '#edf1f6',
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  const styles = useStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      className={styles.switchTrack}
      {...props}
    />
  );
});

export default IOSSwitch;
