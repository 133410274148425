import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import WithdrawModal from 'src/components/widgets/modals/WithdrawModal';
import GetFlag from 'src/utils/getFlags';
import Loading from 'src/components/Loading';
import DragScrollbar from 'src/components/DragScrollbar';

import { CREATE_TOURNAMENT_REG_PAYMENT } from 'src/graphql/mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import NoDataFound from '../../NoDataFound';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { GET_PAYMENT_CALCULATIONS } from 'src/graphql/queries';
import { getPlatformId } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  categoriesContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },
  ponits: {
    color: '#1B3861',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  heading: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
  },
  head: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  withdraw: {
    padding: '8px 16px',
    borderRadius: '6px',
    textAlign: 'center',
    border: '1px solid #EDF1F6',
    background: '#FFF',
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  label: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: '22.5px',
    marginTop: '15px',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  registerBtn: {
    padding: '10px 24px',
    borderRadius: '8px',
    // height: '32px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  card: {
    // border: "1px solid #EDF1F6",
    borderRadius: '6px',
    padding: '16px 16px 0 16px',
    // marginBottom: "16px",
    maxWidth: '800px',
    width: '100%',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginBottom: "8px",
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  status: {
    // padding: "6px 10px",
    // borderRadius: "30px",
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    lineHeight: '18px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);
const UserEntries: FC<any> = ({ tournament, Enteries, loading, refetchTournament }) => {
  // const FilteredEntries = Enteries?.filter((entry) => entry?.status == 'confirm');
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const auth = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [entryName, setEntryName] = useState(null);
  const [entryId, setEntryId] = useState(null);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [fetchPayments, { data, loading: calculationsLoading }] = useLazyQuery(GET_PAYMENT_CALCULATIONS, {});
  const [tournamentPayment, { data: paymentData, loading: paymentLoading }] = useMutation(CREATE_TOURNAMENT_REG_PAYMENT);

  const handlePayment = async (entry) => {
    const payments = await fetchPayments({
      variables: {
        categoryPaymentInput: [
          {
            categoryId: parseInt(entry?.categoryId?.category?.id),
            additionalFee: entry?.additionalFee,
            onSiteAmount: parseInt(entry?.categoryId?.onsiteFee),
            currency: entry?.categoryId?.currency,
            amount: parseInt(entry?.categoryId?.fee),
            partnerId: parseInt(entry?.users[0]?.user?.id),
          },
        ],
        calculatePaymentInput: {
          payForMyself: entry?.payForMyself,
          tournamentId: parseInt(entry?.tournament?.id),
          userId: parseInt(auth?.user?.id),
          platformId: getPlatformId(),
        },
      },
    });
    const isDoubleAmount = entry?.categoryId?.category?.type === 'doubles' && entry?.categoryId?.onlinePayments?.toLowerCase() === 'per athlete';
    if (payments?.data?.calculatePayments && parseFloat(entry?.categoryId?.fee) + entry?.additionalFee) {
      const paymentResponse = await tournamentPayment({
        variables: {
          email: auth?.user?.email,
          name: auth?.user?.name,
          userId: parseInt(auth?.user?.id),
          amount: parseFloat(payments?.data?.calculatePayments?.totalPayable),
          platformProcessingFee: payments?.data?.calculatePayments?.totalProcessingFee,
          entryFee: payments?.data?.calculatePayments?.totalEntryFee,
          VATFee: payments?.data?.calculatePayments?.totalVatFee,
          totalTournatedServiceFee: payments?.data?.calculatePayments?.totalTournatedServiceFee,
          totalPartnerServiceFee: payments?.data?.calculatePayments?.totalPartnerServiceFee,
          successUrl: window.location.href,
          currency: entry?.categoryId?.currency,
          registrationRequestIds: [entry?.id],
          tournamentName: entry?.tournament?.title,
          tournamentId: entry?.tournament?.id,
        },
      });
      if (paymentResponse?.data?.createTournamentRegistrationPayment?.url) {
        window.open(`${paymentResponse?.data?.createTournamentRegistrationPayment?.url}`, '_self');
      }
    }
  };

  function withdrawEntry(cat) {
    setOpenWithdrawModal(true);
    setEntryName(cat?.categoryId?.category?.name);
    setEntryId(cat?.id);
  }
  function getColorByName(text) {
    let color = '';
    if (text == 'pending') {
      color = '#1F6BFF';
    } else if (text == 'payment_pending') {
      color = '#1F6BFF';
    } else if (text == 'confirm') {
      color = '#00A100';
    } else if (text == 'withdrawn') {
      color = 'linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)';
    } else if (text == 'decline') {
      color = '#ff0f0f';
    } else if (text == 'partial_payment') {
      color = '#1F6BFF';
    }
    return color;
  }
  function getStatusText(text) {
    let status = text;
    if (text == 'confirm') {
      status = t('confirmed');
    } else if (text == 'decline') {
      status = t('declined');
    } else if (text == 'payment_pending') {
      status = t('payment pending');
    } else if (text == 'partial_payment') {
      status = t('partial payment');
    }
    return status;
  }
  return (
    <>
      <DragScrollbar style={{ overflow: 'scroll' }}>
        <Box sx={{ width: '100%', maxHeight: 394 }}>
          {!loading ? (
            <Box>
              <Box
                className={classes.cardHeader}
                sx={{
                  padding: '16px',
                }}
              >
                <Typography className={classes.heading}>Entry</Typography>
                <Typography className={classes.heading} sx={{ marginLeft: '17px' }}>
                  Category
                </Typography>
                <Typography className={classes.heading}>Status</Typography>
              </Box>
              <Box sx={{ padding: '0 16px' }}>
                <Divider sx={{ borderColor: '#EDF1F6' }} />
              </Box>
              {!loading && Enteries?.length > 0 ? (
                Enteries?.map((entry, index) => (
                  <Box
                    key={entry?.id}
                    className={classes.card}
                    sx={{
                      background: index % 2 === 1 ? '#F6F6F6' : '#FFFF',
                    }}
                  >
                    <Box
                      className={classes.cardHeader}
                      sx={{
                        borderBottom: '1px solid #EDF1F6',
                        alignItems: 'center',
                        paddingBottom: '10px',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: '2px', flexWrap: 'wrap', width: '80px' }}>
                        {entry?.users?.map((user, index, array) => (
                          <Box
                            key={user.id}
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <GetFlag
                              country={user?.user?.citizenship || user?.user?.nation}
                              style={{
                                width: '15px',
                                height: '15px',
                              }}
                            />
                            <Typography
                              onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                              style={{
                                cursor: 'pointer',
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                marginTop: '1px',
                              }}
                              className={classes.text}
                            >
                              {`${user?.user?.name?.charAt(0).toUpperCase()}. ${user?.user?.surname}`}
                            </Typography>
                            {entry?.users?.length > 1 && index === 0 && ' / '}
                          </Box>
                        ))}
                      </Box>
                      <Typography variant="font21" sx={{ lineHeight: '24px', color: '#0A2540BF' }}>
                        {entry?.categoryId?.category?.name}
                      </Typography>
                      <Typography
                        className={classes.status}
                        // style={{ background: getColorByName(entry?.status) }}
                        sx={{
                          paddingTop: '2px',
                          display: 'flex',
                          gap: '3px',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          new Date(entry?.tournament?.withdrawlDeadline) >= new Date() && entry.status === 'payment_pending' && handlePayment(entry);
                        }}
                      >
                        <Box
                          sx={{
                            height: '6px',
                            width: '6px',
                            borderRadius: '50px',
                            background: `${getColorByName(entry?.status)}`,
                          }}
                        ></Box>{' '}
                        {getStatusText(entry?.status)}
                      </Typography>
                    </Box>
                    <Box className={classes.cardBody} sx={{ padding: '10px 0' }}>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2px',
                          }}
                        >
                          <Typography
                            sx={{
                              lineHeight: '20px',
                              color: '#0A25408C',
                            }}
                            variant="font21"
                          >
                            {moment(entry?.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                          </Typography>
                          {entry.status !== 'payment_pending' && (
                            <Typography
                              sx={{
                                lineHeight: '14.52px',
                                color: '#00000026',
                              }}
                              variant="font21"
                            >
                              {t('Registration time')}
                            </Typography>
                          )}
                        </Box>
                        {new Date(entry?.tournament?.withdrawlDeadline) >= new Date() &&
                          (entry?.status === 'payment_pending' ||
                            (entry?.status === 'partial_payment' && entry?.users.some((user) => user?.user?.id == auth.user?.id && user?.paymentStatus === 'pending'))) && (
                            <CustomButton
                              variant="primary"
                              size="small"
                              disabled={(paymentLoading || calculationsLoading) && entryId == entry?.id}
                              onClick={() => {
                                handlePayment(entry);
                                setEntryId(entry?.id);
                              }}
                              sx={{ background: '#00A100', fontSize: '12px' }}
                            >
                              {(paymentLoading || calculationsLoading) && entryId == entry?.id ? <CircularProgress size={25} sx={{ color: '#ffff' }} /> : 'PAY'}
                            </CustomButton>
                          )}
                      </Box>
                      {new Date(entry?.tournament?.withdrawlDeadline) >= new Date() || auth?.user?.role?.toLowerCase() === 'referee' ? (
                        <CustomButton
                          variant="primary"
                          size="small"
                          key={entry?.id}
                          sx={{ cursor: 'pointer', fontSize: '12px' }}
                          onClick={() => withdrawEntry(entry)}
                          disabled={entry?.status === 'withdrawn' || (withdrawLoading && entryId === entry?.id)}
                        >
                          {withdrawLoading && entryId === entry?.id ? <CircularProgress size={20} sx={{ color: '#ffff' }} /> : 'WITHDRAW'}
                        </CustomButton>
                      ) : (
                        <Typography className={classes.withdraw}>-</Typography>
                      )}
                    </Box>
                  </Box>
                ))
              ) : (
                <NoDataFound text="No entries found" sx={{ margin: '70px 0' }} />
              )}
            </Box>
          ) : (
            <Loading />
          )}
        </Box>
      </DragScrollbar>
      <WithdrawModal
        openWithdrawModal={openWithdrawModal}
        setOpenWithdrawModal={setOpenWithdrawModal}
        entryId={entryId}
        refetchTournament={refetchTournament}
        setWithdrawLoading={setWithdrawLoading}
        entryName={entryName}
      />
    </>
  );
};

export default UserEntries;
