import { gql } from "@apollo/client";
const ELIGIBLEMEMBERS = gql`
query eligibleTeamMembers($category:Float $filter:ListTeamMemberInput $tournament:Float) {
    eligibleTeamMembers(category: $category,filter: $filter, tournament: $tournament) {
      id
      member{
          email
          gender
          name
          role
          dob
      }
      team{
          id
      }
    }
  }
  `;
export default ELIGIBLEMEMBERS;