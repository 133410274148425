import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Checkbox, Typography, Tooltip, IconButton, TextField, InputAdornment } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { makeStyles } from '@material-ui/core/styles';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import NoDataFound from '../NoDataFound';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { calculateFee } from 'src/utils/formatFee';
import CustomFilterBox from 'src/components/CustomComponents/CustomFilterBox';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import CustomInfo from './CustomInfo';
import CustomAccordion from 'src/components/CustomComponents/CustomAccordian';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    position: 'relative',
    paddingRight: '10px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px',
    },
  },
  categoryName: {
    lineHeight: '18px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  categoryFee: {
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  perAthlete: {
    fontSize: '10px',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '12.1px',
    color: '#00000059',
    textTransform: 'lowercase',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  infoIcon: {
    // marginLeft: theme.spacing(1),
  },
}));

const StepCategorySelection = ({ tournament, tournamentCategories, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();
  const [searchCategory, setSearchCategory] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [firstSelectedMethod, setFirstSelectedMethod] = useState(null);
  const { user } = useAuth();

  const doesInfoExist = !!tournament?.customInfoMessage?.replace(/<\/?[^>]+(>|$)/g, '') && !tournament.customInfoMessage.toLowerCase().includes('null');
  const info = doesInfoExist ? tournament?.customInfoMessage?.replaceAll('<p><br></p>', '<br>') : undefined;

  const handleCategorySelect = (category) => {
    let updatedCategories = [...registrationData?.selectedCategories];
    const isSelected = updatedCategories?.find((entry) => entry?.category?.id === category?.id);

    if (isSelected) {
      updatedCategories = updatedCategories?.filter((entry) => entry?.category?.id !== category?.id);
      if (updatedCategories?.length === 0) {
        setFirstSelectedMethod(null);
        updateRegistrationData({ paymentMethod: null });
      }
    } else {
      const player1AdditionalQuestions =
        category?.additionalQuestions?.map((question) => ({
          questionId: question?.id,
          question: question?.question,
          fieldOptions: question?.fieldOptions,
          fieldType: question?.fieldType,
          answer: question?.fieldOptions?.[0]?.price || '',
          askEachAthlete: question?.askEachAthlete,
          isMandatory: question?.isMandatory,
          file: null,
          user: user?.id,
        })) ?? [];

      let player2AdditionalQuestions = [];

      if (category?.category?.type === 'doubles') {
        player2AdditionalQuestions =
          category?.additionalQuestions
            ?.filter((question) => question?.askEachAthlete)
            ?.map((question) => ({
              questionId: question?.id,
              question: question?.question,
              fieldOptions: question?.fieldOptions,
              fieldType: question?.fieldType,
              answer: question?.fieldOptions?.[0]?.price || '',
              askEachAthlete: question?.askEachAthlete,
              isMandatory: question?.isMandatory,
              file: null,
            })) ?? [];
      }

      const newCategoryEntry = {
        category: {
          ...category,
        },
        partners: { player1: null, player2: null },
        player1additionalQuestions: player1AdditionalQuestions,
        player2additionalQuestions: player2AdditionalQuestions,
        additionalFees: { player1: 0, player2: 0 },
        totalAdditionalFees: 0,
        currency: category?.currency || '',
      };

      updatedCategories.push(newCategoryEntry);
      if (updatedCategories?.length === 1) {
        setFirstSelectedMethod(category?.method);
        updateRegistrationData({ paymentMethod: category?.method });
      }
    }

    updateRegistrationData({ selectedCategories: updatedCategories });
  };

  const filteredCategories = tournamentCategories?.filter((category) => category?.category?.name?.toLowerCase()?.includes(searchCategory?.toLowerCase()));

  const isCategoryDisabled = (category) => {
    const categoryMethod = category?.method;
    if (!firstSelectedMethod) {
      return category?.isRegistered || category?.type?.status === 'Closed' || category?.category?.status === 'inActive' || category?.category?.status === 'null';
    }
    return (
      category?.isRegistered ||
      category?.type?.status === 'Closed' ||
      category?.category?.status === 'inActive' ||
      category?.category?.status === 'null' ||
      (firstSelectedMethod && categoryMethod !== firstSelectedMethod)
    );
  };

  if (loading) {
    return <Loading height="30vh" />;
  }
  return (
    <Box>
      {tournament?.discounts?.length > 0 ? (
        <Box sx={{ marginBottom: '15px' }}>
          <CustomButton
            size="small"
            variant="secondary"
            sx={{
              width: '100%',
              justifyContent: 'start',
              gap: '20px',
              height: '100%',
              minHeight: '50px',
              whiteSpace: 'normal',
              textAlign: 'start',
              mb: 1,
              background: '#0A25400D',
              color: '#d5d5d5',
              padding: '15px 20px',
            }}
          >
            <img src="/images/percentage.svg" style={{ width: '28px', height: '28px' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="font15" sx={{ lineHeight: '14.52px', color: '#0A2540' }}>
                  Discount
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }}>
                {tournament?.discounts?.slice(0, 2)?.map((discountItem, index) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    <Typography key={index} variant="font18" sx={{ lineHeight: '14.52px', color: '#0A2540' }}>
                      {discountItem?.numberOfCategories} categories =
                    </Typography>
                    <Typography key={index} variant="font18" sx={{ lineHeight: '14.52px', color: '#FF5733' }}>
                      {discountItem?.discount?.toFixed(2)}%
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            {tournament?.discounts?.length > 2 && (
              <CustomButton variant="text" size="small" onClick={() => setModalOpen(true)}>
                View all
              </CustomButton>
            )}
          </CustomButton>
        </Box>
      ) : (
        ''
      )}

      <Box sx={{ marginBottom: '10px 0' }}>
        <Box sx={{ width: '100%', mt: 1 }}>
          <CustomInfo />
        </Box>
        <CustomFilterBox
          fullWidth
          placeholder={t('search')}
          name="search"
          variant="outlined"
          size="small"
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
          sx={{ width: '100%', maxWidth: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ReactSVG src="/images/search.svg" style={{ marginTop: '5px' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ margin: '10px 0' }}>
        <Typography sx={{ lineHeight: '14.52px', color: '#0A2540' }} variant="font15">
          Select category
        </Typography>
      </Box>
      <Box className={`${classes.scrollContainer}`}>
        {filteredCategories?.length > 0 ? (
          filteredCategories?.map((category) => {
            const isSelected = !!registrationData.selectedCategories.find((entry) => entry.category?.id === category?.id);
            const disabled = isCategoryDisabled(category);
            return (
              <>
                {' '}
                <Tooltip key={category?.id} title={disabled ? category?.message || 'Category unavailable' : ''} arrow disableHoverListener={!disabled}>
                  <Box>
                    <CustomButton
                      selected={isSelected}
                      key={category?.id}
                      size="small"
                      disabled={disabled}
                      variant="secondary"
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        height: '50px',
                        whiteSpace: 'normal',
                        textAlign: 'start',
                        mb: 1,
                        background: isSelected ? '#FF57330D' : '#ffff',
                        color: '#d5d5d5',
                      }}
                      onClick={() => !disabled && handleCategorySelect(category)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {disabled && (
                          <div onClick={(e) => e.stopPropagation()}>
                            <IconButton className={classes.infoIcon} size="small">
                              <InfoIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </div>
                        )}
                        <Checkbox size="small" color="primary" disabled={disabled} checked={isSelected} onChange={() => !disabled && handleCategorySelect(category)} />
                        <Typography variant="font18" className={classes.categoryName} sx={{ color: disabled ? '#0A254059' : '#0A2540' }}>
                          {category?.category?.name || 'Category Name'}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="font18" className={classes.categoryFee} sx={{ textAlign: 'right', color: disabled ? '#0A254059' : '#0A2540' }}>
                          {calculateFee(category) ?? 'Free'}
                        </Typography>
                        {category?.onlinePayments && (category?.fee > 0 || category?.onsiteFee > 0) && <Typography className={classes.perAthlete}>{category?.onlinePayments}</Typography>}
                      </Box>
                    </CustomButton>
                  </Box>
                </Tooltip>
              </>
            );
          })
        ) : (
          <NoDataFound text={t('No eligible category found')} sx={{ height: '20vh' }} />
        )}
      </Box>
      <CustomModal openOvveride={modalOpen} title={'Discounts'} onClose={() => setModalOpen(false)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }}>
            {tournament?.discounts?.map((discountItem, index) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <Typography key={index} variant="font18" sx={{ lineHeight: '14.52px', color: '#0A2540' }}>
                  {discountItem?.numberOfCategories} categories =
                </Typography>
                <Typography key={index} variant="font18" sx={{ lineHeight: '14.52px', color: '#FF5733' }}>
                  {discountItem?.discount?.toFixed(2)}%
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default StepCategorySelection;
