import { gql } from "@apollo/client";

const DECLINE_PARTNER_SEARCH = gql`
  mutation updatePartnerSearch($id: Int!, $status: String, $partner: Int) {
    updatePartnerSearch(updatePartnerSearchInput: { id: $id, status: $status, partner: $partner }) {
      id
    }
  }
`;

export default DECLINE_PARTNER_SEARCH;
