import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./locales/en.json"
import la from "./locales/la.json"
import ka from "./locales/ka.json"
import esp from "./locales/esp.json"
import ru from "./locales/ru.json"
import pl from "./locales/pl.json"
import hr from "./locales/hr.json"
import cs from "./locales/cs.json"
import et from "./locales/et.json"
import lt from "./locales/lt.json"
import pt from "./locales/pt.json"
import hi from "./locales/hi.json"
import hu from "./locales/hu.json"

const resources = {
  en: {translation:en},
  la: {
    translation:la ,
  },
  ka: {
    translation: ka
  },
  esp: {
    translation:esp ,
  },
  ru: {
    translation:ru ,
  },
  pl: {
    translation:pl ,
  },
  hr: {
    translation:hr ,
  },
  cs: {
    translation:cs ,
  },
  et: {
    translation: et,
  },
  lt: {
    translation: lt,
  },
  pt: {
    translation:pt ,
  },
  hi: {
    translation:hi ,
  },
  hu: {
    translation:hu ,
  },
  ja:{ 
    translation: {
      header: {
        "discover": "発見する",
        "enterName": "名前を入力してください",
        "sportsManager": "スポーツマネージャー？",
        "learnHow": "方法を学ぶ",
        "accelerate": "私たちはあなたの組織を加速させます。",
        "powering": "電力供給",
        "tournaments": "トーナメント、",
        "leagues": "リーグ",
        "organizations": "組織",
        "clubs": "クラブ",
        "coaches": "コーチ",
        placeholder: {
          "tournaments": "大会名を入力してください",
          "leagues": "リーグ名を入力してください",
          "organizations": "組織名を入力してください",
          "clubs": "クラブ名を入力してください",
          "coaches": "コーチ名を入力してください"
        }
      },
      "registrationOpen": "登録受付中",
      "read_more": "続きを読む",
      "registrationClosed": "登録は終了しました",
      "live": "ライブ",
      "Close": "近い",
      "bannerHeading": "完全に無料でトーナメントの管理を始めましょう",
      "bannerDescription": "エントリー管理からグループの自動生成、抽選とプレイ順序、結果の管理など",
      "startNow": "今すぐ始めましょう",
      "featureTournaments": "注目のトーナメント",
      "tournamentNotFound": "注目のトーナメントが見つかりませんでした",
      "viewAll": "すべて見る",
      "featureLeague": "注目のリーグ",
      "leaguesNotFound": "注目のリーグが見つかりませんでした",
      "Tournaments": "トーナメント",
      "NoTournamentsFound": "トーナメントが見つかりませんでした",
      "Rankings": "ランキング",
      "noRankingFound": "ランキングが見つかりませんでした",
      "news": "ニュース",
      "noNewsFound": "ニュースは見つかりませんでした",
      "partners": "私たちのパートナー",
      "Partners": "パートナー",
      "noPartnersFound": "パートナーが見つかりませんでした",
      "worldwide": "世界中",
      "search": "検索",
      "pricing": "価格設定",
      "loginRegister": "ログイン/登録",
      "home": "家",
      "organizations": "組織",
      "leagues": "リーグ",
      "teams": "チーム",
      "clubs": "クラブ",
      "coaches": "コーチ",
      "tennis": "テニス",
      "beachTenis": "ビーチテニス",
      "Beach Volley": "ビーチバレー",
      "Sport Fishing": "スポーツフィッシング",
      "Volleyball": "バレーボール",
      "Sports": "スポーツ",
      "RESOURSES": "リソース",
      "Feature": "特徴",
      "Create account": "アカウントを作成する",
      "Login": "ログイン",
      "Packages": "パッケージ",
      "Package one": "パッケージ 1",
      "Package two": "パッケージ 2",
      "Package three": "パッケージ 3",
      "Package Four": "パッケージ 4",
      "Customer suppor": "カスタマーサポート",
      "FAQ": "よくある質問",
      "Documentation": "ドキュメント",
      "Video tutorials": "ビデオチュートリアル",
      "Submit ticket": "チケットを送信する",
      "Contact us": "お問い合わせ",
      "Our contacts": "私たちの連絡先",
      "Padel": "パデル",
      "Pickleball": "ピックルボール",
      "FEATURED LEAGUES": "注目のリーグ",
      "View": "ビュー",
      "No leagues found": "リーグが見つかりませんでした",
      "Athletes": "アスリート",
      "Managed by": "管理者",
      "View League": "リーグを見る",
      "Type": "タイプ",
      "National": "全国",
      "International": "国際的",
      "Local": "地元",
      "Period": "期間",
      "League": "リーグ",
      "Country": "国",
      "Year": "年",
      "Points": "ポイント",
      "Position": "位置",
      "Team": "チーム",
      "No teams found": "チームが見つかりませんでした",
      "Team Members": "チームメンバー",
      "Sport": "スポーツ",
      "No clubs found": "クラブが見つかりませんでした",
      "Experience": "経験",
      "Students": "学生",
      "Author": "著者",
      "Category": "カテゴリ",
      "My Entries": "私のエントリー",
      "My Leagues": "私のリーグ",
      "My Licences": "私のライセンス",
      "My Teams": "私のチーム",
      "Get Licence": "ライセンスを取得する",
      "demo": "デモ",
      "Settings": "設定",
      "Log out": "ログアウト",
      "Dark view": "暗い景色",
      "Manager": "マネージャー",
      "Tournament": "トーナメント",
      "Cost": "料金",
      "Duration": "間隔",
      "Purchase on": "で購入",
      "No license found": "ライセンスが見つかりません",
      "Additional": "追加",
      "Status": "状態",
      "Date": "日付",
      "No entries found": "エントリが見つかりませんでした",
      "My Licenses": "私のライセンス",
      "Add new License": "新しいライセンスを追加",
      "Create Team": "チームの作成",
      "Info": "情報",
      "infoDes": "特定のリーグ/ツアーに参加するには、プレーヤーは対応するアクティブなライセンスを持っている必要があります。",
      "Select League": "リーグを選択してください",
      "Select License": "ライセンスの選択",
      "Promo code": "プロモーションコード",
      "I agree to the": "に同意します",
      "Terms and Conditions": "利用規約",
      "Price": "価格",
      "Confirm Payment": "支払いの確認",
      "History": "歴史",
      "Tournated Manager": "荒れ狂うマネージャー",
      "Tournated Custom": "トーナメントカスタム",
      "Customer support": "カスタマーサポート",
      "Categories": "カテゴリー",
      "Matches": "一致",
      "Organized by": "主催",
      "Register": "登録する",
      "Entries": "エントリー",
      "Location": "位置",
      "National Tour": "全国ツアー",
      "4 mins read": "4 分で読めます",
      "Age group": "年齢層",
      "Skills level": "スキルレベル",
      "someDes": "SIA Vertexo © 2020.全著作権所有",
      "Manage in CMS": "CMSで管理する",
      "Fact Sheet": "ファクトシート",
      "General": "一般的な",
      "Participants": "参加者",
      "Groups": "グループ",
      "Draws": "ドロー",
      "Live Standings": "ライブ順位表",
      "Order of play": "プレイの順番",
      "Pairings": "ペアリング",
      "Standings": "順位表",
      "Final standings": "最終順位",
      "List & Results": "リストと結果",
      "Entry Fee": "入場料",
      "Entry Deadline": "エントリー締め切り",
      "Entry deadline": "エントリー締め切り",
      "Withdrawal deadline": "出金期限",
      "Start date": "開始日",
      "End date": "終了日",
      "and": "そして",
      "others": "その他",
      "are confirmed": "確認されています",
      "Description": "説明",
      "Tournament Poster": "大会ポスター",
      "More Info": "詳細情報",
      "Additional materials about tournament": "トーナメントに関する追加資料",
      "Regulations": "規則",
      "Tournaments Discord": "トーナメントの不和",
      "discordDes": "トーナメント管理者と直接話したり、質問したり、他のプレイヤーと話したりしましょう!",
      "Join Channel": "チャンネルに参加する",
      "joinTournament": "トーナメントに参加する",
      "Fact sheet": "ファクトシート",
      "Search hotels and more": "ホテルなどを検索...",
      "Destination": "行き先",
      "Select Destination": "目的地を選択してください",
      "Check in date": "チェックイン日",
      "Check out date": "チェックアウト日",
      "Best players on this tournament": "この大会の最優秀選手",
      "Gallery": "ギャラリー",
      "Recent photos": "最近の写真",
      "Buy licence": "ライセンスを購入する",
      "Select All": "すべて選択",
      "Don't see your sport? Contact us": "あなたのスポーツが見つかりませんか?",
      "Description is missing": "説明がありません",
      "day": "日",
      "days": "日",
      "Organization": "組織",
      "Athlete": "アスリート",
      "Birth year": "誕生年",
      "Joined": "参加しました",
      "Select Sport": "スポーツを選択してください",
      "Select organization": "組織の選択",
      "Select league": "リーグを選択",
      "Overview": "概要",
      "About": "について",
      "Win rate": "勝率",
      "Achievements": "業績",
      "Upcoming Matches": "今後の試合",
      "Past Matches": "過去の試合",
      "Upcoming Tournaments": "今後のトーナメント",
      "Purchase history": "購入履歴",
      "description and detail": "改善: 特定のリーグまたはトーナメントの参加者に必要なライセンス/メンバーシップを取得します。",
      "Purchase datetime": "購入日時",
      "Payment Status": "支払い状況",
      "Licence": "ライセンス",
      "Members": "メンバー",
      "Student": "学生",
      "Personal page": "個人ページ",
      "My Career": "私のキャリア",
      "Latest tournaments": "最新のトーナメント",
      "Upcoming tournaments": "今後のトーナメント",
      "No members found": "メンバーが見つかりませんでした",
      "No tournaments found": "トーナメントが見つかりませんでした",
      "No matches found": "一致するものが見つかりませんでした",
      "No rankings found": "ランキングが見つかりませんでした",
      "No past matches found": "過去の一致は見つかりませんでした",
      "No upcoming matches found": "今後の一致は見つかりませんでした",
      "No achievements found": "実績が見つかりませんでした",
      "Documents": "書類",
      "Contact": "接触",
      "No photos added": "写真は追加されていません",
      "Manage league": "リーグの管理",
      "No documents added": "文書は追加されていません",
      "Email": "電子メール",
      "Phone": "電話",
      "Website": "Webサイト",
      "Place": "場所",
      "Tournament Name": "大会名",
      "Manage organization": "組織の管理",
      "Terms & Conditions": "利用規約",
      "LEGAL": "法律上の",
      "Privacy policy": "プライバシーポリシー",
      "Cancellation policy": "キャンセルポリシー",
      "GDPR": "GDPR",
      "All Rights Reserved": "無断転載を禁じます",
      "League [Category]": "リーグ[カテゴリー]",
      "Other tournaments": "その他のトーナメント",
      "Points Details": "ポイント詳細",
      "International rating": "国際的な評価",
      "Day": "日",
      "Days": "日数",
      "Tennis": "テニス",
      "Beach Tennis": "ビーチテニス",
      "Beach Volleyball": "ビーチバレーボール",
      "Chess": "チェス",
      "In progress": "進行中",
      "Registration": "登録",
      "Deadline past": "締め切りを過ぎました",
      "Completed": "完了",
      "No points yet": "まだポイントがありません",
      "is confirmed": "確認されています",
      "Contacts": "連絡先",
      "Organizer": "主催者",
      "Featured athletes": "注目のアスリート",
      "Top-ranked participants of the tournament": "大会上位出場者",
      "NR": "NR",
      "PLAYER": "プレーヤー",
      "CITY": "市",
      "RATING": "評価",
      "CLUB": "クラブ",
      "TOTAL": "合計",
      "SEGMENT": "セグメント",
      "SEED": "シード",
      "Search": "検索",
      "No participants found": "参加者が見つかりませんでした",
      "Total": "合計",
      "Seed": "シード",
      "Rating": "評価",
      "Rank": "ランク",
      "Category:": "カテゴリ：",
      "No category found": "カテゴリが見つかりません",
      "No Rounds found": "ラウンドが見つかりませんでした",
      "White": "白",
      "Black": "黒",
      "Name": "名前",
      "Pts": "ポイント",
      "Score": "スコア",
      "There is no available pairings!": "利用可能なペアリングがありません!",
      "Generating PDF...": "PDF を生成しています...",
      "PDF Generated Successfully!": "PDF が正常に生成されました。",
      "PDF Downloaded Successfully!": "PDFが正常にダウンロードされました！",
      "Downloading...": "ダウンロード中...",
      "Pairing Standings": "組み合わせ順位表",
      "Opponents": "対戦相手",
      "BHC1": "BHC1",
      "BHC": "BHC",
      "BGP": "BGP",
      "WIN": "勝つ",
      "There is no available entries!": "利用可能なエントリはありません!",
      "I'm a coach/parent": "私はコーチ/保護者です",
      "Step 1": "ステップ1",
      "Select category": "カテゴリを選択してください",
      "Request": "リクエスト",
      "Register & Pay Now": "今すぐ登録して支払う",
      "Register & Pay On-Site": "現地で登録＆支払い",
      "Terms And Conditions": "利用規約",
      "Latest Winners": "最新の受賞者",
      "No winners found": "勝者は見つかりませんでした",
      "No news found": "ニュースは見つかりませんでした",
      "No partners found": "パートナーが見つかりませんでした",
      "Skills Levels": "スキルレベル",
      "To get register for the tournament you must have active tour licence.": "トーナメントに登録するには、有効なツアーライセンスを持っている必要があります。",
      "Contact Details": "連絡先の詳細",
      "Our Partners": "私たちのパートナー",
      "View all": "すべて見る",
      "Member": "メンバー",
      "Team Name": "チーム名",
      "News": "ニュース",
      "Men Pro": "メンプロ",
      "Latvia Tennis Union League": "ラトビアテニスユニオンリーグ",
      "Leagues": "リーグ",
      "No league found": "リーグが見つかりませんでした",
      "Home Page": "ホームページ",
      "Upcoming": "今後の予定",
      "This month": "今月",
      "Last 7 days": "過去 7 日間",
      "Last 30 days": "過去 30 日間",
      "Hours": "営業時間",
      "Minutes": "分",
      "Teams": "チーム",
      "No student found": "学生が見つかりませんでした",
      "Other news": "その他のニュース",
      "Don’t have an account?": "アカウントをお持ちでない場合は、",
      "Sign up": "サインアップ",
      "or log in": "またはログインしてください",
      "Did you forget your password?": "パスワードを忘れましたか?",
      "Must be a valid email": "有効な電子メールである必要があります",
      "Email is required": "メールアドレスは必須です",
      "Password is required": "パスワードが必要です",
      "Password": "パスワード",
      "Remember me": "私を覚えてますか",
      "Log In": "ログイン",
      "Unauthorized , please sign in": "許可されていません。サインインしてください",
      "Reset your password": "パスワードをリセットする",
      "Enter the e-mail address": "メールアドレスを入力してください",
      "Enter your e-mail address with which you are registered": "登録しているメールアドレスを入力してください",
      "Please check your email for reset password link!": "パスワードをリセットするリンクについては、メールを確認してください。",
      "Send instructions": "指示を送信する",
      "My Licenes": "私のライセンス",
      "My leagues": "私のリーグ",
      "My tournaments": "私のトーナメント",
      "My matches": "私の試合",
      "My entries": "私のエントリー",
      "My rankings": "私のランキング",
      "Stats": "統計",
      "Leagues took part in": "参加したリーグ",
      "Active licences": "アクティブなライセンス",
      "Titles": "タイトル",
      "Matches played": "試合数",
      "Matches lost": "負けた試合",
      "Games/points played": "ゲーム/プレイポイント",
      "All sports": "すべてのスポーツ",
      "All organizations": "すべての組織",
      "All leagues": "すべてのリーグ",
      "Registered": "登録済み",
      "Withdraw": "撤回する",
      "Withdraw deadline passed": "出金期限を過ぎました",
      "Request Withdrawn": "リクエストの取り下げ",
      "Something went wrong!": "何か問題が発生しました!",
      "Withdrawal": "出金",
      "Entry to withdraw": "出金エントリー",
      "Withdrawal Reason": "退会理由",
      "Write your reason here": "ここに理由を書いてください",
      "Confirm": "確認する",
      "Please specify reason": "理由を明記してください",
      "AUDI - The Beach Tennis Championship": "AUDI - ビーチテニスチャンピオンシップ",
      "Get licence": "ライセンスを取得する",
      "Additional questions": "追加の質問",
      "Select duration": "期間を選択してください",
      "Career": "キャリア",
      "Third Party Licenes": "サードパーティライセンス",
      "User updated successfully": "ユーザーは正常に更新されました",
      "User Account": "ユーザーアカウント",
      "We recommend an image that is 256 x 256 pixels": "256 x 256 ピクセルの画像をお勧めします",
      "Enter Email Address": "メールアドレスを入力してください",
      "Enter name": "名前を入力してください",
      "Surname": "姓",
      "Enter surname": "姓を入力してください",
      "Contact phone number": "連絡先電話番号",
      "Enter phone number": "電話番号を入力してください",
      "Date of Birth": "生年月日",
      "Date of birth": "生年月日",
      "Gender": "性別",
      "Select gender": "性別を選択してください",
      "Representing country": "代表国",
      "Select country": "国を選択してください",
      "City": "市",
      "Select role(s)": "役割を選択してください",
      "Address": "住所",
      "Enter address": "住所を入力してください",
      "Parent found": "親が見つかりました",
      "Parent not found": "親が見つかりません",
      "Removed coach": "コーチを削除しました",
      "Failed to removed coach": "コーチの削除に失敗しました",
      "Coach created": "コーチが作成しました",
      "Failed to create coach": "コーチの作成に失敗しました",
      "Club": "クラブ",
      "Save": "保存",
      "Select club": "クラブを選択してください",
      "Coach": "コーチ",
      "Select a coach": "コーチを選択してください",
      "Parent": "親",
      "Enter email": "メールアドレスを入力してください",
      "Biography": "バイオグラフィー",
      "Write a bio": "略歴を書く",
      "CHECKBOXES": "チェックボックス",
      "Give access to coach to purchase licences and manage entries on your behalf": "Coach にアクセスしてライセンスを購入し、ユーザーに代わってエントリーを管理できるようにします。",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf": "あなたに代わってライセンスを購入し、エントリーを管理するためのアクセス権をクラブマネージャー(-es)に付与します。",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf": "あなたに代わってライセンスを購入し、エントリを管理するためのアクセス権を親に付与します",
      "Socials": "ソーシャル",
      "Insert link here": "ここにリンクを挿入",
      "Facebook": "フェイスブック",
      "Instagram": "インスタグラム",
      "Linkedin": "リンクトイン",
      "Tiktok": "ティックトック",
      "Youtube": "ユーチューブ",
      "Enter Id": "IDを入力してください",
      "Password should be 8 chars minimum.": "パスワードは 8 文字以上にする必要があります。",
      "Passwords must match": "パスワードが一致する必要があります",
      "Password changed successfully": "パスワードが正常に変更されました",
      "New Password": "新しいパスワード",
      "Re-Enter": "パスワードを認証する",
      "Name is Required": "名前は必須です",
      "Surname is Required": "姓は必須です",
      "Re enter password": "パスワード再入力",
      "User is already registered": "ユーザーはすでに登録されています",
      "Create an account": "アカウントを作成する",
      "Already have an account?": "すでにアカウントをお持ちですか?",
      "Log in": "ログイン",
      "or create account": "またはアカウントを作成する",
      "I'm creating an account for a kid": "子供用のアカウントを作成しています",
      "sign-up description": "トーナメント参加者のアカウントを作成しています。",
      "Email Address": "電子メールアドレス",
      "Continue": "続く",
      "Pick a sport": "スポーツを選ぶ",
      "Pick a sport that you play": "プレイするスポーツを選択してください",
      "Gender is required": "性別は必須です",
      "Phone is required": "電話番号は必須です",
      "Citizenship is required": "国は必須です",
      "Dob is required": "ドブは必須です",
      "FIDE ID Not found": "FIDE ID が見つかりません",
      "FIDE ID found": "FIDE ID が見つかりました",
      "Tell us more about you": "あなたについてもっと詳しく教えてください",
      "More information about yourself": "あなた自身についての詳しい情報",
      "Enter FIDE ID": "FIDE IDを入力してください",
      "IPIN ID is correct": "IPIN IDは正しいです",
      "Your Licenses": "あなたのライセンス",
      "Attach your licenses": "ライセンスを添付する",
      "Skip": "スキップ",
      "User registered successfully": "ユーザーが正常に登録されました",
      "What is your primary role in the world of sports?": "スポーツの世界でのあなたの主な役割は何ですか?",
      "Register & Go to profile": "登録してプロフィールに移動",
      "PUBLISHED ON": "公開日",
      "Federation member": "連盟会員",
      "Club owner": "クラブオーナー",
      "Club manager": "クラブマネージャー",
      "Referee": "審判",
      "Tournament organizer": "大会主催者",
      "Sport fan": "スポーツファン",
      "Other": "他の",
      "PAY": "支払う",
      "1 Year": "1年",
      "2 Years": "2年",
      "3 Years": "3年",
      "4 Years": "4年",
      "Registration time": "登録時間",
      "In progress / Upcoming": "進行中 / 今後の予定",
      "Matches won": "勝った試合数",
      "In progress / upcoming entries": "進行中/今後のエントリー",
      "In progress / upcoming tournaments": "進行中/今後のトーナメント",
      "Past tournaments": "過去の大会",
      "Past entries": "過去のエントリー",
      "Your password must be different to previous passwords": "パスワードは以前のパスワードとは異なる必要があります",
      "less than a minute": "1分未満",
      "read": "読む",
      "minute": "分",
      "Select Licence": "ライセンスの選択",
      "Start time": "開始時間",
      "Try again": "もう一度やり直してください",
      "maintenanceMessage": "現在プラットフォームを更新中です。これには数分かかる場合があります。",
      "networkErrorMessage": "ネットワーク接続を確認して、もう一度試してください。",
      "errorTitle": "おっと！",
      "View List": "リストを見る",
      "Free": "無料",
      "Primary Sport": "プライマリースポーツ",
      "Role(s)": "役割",
      "Menu": "メニュー",
      "Calendar": "カレンダー",
      "Manage tournament": "トーナメントの管理",
      "Partner search": "パートナー検索",
      "Partner Search": "パートナー検索",
      "Searching...": "検索中...",
      "Apply": "適用する",
      "Delete": "消去",
      "Create application": "アプリケーションの作成",
      "No partner search applications found": "パートナー検索アプリケーションが見つかりませんでした",
      "Log in to apply": "ログインして応募してください",
      "Player": "プレーヤー",
      "Time of registration": "登録時間",
      "confirmed": "確認済み",
      "declined": "断った",
      "payment pending": "支払いが保留中",
      "WITHDRAW": "撤回する",
      "No other tournament found": "他のトーナメントは見つかりませんでした",
      "welcomeTextCustom": "Tournated は、スポーツ組織が運営を効率的に管理、成長させ、収益化できるようにするオールインワン スポーツ管理ソフトウェアである Tournated を利用しています。",
      "welcomeText": "5 年間にわたる研究開発と 200 を超える組織とのコラボレーションの結果、あらゆる規模のスポーツ組織向けの、革新的でカスタマイズ可能、ユーザーフレンドリー、そして手頃な価格のオールインワン ソリューションが誕生しました。",
      "Did you have an account in one of ": "次のいずれかのアカウントをお持ちですか ",
      "those": "それらの",
      "platforms?": "プラットフォーム？",
      "Recover password": "パスワードを回復する",
      "newUser": "私は新しいユーザーです。",
      "Continue as a guest": "ゲストとして続行",
      "Support": "サポート",
      "Welcome to": "へようこそ",
      "If you have an account on one of ": "いずれかのアカウントをお持ちの場合 ",
      "these": "これら",
      "platforms, log in with your e-mail or create a new profile": "プラットフォームでは、電子メールでログインするか、新しいプロファイルを作成してください",
      "Reset password": "パスワードをリセットする",
      "Welcome to the": "へようこそ",
      "platform": "プラットフォーム",
      "Powered by": "搭載",
      "All Categories": "すべてのカテゴリ",
      "Tournament Results": "大会結果",
      "versionText": "ベータ版はライブ中です。",
      "here": "ここ",
      "Full view": "全景",
      "Timeline": "タイムライン",
      "Grid": "グリッド",
      "Bird's View": "鳥の眺め",
      "Third Party Licences": "サードパーティライセンス",
      "Friend": "友達",
      "Step 2": "ステップ 2:",
      "Step 3": "ステップ 3:",
      "Your Details": "あなたの詳細",
      "Partner Details": "パートナーの詳細",
      "Enter partner email": "パートナーのメールアドレスを入力してください",
      "Register & Pay": "「登録して支払う」",
      "On-Site": "現場で",
      "Now": "今"
    }
  },
  'zh-CN': {
    translation: {
      "header": {
        "discover": "发现",
        "enterName": "输入姓名",
        "sportsManager": "体育经理？",
        "learnHow": "了解如何",
        "accelerate": "我们可以加速您的组织。",
        "powering": "供电",
        "tournaments": "锦标赛,",
        "leagues": "联赛",
        "organizations": "组织",
        "clubs": "俱乐部",
        "coaches": "教练",
        "placeholder": {
          "tournaments": "输入比赛名称",
          "leagues": "输入联赛名称",
          "organizations": "输入组织名称",
          "clubs": "输入俱乐部名称",
          "coaches": "输入教练姓名"
        }
      },
      "registrationOpen": "开放报名",
      "read_more": "阅读更多",
      "registrationClosed": "登记已截止",
      "live": "居住",
      "Close": "关闭",
      "bannerHeading": "开始完全免费管理您的锦标赛",
      "bannerDescription": "从条目管理到自动生成分组、抽签和比赛顺序、结果管理等等",
      "startNow": "现在开始",
      "featureTournaments": "特色赛事",
      "tournamentNotFound": "未找到特色赛事",
      "viewAll": "查看全部",
      "featureLeague": "特色联赛",
      "leaguesNotFound": "未找到特色联赛",
      "Tournaments": "锦标赛",
      "NoTournamentsFound": "没有找到比赛",
      "Rankings": "排行榜",
      "noRankingFound": "没有找到排名",
      "news": "消息",
      "noNewsFound": "没有找到新闻",
      "partners": "我们的合作伙伴",
      "Partners": "合作伙伴",
      "noPartnersFound": "未找到合作伙伴",
      "worldwide": "全世界",
      "search": "搜索",
      "pricing": "定价",
      "loginRegister": "登录/注册",
      "home": "家",
      "organizations": "组织机构",
      "leagues": "联赛",
      "teams": "团队",
      "clubs": "俱乐部",
      "coaches": "教练",
      "tennis": "网球",
      "beachTenis": "沙滩网球",
      "Beach Volley": "沙滩排球",
      "Sport Fishing": "运动钓鱼",
      "Volleyball": "排球",
      "Sports": "运动的",
      "RESOURSES": "资源",
      "Feature": "特征",
      "Create account": "创建账户",
      "Login": "登录",
      "Packages": "套餐",
      "Package one": "套餐一",
      "Package two": "套餐二",
      "Package three": "套餐三",
      "Package Four": "套餐四",
      "Customer suppor": "客户支持",
      "FAQ": "常问问题",
      "Documentation": "文档",
      "Video tutorials": "视频教程",
      "Submit ticket": "提交票证",
      "Contact us": "联系我们",
      "Our contacts": "我们的联系方式",
      "Padel": "帕德尔",
      "Pickleball": "匹克球",
      "FEATURED LEAGUES": "特色联赛",
      "View": "看法",
      "No leagues found": "没有找到联赛",
      "Athletes": "运动员",
      "Managed by": "管理人",
      "View League": "查看联赛",
      "Type": "类型",
      "National": "国家的",
      "International": "国际的",
      "Local": "当地的",
      "Period": "时期",
      "League": "联盟",
      "Country": "国家",
      "Year": "年",
      "Points": "积分",
      "Position": "位置",
      "Team": "团队",
      "No teams found": "没有找到队伍",
      "Team Members": "团队成员",
      "Sport": "运动",
      "No clubs found": "没有找到俱乐部",
      "Experience": "经验",
      "Students": "学生",
      "Author": "作者",
      "Category": "类别",
      "My Entries": "我的参赛作品",
      "My Leagues": "我的联赛",
      "My Licences": "我的许可证",
      "My Teams": "我的团队",
      "Get Licence": "获得许可证",
      "demo": "演示",
      "Settings": "设置",
      "Log out": "退出",
      "Dark view": "黑暗视野",
      "Manager": "经理",
      "Tournament": "比赛",
      "Cost": "成本",
      "Duration": "期间",
      "Purchase on": "购买于",
      "No license found": "未找到许可证",
      "Additional": "额外的",
      "Status": "地位",
      "Date": "日期",
      "No entries found": "没有找到条目",
      "My Licenses": "我的许可证",
      "Add new License": "添加新许可证",
      "Create Team": "创建团队",
      "Info": "信息",
      "infoDes": "要参加某些联赛/巡回赛，球员必须拥有相应的有效执照。",
      "Select League": "选择联赛",
      "Select License": "选择许可证",
      "Promo code": "促销代码",
      "I agree to the": "我同意",
      "Terms and Conditions": "条款和条件",
      "Price": "价格",
      "Confirm Payment": "确认付款",
      "History": "历史",
      "Tournated Manager": "巡回赛经理",
      "Tournated Custom": "巡回定制",
      "Customer support": "客户支持",
      "Categories": "类别",
      "Matches": "火柴",
      "Organized by": "主办单位：",
      "Register": "登记",
      "Entries": "参赛作品",
      "Location": "地点",
      "National Tour": "全国巡演",
      "4 mins read": "4 分钟阅读",
      "Age group": "年龄组",
      "Skills level": "技能等级",
      "someDes": "SIA Vertexo © 2020。保留所有权利",
      "Manage in CMS": "在 CMS 中管理",
      "Fact Sheet": "情况说明书",
      "General": "一般的",
      "Participants": "参加者",
      "Groups": "团体",
      "Draws": "抽奖",
      "Live Standings": "实时排名",
      "Order of play": "比赛顺序",
      "Pairings": "配对",
      "Standings": "积分榜",
      "Final standings": "最终排名",
      "List & Results": "列表与结果",
      "Entry Fee": "报名费",
      "Entry Deadline": "报名截止日期",
      "Entry deadline": "报名截止日期",
      "Withdrawal deadline": "提款期限",
      "Start date": "开始日期",
      "End date": "结束日期",
      "and": "和",
      "others": "其他的",
      "are confirmed": "已确认",
      "Description": "描述",
      "Tournament Poster": "比赛海报",
      "More Info": "更多信息",
      "Additional materials about tournament": "有关锦标赛的附加材料",
      "Regulations": "法规",
      "Tournaments Discord": "锦标赛不和谐",
      "discordDes": "直接与锦标赛管理员交谈，提出问题并与其他玩家交谈！",
      "Join Channel": "加入频道",
      "joinTournament": "参加锦标赛",
      "Fact sheet": "情况说明书",
      "Search hotels and more": "搜索酒店及更多...",
      "Destination": "目的地",
      "Select Destination": "选择目的地",
      "Check in date": "入住日期",
      "Check out date": "退房日期",
      "Best players on this tournament": "本次比赛的最佳选手",
      "Gallery": "画廊",
      "Recent photos": "最近的照片",
      "Buy licence": "购买许可证",
      "Select All": "选择全部",
      "Don't see your sport? Contact us": "没看到你的运动项目？",
      "Description is missing": "缺少说明",
      "day": "天",
      "days": "天",
      "Organization": "组织",
      "Athlete": "运动员",
      "Birth year": "出生年份",
      "Joined": "已加入",
      "Select Sport": "选择运动",
      "Select organization": "选择组织",
      "Select league": "选择联赛",
      "Overview": "概述",
      "About": "关于",
      "Win rate": "胜率",
      "Achievements": "成就",
      "Upcoming Matches": "即将举行的比赛",
      "Past Matches": "过去的比赛",
      "Upcoming Tournaments": "即将举行的比赛",
      "Purchase history": "购买记录",
      "description and detail": "改进：获得特定联赛或锦标赛参与者所需的许可证/会员资格",
      "Purchase datetime": "购买日期时间",
      "Payment Status": "付款状态",
      "Licence": "执照",
      "Members": "会员",
      "Student": "学生",
      "Personal page": "个人页面",
      "My Career": "我的职业生涯",
      "Latest tournaments": "最新赛事",
      "Upcoming tournaments": "即将举行的比赛",
      "No members found": "没有找到会员",
      "No tournaments found": "没有找到比赛",
      "No matches found": "未找到匹配项",
      "No rankings found": "没有找到排名",
      "No past matches found": "未找到过去的匹配项",
      "No upcoming matches found": "未找到即将进行的比赛",
      "No achievements found": "没有找到成就",
      "Documents": "文件",
      "Contact": "接触",
      "No photos added": "没有添加照片",
      "Manage league": "管理联赛",
      "No documents added": "没有添加文档",
      "Email": "电子邮件",
      "Phone": "电话",
      "Website": "网站",
      "Place": "地方",
      "Tournament Name": "赛事名称",
      "Manage organization": "管理组织",
      "Terms & Conditions": "条款及条件",
      "LEGAL": "合法的",
      "Privacy policy": "隐私政策",
      "Cancellation policy": "取消政策",
      "GDPR": "通用数据保护条例",
      "All Rights Reserved": "版权所有",
      "League [Category]": "联赛[类别]",
      "Other tournaments": "其他赛事",
      "Points Details": "积分详情",
      "International rating": "国际评级",
      "Day": "天",
      "Days": "天",
      "Tennis": "网球",
      "Beach Tennis": "沙滩网球",
      "Beach Volleyball": "沙滩排球",
      "Chess": "棋",
      "In progress": "进行中",
      "Registration": "登记",
      "Deadline past": "截止日期已过",
      "Completed": "完全的",
      "No points yet": "还没有积分",
      "is confirmed": "已确认",
      "Contacts": "联系方式",
      "Organizer": "组织者",
      "Featured athletes": "特色运动员",
      "Top-ranked participants of the tournament": "锦标赛排名靠前的参赛者",
      "NR": "NR",
      "PLAYER": "玩家",
      "CITY": "城市",
      "RATING": "等级",
      "CLUB": "俱乐部",
      "TOTAL": "全部的",
      "SEGMENT": "部分",
      "SEED": "种子",
      "Search": "搜索",
      "No participants found": "未找到参与者",
      "Total": "全部的",
      "Seed": "种子",
      "Rating": "等级",
      "Rank": "秩",
      "Category:": "类别：",
      "No category found": "没有找到类别",
      "No Rounds found": "未找到回合",
      "White": "白色的",
      "Black": "黑色的",
      "Name": "姓名",
      "Pts": "分",
      "Score": "分数",
      "There is no available pairings!": "没有可用的配对！",
      "Generating PDF...": "正在生成 PDF...",
      "PDF Generated Successfully!": "PDF生成成功！",
      "PDF Downloaded Successfully!": "PDF下载成功！",
      "Downloading...": "正在下载...",
      "Pairing Standings": "配对排名",
      "Opponents": "对手",
      "BHC1": "六六六",
      "BHC": "六六六",
      "BGP": "边界网关协议",
      "WIN": "赢",
      "There is no available entries!": "没有可用的条目！",
      "I'm a coach/parent": "我是一名教练/家长",
      "Step 1": "步骤1",
      "Select category": "选择类别",
      "Request": "要求",
      "Register & Pay Now": "立即注册并付款",
      "Register & Pay On-Site": "现场报名并缴费",
      "Terms And Conditions": "条款和条件",
      "Latest Winners": "最新获奖者",
      "No winners found": "未找到获奖者",
      "No news found": "没有找到新闻",
      "No partners found": "未找到合作伙伴",
      "Skills Levels": "技能水平",
      "To get register for the tournament you must have active tour licence.": "要注册参加锦标赛，您必须拥有有效的巡回赛许可证。",
      "Contact Details": "联系方式",
      "Our Partners": "我们的合作伙伴",
      "View all": "查看全部",
      "Member": "成员",
      "Team Name": "团队名称",
      "News": "消息",
      "Men Pro": "男士专业版",
      "Latvia Tennis Union League": "拉脱维亚网球联盟联赛",
      "Leagues": "联赛",
      "No league found": "没有找到联赛",
      "Home Page": "主页",
      "Upcoming": "即将推出",
      "This month": "本月",
      "Last 7 days": "过去 7 天",
      "Last 30 days": "过去 30 天",
      "Hours": "时间",
      "Minutes": "分钟",
      "Teams": "团队",
      "No student found": "没有找到学生",
      "Other news": "其他新闻",
      "Don’t have an account?": "没有帐户？",
      "Sign up": "报名",
      "or log in": "或登录",
      "Did you forget your password?": "您忘记密码了吗？",
      "Must be a valid email": "必须是有效的电子邮件",
      "Email is required": "电子邮件为必填项",
      "Password is required": "需要密码",
      "Password": "密码",
      "Remember me": "记住账号",
      "Log In": "登录",
      "Unauthorized , please sign in": "未授权，请登录",
      "Reset your password": "重置您的密码",
      "Enter the e-mail address": "输入电子邮件地址",
      "Enter your e-mail address with which you are registered": "输入您注册时使用的电子邮件地址",
      "Please check your email for reset password link!": "请检查您的电子邮件以获取重置密码链接！",
      "Send instructions": "发送指令",
      "My Licenes": "我的许可证",
      "My leagues": "我的联赛",
      "My tournaments": "我的比赛",
      "My matches": "我的比赛",
      "My entries": "我的参赛作品",
      "My rankings": "我的排名",
      "Stats": "统计数据",
      "Leagues took part in": "联赛参加过",
      "Active licences": "有效许可证",
      "Titles": "标题",
      "Matches played": "比赛次数",
      "Matches lost": "输掉的比赛",
      "Games/points played": "比赛/得分",
      "All sports": "所有运动",
      "All organizations": "所有组织",
      "All leagues": "所有联赛",
      "Registered": "挂号的",
      "Withdraw": "提取",
      "Withdraw deadline passed": "提款期限已过",
      "Request Withdrawn": "请求撤回",
      "Something went wrong!": "出了问题！",
      "Withdrawal": "提款",
      "Entry to withdraw": "进入退出",
      "Withdrawal Reason": "提款原因",
      "Write your reason here": "在这里写下你的理由",
      "Confirm": "确认",
      "Please specify reason": "请注明原因",
      "AUDI - The Beach Tennis Championship": "奥迪 - 沙滩网球锦标赛",
      "Get licence": "获得许可证",
      "Additional questions": "其他问题",
      "Select duration": "选择持续时间",
      "Career": "职业",
      "Third Party Licenes": "第三方许可证",
      "User updated successfully": "用户更新成功",
      "User Account": "用户帐户",
      "We recommend an image that is 256 x 256 pixels": "我们建议使用 256 x 256 像素的图像",
      "Enter Email Address": "输入电子邮件地址",
      "Enter name": "输入姓名",
      "Surname": "姓",
      "Enter surname": "输入姓氏",
      "Contact phone number": "联系电话",
      "Enter phone number": "输入电话号码",
      "Date of Birth": "出生日期",
      "Date of birth": "出生日期",
      "Gender": "性别",
      "Select gender": "选择性别",
      "Representing country": "代表国家",
      "Select country": "选择国家",
      "City": "城市",
      "Select role(s)": "选择角色",
      "Address": "地址",
      "Enter address": "输入地址",
      "Parent found": "找到家长",
      "Parent not found": "未找到父级",
      "Removed coach": "删除了教练",
      "Failed to removed coach": "删除教练失败",
      "Coach created": "教练已创建",
      "Failed to create coach": "创建教练失败",
      "Club": "俱乐部",
      "Save": "节省",
      "Select club": "选择俱乐部",
      "Coach": "教练",
      "Select a coach": "选择教练",
      "Parent": "家长",
      "Enter email": "输入电子邮件",
      "Biography": "传",
      "Write a bio": "写简历",
      "CHECKBOXES": "复选框",
      "Give access to coach to purchase licences and manage entries on your behalf": "授予教练代表您购买许可证和管理参赛作品的权限",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf": "授予俱乐部经理代表您购买许可证和管理参赛作品的权限",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf": "允许家长代表您购买许可证并管理条目",
      "Socials": "社交",
      "Insert link here": "在此插入链接",
      "Facebook": "Facebook",
      "Instagram": "Instagram",
      "Linkedin": "领英",
      "Tiktok": "抖音",
      "Youtube": "YouTube",
      "Enter Id": "输入身份证号",
      "Password should be 8 chars minimum.": "密码至少应为 8 个字符。",
      "Passwords must match": "密码必须匹配",
      "Password changed successfully": "密码修改成功",
      "New Password": "新密码",
      "Re-Enter": "确认密码",
      "Name is Required": "姓名为必填项",
      "Surname is Required": "姓氏为必填项",
      "Re enter password": "重新输入密码",
      "User is already registered": "用户已注册",
      "Create an account": "创建一个帐户",
      "Already have an account?": "已经有帐户？",
      "Log in": "登录",
      "or create account": "或创建帐户",
      "I'm creating an account for a kid": "我正在为孩子创建一个帐户",
      "sign-up description": "您正在为锦标赛参与者创建一个帐户。",
      "Email Address": "电子邮件",
      "Continue": "继续",
      "Pick a sport": "选择一项运动",
      "Pick a sport that you play": "选择您喜欢的运动",
      "Gender is required": "性别为必填项",
      "Phone is required": "电话为必填项",
      "Citizenship is required": "国家/地区为必填项",
      "Dob is required": "需要多布",
      "FIDE ID Not found": "国际棋联 ID 未找到",
      "FIDE ID found": "已找到国际棋联 ID",
      "Tell us more about you": "告诉我们更多关于您的信息",
      "More information about yourself": "有关您自己的更多信息",
      "Enter FIDE ID": "输入国际棋联 ID",
      "IPIN ID is correct": "IPIN ID 正确",
      "Your Licenses": "您的许可",
      "Attach your licenses": "附上您的许可证",
      "Skip": "跳过",
      "User registered successfully": "用户注册成功",
      "What is your primary role in the world of sports?": "您在体育界的主要角色是什么？",
      "Register & Go to profile": "注册并转到个人资料",
      "PUBLISHED ON": "发表于",
      "Federation member": "联合会会员",
      "Club owner": "俱乐部老板",
      "Club manager": "俱乐部经理",
      "Referee": "裁判",
      "Tournament organizer": "赛事主办方",
      "Sport fan": "体育迷",
      "Other": "其他",
      "PAY": "支付",
      "1 Year": "1年",
      "2 Years": "2年",
      "3 Years": "3年",
      "4 Years": "4年",
      "Registration time": "报名时间",
      "In progress / Upcoming": "进行中/即将进行",
      "Matches won": "赢得比赛",
      "In progress / upcoming entries": "正在进行中/即将推出的条目",
      "In progress / upcoming tournaments": "进行中/即将举行的比赛",
      "Past tournaments": "过去的比赛",
      "Past entries": "过去的条目",
      "Your password must be different to previous passwords": "您的密码必须与之前的密码不同",
      "less than a minute": "不到一分钟",
      "read": "读",
      "minute": "分钟",
      "Select Licence": "选择许可证",
      "Start time": "开始时间",
      "Try again": "再试一次",
      "maintenanceMessage": "我们目前正在更新平台，这可能需要几分钟的时间。",
      "networkErrorMessage": "请检查您的网络连接并重试。",
      "errorTitle": "哎呀！",
      "View List": "查看列表",
      "Free": "自由的",
      "Primary Sport": "主要运动",
      "Role(s)": "角色",
      "Menu": "菜单",
      "Calendar": "日历",
      "Manage tournament": "管理锦标赛",
      "Partner search": "合作伙伴搜索",
      "Partner Search": "合作伙伴搜索",
      "Searching...": "正在寻找...",
      "Apply": "申请",
      "Delete": "删除",
      "Create application": "创建应用程序",
      "No partner search applications found": "未找到合作伙伴搜索应用程序",
      "Log in to apply": "登录申请",
      "Player": "玩家",
      "Time of registration": "报名时间",
      "confirmed": "确认的",
      "declined": "拒绝",
      "payment pending": "待付款",
      "WITHDRAW": "提取",
      "No other tournament found": "没有找到其他比赛",
      "welcomeTextCustom": "由 Tournated 提供支持，Tournated 是一款一体化体育管理软件，使体育组织能够有效地管理、发展其运营并实现盈利。",
      "welcomeText": "经过五年研发以及与 200 多家组织合作的成果，是一款创新、可定制、用户友好且价格实惠的一体化解决方案，适用于任何规模的体育组织",
      "Did you have an account in one of ": "您是否在其中之一拥有帐户 ",
      "those": "那些",
      "platforms?": "平台？",
      "Recover password": "恢复密码",
      "newUser": "我是新用户。",
      "Continue as a guest": "继续以访客身份",
      "Support": "支持",
      "Welcome to": "欢迎来到",
      "If you have an account on one of ": "如果您有其中之一的帐户 ",
      "these": "这些",
      "platforms, log in with your e-mail or create a new profile": "平台，使用您的电子邮件登录或创建新的个人资料",
      "Reset password": "重置密码",
      "Welcome to the": "欢迎来到",
      "platform": "平台",
      "Powered by": "供电",
      "All Categories": "所有类别",
      "Tournament Results": "比赛结果",
      "versionText": "测试版已上线。",
      "here": "这里",
      "Full view": "全视图",
      "Timeline": "时间轴",
      "Grid": "网格",
      "Bird's View": "鸟瞰图",
      "Third Party Licences": "第三方许可证",
      "Friend": "朋友",
      "Step 2": "步骤2：",
      "Step 3": "步骤3：",
      "Your Details": "您的详细信息",
      "Partner Details": "合作伙伴详情",
      "Enter partner email": "输入合作伙伴电子邮件",
      "Register & Pay": "`注册并付款`",
      "On-Site": "现场",
      "Now": "现在"
    },
  },
  'vi-VN': {
    translation: {
      "header": {
        "discover": "Phát hiện",
        "enterName": "Nhập tên",
        "sportsManager": "Giám đốc thể thao?",
        "learnHow": "Tìm hiểu cách",
        "accelerate": "chúng tôi có thể tăng tốc tổ chức của bạn.",
        "powering": "Cấp nguồn",
        "tournaments": "giải đấu,",
        "leagues": "giải đấu",
        "organizations": "tổ chức",
        "clubs": "câu lạc bộ",
        "coaches": "huấn luyện viên",
        "placeholder": {
          "tournaments": "Nhập tên giải đấu",
          "leagues": "Nhập tên giải đấu",
          "organizations": "Nhập tên tổ chức",
          "clubs": "Nhập tên câu lạc bộ",
          "coaches": "Nhập tên huấn luyện viên"
        }
      },
      "registrationOpen": "Đăng ký mở",
      "read_more": "Đọc thêm",
      "registrationClosed": "Đã đóng đăng ký",
      "live": "Sống",
      "Close": "Đóng",
      "bannerHeading": "Bắt đầu quản lý các giải đấu của bạn hoàn toàn miễn phí",
      "bannerDescription": "Từ quản lý mục nhập đến tạo nhóm tự động, rút ​​thăm và thứ tự chơi, quản lý kết quả, v.v.",
      "startNow": "Bắt đầu ngay bây giờ",
      "featureTournaments": "Giải đấu nổi bật",
      "tournamentNotFound": "Không tìm thấy giải đấu nổi bật",
      "viewAll": "Xem tất cả",
      "featureLeague": "Giải đấu nổi bật",
      "leaguesNotFound": "Không tìm thấy giải đấu nổi bật nào",
      "Tournaments": "Giải đấu",
      "NoTournamentsFound": "Không tìm thấy giải đấu nào",
      "Rankings": "Xếp hạng",
      "noRankingFound": "Không tìm thấy thứ hạng nào",
      "news": "Tin tức",
      "noNewsFound": "Không tìm thấy tin tức nào",
      "partners": "Đối tác của chúng tôi",
      "Partners": "Đối tác",
      "noPartnersFound": "Không tìm thấy đối tác nào",
      "worldwide": "Trên toàn thế giới",
      "search": "Tìm kiếm",
      "pricing": "Định giá",
      "loginRegister": "Đăng nhập / Đăng ký",
      "home": "Trang chủ",
      "organizations": "Tổ chức",
      "leagues": "Giải đấu",
      "teams": "Đội",
      "clubs": "Câu lạc bộ",
      "coaches": "Huấn luyện viên",
      "tennis": "quần vợt",
      "beachTenis": "Quần vợt bãi biển",
      "Beach Volley": "Bóng chuyền bãi biển",
      "Sport Fishing": "Câu cá thể thao",
      "Volleyball": "Bóng chuyền",
      "Sports": "Thể thao",
      "RESOURSES": "NGUỒN LỰC",
      "Feature": "Tính năng",
      "Create account": "Tạo tài khoản",
      "Login": "Đăng nhập",
      "Packages": "Gói",
      "Package one": "Gói một",
      "Package two": "Gói hai",
      "Package three": "Gói ba",
      "Package Four": "Gói 4",
      "Customer suppor": "Hỗ trợ khách hàng",
      "FAQ": "Câu hỏi thường gặp",
      "Documentation": "Tài liệu",
      "Video tutorials": "Video hướng dẫn",
      "Submit ticket": "Gửi vé",
      "Contact us": "Liên hệ với chúng tôi",
      "Our contacts": "Địa chỉ liên hệ của chúng tôi",
      "Padel": "Padel",
      "Pickleball": "dưa muối",
      "FEATURED LEAGUES": "GIẢI ĐẤU NỔI BẬT",
      "View": "Xem",
      "No leagues found": "Không tìm thấy giải đấu nào",
      "Athletes": "vận động viên",
      "Managed by": "Được quản lý bởi",
      "View League": "Xem giải đấu",
      "Type": "Kiểu",
      "National": "Quốc gia",
      "International": "Quốc tế",
      "Local": "Địa phương",
      "Period": "Giai đoạn",
      "League": "Liên minh",
      "Country": "Quốc gia",
      "Year": "Năm",
      "Points": "Điểm",
      "Position": "Chức vụ",
      "Team": "Đội",
      "No teams found": "Không tìm thấy đội nào",
      "Team Members": "Thành viên nhóm",
      "Sport": "Thể thao",
      "No clubs found": "Không tìm thấy câu lạc bộ nào",
      "Experience": "Kinh nghiệm",
      "Students": "Sinh viên",
      "Author": "Tác giả",
      "Category": "Loại",
      "My Entries": "Bài viết của tôi",
      "My Leagues": "Giải đấu của tôi",
      "My Licences": "Giấy phép của tôi",
      "My Teams": "Đội của tôi",
      "Get Licence": "Nhận giấy phép",
      "demo": "thử nghiệm",
      "Settings": "Cài đặt",
      "Log out": "Đăng xuất",
      "Dark view": "Chế độ xem tối",
      "Manager": "Giám đốc",
      "Tournament": "Giải đấu",
      "Cost": "Trị giá",
      "Duration": "Khoảng thời gian",
      "Purchase on": "Mua trên",
      "No license found": "Không tìm thấy giấy phép",
      "Additional": "Thêm vào",
      "Status": "Trạng thái",
      "Date": "Ngày",
      "No entries found": "Không tìm thấy mục nào",
      "My Licenses": "Giấy phép của tôi",
      "Add new License": "Thêm giấy phép mới",
      "Create Team": "Tạo nhóm",
      "Info": "Thông tin",
      "infoDes": "Để tham gia một số giải đấu / giải đấu nhất định, người chơi phải có giấy phép hoạt động tương ứng.",
      "Select League": "Chọn giải đấu",
      "Select License": "Chọn giấy phép",
      "Promo code": "Mã khuyến mãi",
      "I agree to the": "Tôi đồng ý với",
      "Terms and Conditions": "Điều khoản và Điều kiện",
      "Price": "Giá",
      "Confirm Payment": "Xác nhận thanh toán",
      "History": "Lịch sử",
      "Tournated Manager": "Người quản lý đã tham gia",
      "Tournated Custom": "Tùy chỉnh giải đấu",
      "Customer support": "HỖ TRỢ KHÁCH HÀNG",
      "Categories": "Thể loại",
      "Matches": "Trận đấu",
      "Organized by": "Được tổ chức bởi",
      "Register": "Đăng ký",
      "Entries": "Bài dự thi",
      "Location": "Vị trí",
      "National Tour": "Tour du lịch quốc gia",
      "4 mins read": "4 phút đọc",
      "Age group": "Nhóm tuổi",
      "Skills level": "Cấp độ kỹ năng",
      "someDes": "© 2020 SIA Vertexo. Đã đăng ký Bản quyền",
      "Manage in CMS": "Quản lý trong CMS",
      "Fact Sheet": "Tờ thông tin",
      "General": "Tổng quan",
      "Participants": "Người tham gia",
      "Groups": "Nhóm",
      "Draws": "Hòa",
      "Live Standings": "Bảng xếp hạng trực tiếp",
      "Order of play": "Thứ tự chơi",
      "Pairings": "Cặp đôi",
      "Standings": "Bảng xếp hạng",
      "Final standings": "Bảng xếp hạng cuối cùng",
      "List & Results": "Danh sách & Kết quả",
      "Entry Fee": "Phí vào cửa",
      "Entry Deadline": "Hạn chót nhập học",
      "Entry deadline": "Hạn chót nhập học",
      "Withdrawal deadline": "Thời hạn rút tiền",
      "Start date": "Ngày bắt đầu",
      "End date": "Ngày kết thúc",
      "and": "Và",
      "others": "người khác",
      "are confirmed": "được xác nhận",
      "Description": "Sự miêu tả",
      "Tournament Poster": "Áp phích giải đấu",
      "More Info": "Thêm thông tin",
      "Additional materials about tournament": "Tài liệu bổ sung về giải đấu",
      "Regulations": "Quy định",
      "Tournaments Discord": "Giải đấu Bất hòa",
      "discordDes": "Nói chuyện trực tiếp với quản trị viên giải đấu, đặt câu hỏi và nói chuyện với những người chơi khác!",
      "Join Channel": "Tham gia kênh",
      "joinTournament": "Tham gia giải đấu",
      "Fact sheet": "Tờ thông tin",
      "Search hotels and more": "Tìm kiếm khách sạn và hơn thế nữa...",
      "Destination": "Điểm đến",
      "Select Destination": "Chọn điểm đến",
      "Check in date": "Ngày nhận phòng",
      "Check out date": "Ngày trả phòng",
      "Best players on this tournament": "Cầu thủ xuất sắc nhất giải đấu này",
      "Gallery": "Phòng trưng bày",
      "Recent photos": "Ảnh gần đây",
      "Buy licence": "Mua giấy phép",
      "Select All": "Chọn tất cả",
      "Don't see your sport? Contact us": "Bạn không thấy môn thể thao của mình? ",
      "Description is missing": "Thiếu mô tả",
      "day": "ngày",
      "days": "ngày",
      "Organization": "Tổ chức",
      "Athlete": "Vận động viên",
      "Birth year": "Năm sinh",
      "Joined": "Đã tham gia",
      "Select Sport": "Chọn môn thể thao",
      "Select organization": "Chọn tổ chức",
      "Select league": "Chọn giải đấu",
      "Overview": "Tổng quan",
      "About": "Về",
      "Win rate": "Tỷ lệ thắng",
      "Achievements": "Thành tích",
      "Upcoming Matches": "Các trận đấu sắp tới",
      "Past Matches": "Các trận đấu trước đây",
      "Upcoming Tournaments": "Giải đấu sắp tới",
      "Purchase history": "Lịch sử mua hàng",
      "description and detail": "Cải thiện: Nhận giấy phép / tư cách thành viên cần thiết cho người tham gia các giải đấu hoặc giải đấu cụ thể",
      "Purchase datetime": "Ngày mua hàng",
      "Payment Status": "Trạng thái thanh toán",
      "Licence": "Giấy phép",
      "Members": "Thành viên",
      "Student": "Học sinh",
      "Personal page": "Trang cá nhân",
      "My Career": "Sự nghiệp của tôi",
      "Latest tournaments": "Giải đấu mới nhất",
      "Upcoming tournaments": "Các giải đấu sắp tới",
      "No members found": "Không tìm thấy thành viên nào",
      "No tournaments found": "Không tìm thấy giải đấu nào",
      "No matches found": "Không tìm thấy kết quả phù hợp",
      "No rankings found": "Không tìm thấy thứ hạng nào",
      "No past matches found": "Không tìm thấy kết quả phù hợp trước đây",
      "No upcoming matches found": "Không tìm thấy trận đấu sắp tới",
      "No achievements found": "Không tìm thấy thành tích nào",
      "Documents": "Tài liệu",
      "Contact": "Liên hệ",
      "No photos added": "Không có ảnh nào được thêm vào",
      "Manage league": "Quản lý giải đấu",
      "No documents added": "Không có tài liệu nào được thêm vào",
      "Email": "E-mail",
      "Phone": "Điện thoại",
      "Website": "Trang web",
      "Place": "Địa điểm",
      "Tournament Name": "Tên giải đấu",
      "Manage organization": "Quản lý tổ chức",
      "Terms & Conditions": "Điều khoản & Điều kiện",
      "LEGAL": "HỢP PHÁP",
      "Privacy policy": "Chính sách bảo mật",
      "Cancellation policy": "Chính sách hủy",
      "GDPR": "GDPR",
      "All Rights Reserved": "Đã đăng ký Bản quyền",
      "League [Category]": "Giải đấu [Danh mục]",
      "Other tournaments": "Các giải đấu khác",
      "Points Details": "Chi tiết điểm",
      "International rating": "Xếp hạng quốc tế",
      "Day": "Ngày",
      "Days": "Ngày",
      "Tennis": "quần vợt",
      "Beach Tennis": "Quần vợt bãi biển",
      "Beach Volleyball": "Bóng chuyền bãi biển",
      "Chess": "Cờ vua",
      "In progress": "Đang tiến hành",
      "Registration": "Sự đăng ký",
      "Deadline past": "Quá hạn chót",
      "Completed": "Hoàn thành",
      "No points yet": "Chưa có điểm",
      "is confirmed": "được xác nhận",
      "Contacts": "Danh bạ",
      "Organizer": "Người tổ chức",
      "Featured athletes": "Vận động viên tiêu biểu",
      "Top-ranked participants of the tournament": "Những người tham gia có thứ hạng cao nhất của giải đấu",
      "NR": "NR",
      "PLAYER": "NGƯỜI CHƠI",
      "CITY": "THÀNH PHỐ",
      "RATING": "ĐÁNH GIÁ",
      "CLUB": "CÂU LẠC BỘ",
      "TOTAL": "TỔNG CỘNG",
      "SEGMENT": "PHÂN ĐOẠN",
      "SEED": "HẠT GIỐNG",
      "Search": "Tìm kiếm",
      "No participants found": "Không tìm thấy người tham gia",
      "Total": "Tổng cộng",
      "Seed": "Hạt giống",
      "Rating": "Đánh giá",
      "Rank": "Thứ hạng",
      "Category:": "Loại:",
      "No category found": "Không tìm thấy danh mục nào",
      "No Rounds found": "Không tìm thấy vòng nào",
      "White": "Trắng",
      "Black": "Đen",
      "Name": "Tên",
      "Pts": "Điểm",
      "Score": "Điểm",
      "There is no available pairings!": "Không có cặp nào có sẵn!",
      "Generating PDF...": "Đang tạo PDF...",
      "PDF Generated Successfully!": "PDF được tạo thành công!",
      "PDF Downloaded Successfully!": "Đã tải xuống PDF thành công!",
      "Downloading...": "Đang tải xuống...",
      "Pairing Standings": "Bảng xếp hạng ghép đôi",
      "Opponents": "đối thủ",
      "BHC1": "BHC1",
      "BHC": "BHC",
      "BGP": "BGP",
      "WIN": "THẮNG",
      "There is no available entries!": "Không có mục nào có sẵn!",
      "I'm a coach/parent": "Tôi là huấn luyện viên/phụ huynh",
      "Step 1": "Bước 1",
      "Select category": "Chọn danh mục",
      "Request": "Lời yêu cầu",
      "Register & Pay Now": "Đăng ký và thanh toán ngay",
      "Register & Pay On-Site": "Đăng ký và thanh toán tại chỗ",
      "Terms And Conditions": "Điều khoản và Điều kiện",
      "Latest Winners": "Người chiến thắng mới nhất",
      "No winners found": "Không tìm thấy người chiến thắng",
      "No news found": "Không tìm thấy tin tức nào",
      "No partners found": "Không tìm thấy đối tác nào",
      "Skills Levels": "Cấp độ kỹ năng",
      "To get register for the tournament you must have active tour licence.": "Để đăng ký tham gia giải đấu, bạn phải có giấy phép tham gia hoạt động.",
      "Contact Details": "Chi tiết liên hệ",
      "Our Partners": "Đối tác của chúng tôi",
      "View all": "Xem tất cả",
      "Member": "Thành viên",
      "Team Name": "Tên đội",
      "News": "Tin tức",
      "Men Pro": "Đàn ông chuyên nghiệp",
      "Latvia Tennis Union League": "Liên đoàn quần vợt Latvia",
      "Leagues": "Giải đấu",
      "No league found": "Không tìm thấy giải đấu nào",
      "Home Page": "Trang chủ",
      "Upcoming": "Sắp tới",
      "This month": "Tháng này",
      "Last 7 days": "7 ngày qua",
      "Last 30 days": "30 ngày qua",
      "Hours": "Giờ",
      "Minutes": "Phút",
      "Teams": "Đội",
      "No student found": "Không tìm thấy học sinh nào",
      "Other news": "Tin tức khác",
      "Don’t have an account?": "Bạn chưa có tài khoản?",
      "Sign up": "Đăng ký",
      "or log in": "hoặc đăng nhập",
      "Did you forget your password?": "Bạn đã quên mật khẩu của bạn?",
      "Must be a valid email": "Phải là một email hợp lệ",
      "Email is required": "Email là bắt buộc",
      "Password is required": "Cần có mật khẩu",
      "Password": "Mật khẩu",
      "Remember me": "nhớ tôi",
      "Log In": "Đăng nhập",
      "Unauthorized , please sign in": "Chưa được phép, vui lòng đăng nhập",
      "Reset your password": "Đặt lại mật khẩu của bạn",
      "Enter the e-mail address": "Nhập địa chỉ email",
      "Enter your e-mail address with which you are registered": "Nhập địa chỉ email mà bạn đã đăng ký",
      "Please check your email for reset password link!": "Vui lòng kiểm tra email của bạn để lấy link đặt lại mật khẩu!",
      "Send instructions": "Gửi hướng dẫn",
      "My Licenes": "Giấy phép của tôi",
      "My leagues": "Giải đấu của tôi",
      "My tournaments": "Giải đấu của tôi",
      "My matches": "Trận đấu của tôi",
      "My entries": "Mục nhập của tôi",
      "My rankings": "Thứ hạng của tôi",
      "Stats": "Thống kê",
      "Leagues took part in": "Các giải đấu đã tham gia",
      "Active licences": "Giấy phép đang hoạt động",
      "Titles": "Tiêu đề",
      "Matches played": "Trận đấu đã chơi",
      "Matches lost": "Trận thua",
      "Games/points played": "Trò chơi/điểm đã chơi",
      "All sports": "Tất cả các môn thể thao",
      "All organizations": "Tất cả các tổ chức",
      "All leagues": "Tất cả các giải đấu",
      "Registered": "Đăng ký",
      "Withdraw": "Rút",
      "Withdraw deadline passed": "Thời hạn rút tiền đã qua",
      "Request Withdrawn": "Yêu cầu đã rút lại",
      "Something went wrong!": "Đã xảy ra lỗi!",
      "Withdrawal": "Rút tiền",
      "Entry to withdraw": "Nhập để rút tiền",
      "Withdrawal Reason": "Lý do rút tiền",
      "Write your reason here": "Viết lý do của bạn ở đây",
      "Confirm": "Xác nhận",
      "Please specify reason": "Vui lòng nêu rõ lý do",
      "AUDI - The Beach Tennis Championship": "AUDI - Giải vô địch quần vợt bãi biển",
      "Get licence": "Nhận giấy phép",
      "Additional questions": "Câu hỏi bổ sung",
      "Select duration": "Chọn thời lượng",
      "Career": "Sự nghiệp",
      "Third Party Licenes": "Giấy phép của bên thứ ba",
      "User updated successfully": "Người dùng đã cập nhật thành công",
      "User Account": "Tài khoản người dùng",
      "We recommend an image that is 256 x 256 pixels": "Chúng tôi khuyên dùng hình ảnh có kích thước 256 x 256 pixel",
      "Enter Email Address": "Nhập địa chỉ email",
      "Enter name": "Nhập tên",
      "Surname": "Họ",
      "Enter surname": "Nhập họ",
      "Contact phone number": "Số điện thoại liên hệ",
      "Enter phone number": "Nhập số điện thoại",
      "Date of Birth": "Ngày sinh",
      "Date of birth": "Ngày sinh",
      "Gender": "Giới tính",
      "Select gender": "Chọn giới tính",
      "Representing country": "Nước đại diện",
      "Select country": "Chọn quốc gia",
      "City": "Thành phố",
      "Select role(s)": "Chọn (các) vai trò",
      "Address": "Địa chỉ",
      "Enter address": "Nhập địa chỉ",
      "Parent found": "Đã tìm thấy phụ huynh",
      "Parent not found": "Không tìm thấy phụ huynh",
      "Removed coach": "Huấn luyện viên đã bị xóa",
      "Failed to removed coach": "Không thể xóa huấn luyện viên",
      "Coach created": "Huấn luyện viên đã tạo",
      "Failed to create coach": "Không tạo được huấn luyện viên",
      "Club": "câu lạc bộ",
      "Save": "Cứu",
      "Select club": "Chọn câu lạc bộ",
      "Coach": "Huấn luyện viên",
      "Select a coach": "Chọn huấn luyện viên",
      "Parent": "cha mẹ",
      "Enter email": "Nhập email",
      "Biography": "Tiểu sử",
      "Write a bio": "Viết tiểu sử",
      "CHECKBOXES": "HỘP KIỂM",
      "Give access to coach to purchase licences and manage entries on your behalf": "Cấp quyền truy cập vào huấn luyện viên để mua giấy phép và quản lý các mục thay mặt bạn",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf": "Cấp quyền truy cập cho (-es) người quản lý câu lạc bộ để mua giấy phép và quản lý các mục thay mặt bạn",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf": "Cấp quyền truy cập cho (-) phụ huynh để mua giấy phép và quản lý các mục thay mặt bạn",
      "Socials": "Xã hội",
      "Insert link here": "Chèn liên kết vào đây",
      "Facebook": "Facebook",
      "Instagram": "Instagram",
      "Linkedin": "Linkedin",
      "Tiktok": "Tiktok",
      "Youtube": "Youtube",
      "Enter Id": "Nhập ID",
      "Password should be 8 chars minimum.": "Mật khẩu phải có tối thiểu 8 ký tự.",
      "Passwords must match": "Mật khẩu phải trùng khớp",
      "Password changed successfully": "Đã thay đổi mật khẩu thành công",
      "New Password": "Mật khẩu mới",
      "Re-Enter": "Xác nhận mật khẩu",
      "Name is Required": "Tên là bắt buộc",
      "Surname is Required": "Họ là bắt buộc",
      "Re enter password": "Nhập lại mật khẩu",
      "User is already registered": "Người dùng đã được đăng ký",
      "Create an account": "Tạo một tài khoản",
      "Already have an account?": "Đã có tài khoản?",
      "Log in": "Đăng nhập",
      "or create account": "hoặc tạo tài khoản",
      "I'm creating an account for a kid": "Tôi đang tạo một tài khoản cho một đứa trẻ",
      "sign-up description": "Bạn đang tạo một tài khoản cho một người tham gia giải đấu. ",
      "Email Address": "Địa chỉ email",
      "Continue": "Tiếp tục",
      "Pick a sport": "Chọn một môn thể thao",
      "Pick a sport that you play": "Chọn một môn thể thao mà bạn chơi",
      "Gender is required": "Giới tính là bắt buộc",
      "Phone is required": "Cần có điện thoại",
      "Citizenship is required": "Quốc gia là bắt buộc",
      "Dob is required": "Dob là bắt buộc",
      "FIDE ID Not found": "ID FIDE Không tìm thấy",
      "FIDE ID found": "Đã tìm thấy ID FIDE",
      "Tell us more about you": "Hãy cho chúng tôi biết thêm về bạn",
      "More information about yourself": "Thông tin thêm về bản thân bạn",
      "Enter FIDE ID": "Nhập ID FIDE",
      "IPIN ID is correct": "ID IPIN là chính xác",
      "Your Licenses": "Giấy phép của bạn",
      "Attach your licenses": "Đính kèm giấy phép của bạn",
      "Skip": "Nhảy",
      "User registered successfully": "Người dùng đã đăng ký thành công",
      "What is your primary role in the world of sports?": "Vai trò chính của bạn trong thế giới thể thao là gì?",
      "Register & Go to profile": "Đăng ký & Đi tới hồ sơ",
      "PUBLISHED ON": "ĐÃ XUẤT BẢN TRÊN",
      "Federation member": "thành viên liên đoàn",
      "Club owner": "Chủ câu lạc bộ",
      "Club manager": "Quản lý câu lạc bộ",
      "Referee": "Trọng tài",
      "Tournament organizer": "Người tổ chức giải đấu",
      "Sport fan": "người hâm mộ thể thao",
      "Other": "Khác",
      "PAY": "CHI TRẢ",
      "1 Year": "1 năm",
      "2 Years": "2 năm",
      "3 Years": "3 năm",
      "4 Years": "4 năm",
      "Registration time": "Thời gian đăng ký",
      "In progress / Upcoming": "Đang tiến hành / Sắp tới",
      "Matches won": "Trận thắng",
      "In progress / upcoming entries": "Đang tiến hành/các mục sắp tới",
      "In progress / upcoming tournaments": "Đang diễn ra/các giải đấu sắp tới",
      "Past tournaments": "Các giải đấu trước đây",
      "Past entries": "Các mục trước đây",
      "Your password must be different to previous passwords": "Mật khẩu của bạn phải khác với mật khẩu trước đó",
      "less than a minute": "chưa đầy một phút",
      "read": "đọc",
      "minute": "phút",
      "Select Licence": "Chọn giấy phép",
      "Start time": "Thời gian bắt đầu",
      "Try again": "Thử lại",
      "maintenanceMessage": "Chúng tôi hiện đang cập nhật nền tảng, quá trình này có thể mất vài phút. ",
      "networkErrorMessage": "Vui lòng kiểm tra kết nối mạng của bạn và thử lại.",
      "errorTitle": "Ối! ",
      "View List": "Xem danh sách",
      "Free": "Miễn phí",
      "Primary Sport": "Thể thao tiểu học",
      "Role(s)": "(Các) vai trò",
      "Menu": "Thực đơn",
      "Calendar": "Lịch",
      "Manage tournament": "Quản lý giải đấu",
      "Partner search": "Tìm kiếm đối tác",
      "Partner Search": "Tìm kiếm đối tác",
      "Searching...": "Đang tìm kiếm...",
      "Apply": "Áp dụng",
      "Delete": "Xóa bỏ",
      "Create application": "Tạo ứng dụng",
      "No partner search applications found": "Không tìm thấy ứng dụng tìm kiếm đối tác",
      "Log in to apply": "Đăng nhập để áp dụng",
      "Player": "Người chơi",
      "Time of registration": "Thời gian đăng ký",
      "confirmed": "xác nhận",
      "declined": "từ chối",
      "payment pending": "thanh toán đang chờ xử lý",
      "WITHDRAW": "RÚT",
      "No other tournament found": "Không tìm thấy giải đấu nào khác",
      "welcomeTextCustom": "được cung cấp bởi Tournated, một phần mềm quản lý thể thao tất cả trong một giúp trao quyền cho các tổ chức thể thao quản lý, phát triển và kiếm tiền từ hoạt động của họ một cách hiệu quả. ",
      "welcomeText": "là kết quả của 5 năm nghiên cứu & phát triển và cộng tác với hơn 200 tổ chức, là giải pháp tất cả trong một sáng tạo, có thể tùy chỉnh, thân thiện với người dùng và giá cả phải chăng dành cho các tổ chức thể thao ở mọi quy mô",
      "Did you have an account in one of ": "Bạn đã có tài khoản ở một trong những ",
      "those": "những thứ kia",
      "platforms?": "nền tảng?",
      "Recover password": "Khôi phục mật khẩu",
      "newUser": "Tôi là người dùng mới. ",
      "Continue as a guest": "Tiếp tục làm khách",
      "Support": "Ủng hộ",
      "Welcome to": "Chào mừng đến với",
      "If you have an account on one of ": "Nếu bạn có tài khoản trên một trong ",
      "these": "những cái này",
      "platforms, log in with your e-mail or create a new profile": "nền tảng, đăng nhập bằng e-mail của bạn hoặc tạo một hồ sơ mới",
      "Reset password": "Đặt lại mật khẩu",
      "Welcome to the": "Chào mừng đến với",
      "platform": "nền tảng",
      "Powered by": "Được cung cấp bởi",
      "All Categories": "Tất cả danh mục",
      "Tournament Results": "Kết quả giải đấu",
      "versionText": "Beta đang trực tiếp. ",
      "here": "đây",
      "Full view": "Xem toàn bộ",
      "Timeline": "Dòng thời gian",
      "Grid": "Lưới",
      "Bird's View": "Xem chim",
      "Third Party Licences": "Giấy phép của bên thứ ba",
      "Friend": "Bạn bè",
      "Step 2": "Bước 2:",
      "Step 3": "Bước 3:",
      "Your Details": "Thông tin chi tiết của bạn",
      "Partner Details": "Chi tiết đối tác",
      "Enter partner email": "Nhập email đối tác",
      "Register & Pay": "`Đăng ký và thanh toán`",
      "On-Site": "Tại chỗ",
      "Now": "Hiện nay"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
