import { gql } from "@apollo/client";

const REMOVE_PARTNER_SEARCH = gql`
  mutation removePartnerSearch($id: Int!) {
    removePartnerSearch(id: $id) {
      id
    }
  }
`;
export default REMOVE_PARTNER_SEARCH;
