import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Props<T> = {
  key: string;
  defaultValue: T;
};

const useLocalStorageState = <T extends any>({ key, defaultValue }: Props<T>): [state: T, setState: Dispatch<SetStateAction<T>>] => {
  const getStoredValue = (): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.error(`Error parsing localStorage key "${key}":`, error);
      return defaultValue;
    }
  };

  const [state, setState] = useState<T>(getStoredValue);

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.error(`Error setting localStorage key "${key}":`, error);
    }
  }, [key, state]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setState(getStoredValue());
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return [state, setState];
};

export default useLocalStorageState;
