import { gql } from "@apollo/client";

const CREATE_USER_PARENT = gql`
  mutation createUserParent($user: Int!, $parent: Int!) {
    createUserParent(createUserParentInput: { user: $user, parent: $parent }) {
      id
    }
  }
`;

export default CREATE_USER_PARENT;
