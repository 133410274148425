import React from 'react';
import { Box, Typography, Stack, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomPaperCard from '../../../../CustomComponents/CustomPaperCard';
import { useTournament } from '../../TournamentPage';

const FactSheet = () => {
  const { t } = useTranslation();
  const { tournament } = useTournament();
  const facts = tournament?.facts?.filter((fact) => !!fact.factSheet.fieldTitle && !!fact.answer) ?? [];

  if (facts.length === 0) return null;

  return (
    <>
      <Box sx={{ height: '1px', width: '100%', background: '#DFDFDF' }} />
      <Stack gap="14px">
        <Typography variant="font20"> {t('Fact sheet')}</Typography>
        <Grid container spacing="14px" sx={{ maxHeight: '400px', overflow: 'auto', marginTop: 0 }}>
          {facts?.map((fact, index) => (
            <Grid item xs={12} md={6} xl={4} key={index} sx={{ '&:first-of-type': { paddingTop: 0 } }}>
              <CustomPaperCard
                sx={{
                  padding: '12px',
                  height: '70px',
                }}
              >
                <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
                  <Typography variant="heading13" sx={{ fontSize: { xs: 12, sm: 14 }, lineHeight: '100%' }}>
                    {fact?.factSheet?.fieldTitle}
                  </Typography>
                  <Typography variant="font21" sx={{ fontSize: { xs: 10, sm: 12 }, lineHeight: '100%' }}>
                    {fact?.answer}
                  </Typography>
                </Stack>
              </CustomPaperCard>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};

export default FactSheet;
