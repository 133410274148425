import { gql } from "@apollo/client";

const PAIRINGS = gql`
  query pairings($filter: ListPairingInput) {
    pairings(filter: $filter) {
      id
      title
      isCompleted
      hide
      hideStandings
    }
  }
`;

export default PAIRINGS;
