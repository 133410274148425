import React, { cloneElement, ComponentProps, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CustomModal from 'src/components/CustomComponents/CustomModal/CustomModal';

type Props = {
  triggerEl: ReactElement | ((open: boolean) => ReactElement);
  children: ((onClose: () => void) => ReactElement) | ReactNode;
  preventClosing?: (() => void) | boolean;
  popoverProps?: Partial<ComponentProps<typeof Popover>>;
  onClose?: () => void;
  onOpen?: () => void;
  modalOnMobile?: boolean;
  align?: 'left' | 'right' | 'center';
  activated?: boolean;
  disablePadding?: boolean;
  anchorEl?: HTMLElement | null; // anchorEl is optional
  matchTriggerWidth?: boolean;
  transformOriginVertical?: number;
};

const CustomPopover = ({
  triggerEl,
  children,
  preventClosing,
  popoverProps,
  onClose,
  modalOnMobile,
  onOpen,
  disablePadding,
  activated = true,
  align = 'left',
  anchorEl, // Receive anchorEl as a prop
  matchTriggerWidth = false,
  transformOriginVertical = -10,
}: Props) => {
  const [internalAnchorEl, setInternalAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const isMobile = modalOnMobile && useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (preventClosing) {
      if (typeof preventClosing === 'function') preventClosing();
      return;
    }

    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    if (open) onOpen?.();
  }, [open]);
  useEffect(() => {
    if (anchorEl) setOpen(true);
  }, [anchorEl]);
  const anchorElement = anchorEl ?? internalAnchorEl;
  return activated ? (
    !isMobile ? (
      <>
        {cloneElement(typeof triggerEl === 'function' ? triggerEl(open) : triggerEl, {
          ref: anchorEl ? undefined : setInternalAnchorEl, // Use internal ref if anchorEl is not provided
          onClick: () => setOpen(true),
        })}
        {anchorElement && (
          <Popover
            anchorEl={anchorElement} // Use anchorElement (either from props or internal)
            onClose={handleClose}
            open={open}
            PaperProps={{
              sx: {
                borderRadius: '6px',
                padding: disablePadding ? 0 : '10px',
                width: matchTriggerWidth && anchorElement ? anchorElement.getBoundingClientRect().width : 'auto', // Conditionally apply width based on matchTriggerWidth
              },
              elevation: 1,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: align,
            }}
            transformOrigin={{ vertical: transformOriginVertical, horizontal: align }}
            {...popoverProps}
            marginThreshold={0}
          >
            <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>{typeof children === 'function' ? children(handleClose) : children}</Box>
          </Popover>
        )}
      </>
    ) : (
      <CustomModal triggerEl={triggerEl} onClose={onClose} onOpen={onOpen} preventClosing={preventClosing}>
        {children}
      </CustomModal>
    )
  ) : (
    <>{children}</>
  );
};

export default CustomPopover;
