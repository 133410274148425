import { gql } from "@apollo/client";
const TEAMS = gql`
  query teams($filter: ListTeamInput) {
    teams(page: 1, limit: 100, filter: $filter) {
      teams{
      id
      title
      sport {
        id
        title
        icon
      }
      club {
        id
        clubName
      }
      discipline {
        id
        title
      }
      country
      status
      image
      members {
        member {
          surname
          name
          email
          avatar
          id
        }
        team {
          title
        }
        role
      }
    }
    total
    }
  }
`;
export default TEAMS;
