import { useState, useEffect, useRef, Fragment } from 'react';
import { Box, Button, Container, Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import { ReactSVG } from 'react-svg';
import ResultsModal from './ResultsModal';
import { useQuery } from '@apollo/client';
import { MATCHCOURTS, SPORTS_FISHING_RESULTS, TOTAL_RESULTS, TEAM_RESULTS, SPORTS_FISHING_RESULTS_BY_FILTER, ALL_TOURNAMENT_CATEGORIES } from 'src/graphql/queries';
import Loading from 'src/components/Loading';
import ListResultsTable from './ListResultsTable';
import { useClickOutside } from 'src/utils/useClickOutside';
import DragScrollbar from 'src/components/DragScrollbar';
import gtm from 'src/lib/gtm';
import { Helmet } from 'react-helmet';
import useAuth from 'src/hooks/useAuth';
import { useTournament } from '../TournamentPage';

const useStyles = makeStyles((theme) => ({
  participantsContainer: {
    minHeight: '550px',
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '12px',
    // [theme.breakpoints.down("md")]: {
    //   flexWrap: "wrap",
    // },
  },
  select: {
    display: 'flex',
    width: '248px',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  downloadBtn: {
    display: 'flex',
    padding: '10px 24px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #EFEFEF',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textfield: {
    height: '40px',
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FFF',
    '& .MuiOutlinedInput-root': {
      border: '1px solid #EFEFF0',
      background: '#FFF',
      height: '40px',
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  label: {
    color: '#1B3861',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
  },
  subTitle: {
    marginTop: '2px',
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  container: {
    padding: '0px',
    display: 'flex',
    // flexWrap: "wrap",
    gap: 10,
    width: '100%',
  },
  activeText: {
    color: '#ffff',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  activePill: {
    display: 'flex',
    padding: '8px 12px',
    alignItems: 'flex-start',
    borderRadius: '23px',
    border: '1px solid #00D010',
    background: 'linear-gradient(99.53deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 12px',
    },
  },
  inActiveText: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  inActivePill: {
    display: 'flex',
    padding: '8px 12px',
    alignItems: 'flex-start',
    borderRadius: '23px',
    border: '1px solid rgba(25, 54, 96, 0.40)',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 12px',
    },
  },
  pill: {
    padding: '8px 16px',
    border: '1px solid #EFEFF0',
    borderRadius: '30px',
    height: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    // pointerEvents:"none"
  },
  popoveer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      padding: '24px 30px 24px 24px',
      position: 'absolute',
      top: 45,
      right: '0px',
      zIndex: 99999999999,
      borderRadius: '6px',
      border: '1px solid #F6F7F9',
      background: '#FFF',
      boxShadow: '20px 20px 52px 0px rgba(0, 0, 0, 0.10)',
      flexWrap: 'wrap',
    },
  },
}));

const ListAndResults = () => {
  const { id, tournament } = useTournament();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('custom');
  const [openResult, setOpenResult] = useState(false);
  const [category, setCategory] = useState<number>(null);
  const [isCustomMetrics, setIsCustomMetrics] = useState<boolean>(false);
  const { user } = useAuth();

  const handleSelectChange = (event) => {
    setCategory(parseInt(event.target.value));
  };
  const handleClick = (value) => {
    setActiveTab(value);
  };
  const [activeStage, setActiveStage] = useState<string | number>(null);
  const [zone, setZone] = useState<string>('');
  const [availableZones, setAvailableZones] = useState<string[]>(null);
  const [isPublished, setIsPublished] = useState(false);
  const [tableResults, setTableResults] = useState<any>(null);
  const [openTourModal, setOpenTourModal] = useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { loading: catLoading, data: allCategories } = useQuery(ALL_TOURNAMENT_CATEGORIES, {
    skip:!id,
    variables: {
      filter: {
        tournament: id,
        registrationStatus: 'confirm',
      },
    },
    onCompleted: (data) => {
      if (!category) {
        setCategory(data.allTournamentCategories[0]?.category?.id);
        setIsCustomMetrics(data.allTournamentCategories[0]?.category?.category?.discipline?.resultType === 'Custom metrics');
      }
    },
  });

  const { loading: stagesLoading, data: courtsData } = useQuery(MATCHCOURTS, {
    variables: {
      filter: {
        tournament: id,
      },
    },
    onCompleted: (data) => {
      setActiveStage(data?.courts?.filter((item) => item?.isPublish)[0]?.id);
    },
  });

  const { loading } = useQuery(SPORTS_FISHING_RESULTS, {
    skip: !id || !activeStage || activeStage === 'total' || activeStage === 'team' || !!zone,
    variables: {
      filter: {
        tournament: id,
        ...(category && { categoryId: category }),
        ...(activeStage && activeStage !== 'total' && activeStage !== 'team' && { stage: activeStage }),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTableResults(data?.sportFishingResults);
      setIsPublished(data?.sportFishingResults[0]?.isPublish);
      setAvailableZones(
        data?.sportFishingResults[0]?.entries
          ?.map((item: any) => item?.location?.toUpperCase()?.charAt(0)) // Convert to lowercase and get the first letter
          .filter((value: any) => value !== null)
          .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index) || []
      );
    },
  });

  const { loading: zoneLoading } = useQuery(SPORTS_FISHING_RESULTS_BY_FILTER, {
    skip: !id || !activeStage || activeStage === 'total' || activeStage === 'team' || !zone,
    variables: {
      filter: {
        tournament: id,
        ...(category && { categoryId: category }),
        ...(activeStage && activeStage !== 'total' && activeStage !== 'team' && { stage: activeStage }),
        ...(zone && { location: zone }),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTableResults([{ entries: data?.sportFishingResultEntries }]);
    },
  });

  const { loading: totalLoading } = useQuery(TOTAL_RESULTS, {
    skip: !id || !activeStage || activeStage !== 'total',
    variables: {
      filter: {
        tournament: id,
        ...(category && { categoryId: category }),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTableResults(data?.calculateTotaltFishingResult);
    },
  });

  const { loading: teamLoading } = useQuery(TEAM_RESULTS, {
    skip: !id || !activeStage || activeStage !== 'team',
    variables: {
      filter: {
        tournament: id,
        ...(category && { categoryId: category }),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTableResults(data?.calculateTeamTotaltResult);
    },
  });

  const Stages = [
    { id: 'total', name: 'Total' },
    { id: 'team', name: 'Team Results' },
  ];
  const handleClose = () => {
    setOpenTourModal(false);
  };
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const mdScreenSize = 768;

    if (screenSize >= mdScreenSize) {
      setOpenTourModal(false);
    }
  }, [screenSize]);

  const gridRef = useRef(null);
  useClickOutside(gridRef, () => {
    handleClose();
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{tournament?.title} | List & Results</title>
      </Helmet>
      <Grid container className={classes.participantsContainer}>
        <Container maxWidth={false} sx={{ padding: '0px !important' }}>
          <Box
            sx={{
              justifyContent: 'space-between',
              position: 'relative',
            }}
            className={classes.container}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: { sm: '-moz-initial', xs: 'space-between' },
                width: { sm: 'auto', xs: '100%' },
              }}
            >
              <Typography variant="heading17" className={classes.title}>
                List & Results
              </Typography>
            </Box>
            <Box
              ref={gridRef}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <Grid
                container
                xs={12}
                xl={7}
                lg={10}
                md={10}
                spacing={smDown ? 1 : 0}
                sx={{
                  display: {
                    md: 'flex',
                    xs: openTourModal ? 'flex' : 'none',
                  },
                  justifyContent: { md: 'flex-end', xs: 'start' },
                  gap: { md: '8px', xs: '0px' },
                }}
                className={openTourModal ? classes.popoveer : ''}
              >
                <Grid item lg={4} md={openTourModal ? 12 : 4} xs={12}>
                  <TextField
                    fullWidth
                    name="role"
                    // label="Select a category"
                    onChange={handleSelectChange}
                    select
                    SelectProps={{ native: true }}
                    value={category}
                    sx={{
                      height: '20px',
                      border: 0,

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '0px',
                        },
                        '&:hover fieldset': {
                          borderWidth: '0px',
                          transform: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          borderWidth: '0px',
                        },
                      },
                    }}
                    className={classes.textfield}
                    // sx={{ mt: 3 }}
                  >
                    {allCategories?.allTournamentCategories?.length ? (
                      <Fragment>
                        {allCategories?.allTournamentCategories?.map(
                          (category) =>
                            !category.category.category.moveToSingles && (
                              <option key={category.category.id} value={category.category.id}>
                                {category.category.category.name + ' ' + `(${category.count})`}
                              </option>
                            )
                        )}
                      </Fragment>
                    ) : (
                      <option disabled>No category found</option>
                    )}
                  </TextField>
                </Grid>
                <Grid item lg={3} md={openTourModal ? 6 : 3} xs={openTourModal ? 6 : 12}>
                  <TextField
                    fullWidth
                    name="role"
                    // label="Select a category"
                    onChange={(e) => setZone(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                    value={zone}
                    sx={{
                      height: '20px',
                      border: 0,

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '0px',
                        },
                        '&:hover fieldset': {
                          borderWidth: '0px',
                          transform: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          borderWidth: '0px',
                        },
                      },
                    }}
                    className={classes.textfield}
                    // sx={{ mt: 3 }}
                  >
                    <option value="">All Zones</option>
                    {availableZones?.map((zone) => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={3} md={openTourModal ? 6 : 3} xs={openTourModal ? 6 : 12}>
                  <TextField
                    fullWidth
                    name="search"
                    placeholder="Search"
                    // value={search}
                    variant="outlined"
                    // onChange={handleSearch}
                    type="text"
                    size="small"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #EFEFF0',
                        '& fieldset': {
                          borderWidth: '0px',
                        },
                        '&:hover fieldset': {
                          borderWidth: '0px',
                        },
                        '&.Mui-focused fieldset': {
                          borderWidth: '0px',
                        },
                      },
                    }}
                    className={classes.textfield}
                    InputLabelProps={{
                      className: classes.label,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ReactSVG src="/images/search.svg" style={{ padding: '2px 0 0 0 ' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: { md: 'none', xs: 'block' },
                  width: '26px',
                  height: '26px',
                }}
                onClick={() => setOpenTourModal(!openTourModal)}
              >
                <img src="/images/updated/tourFilters.svg" style={{ width: '26px', height: '26px' }} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '7px',
            }}
          >
            <DragScrollbar
              style={{
                width: '100%',
                overflow: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                margin: '8px 0',
              }}
              className="my-scroller"
            >
              {/* <Scrollbar options={{ suppressScrollX: true }}> */}
              <Box
                sx={{
                  display: 'flex',
                  gap: '3px',
                  maxWidth: 'max-content',
                  width: '100%',
                }}
              >
                {courtsData?.courts?.map(
                  (item) =>
                    item?.isPublish && (
                      <Box
                        className={`${classes.pill} ${activeStage == item?.id ? classes.activePill : classes.inActivePill} `}
                        onClick={() => setActiveStage(item?.id)}
                        sx={{ maxWidth: '114px', width: '100%' }}
                      >
                        <Typography className={` ${activeStage == item?.id ? classes.activeText : classes.inActiveText}`} sx={{ whiteSpace: 'nowrap' }}>
                          {item?.name}
                        </Typography>
                      </Box>
                    )
                )}
                {courtsData?.courts?.filter((item) => item?.isPublish)?.length > 0 &&
                  Stages?.map((item) => (
                    <Box
                      className={`${classes.pill} ${activeStage == item?.id ? classes.activePill : classes.inActivePill} `}
                      onClick={() => setActiveStage(item?.id)}
                      sx={{ maxWidth: '114px', width: '100%' }}
                    >
                      <Typography className={` ${activeStage == item?.id ? classes.activeText : classes.inActiveText}`} sx={{ whiteSpace: 'nowrap' }}>
                        {item?.name}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              {/* </Scrollbar> */}
            </DragScrollbar>

            {(tableResults?.some((item: any) => item?.entries?.length) || tableResults?.length > 0) && activeStage && activeStage !== 'total' && activeStage !== 'team' && user?.id && (
              <Box sx={{ mt: { sm: 0, xs: 1.3 }, width: { sm: 'auto', xs: '100%' } }}>
                <Button
                  sx={{
                    padding: '16px 32px 16px 32px',
                    color: '#fff',
                    width: '100%',
                    height: { sm: '38px', xs: '32px' },
                  }}
                  onClick={() => setOpenResult(true)}
                >
                  My Results
                </Button>
              </Box>
            )}
          </Box>

          {loading || stagesLoading || totalLoading || teamLoading || catLoading || zoneLoading ? (
            <Loading height="40vh" />
          ) : tableResults?.some((item: any) => item?.entries?.length) ? (
            tableResults?.map((item: any, index) => (
              <Box sx={{ mt: index !== 0 && 3 }} key={item.id}>
                <ListResultsTable listAndResults={item?.entries} isStage category={category} matrics={item?.matrics} isCustomMetrics={isCustomMetrics} />
              </Box>
            ))
          ) : (
            <Box>
              <ListResultsTable listAndResults={tableResults} stages={courtsData?.courts} />
            </Box>
          )}
        </Container>
        <ResultsModal open={openResult} setOpen={setOpenResult} category={category} activeStage={activeStage} zone={zone} isCustomMetrics={isCustomMetrics} />
      </Grid>
    </Fragment>
  );
};

export default ListAndResults;
