import { gql } from "@apollo/client";

const DELETE_TEAM_MEMBER = gql`
  mutation removeTeamMember($id: Int!) {
    removeTeamMember(id: $id) {
      role
    }
  }
`;

export default DELETE_TEAM_MEMBER;
