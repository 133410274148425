import { gql } from "@apollo/client";
const NEWSBYLEAGUEID = gql`
  query allLeagueNews($filter: ListLeagueNewsInput) {
    allLeagueNews(filter: $filter) {
      id
      news {
        id
        title
        image
        slug
        postText
        date
        author {
          id
          email
          name
          surname
        }
      }
    }
  }
`;
export default NEWSBYLEAGUEID;