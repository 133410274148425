import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';

import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';

const Logos = ['/images/clogo.svg', '/images/braze.svg', '/images/cl2.svg', '/images/cl3.svg', '/images/cl4.svg', '/images/spacelogo.svg'];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '29px 23px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 5px',
    },
    backgroundColor: '#F8F8F8',
    minHeight: '100vh',
    height: '100%',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logo: {
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '14px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    marginTop: '10px',
  },
  image: {
    width: '100%',
    marginTop: '10px',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  heading: {
    color: theme.palette.text.primary,
    width: '48%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  leadText: {
    color: theme.palette.text.primary,
    fontFamily: 'Inter',
    fontWeight: 500,
    width: '48%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '65px',
    flexWrap: 'wrap',
    background: 'linear-gradient(90deg, #F6F6F6 0%, rgba(246, 246, 246, 0.00) 100%)',
    marginTop: theme.spacing(10),
  },
}));

const AuthLeftSection = () => {
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <Box className={classes.root}>
      <Box onClick={() => navigate('/')}>
        {/* <ReactSVG src="/images/logo.svg" className={classes.logo} /> */}
        <img src={auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON} width="209px" height="41px" className={classes.logo} />
      </Box>
      <Container maxWidth="lg">
        <Box className={classes.imageContainer}>
          <img src="/images/regbanner.jpg" alt="dash-img" className={classes.image} />
        </Box>
        <Box className={classes.content}>
          <Typography variant="h5" className={classes.heading}>
            Tournanted - Your all-in-one solution for managing all sports-related processes.
          </Typography>
          <Typography variant="subtitle1" className={classes.leadText}>
            Streamline the management of sports organizations, leagues, tournaments, clubs, athletes, and more with our comprehensive platform. Experience fully automated management of licenses,
            entries, match results, rankings, analytics, and much more.
          </Typography>
        </Box>
        {/* <Box className={classes.logoContainer}>
          {Logos.map((logo) => (
            <ReactSVG key={logo} src={logo} />
          ))}
        </Box> */}
      </Container>
    </Box>
  );
};

export default AuthLeftSection;
