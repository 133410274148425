import React from 'react';
import CustomSection from '../../../layout/CustomSection';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import { useTournament } from '../TournamentPage';
import { Box, Stack, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import TablerIcon from '../../../CustomComponents/TablerIcon';
import useAuth from 'src/hooks/useAuth';

const ContactInfo = ({ icon, text, onClick }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <TablerIcon icon={icon} fontSize="18px" color={process.env.REACT_APP_PRIMARY_COLOR} />
    <Typography
      variant="font17"
      sx={{
        color: '#0A2540',
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text || '-'}
    </Typography>
  </Box>
);

const Contacts = () => {
  const { t } = useTranslation();
  const { platformData } = useAuth();
  const { tournament, loading } = useTournament();

  const handleEmail = (email) => {
    window.location.href = `mailto:${encodeURIComponent(email)}`;
  };
  const handlePhone = (phone) => {
    window.location.href = `tel:${encodeURIComponent(phone)}`;
  };

  const organizer = tournament?.organizer;
  const isOrganization = organizer?.isOrganization;
  const avatarSrc = isOrganization ? organizer?.organizationLogo : organizer?.member?.avatar;
  const name = isOrganization ? organizer?.organizationName : `${organizer?.member?.name} ${organizer?.member?.surname}`;
  const email = isOrganization ? organizer?.organizationEmail : organizer?.member?.email;
  const phone = isOrganization ? organizer?.organizationPhone : organizer?.member?.phone;

  return (
    <CustomSection icon={<TablerIcon icon="address-book" fontSize="24px" />} title={t('Contacts')} sx={{ mt: 0 }} gap={6}>
      <CustomPaperCard sx={{ padding: { xs: '12px', md: '22px' } }}>
        <CustomPaperCard skeleton={loading} sx={{ background: '#F9F9F9', padding: '12px', height: loading ? '128px' : undefined }}>
          <Stack gap="16px">
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <CustomAvatar size={32} src={avatarSrc || platformData?.lightLogo} seed={name} />
              <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <Typography
                  variant="font22"
                  sx={{
                    lineHeight: '100%',
                    color: '#0A2540',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="font21"
                  sx={{
                    lineHeight: '100%',
                    color: '#666',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {organizer?.member && t('Organizer')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <ContactInfo icon="mail-filled" text={email} onClick={() => handleEmail(email)} />
              <ContactInfo icon="phone-filled" text={phone} onClick={() => handlePhone(phone)} />
            </Box>
          </Stack>
        </CustomPaperCard>
      </CustomPaperCard>
    </CustomSection>
  );
};

export default Contacts;
