import { gql } from "@apollo/client";

const CREATE_LICENSE_PAYMENT = gql`
  mutation createLicensePayment(
    $amount: Float
    $email: String!
    $name: String!
    $leagueTitle: String
    $licenseTitle: String
    $promo: String
    $successUrl: String!
    $currency: String!
    $purchasedLicenseId: Int!
    $leagueId: Int
    $alreayExist: Boolean
    $yearsToAdd: Int
    $licenseId: Int!
  ) {
    createLicensePayment(
      createLicensePaymentInput: {
        amount: $amount
        email: $email
        name: $name
        leagueTitle: $leagueTitle
        licenseTitle: $licenseTitle
        promo: $promo
        successUrl: $successUrl
        currency: $currency
        purchasedLicenseId: $purchasedLicenseId
        leagueId: $leagueId
        yearsToAdd: $yearsToAdd
        alreayExist: $alreayExist
        licenseId: $licenseId
      }
    )
  }
`;
export default CREATE_LICENSE_PAYMENT;
