import React from 'react';
import SliderWrapper from './SliderWrapper';
import { ButtonBase, Tooltip } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

type Partner = {
  title: string;
  link: string;
  image: string;
};

type PartnersProps = {
  partners: Partner[] | undefined;
  title: string;
};

const useStyles = makeStyles(() => ({
  buttonBase: {
    display: 'flex',
    padding: '24px',
    alignItems: 'center',
    gap: '20px',
    borderRadius: '8px',
    border: '1px solid #EDF1F6',
    background: '#FFF',
    width: '100%',
    height: '150px',
    marginBlock: '15px',
    cursor: 'pointer',
    transition: 'transform 0.5s ease, box-shadow 0.5s ease',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.03)',
    },
  },
  image: {
    width: '100%',
    height: '70%',
    objectFit: 'contain',
  },
}));

const PartnersSlider: React.FC<PartnersProps> = ({ partners, title }) => {
  const auth = useAuth();
  const classes = useStyles();

  return (
    partners?.length > 0 && (
      <SliderWrapper heading={title} toShow={auth.isDrawerOpen ? 3.2 : 4} isFramed={true} gap={0} autoplay>
        {() => {
          return partners.map((partner, index) => (
            <Tooltip
              key={index}
              title={partner.title}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -30],
                    },
                  },
                ],
              }}
            >
              <ButtonBase onClick={() => window.open(partner.link)} className={classes.buttonBase}>
                <img src={partner.image} className={classes.image} />
              </ButtonBase>
            </Tooltip>
          ));
        }}
      </SliderWrapper>
    )
  );
};

export default PartnersSlider;
