import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import AuthLayout from './components/authentication/AuthLayout';
import TournamentPage from './components/dashboard/TournamentPage/TournamentPage';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Maintenance page
const MaintenancePage = Loadable(lazy(() => import('./pages/maintenance/MaintenancePage')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Profile = Loadable(lazy(() => import('./pages/dashboard/UserProfileUpdated')));
const MyCareer = Loadable(lazy(() => import('./pages/dashboard/MyCareer')));
const Tournament = Loadable(lazy(() => import('./pages/dashboard/Tournament')));
const LeagueDetails = Loadable(lazy(() => import('./pages/dashboard/LeagueDetailsUpdated')));
const ClubDetails = Loadable(lazy(() => import('./pages/dashboard/ClubDetails')));
const CoachDetails = Loadable(lazy(() => import('./pages/dashboard/CoachDetails')));
const TeamDetails = Loadable(lazy(() => import('./pages/dashboard/TeamDetails')));
const OrgDetails = Loadable(lazy(() => import('./pages/dashboard/OrgDetails')));
const League = Loadable(lazy(() => import('./pages/dashboard/League')));
const Albums = Loadable(lazy(() => import('./pages/dashboard/Album')));
const Gallery = Loadable(lazy(() => import('./pages/dashboard/gallery')));

const RankingDetailsUpdated = Loadable(lazy(() => import('./pages/dashboard/RankingDetailsUpdated')));
const Coaches = Loadable(lazy(() => import('./pages/dashboard/Coaches')));
const Clubs = Loadable(lazy(() => import('./pages/dashboard/Clubs')));
const NewsPage = Loadable(lazy(() => import('./pages/dashboard/NewsUpdated')));
const NewsDetails = Loadable(lazy(() => import('./pages/dashboard/NewsDetailsUpdated')));
const Federations = Loadable(lazy(() => import('./pages/dashboard/Federations')));
const Teams = Loadable(lazy(() => import('./pages/dashboard/Teams')));
const PlatformCustom = Loadable(lazy(() => import('./pages/dashboard/PlatformCustom')));
const PlatformManager = Loadable(lazy(() => import('./pages/dashboard/PlatformManagerUpdated')));
const CustomOnboard = Loadable(lazy(() => import('./pages/dashboard/CustomOnboarding')));
const PaidBasic = Loadable(lazy(() => import('src/components/dashboard/onBoarding/PaidBasic')));
const CreateTeam = Loadable(lazy(() => import('src/components/dashboard/teams/TeamsCreationForm')));
const UserSettings = Loadable(lazy(() => import('./pages/dashboard/UserSettings')));

const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const GetLicense = Loadable(lazy(() => import('./pages/dashboard/GetLicense')));
const ApplyForNewRole = Loadable(lazy(() => import('./pages/dashboard/ApplyNewRole')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'maintenance-code',
    element: <MaintenancePage />,
  },
  {
    path: 'authentication',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset/:email',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Overview />,
      },
    ],
  },
  {
    path: '*',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Overview />,
      },
      {
        path: 'athlete/:id?/:name?',
        element: <Profile />,
      },
      {
        path: 'tournaments/:id?',
        element: <Tournament />,
      },
      {
        path: 'tournament/:id/:name?',
        element: <TournamentPage />,
      },
      {
        path: 'league/:id/:name?',
        element: <LeagueDetails />,
      },
      {
        path: 'club/:id/:name?',
        element: <ClubDetails />,
      },
      {
        path: 'coach/:id/:name?',
        element: <CoachDetails />,
      },
      { path: 'team/:id/:name?', element: <TeamDetails /> },
      {
        path: 'organization/:id/:name?',
        element: <OrgDetails />,
      },
      { path: 'get-licence', element: <GetLicense /> },
      { path: 'apply-newrole', element: <ApplyForNewRole /> },
      {
        path: 'leagues',
        element: <League />,
      },
      {
        path: 'album/:id',
        element: <Albums />,
      },
      {
        path: 'rankings',
        element: <RankingDetailsUpdated />,
      },
      {
        path: 'mycareer',
        element: <MyCareer />,
      },
      {
        path: 'teams',
        element: <Teams />,
      },
      {
        path: 'pricing',
        element: <PlatformManager />,
      },
      {
        path: 'custom-onboarding',
        element: <CustomOnboard />,
      },
      {
        path: 'basic-onboarding',
        element: <PaidBasic />,
      },
      {
        path: 'create-team',
        element: <CreateTeam />,
      },
      {
        path: 'userSettings/:id?',
        element: <UserSettings />,
      },
      {
        path: 'coaches',
        element: <Coaches />,
      },
      {
        path: 'clubs',
        element: <Clubs />,
      },
      {
        path: 'news',
        element: <NewsPage />,
      },
      {
        path: 'gallery',
        element: <Gallery />,
      },
      {
        path: 'organizations',
        element: <Federations />,
      },
      {
        path: 'news-details',
        element: <NewsDetails />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
