import { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const useEventLocation = ({ address }: { address?: string }) => {
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  useEffect(() => {
    if (!isLoaded) return;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results && results[0]?.geometry?.location) {
        const location = results[0].geometry.location;
        setMarkerPosition({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error('Geocoding failed: ', status);
        setMarkerPosition({ lat: 0, lng: 0 });
      }
    });
  }, [address, isLoaded]);

  return address ? markerPosition : undefined;
};

export default useEventLocation;
