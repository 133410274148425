import { gql } from "@apollo/client";
const LEAGUESWITHRANKINGS = gql`
  query leagueRanking(
    $sports: [Int!]
    $filter: ListLeagueRankingInput
    $platform: Int
  ) {
    leagueRankings(filter: $filter, sports: $sports, platform: $platform) {
      league {
        title
        abbreviation
        id
      }
    }
  }
`;

export default LEAGUESWITHRANKINGS;
