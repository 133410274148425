import { Fragment, useEffect, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, Avatar, makeStyles, withStyles } from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Rankings } from 'src/types/rankings';
import GetFlag from 'src/utils/getFlags';
import { useTranslation } from 'react-i18next';

interface RankingsListTableProps {
  leagues: Rankings[];
  title?: string;
  category?: string;
  tableId: number;
  categoryId: number;
  selectedCategory?: number;
  setLimit?: any;
  limit?: number;
  page?: number;
  setPage?: any;
  count?: any;
}

const useStyles = makeStyles((theme: any) => ({
  topBarButton: {
    height: '42px',
    mt: -0.3,
    backgroundColor: '#FFFF',
    color: '#425466',
    // border: `1px solid ${theme.custom.borderColor}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "10px",
    // },
  },
  subText: {
    color: '#193660',
    // textAlign: "center",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "10px",
    // },
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

type Sort = 'all' | 'updatedAt|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'All',
    value: 'all',
  },
];

const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);
const Points: FC<RankingsListTableProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { leagues, title, category, categoryId, tableId, selectedCategory, count, page, limit, setLimit, setPage, ...other } = props;
  const [openModal, setOpenModal] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [selectedRankings, setSelectedRankings] = useState<string[]>([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0]?.value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      {leagues?.length > 0 && (
        <Card
          {...other}
          sx={{
            boxShadow: 'none',
            // border: '1px solid #EDF1F6',
            borderRadius: '6px',
            mt: 2,
          }}
        >
          {selectedCategory == null && (
            <Box sx={{ padding: '10px 12px 0px 12px' }}>
              <Typography
                sx={{
                  width: 'max-content',
                  borderBottom: '2px solid #edeaea',
                  paddingBottom: '5px',
                  marginTop: '2px',
                  color: '#193660',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '140%',
                }}
              >
                {category}
              </Typography>
            </Box>
          )}
          <Scrollbar>
            <Box sx={{ minWidth: 700, padding: '0 12px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>
                      <Typography className={classes.tablehead}>{t('Place')}</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography className={classes.tablehead}>{t('Entry')}</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography className={classes.tablehead}>{t('Points')}</Typography>
                    </CustomTableCell>
                    <CustomTableCell align="right"></CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leagues?.map((Rankings: any, index) => {
                    return (
                      <TableRow
                        hover
                        key={Rankings.id}
                        // selected={isDocumentsSelected}
                        className="entry-row"
                        sx={{
                          borderRadius: '6px',
                          padding: '12px',
                          border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                          background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                        }}
                      >
                        <CustomTableCell sx={{ width: '10%' }}>
                          <Typography className={classes.name}>{Rankings?.place}</Typography>
                        </CustomTableCell>
                        <CustomTableCell sx={{ width: '40%', maxWidth: 'max-content' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {Rankings?.entry?.users.length === 2 ? (
                              <Fragment>
                                <Avatar
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    mr: 1,
                                    border: '3px solid #eee',
                                  }}
                                  src={Rankings?.entry?.users[0]?.user?.avatar}
                                />
                                <Avatar
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    mr: 1,
                                    marginLeft: '-25px',
                                    border: '3px solid #eee',
                                    // mt: "5px"
                                  }}
                                  src={Rankings?.entry?.users[1]?.user?.avatar}
                                />
                              </Fragment>
                            ) : (
                              <Avatar
                                sx={{
                                  width: '24px',
                                  height: '24px',
                                  mr: 1,
                                  border: '3px solid #eee',
                                }}
                                src={Rankings?.entry?.users[0]?.user?.avatar}
                              />
                            )}
                            <Box
                            // sx={{
                            //   minWidth: "190px",
                            //   gap: "4px",
                            //   // flex: "1 0 0",
                            // }}
                            >
                              {Rankings?.entry?.users?.map((user: any, Array) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                  key={user?.id}
                                >
                                  <Typography
                                    className={classes.name}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                                  >
                                    {user?.user?.name + ' ' + user?.user?.surname}
                                  </Typography>
                                  <GetFlag country={user?.user?.nation} style={{ width: '15px', height: '15px', marginTop: '5px', marginLeft: '1px' }} />
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Typography className={classes.name}>{Rankings?.points}</Typography>
                        </CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Scrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: '10px',
            }}
          >
            <Box
              sx={{
                marginLeft: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                '@media (max-width: 525px)': {
                  mt: '10px',
                },
              }}
            ></Box>
            {leagues?.length > 0 && (
              <TablePagination
                sx={{
                  '.MuiToolbar-gutters': { paddingRight: '0px !important', paddingLeft: '0px !important' },
                }}
                component="div"
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 100, 150]}
              />
            )}
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
};

Points.propTypes = {
  leagues: PropTypes.array.isRequired,
};

export default Points;
