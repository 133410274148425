import { gql } from "@apollo/client";
const LEAGUE_SPECIFIC_RANKINGS = gql`
  query leaguePageLeagueRanking($leagueId: Int!) {
    leaguePageLeagueRanking(leagueId: $leagueId) {
      id
      place
      points
      tournaments
      user {
        name
        avatar
        id
      }
      league {
        title
      }
      rankingCategory {
        category {
          id
          name
        }
      }
    }
  }
`;

export default LEAGUE_SPECIFIC_RANKINGS;
