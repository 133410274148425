import React from 'react';
import Galleries from './leagueDetails/LeagueGallery';
import { GET_ALL_GALLERIES } from 'src/graphql/queries';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import { useTournament } from './TournamentPage/TournamentPage';

export default function TournamentGallery() {
  const { id } = useParams();
  const { tournament } = useTournament();

  return (
    <div>
      <Galleries gallery={tournament?.Album} />
    </div>
  );
}
