import { useState, FC, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles, useMediaQuery, Stack } from '@material-ui/core';
import { useNavigate } from 'react-router';
import GetFlag from 'src/utils/getFlags';
import { Info } from '@material-ui/icons';
import ApplyModal from './ApplyModal';
import InfoModal from './InfoModal';
import useAuth from 'src/hooks/useAuth';
import DeleteWarning from 'src/components/shared/DeleteWarning';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { DECLINE_PARTNER_SEARCH, REMOVE_PARTNER_SEARCH } from 'src/graphql/mutations';
import CustomButton from '../../../CustomComponents/CustomButton';
import chroma from 'chroma-js';
import { Theme } from '@material-ui/core/styles';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgba(25, 54, 96, 0.70)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  country: {
    color: '#1B3861',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  selectInput: {
    '& .MuiInputBase-root': {
      height: '36px!important',
    },
  },
  textfield: {
    // height: "41px",
    flexShrink: 0,
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      //   height: "40px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#1B3861',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  subText: {
    color: '#193660',
    // textAlign: "center",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
}));

const CustomTableRow = withStyles({
  root: {
    borderBottom: 'none',
    padding: 0,
  },
})(TableCell);

const CustomTableHead = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0px 15px 8px 15px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#86909F',
  },
})(TableCell);

const PartnerSearchListView: FC<any> = ({ PartnerSearch, categories, tournament }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [applyModal, setApplyModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { user, setLoginModalOpen } = useAuth();
  const [params] = useSearchParams();
  const [warningModal, setWarningModal] = useState(false);

  const handleApplyModal = (item) => {
    setSelectedItem(item);
    setApplyModal(true);
  };

  const handleInfoModal = (item) => {
    setSelectedItem(item);
  };

  const declinePartner = params.get('declinePartner');
  const user2 = params.get('user2');

  useEffect(() => {
    if (declinePartner) {
      setWarningModal(true);
    }
  }, [declinePartner]);

  const [declineReq] = useMutation(DECLINE_PARTNER_SEARCH, {
    refetchQueries: ['partnerSearches'],
  });

  const handleDecline = () => {
    setWarningModal(false);
    toast.promise(
      declineReq({
        variables: {
          id: parseInt(declinePartner),
          partner: null,
        },
      }),
      {
        loading: 'Declining...',
        success: () => {
          navigate(`/tournament/${tournament?.id}?tab=search`);
          return `Declined ${user2} as your partner`;
        },
        error: 'Error declining',
      }
    );
  };

  const [removePartnerSearch] = useMutation(REMOVE_PARTNER_SEARCH, {
    refetchQueries: ['partnerSearches'],
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (entryData, handleClose?: () => void) => {
    try {
      const variables = {
        id: entryData?.id,
      };
      await removePartnerSearch({ variables });
      handleClose?.();
      toast.success(`Partner search record removed successfully`);
    } catch (err) {
      toast.error(err?.message);
      setIsDeleting(false);
    }
  };

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <>
      {isTablet ? (
        <Stack sx={{ width: '100%' }}>
          {PartnerSearch?.length > 0 &&
            PartnerSearch?.map((entry, index) => (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '8px',
                  ':not(:last-child)': { borderBottom: '1px solid rgba(0, 0, 0, 0.10)', paddingBottom: '12px' },
                  ':not(:first-child)': { paddingTop: '12px' },
                }}
              >
                <Typography variant="font15" sx={{ textTransform: 'uppercase', lineHeight: '26px' }}>
                  {index + 1}.
                </Typography>
                <Stack sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <GetFlag country={entry?.user?.citizenship ? entry?.user?.citizenship : entry?.user?.nation} style={{ width: '15px', height: '12px', marginTop: '3px', borderRadius: '3px' }} />

                      <CustomAvatar onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} src={entry?.user?.avatar} size={24} seed={entry?.user?.name + ' ' + entry.user?.surname} />
                      <CustomButton size="auto" variant="text" onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)}>
                        <Typography color="#0A2540" variant="font18">
                          {`${entry?.user?.name?.charAt(0).toUpperCase()}. ${entry?.user?.surname}`}
                        </Typography>
                      </CustomButton>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                        maxWidth: 'max-content',
                      }}
                    >
                      <Box sx={{ width: '6px', height: '6px', borderRadius: '4px', backgroundColor: entry?.status === 'found' ? '#5b8fdb' : '#14a541' }}></Box>
                      <Typography className={classes.country} sx={{ textTransform: 'capitalize' }}>
                        {entry?.status === 'active' ? t('Searching...') : entry?.status}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      paddingBlock: '14px',
                      gap: '20px',
                    }}
                  >
                    <Stack>
                      <Typography variant="font21" color="#0d6f30" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                        {entry?.categoryId?.category?.name}
                      </Typography>
                      <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                        {t('Category')}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="font21" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                        #{entry?.rank ?? 0}
                      </Typography>
                      <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                        {t('Rank')}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <InfoModal entryData={selectedItem} onClick={() => handleInfoModal(entry)} setApplyModal={setApplyModal} isDeleting={isDeleting} handleDelete={handleDelete}>
                      <CustomButton size="medium" shape="chip" variant="primary" color={'#0A25400D'} sx={{ paddingInline: '12px', gap: '4px', 'svg > *': { fill: '#0A254059' }, color: '#0A254059' }}>
                        <Info /> {t('Info')}
                      </CustomButton>
                    </InfoModal>
                    {entry?.status === 'found' ? (
                      <CustomButton disabled size="medium" variant="outline" sx={{ minWidth: '100px', flex: 1, maxWidth: '150px' }}>
                        Partner found
                      </CustomButton>
                    ) : user?.id ? (
                      entry?.user?.id === user?.id ? (
                        <CustomButton
                          size="medium"
                          variant="primary"
                          color="#C41E3A"
                          sx={{ width: '100px', flex: 1, maxWidth: '150px' }}
                          onClick={() => {
                            handleDelete(entry);
                          }}
                        >
                          {t('Delete')}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          size="medium"
                          variant="primary"
                          sx={{ width: '100px', flex: 1, maxWidth: '150px' }}
                          onClick={() => {
                            handleApplyModal(entry);
                          }}
                        >
                          {t('Apply')}
                        </CustomButton>
                      )
                    ) : (
                      <CustomButton
                        size="medium"
                        variant="outline"
                        sx={{ minWidth: '100px', flex: 1, maxWidth: '150px' }}
                        onClick={() => {
                          setLoginModalOpen(true);
                        }}
                        disabled={entry?.status === 'found' || entry?.user?.id === user?.id}
                      >
                        {t('Log in to apply')}
                      </CustomButton>
                    )}
                  </Box>
                </Stack>
              </Box>
            ))}
        </Stack>
      ) : (
        <Box sx={{ minWidth: 800, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableHead sx={{ width: '1%' }}>#</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Player')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Rank')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Category')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Status')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}></CustomTableHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {PartnerSearch?.length > 0 &&
                PartnerSearch?.map((entry, index) => (
                  <TableRow
                    hover
                    key={entry?.id}
                    sx={{
                      'td > div': {
                        overflow: 'hidden',
                        height: '55px',
                        background: index % 2 === 0 ? '#FBFBFB' : '#fff',
                        border: index % 2 === 0 ? '1px solid #EDF1F6' : '1px solid transparent',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 15px',
                      },
                      'td:not(:first-child):not(:last-child) > div': {
                        borderLeft: 'none',
                        borderRight: 'none',
                      },
                      'td:first-child > div': {
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        borderRight: 'none',
                      },
                      'td:last-child > div': {
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                        borderLeft: 'none',
                      },
                    }}
                  >
                    <CustomTableRow sx={{ width: '2%', maxWidth: '40px' }}>
                      <Box>
                        <Typography color="#0A2540" variant="font18">
                          {index + 1}
                        </Typography>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box sx={{ gap: '8px' }}>
                        <GetFlag country={entry?.user?.citizenship ? entry?.user?.citizenship : entry?.user?.nation} style={{ width: '15px', height: '12px', marginTop: '3px', borderRadius: '3px' }} />

                        <CustomAvatar onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} src={entry?.user?.avatar} size={24} seed={entry?.user?.name + ' ' + entry.user?.surname} />
                        <CustomButton size="auto" variant="text" onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)}>
                          <Typography color="#0A2540" variant="font18">
                            {`${entry?.user?.name?.charAt(0).toUpperCase()}. ${entry?.user?.surname}`}
                          </Typography>
                        </CustomButton>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box>
                        <Typography className={classes.country}>#{entry?.rank ? entry?.rank : 0}</Typography>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box>
                        <Typography className={classes.country} sx={{ background: '#ecf7ec', width: 'max-content', color: '#0d6f30 !important', padding: '2px 8px', borderRadius: '5px' }}>
                          {entry?.categoryId?.category?.name}
                        </Typography>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                            maxWidth: 'max-content',
                          }}
                        >
                          <Box sx={{ width: '6px', height: '6px', borderRadius: '4px', backgroundColor: entry?.status === 'found' ? '#5b8fdb' : '#14a541' }}></Box>
                          <Typography className={classes.country} sx={{ textTransform: 'capitalize' }}>
                            {entry?.status === 'active' ? t('Searching...') : entry?.status}
                          </Typography>
                        </Box>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end', width: '100%', alignItems: 'center' }}>
                          {entry?.status === 'found' ? (
                            <CustomButton disabled size="medium" variant="outline" sx={{ minWidth: '100px' }}>
                              Partner found
                            </CustomButton>
                          ) : user?.id ? (
                            entry?.user?.id === user?.id ? (
                              <CustomButton
                                size="medium"
                                variant="primary"
                                color="#C41E3A"
                                sx={{ minWidth: '100px' }}
                                onClick={() => {
                                  handleDelete(entry);
                                }}
                              >
                                {t('Delete')}
                              </CustomButton>
                            ) : (
                              <CustomButton
                                size="medium"
                                variant="primary"
                                sx={{ minWidth: '100px' }}
                                onClick={() => {
                                  handleApplyModal(entry);
                                }}
                              >
                                {t('Apply')}
                              </CustomButton>
                            )
                          ) : (
                            <CustomButton
                              size="medium"
                              variant="outline"
                              sx={{ minWidth: '100px' }}
                              onClick={() => {
                                setLoginModalOpen(true);
                              }}
                              disabled={entry?.status === 'found' || entry?.user?.id === user?.id}
                            >
                              {t('Log in to apply')}
                            </CustomButton>
                          )}
                          <InfoModal entryData={selectedItem} onClick={() => handleInfoModal(entry)} setApplyModal={setApplyModal} isDeleting={isDeleting} handleDelete={handleDelete}>
                            <CustomButton
                              shape="circle"
                              size="small"
                              variant="outline"
                              color={chroma('#0A254059').darken(2).hex()}
                              sx={{ padding: 0, minHeight: '32px', minWidth: '32px', 'svg > *': { fill: '#0A254059' } }}
                            >
                              <Info sx={{ fontSize: '32px' }} />
                            </CustomButton>
                          </InfoModal>
                        </Box>
                      </Box>
                    </CustomTableRow>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <DeleteWarning
            openDeleteModal={warningModal}
            handleClose={() => setWarningModal(false)}
            handleDelete={handleDecline}
            warningTitle="Confirm Decline"
            warningText={`Are you sure you want to decline ${user2} as your partner?`}
            confirmText="Decline"
          />
        </Box>
      )}
      <ApplyModal openModal={applyModal} setOpenModal={setApplyModal} categories={categories} entryData={selectedItem} />
    </>
  );
};

export default PartnerSearchListView;
