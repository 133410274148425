import { gql } from '@apollo/client';

const LEAGUE_RANKINGS = gql`
  query leagueRankings($filter: ListLeagueRankingInput!, $country: String, $platform: Int) {
    leagueRankings(country: $country, filter: $filter, platform: $platform) {
      createdAt
      date
      id
      rankingCategory {
        id
        category {
          id
          name
        }
      }
      league {
        id
        title
        logo
      }
      user {
        id
        name
        surname
        country
        nation
        avatar
        dob
      }
      points
      place
      tournaments
      thirdPartyPoints
    }
  }
`;

export default LEAGUE_RANKINGS;
