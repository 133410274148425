import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ALL_TOURNAMENT_CATEGORIES, TOP_FIVE_PLAYERS } from '../../../../graphql/queries';
import CustomSection from '../../../layout/CustomSection';
import { useNavigate } from 'react-router';
import CustomButton from '../../../CustomComponents/CustomButton';
import { AutoGraph } from '@material-ui/icons';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import { useTranslation } from 'react-i18next';
import Top5AthletesList from './Top5AthletesList';
import { useTournament } from '../TournamentPage';

const FeaturedAthletes = () => {
  const { id } = useTournament();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [category, setCategory] = useState<any>(null);
  const [categories, setCategories] = useState([]);

  useQuery(ALL_TOURNAMENT_CATEGORIES, {
    skip: !id,
    variables: {
      filter: {
        tournament: id,
        registrationStatus: 'confirm',
      },
    },
    onCompleted: (data) => {
      const validCategories = data.allTournamentCategories?.filter((item) => item?.count > 0);
      setCategories(validCategories);
      if (validCategories.length > 0) {
        setCategory(validCategories[0]?.category?.id);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { data: athletes, loading: athletesLoading } = useQuery(TOP_FIVE_PLAYERS, {
    variables: {
      tournamentId: id,
      ...(category ? { categoryId: parseInt(category) } : { categoryId: parseInt(category) }),
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data?.tournamentTopFivePlayers?.length) {
        const currentIndex = categories.findIndex((cat) => cat.category.id === category);
        const nextCategory = categories.slice(currentIndex + 1).find((cat) => cat.count > 0);
        if (nextCategory) {
          setCategory(nextCategory.category.id);
        } else {
          return;
        }
      }
    },
  });

  const isLastCategory = categories.findIndex((cat) => cat.category.id === category) === categories.length - 1;
  const noPlayersInCategory = !athletesLoading && !athletes?.tournamentTopFivePlayers?.length;

  if (isLastCategory && noPlayersInCategory) {
    return null;
  }

  return (
    <CustomSection
      gap={6}
      icon={<AutoGraph />}
      title={t('Featured athletes')}
      sx={{ mt: 0 }}
      titleRightSideElement={
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <CustomButton
            size="auto"
            variant="text"
            onClick={() => {
              const currentUrl = new URL(window.location.href);
              currentUrl.searchParams.set('tab', 'participants');
              navigate(currentUrl.pathname + currentUrl.search);
            }}
          >
            <Typography variant="font18" sx={{ textDecoration: 'underline' }} color="primary">
              {t('View all')}
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <CustomPaperCard sx={{ padding: { xs: '12px', md: '22px' }, display: 'flex', gap: '16px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <CustomSelect
            placeholder={t('Select category')}
            options={categories?.map((item) => ({ title: item?.category?.category?.name, value: item?.category?.id }))}
            onChange={(value) => setCategory(parseInt(value))}
            selectedValue={category}
            buttonProps={{ sx: { flex: { xs: 1, sm: 'none' } }, size: 'medium' }}
            matchTriggerWidth
          />
          <Top5AthletesList athletes={athletes} loading={athletesLoading} />
        </Box>
      </CustomPaperCard>
    </CustomSection>
  );
};

export default FeaturedAthletes;
