import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Link, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import CloseIcon from '@material-ui/icons/Close';
import useAuth from 'src/hooks/useAuth';
import BlockIcon from '@material-ui/icons/Block';
import { FishResults, singleResult } from 'src/constants';
import Scrollbar from 'src/components/Scrollbar';
import GetFlag from 'src/utils/getFlags';
import AddResultsModal from './AddResults';
import { useQuery } from '@apollo/client';
import { SPORTS_FISHING_RESULTS, SPORTS_FISHING_RESULTS_BY_FILTER } from 'src/graphql/queries';
import Loading from 'src/components/Loading';
import moment from 'moment';

interface AddResultsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  activeStage: string | number;
  category: number;
  zone: string;
  isCustomMetrics: boolean;
}

const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 18,
    width: '100%',
  },
  modalTitle: {
    fontFamily: 'Eudoxus Sans',
    fontSize: '18px',
    fontWeight: 700,
  },
  heading: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: 12,
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#0A2540',
  },
}));

const ResultsModal: FC<AddResultsProps> = ({ open, setOpen, activeStage, category, zone, isCustomMetrics }) => {
  const auth = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [openAddResults, setOpenAddResults] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const { loading, data } = useQuery(SPORTS_FISHING_RESULTS_BY_FILTER, {
    skip: !parseInt(id) || !activeStage || activeStage === 'total' || activeStage === 'team' || !open,
    variables: {
      filter: {
        tournament: parseInt(id),
        ...(category && { categoryId: category }),
        ...(activeStage && activeStage !== 'total' && activeStage !== 'team' && { stage: activeStage }),
        ...(zone && { location: zone }),
        entry: auth?.user?.id,
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '912px',
            borderRadius: '16px',

            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            outline: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Paper elevation={12} sx={{ width: '100%', background: '#FFF', padding: '30px' }}>
            <Box
              sx={{
                // padding: "30px 30px 0px 30px",
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography className={classes.modalTitle}>
                {/* Results Pike (CM2) */}
                My Results
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>
            {loading ? (
              <Loading height="20vh" />
            ) : data?.sportFishingResultEntries?.length === 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                <Typography className={classes.text}>No Results Found</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  mt: 2,
                  padding: '12px',
                  backgroundColor: '#fff',
                  borderRadius: '6px',
                  border: '1px solid #EDF1F6',
                }}
              >
                <Scrollbar>
                  <Box sx={{ minWidth: 700 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <CustomTableCell className={classes.heading}>Nr</CustomTableCell>
                          <CustomTableCell className={classes.heading}>Zone</CustomTableCell>
                          {/* <CustomTableCell className={classes.heading}>Identify Unit (cm)</CustomTableCell> */}
                          <CustomTableCell className={classes.heading}>Weight</CustomTableCell>
                          <CustomTableCell className={classes.heading}>Result</CustomTableCell>

                          <CustomTableCell align="left" className={classes.heading}>
                            Submit Time
                          </CustomTableCell>
                          {/* <CustomTableCell align="left" className={classes.heading}>
                          Approved Time
                        </CustomTableCell> */}
                          {/* <CustomTableCell align="left" className={classes.heading}>
                          History
                        </CustomTableCell> */}
                          {/* <CustomTableCell align="left" className={classes.heading}>
                            Attachments
                          </CustomTableCell> */}
                          {/* <CustomTableCell align="left" className={classes.heading}></CustomTableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.sportFishingResultEntries?.map((entry, index) => (
                          <TableRow
                            key={entry.id}
                            sx={{
                              border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                              background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                            }}
                          >
                            <CustomTableCell style={{ width: '5%' }}>
                              <Typography className={classes.text}>{index + 1}</Typography>
                            </CustomTableCell>
                            <CustomTableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
                              <Typography className={classes.text}>{entry?.location || 0}</Typography>
                            </CustomTableCell>
                            <CustomTableCell style={{ width: '20%' }}>
                              <Typography className={classes.text} sx={{ whiteSpace: 'nowrap' }}>
                                {entry?.weight}
                              </Typography>
                            </CustomTableCell>
                            <CustomTableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
                              <Typography className={classes.text}>{entry?.points || 0}</Typography>
                            </CustomTableCell>
                            <CustomTableCell align="left">
                              <Typography className={classes.text}>{moment(entry?.updatedAt).format('DD/MM/YYYY')}</Typography>
                            </CustomTableCell>
                            {/* <CustomTableCell>
                            <Typography className={classes.text}>01/07/2023</Typography>
                          </CustomTableCell> */}
                            {/* <CustomTableCell align="center">
                            <Typography className={classes.text}>Pending</Typography>
                          </CustomTableCell> */}
                            {/* <CustomTableCell align="center">
                              <Box sx={{ display: 'flex', gap: '3px' }}>
                                <img src="/images/updated/attachment.svg" />
                                <img src="/images/updated/attachment.svg" />
                              </Box>
                            </CustomTableCell> */}
                            {/* <CustomTableCell align="center">
                            <Box sx={{ display: 'flex', gap: '3px' }}>
                              <img src="/images/updated/editdark.svg" />
                              <img src="/images/updated/deldark.svg" />
                            </Box>
                          </CustomTableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Scrollbar>
              </Box>
            )}
            {isCustomMetrics && (
              <Box sx={{ paddingTop: '28px' }}>
                <Button
                  sx={{
                    maxWidth: '158px',
                    width: '100%',
                    height: '38px',
                    color: '#fff',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    setOpenAddResults(true), setOpen(false);
                  }}
                >
                  Add Results
                </Button>
              </Box>
            )}
          </Paper>
        </Box>
      </Modal>
      <AddResultsModal openAddResults={openAddResults} setOpenAddResults={setOpenAddResults} />
    </React.Fragment>
  );
};

export default ResultsModal;
