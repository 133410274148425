import { gql } from "@apollo/client";

const CREATE_PLATFORM_SPORT = gql`
  mutation createPlateformSport($sport: Int!, $platform: Int!) {
    createPlateformSport(
      createPlateformSportInput: { sport: $sport, platform: $platform }
    ) {
      id
    }
  }
`;
export default CREATE_PLATFORM_SPORT;
