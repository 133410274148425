import { Tooltip, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import CustomPaperCard from './CustomPaperCard';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    fontSize: 14,
  },
  customArrow: {
    color: 'transparent',
  },
}));

const CustomTooltip = ({ mobileFriendly = true, disabled, ...props }: CustomTooltipProps & { mobileFriendly?: boolean; disabled?: boolean }) => {
  const { children, title, sx, ...otherProps } = props;
  const classes = useStyles();

  const isMobile = mobileFriendly && useMediaQuery('(hover: none) and (pointer: coarse)');

  return disabled ? (
    children
  ) : !isMobile ? (
    <Tooltip
      title={<CustomPaperCard sx={{ padding: '6px', ...sx }}>{title}</CustomPaperCard>}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, isMobile ? -15 : -5],
            },
          },
        ],
      }}
      {...otherProps}
    >
      {children}
    </Tooltip>
  ) : (
    <CustomPopover modalOnMobile triggerEl={children}>
      {title}
    </CustomPopover>
  );
};

type CustomTooltipProps = ComponentProps<typeof Tooltip>;

export default CustomTooltip;
