import { gql } from "@apollo/client";

const CREATE_PARTNER_SEARCH = gql`
  mutation createPartnerSearch($user: Int!, $tournament: Int!, $categoryId: Int!, $status: String, $selfInformation: String, $partnerInformation: String, $rank: Int, $userEmail: String) {
    createPartnerSearch(
      createPartnerSearchInput: {
        user: $user
        tournament: $tournament
        categoryId: $categoryId
        status: $status
        selfInformation: $selfInformation
        partnerInformation: $partnerInformation
        rank: $rank
        userEmail: $userEmail
      }
    ) {
      id
    }
  }
`;
export default CREATE_PARTNER_SEARCH;
