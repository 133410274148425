import React, { PropsWithChildren } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  blackButton: {
    color: '#fff !important',
    background: '#000 !important',
    '&:hover': {
      background: '#333 !important',
    },
  },
  whiteButton: {
    background: '#FFFF',
    color: '#425466',
    border: `1px solid #EDF1F6`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  grayButton: {
    minHeight: '40px',
    maxHeight: '55px',
    height: 'auto',
    background: '#FAFAFA',
    color: '#0A2540',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));

const OutlinedButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  return (
    <Button variant="outlined" {...props}>
      {children}
    </Button>
  );
};

const PrimaryButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  return (
    <Button variant="contained" color="primary" {...props}>
      {children}
    </Button>
  );
};

const BlackButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" className={classes.blackButton} {...props}>
      {children}
    </Button>
  );
};

const WhiteButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" className={classes.whiteButton} {...props}>
      {children}
    </Button>
  );
};

const GrayButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" disableElevation className={classes.grayButton} {...props}>
      {children}
    </Button>
  );
};

export { OutlinedButton, PrimaryButton, BlackButton, WhiteButton, GrayButton };
