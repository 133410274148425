import { gql } from '@apollo/client';

const LEAGUE_LATEST_WINNER = gql`
  query leagueLatestWinners($limit: Int, $filter: ListTournamentRankingsEntryInput) {
    leagueLatestWinners(limit: $limit, filter: $filter) {
      id
      createdAt
      updatedAt
      tournamentCategory {
        id
        category {
          id
          name
        }
      }
      league {
        id
      }
      tournament {
        id
        title
        startDate
        endDate
      }
      points
      place
      entry {
        id
        users {
          id
          rank
          user {
            id
            name
            surname
            avatar
            nation
            citizenship
          }
        }
      }
    }
  }
`;
export default LEAGUE_LATEST_WINNER;
