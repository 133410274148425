import type { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const prevRoute = Cookies.get("prevRoute");
  if (isAuthenticated && prevRoute) {
    return <Navigate to={prevRoute} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
