import { gql } from '@apollo/client';

const TournamentRegistration = gql`
  mutation createTournamentRegistration(
    $type: String!
    $tournament: Int!
    $user: [String!]
    $userId: Int
    $status: String!
    $request: String!
    $termsAndConditions: Boolean!
    $additionalQuestion: [JSONObject!]
    $additionalFee: Float
    $categoryId: Int!
    $partnerSearchId: Int
    $redirectURL: String
    $platformId: Float
    $bothPaymentMethod: String
  ) {
    createTournamentRegistration(
      type: $type
      partnerSearchId: $partnerSearchId
      redirectURL: $redirectURL
      platformId: $platformId
      bothPaymentMethod: $bothPaymentMethod
      createTournamentRegistrationInput: {
        categoryId: $categoryId
        tournament: $tournament
        user: $user
        userId: $userId
        status: $status
        request: $request
        termsAndConditions: $termsAndConditions
        additionalFee: $additionalFee
        additionalQuestion: $additionalQuestion
      }
    ) {
      id
      status
      tournament {
        club {
          id
        }
      }
      additionalFee
      # user {
      #   id
      #   city
      #   user_poster
      # }
    }
  }
`;

export default TournamentRegistration;
