import { gql } from "@apollo/client";
const GETTEAMBYID = gql`
  query teamById($id: Int!) {
    team(id: $id) {
      createdAt
      id
      title
      image
      sport {
        id
        title
      }
      club {
        id
      }
      discipline {
        id
      }
      country
      status
      image
      members {
        id
        member {
          email
          surname
          name
          id
          avatar
          nation
        }
        team {
          title
        }
        role
      }
    }
  }
`;
export default GETTEAMBYID;
