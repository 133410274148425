import { gql } from "@apollo/client";

const REMOVE_USER_COACH = gql`
  mutation removeUserCoach($id: Int!) {
    removeUserCoach(id: $id) {
      user {
        id
      }
    }
  }
`;

export default REMOVE_USER_COACH;
