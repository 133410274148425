import { useEffect } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { PasswordRecoveryAmplify } from 'src/components/authentication/password-recovery';
import { useTranslation } from 'react-i18next';
import { Modals } from './AuthModal';

type Props = {
  handleClose: () => void;
  setModal: (state: Modals) => void;
  backButtonClickCounter: number;
  setBackButtonClickCounter: (state: number) => void;
  setShowBackBtn: (state: boolean) => void;
};

const ForgetModal = ({ handleClose, setModal, backButtonClickCounter, setShowBackBtn, setBackButtonClickCounter }: Props) => {
  const { platform } = useAuth() as any;
  const { t } = useTranslation();

  useEffect(() => {
    setShowBackBtn(true);
    if (backButtonClickCounter > 0) {
      setModal('login');
    }

    return () => {
      setShowBackBtn(false);
      setBackButtonClickCounter(0);
    };
  }, [backButtonClickCounter]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
              {t('Enter the e-mail address')}
            </Typography>
            <Typography
              variant="font21"
              sx={{
                color: '#0A2540',
                lineHeight: '20px',
                opacity: '35%',
              }}
            >
              {t('Enter your e-mail address with which you are registered')}
            </Typography>
            <Box sx={{ mt: 1.5 }}>
              <Divider />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'max-content',
              maxHeight: '100%',
              minHeight: '300px',
            }}
          >
            {platform === 'JWT' && <PasswordRecoveryAmplify handleClose={handleClose} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgetModal;
