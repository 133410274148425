import type { FC } from 'react';
import { Fragment } from 'react';
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles } from '@material-ui/core';
import Scrollbar from '../../../Scrollbar';
import moment from 'moment';
import GetFlag from 'src/utils/getFlags';
import ScoresCells from 'src/components/widgets/tables/groupsTable/ScoreCells';
import { formatSeed } from 'src/constants';
import Loading from 'src/components/Loading';
import NoDataFound from '../../NoDataFound';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  winner: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  loser: {
    color: 'rgba(25, 54, 96, 0.40)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  ponits: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  heading: {
    color: 'var(--bright-blue-60, rgba(27, 56, 97, 0.60))',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  head: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  matchNotFinish: {
    padding: '5px 0',
    // border: "1px solid rgba(25, 29, 80, 0.06)",
    marginBottom: '8px',
    borderRadius: '6px',
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  playerName: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const Standings: FC<any> = ({ groupsTableData, loading, selectedCat }) => {
  const classes = useStyles();

  const getMatchData = (groupMatches, entry1ID, entry2ID) => {
    const groupMatchData = groupMatches?.find((groupMatch) => {
      const { entry1, entry2 } = groupMatch;
      return (entry1.id === entry2ID && entry2.id === entry1ID) || (entry1.id === entry1ID && entry2.id === entry2ID);
    });

    if (groupMatchData) {
      const scoresByRound = groupMatchData.score.reduce((acc, score) => {
        const round = score.round;
        if (!acc[round]) {
          acc[round] = [];
        }
        acc[round].push(score);
        return acc;
      }, {});

      let formattedScoresString = '';

      Object.keys(scoresByRound).forEach((round) => {
        const scoresForRound = scoresByRound[round];
        const formattedScores = [];

        const entry1 = scoresForRound[0];
        const entry2 = scoresForRound[1];

        if (entry1 && entry2) {
          if (entry1.entry.id === entry1ID && entry2.entry.id === entry2ID) {
            entry1.roundFormat === 'tie' ? formattedScores.push(`[${entry1.score}:${entry2.score}]`) : formattedScores.push(`${entry1.score}:${entry2.score}`);
          } else {
            entry2.roundFormat === 'tie' ? formattedScores.push(`[${entry2.score}:${entry1.score}]`) : formattedScores.push(`${entry2.score}:${entry1.score}`);
          }
        }
        formattedScoresString += formattedScores.join(' ') + ' ';
      });

      // Remove the trailing space if needed
      formattedScoresString = formattedScoresString.trim();
      return { ...groupMatchData, score: formattedScoresString };
    }

    return null;
  };
  const MatchesTableCellData = (entry1ID, entry2ID, group) => {
    const updatedMatchData = getMatchData(group.matches, entry1ID, entry2ID);
    return updatedMatchData?.score?.length > 0 ? (
      <Fragment>
        <Typography
          sx={{
            // borderBottom: "1px solid #E7EBF0",
            pb: '5px',
          }}
          className={classes.text}
        >
          {updatedMatchData?.winner?.id === entry1ID ? 1 : 0}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.text} sx={{ mt: '5px' }}>
            {updatedMatchData?.score || ''}
          </Typography>
        </Box>
      </Fragment>
    ) : (
      <Fragment>
        {updatedMatchData?.date && updatedMatchData?.time && (
          <Box className={classes.matchNotFinish}>
            <Typography className={classes.text}>{`${moment?.utc(updatedMatchData?.date).format('DD/MM')} ${updatedMatchData?.time}` || ''}</Typography>
            <Typography className={classes.text} sx={{ mt: '4px', fontSize: '12px !important' }}>
              {updatedMatchData?.court ? `${updatedMatchData?.court.name}` : ''}
            </Typography>
          </Box>
        )}
      </Fragment>
    );
  };
  return (
    <Box
      sx={{
        // backgroundColor: "background.default",
        // minHeight: "100%",
        width: '100%',
      }}
    >
      {groupsTableData?.length ? (
        groupsTableData?.map((group: Record<string, any>, index: number) => (
          <Box sx={{ width: '100%' }} key={group.id}>
            {groupsTableData?.length > 1 && !group.hide && !group.isDelete && (
              <Box sx={{ margin: '10px 0' }}>
                <Typography
                  sx={{
                    color: '#193660',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    padding: '0px 8px',
                  }}
                >
                  {group?.name}
                </Typography>
                {!selectedCat && (
                  <Box
                    sx={{
                      padding: '0px 8px',
                      position: 'relative',
                      display: 'flex',
                      fontSize: '12px',
                    }}
                  >
                    {group?.tournamentCategory?.category?.name}{' '}
                    <Box
                      sx={{
                        fontSize: '10px',
                        ml: 0.2,
                        mt: -0.5,
                        // position:"absolute"
                      }}
                    >
                      {group?.segment}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {/* <Divider /> */}
            {!group.hide && !group.isDelete && (
              <Scrollbar>
                <Box
                  sx={{
                    // minWidth: 700,
                    borderRadius: '6px',
                    border: '1px solid #EDF1F6',
                    background: '#FFF',
                    padding: '0px 8px 8px 8px',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ height: '36px' }}>
                        <CustomTableCell align="left" className={classes.tablehead}>
                          PLACE
                        </CustomTableCell>
                        <CustomTableCell
                          align="left"
                          className={classes.tablehead}

                          // sx={{ borderRight: "1px solid #E7EBF0" }}
                        >
                          {group?.tournamentCategory?.category?.type == 'single' ? 'ATHLETE' : group?.tournamentCategory?.category?.type == 'doubles' ? 'PAIR' : 'TEAM'}
                        </CustomTableCell>
                        <CustomTableCell align="center" className={classes.tablehead}>
                          POINTS
                        </CustomTableCell>
                        <CustomTableCell align="center" className={classes.tablehead}>
                          SR
                        </CustomTableCell>
                        <CustomTableCell align="center" className={classes.tablehead}>
                          PR
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {group?.entries?.map((entry, index) => (
                        <TableRow
                          hover
                          key={entry.id}
                          sx={{
                            border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                            background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                          }}
                        >
                          <CustomTableCell
                            sx={{
                              width: '1%',
                              maxWidth: 'max-content',
                            }}
                          >
                            <Typography className={classes.text}>{entry?.place || '-'}</Typography>
                          </CustomTableCell>
                          <CustomTableCell
                            sx={{
                              width: '1%',
                              maxWidth: 'max-content',
                            }}
                            align="left"
                          >
                            <Box>
                              {entry?.registrationRequest?.users?.map((user, index, usersArray) => (
                                <Box
                                  key={user.id}
                                  sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <GetFlag
                                    country={user?.user?.nation}
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                    }}
                                  />
                                  <Typography
                                    onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                                    style={{
                                      cursor: 'pointer',
                                      textTransform: 'uppercase',
                                      whiteSpace: 'nowrap',
                                      marginTop: '1px',
                                    }}
                                    // ref={(el) => (userRefs.current[user?.user?.id] = el)}
                                    className={classes.text}
                                  >
                                    {`${user?.user?.name?.charAt(0).toUpperCase()}. ${user?.user?.surname}`}
                                    {(entry?.registrationRequest?.seed || entry?.registrationRequest?.assignedSegment) &&
                                      index === usersArray?.length - 1 &&
                                      formatSeed(
                                        {
                                          seed: entry?.registrationRequest?.seed,
                                          assignedSegment: entry?.registrationRequest?.assignedSegment,
                                          additionalSegment: entry?.registrationRequest?.additionalSegment,
                                        },
                                        group?.segment
                                      )}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </CustomTableCell>
                          <ScoresCells groupType={group?.groupType} entryID={entry?.id} isGroup={false} entryPlace={entry?.place} />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            )}
          </Box>
        ))
      ) : (
        <NoDataFound
          text={'Standing are not available yet'}
          sx={{
            margin: '150px 0',
          }}
        />
      )}
    </Box>
  );
};

export default Standings;
