import { gql } from "@apollo/client";

const WITHDRAWENTRY = gql`
  mutation withdraw($id:Int! $withdrawReason: String ) {
    withdrawRegistrations(
    updateTournamentRegistrationInput:{id:$id, withdrawReason:$withdrawReason} ){
      id
      status
    }
  }
`;

export default WITHDRAWENTRY;
