import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTournament } from '../TournamentPage/TournamentPage';
import CustomPaperCard from 'src/components/CustomComponents/CustomPaperCard';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { Info } from '@material-ui/icons';

const CustomSuccess = () => {
  const { t } = useTranslation();
  const { tournament, loading } = useTournament();
  const [isInfoOverflowing, setIsInfoOverflowing] = useState(false);

  const doesInfoExist = !!tournament?.customSuccessMessage?.replace(/<\/?[^>]+(>|$)/g, '') && !tournament.customSuccessMessage.toLowerCase().includes('null');
  const info = doesInfoExist ? tournament?.customSuccessMessage?.replaceAll('<p><br></p>', '<br>') : undefined;
  const infoRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (infoRef.current) {
        setIsInfoOverflowing(infoRef.current.scrollHeight > infoRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsInfoOverflowing, info, infoRef]);

  if (loading) return <CustomPaperCard skeleton sx={{ width: '100%', height: '300px' }} />;

  if (!info) return null;

  return (
    <>
      <Box sx={{ height: '1px', width: '100%', background: '#DFDFDF' }} />
      <Stack gap="14px" sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#0A25400D', borderRadius: '6px', padding: '10px', my: '10px' }}>
          <Info sx={{ marginRight: '8px', fontSize: '20px', color: '#d5d5d5' }} />
          <Box
            ref={infoRef}
            sx={{
              overflow: 'hidden',
              maxHeight: '65px',
              '& > *': {
                padding: 0,
                margin: 0,
                width: '100%',
                overflow: 'hidden',
                fontSize: '12px',
                '& p': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                '& li': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                'h1, h2, h3': {
                  margin: '0px',
                },
              },
              WebkitMaskImage: isInfoOverflowing ? 'linear-gradient(to top, transparent, black 90% )' : 'none',
            }}
            dangerouslySetInnerHTML={{ __html: info }}
          />
        </Box>
        {isInfoOverflowing && (
          <CustomModal
            triggerEl={
              <CustomButton
                size="xs"
                shape="chip"
                variant="secondary"
                sx={{
                  // borderColor: '#86909F',
                  background: '#E6E9EC',
                  color: '#86909F',
                  gap: '4px',
                  paddingInline: '16px',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, -60%)',
                }}
              >
                {t('read_more')}
              </CustomButton>
            }
          >
            <Box
              sx={{
                maxWidth: '700px',
                overflow: 'auto',
                fontSize: '12px',
                '& p': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                '& li': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                'h1, h2, h3': {
                  margin: '0px',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: doesInfoExist ? info : t('CustomSuccess is missing'),
              }}
            />
          </CustomModal>
        )}
      </Stack>
    </>
  );
};

export default CustomSuccess;
