import { gql } from "@apollo/client";

const DELETE_USER_SPORT = gql`
  mutation removeUserSport($id: Int!) {
    removeUserSport(id: $id) {
      sport {
        id
      }
    }
  }
`;

export default DELETE_USER_SPORT;
