import { gql } from "@apollo/client";

const REMOVE_FRIEND = gql`
mutation removeFriend($userId:Int! $friendId:Int!) {
    removeFriend(userId:$userId,friendId:$friendId){
      id
      friends{
        id
      }
    }
  }
  `;
export default REMOVE_FRIEND;