import { gql } from '@apollo/client';

const SPORTS = gql`
  query sports($filter: ListSportInput!, $platform: Int) {
    sports(filter: $filter, platform: $platform) {
      id
      title
      icon
      abbreviation
      enableDiscipline
      enableCourts
      enableStages
      displayDraws
      displayGroups
      displayLiveStandings
      displayOrderPlay
      displayListResults
      displayPendingResults
      displayTournamentResults
      textBox
    }
  }
`;

export default SPORTS;
