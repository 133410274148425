import { Box, Dialog } from '@material-ui/core';
import { useState } from 'react';

const MediaPopup = ({ src, className, type }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {type === 'image' ? (
        <img src={src} className={className} onClick={handleClickOpen} style={{ cursor: 'zoom-in' }} />
      ) : (
        <video src={src} className={className} onClick={handleClickOpen} style={{ cursor: 'zoom-in' }} />
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ height: '90vh' }}>
        <Box
          style={{
            display: 'flex',
            overflow: 'auto',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {type === 'image' ? (
            <img src={src} width="100%" style={{ cursor: 'zoom-out' }} onClick={handleClose} />
          ) : (
            <video src={src} width="100%" style={{ cursor: 'zoom-out' }} onClick={handleClose} controls />
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default MediaPopup;