import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Modal, Paper, Typography } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
};

const LicenceModal = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <RegModal openRegModal={openRegModal} setOpenRegModal={setOpenRegModal} tournament={tournament} refetchTournament={refetchTournament} /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '512px',
            borderRadius: '16px',

            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            outline: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Paper elevation={12} sx={{ width: '100%', height: 379, background: '#FFF' }}>
            <Box
              sx={{
                padding: '20px 20px 0px 20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="h2">
                {t('Registration')}
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>
            <Box sx={{ padding: '20px' }}>
              <Divider />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Box sx={{ textAlign: 'center' }}>
                <ReactSVG src="/images/diploma.svg" />
                <Typography
                  sx={{
                    color: 'rgba(25, 54, 96, 0.40)',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                    padding: '10px 0',
                  }}
                >
                  {t('To get register for the tournament you must have active tour licence.')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: '12px 22px 22px 22px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                color="primary"
                fullWidth
                variant="contained"
                sx={{
                  display: 'flex',
                  width: '180px',
                  height: '50px',
                  padding: '10px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '8px',
                  // background: "#29ABE2",
                  color: '#FFF',
                }}
                onClick={() => navigate(`/get-licence`)}
              >
                {t('Get Licence')}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default LicenceModal;
