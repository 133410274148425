import React, { useMemo, useState } from 'react';
import { Box, Container, Stack, Typography, useMediaQuery } from '@material-ui/core';
import AthleteSpace from 'src/components/CustomComponents/CustomModal/Modals/AthleteSpace';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import useAuth from 'src/hooks/useAuth';
import { createPortal } from 'react-dom';
import { useTournament } from './TournamentPage';
import { Theme } from '@material-ui/core/styles';
import TablerIcon from '../../CustomComponents/TablerIcon';
import { useQuery } from '@apollo/client';
import { MyENTERIESUPDATED, USER_ALL_MATCHES } from '../../../graphql/queries';
import GetFlags from '../../../utils/getFlags';
import CustomTooltip from '../../CustomComponents/CustomTooltip';
import useEventLocation from '../../../utils/useEventLocation';
import prettifyDates from '../../../utils/prettifyDates';

const BottomBar = () => {
  const { tournament, id, loading } = useTournament();
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const tournamentLogo = useMemo(() => tournament?.logo ?? tournament?.league?.league?.logo ?? auth?.platformData?.lightLogo, [tournament, auth?.platformData]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [initialTab, setInitialTab] = useState<string>();
  const addressParts = [tournament?.address, tournament?.country, tournament?.city].filter(Boolean);
  const address = addressParts.join(', ');
  const markerPosition = useEventLocation({ address });

  const openModal = (tab: string) => {
    setInitialTab(tab);
    setOpen(true);
  };

  const aboveTabEl = document.getElementById('aboveTabBarContainer');

  const { data: athleteEntries, loading: athleteEntriesLoading } = useQuery(MyENTERIESUPDATED, {
    variables: {
      filter: { users: parseInt(auth?.user?.id), tournament: id },
    },
  });

  const { data: athleteMatches } = useQuery(USER_ALL_MATCHES, {
    variables: {
      user: parseInt(auth?.user?.id),
      page: 1,
      limit: 10,
      filter: {
        tournament: id,
      },
    },
  });

  if (!athleteEntries?.tournamentRegistrations.length || loading) return;
  const pendingPaymentsCount = athleteEntries.tournamentRegistrations.filter((entry) => entry.status === 'payment_pending' || entry.status === 'partial_payment')?.length;

  return (
    <>
      {aboveTabEl &&
        createPortal(
          <Box
            sx={{
              width: '100%',
              height: '65px',
              backgroundColor: '#282828',
              color: '#fff',
              overflow: 'hidden',
              zIndex: 1,
            }}
          >
            <Container maxWidth={false} sx={{ maxWidth: '1920px', display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%', alignItems: 'center' }}>
              {!isMobile && (
                <Stack direction="column" spacing={2} alignItems="flex-start">
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box component="img" src={tournamentLogo} sx={{ objectFit: 'contain', height: '31px', width: '24px', borderRadius: '8px' }} />

                    <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
                      <Typography
                        variant="heading11"
                        sx={{
                          color: '#FFFFFF',
                          lineHeight: '15.12px',
                          textTransform: 'uppercase',
                          cursor: 'pointer',
                          wordWrap: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {tournament?.title || '-'}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <TablerIcon icon="calendar" />
                          <Typography
                            variant="caption"
                            sx={{
                              color: '#FBFEFF',
                              fontFamily: 'Inter',
                              fontSize: 10,
                              fontWeight: 400,
                              lineHeight: '16px',
                            }}
                          >
                            {prettifyDates(tournament.startDate, tournament.endDate, tournament.timeZone.abbreviation)}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <GetFlags
                            country={tournament?.country}
                            style={{
                              width: 16,
                              height: 10,
                              marginTop: 1,
                              borderRadius: 1,
                            }}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              color: '#FBFEFF',
                              fontFamily: 'Inter',
                              fontSize: 10,
                              fontWeight: 400,
                              lineHeight: '16px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: 120,
                            }}
                          >
                            {tournament?.country}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              <Box sx={{ flex: { xs: 1, md: 'none' }, display: 'flex', justifyContent: 'space-between', flexDirection: { md: 'row-reverse' }, gap: '12px' }}>
                <CustomButton variant="primary" size="medium" shape="chip" onClick={() => openModal('entries')} sx={{ gap: '10px', paddingInline: '16px' }}>
                  Your details
                  <TablerIcon icon="info-circle" fontSize="24px" />
                </CustomButton>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', marginRight: '12px', maxWidth: { xs: '170px', md: 'none' }, gap: { md: '32px' } }}>
                  <CustomTooltip disabled={isMobile} title="My entries">
                    <CustomButton onClick={() => openModal('entries')} size="auto" variant="transparent" sx={{ color: '#FBFEFF', position: 'relative' }}>
                      <TablerIcon icon="ticket" fontSize="20px" />
                      {!!athleteEntries?.tournamentRegistrations.length && (
                        <Box
                          sx={{
                            background: '#fa3e3e',
                            position: 'absolute',
                            right: -6,
                            top: -6,
                            borderRadius: '999px',
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '9px',
                            color: '#fff',
                          }}
                        >
                          {athleteEntries.tournamentRegistrations?.length}
                        </Box>
                      )}
                    </CustomButton>
                  </CustomTooltip>
                  <CustomTooltip disabled={isMobile} title="My matches">
                    <CustomButton onClick={() => openModal('matches')} size="auto" variant="transparent" sx={{ color: '#FBFEFF', position: 'relative' }}>
                      <TablerIcon icon="tournament" fontSize="20px" />
                      {!!athleteMatches?.userTournamentMatches && (
                        <Box
                          sx={{
                            background: '#fa3e3e',
                            position: 'absolute',
                            right: -6,
                            top: -6,
                            borderRadius: '999px',
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '9px',
                            color: '#fff',
                          }}
                        >
                          {athleteMatches.userTournamentMatches.length}
                        </Box>
                      )}
                    </CustomButton>
                  </CustomTooltip>
                  <CustomTooltip disabled={isMobile} title="Pending payments">
                    <CustomButton onClick={() => openModal('payments')} size="auto" variant="transparent" sx={{ color: '#FBFEFF', position: 'relative' }}>
                      <TablerIcon icon="credit-card" fontSize="22px" />
                      {!!pendingPaymentsCount && (
                        <Box
                          sx={{
                            background: '#fa3e3e',
                            position: 'absolute',
                            right: -6,
                            top: -6,
                            borderRadius: '999px',
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '9px',
                            color: '#fff',
                          }}
                        >
                          {pendingPaymentsCount}
                        </Box>
                      )}
                    </CustomButton>
                  </CustomTooltip>
                  <CustomButton
                    onClick={() => window.open(`https://waze.com/ul?ll=${markerPosition.lat},${markerPosition.lng}&navigate=yes`)}
                    size="auto"
                    variant="transparent"
                    sx={{ color: '#FBFEFF', position: 'relative' }}
                  >
                    <TablerIcon icon="brand-waze" fontSize="22px" />
                  </CustomButton>
                </Box>
              </Box>
            </Container>
          </Box>,
          aboveTabEl,
        )}
      <AthleteSpace
        initialTab={initialTab}
        open={open}
        onClose={() => {
          setOpen(false);
          setInitialTab(undefined);
        }}
        athleteEntries={athleteEntries?.tournamentRegistrations}
        entriesLoading={athleteEntriesLoading}
      />
    </>
  );
};

export default BottomBar;
