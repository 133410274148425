import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { findTeamByKey, getShortMatchStatus } from 'src/utils/helperFunction';
import GetFlag from 'src/utils/getFlags';
import moment from 'moment';
import { parseScores } from 'src/constants';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomModal from 'src/components/CustomComponents/CustomModal';

const DrawsModal = ({ open, setOpen, seed, selectedCat, isDisplayClub }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const teamOne = seed?.teams && findTeamByKey('teamOne', seed?.teams);
  const teamTwo = seed?.teams && findTeamByKey('teamTwo', seed?.teams);
  return (
    <React.Fragment>
      <CustomModal title={selectedCat?.category?.name} openOvveride={open} onClose={handleClose}>
        <Box
          sx={{
            maxWidth: '623px',
            width: '100%',
            minWidth: { xs: '100%', sm: '480px', md: '623px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '25px',
            height: 'max-content',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: { sm: 'none', xs: '5px' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '25px', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', flex: '1 0 0', alignSelf: 'stretch', flexDirection: { sm: 'row', xs: 'column' } }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                {' '}
                {teamOne &&
                  teamOne?.teamOne?.map((team) => (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        padding: '15px 20px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        flex: '1 0 0',
                        borderRadius: '6px',
                        border: seed?.winner?.id === seed?.teams[0]?.id ? '1px solid #FF5733' : '',
                        background: seed?.winner?.id === seed?.teams[0]?.id ? 'rgba(255, 87, 51, 0.10)' : '#FBFBFB',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        {/* <img src={team?.avatar} width="32px" height="32px" /> */}
                        <CustomAvatar size={32} src={team?.avatar} seed={team?.player1 || team?.player2} />

                        <GetFlag
                          country={team?.nation}
                          style={{
                            width: '13px',
                            height: '13px',
                            marginTop: '-5px',
                            marginLeft: '-6.5px',
                            position: 'absolute',
                          }}
                        />
                      </Box>
                      <Typography variant="heading13" sx={{ whiteSpace: 'nowrap', fontSize: '12px', color: '#0A2540', lineHeight: 'normal', fontWeight: 600 }}>
                        {team?.player1 || team?.player2}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              {/* {2} */}
              <Box sx={{ display: 'flex', width: '80px', flexDirection: 'row', alignItems: 'flex-start', borderRadius: '6px', border: '1px solid #EDF1F6' }}>
                {seed?.winner?.id === seed?.teams[0]?.id ? (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch' }}>
                      {parseScores(seed?.addScore?.addScore)?.firstArray?.map((score, index) => {
                        const mainScore2 = parseScores(seed?.addScore?.addScore)?.secondArray[index];
                        const isGreater1 = parseInt(score?.mainScore, 10) > parseInt(mainScore2?.mainScore, 10);

                        return (
                          <Typography
                            key={index}
                            sx={{
                              color: isGreater1 ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                              borderLeft: '1px solid #EDF1F6',
                              borderBottom: '1px solid #EDF1F6',
                              fontFamily: 'Inter',
                              fontSize: '11px',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              display: 'flex',
                              padding: '3px 2px',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                              position: 'relative',
                            }}
                          >
                            {score?.mainScore}
                            {score?.tieScore && (
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  position: 'absolute',
                                  top: 0,
                                  transform: 'translate(5px, -2px)',
                                }}
                              >
                                {score?.tieScore?.replace(')', '')}
                              </Typography>
                            )}
                          </Typography>
                        );
                      })}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch' }}>
                      {parseScores(seed?.addScore?.addScore)?.secondArray?.map((score, index) => {
                        const mainScore1 = parseScores(seed?.addScore?.addScore)?.firstArray[index];
                        const isGreater2 = parseInt(score?.mainScore, 10) > parseInt(mainScore1?.mainScore, 10);

                        return (
                          <Typography
                            key={index}
                            sx={{
                              color: isGreater2 ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                              borderLeft: '1px solid #EDF1F6',
                              borderBottom: '1px solid #EDF1F6',
                              fontFamily: 'Inter',
                              fontSize: '11px',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              display: 'flex',
                              padding: '3px 2px',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                              position: 'relative',
                            }}
                          >
                            {score?.mainScore}
                            {score?.tieScore && (
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  position: 'absolute',
                                  top: 0,
                                  transform: 'translate(5px, -2px)',
                                }}
                              >
                                {score?.tieScore}
                              </Typography>
                            )}
                          </Typography>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch' }}>
                      {parseScores(seed?.addScore?.addScore)?.secondArray?.map((score, index) => {
                        const mainScore2 = parseScores(seed?.addScore?.addScore)?.firstArray[index];
                        const isGreater1 = parseInt(score?.mainScore, 10) > parseInt(mainScore2?.mainScore, 10);

                        return (
                          <Typography
                            key={index}
                            sx={{
                              color: isGreater1 ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                              borderLeft: '1px solid #EDF1F6',
                              borderBottom: '1px solid #EDF1F6',
                              fontFamily: 'Inter',
                              fontSize: '11px',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              display: 'flex',
                              padding: '3px 2px',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                              position: 'relative',
                            }}
                          >
                            {score?.mainScore}
                            {score?.tieScore && (
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  position: 'absolute',
                                  top: 0,
                                  transform: 'translate(5px, -2px)',
                                }}
                              >
                                {score?.tieScore}
                              </Typography>
                            )}
                          </Typography>
                        );
                      })}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch' }}>
                      {parseScores(seed?.addScore?.addScore)?.firstArray?.map((score, index) => {
                        const mainScore1 = parseScores(seed?.addScore?.addScore)?.secondArray[index];
                        const isGreater2 = parseInt(score?.mainScore, 10) > parseInt(mainScore1?.mainScore, 10);

                        return (
                          <Typography
                            key={index}
                            sx={{
                              color: isGreater2 ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                              borderLeft: '1px solid #EDF1F6',
                              borderBottom: '1px solid #EDF1F6',
                              fontFamily: 'Inter',
                              fontSize: '11px',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              display: 'flex',
                              padding: '3px 2px',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                              position: 'relative',
                            }}
                          >
                            {score?.mainScore}
                            {score?.tieScore && (
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  position: 'absolute',
                                  top: 0,
                                  transform: 'translate(5px, -2px)',
                                }}
                              >
                                {score?.tieScore}
                              </Typography>
                            )}
                          </Typography>
                        );
                      })}
                    </Box>
                  </>
                )}
              </Box>

              {/* {3} */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                {teamTwo &&
                  teamTwo?.teamTwo?.map((team) => (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        padding: '15px 20px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        flex: '1 0 0',
                        borderRadius: '6px',
                        border: seed?.winner?.id === seed?.teams[1]?.id ? '1px solid #FF5733' : '',
                        background: seed?.winner?.id === seed?.teams[1]?.id ? 'rgba(255, 87, 51, 0.10)' : '#FBFBFB',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        {/* <img src={team?.avatar} width="32px" height="32px" /> */}
                        <CustomAvatar size={32} src={team?.avatar} seed={team?.player1 || team?.player2} />

                        <GetFlag
                          country={team?.nation}
                          style={{
                            width: '13px',
                            height: '13px',
                            marginTop: '-5px',
                            marginLeft: '-6.5px',
                            position: 'absolute',
                          }}
                        />
                      </Box>
                      <Typography variant="heading13" sx={{ whiteSpace: 'nowrap', fontSize: '12px', color: '#0A2540', lineHeight: 'normal', fontWeight: 600 }}>
                        {team?.player1 || team?.player2}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          {/* {2} */}
          {seed?.date && (
            <Box
              sx={{
                display: 'flex',
                height: '70px',
                padding: '0px 20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignSelf: 'stretch',
                borderTop: '1px solid rgba(0, 0, 0, 0.10)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
                gap: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '20px' }}>
                    {moment?.utc(seed?.date).format('ddd, MMMM DD')}
                  </Typography>
                  <Typography variant="font21" sx={{ color: 'rgba(0, 0, 0, 0.35)', lineHeight: 'normal' }}>
                    Date
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '20px' }}>
                    {getShortMatchStatus(seed?.matchStatus, '~', seed?.time) ?? ''}
                  </Typography>
                  <Typography variant="font21" sx={{ color: 'rgba(0, 0, 0, 0.35)', lineHeight: 'normal' }}>
                    Start
                  </Typography>
                </Box>
              </Box>
              {isDisplayClub && (
                <>
                  <Divider orientation="vertical" sx={{ height: '70%' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                    <Box component="img" src={teamOne?.teamOne?.[0]?.club?.logo} sx={{ width: '20px', height: '20px', borderRadius: '5px', border: '1px solid #eee' }} />
                    <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '20px' }}>
                      {teamOne?.teamOne?.[0]?.club?.clubName}
                    </Typography>
                  </Box>
                </>
              )}

              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    height: '25px',
                    padding: '5px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: '4px',
                    border: '1px solid #EDF1F6',
                    background: '#FAFAFA',
                  }}
                >
                  <Typography variant="font21" sx={{ lineHeight: '18px', color: 'rgba(10, 37, 64, 0.55)', whiteSpace: 'nowrap' }}>
                    {seed?.court?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {/* {3} */}
          <Box></Box>
        </Box>
      </CustomModal>
    </React.Fragment>
  );
};

export default DrawsModal;
