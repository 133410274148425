import { gql } from "@apollo/client";

const CREATE_TEAM = gql`
  mutation createTeam(
    $title: String
    $country: String
    $status: String
    $sport: Int
    $club: Int
    $members: [JSONObject!]
    $discipline: Int
    $file: Upload
  ) {
    createTeam(
      createTeamInput: {
        title: $title
        country: $country
        status: $status
        sport: $sport
        club: $club
        members: $members
        discipline: $discipline
        image: $file
      }
    ) {
      id
      title
      status
      country
    }
  }
`;

export default CREATE_TEAM;

