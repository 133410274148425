import { gql } from '@apollo/client';

const SPORTS_FISHING_RESULTS_BY_FILTER = gql`
  query sportFishingResultEntries($filter: ListSportFishingResultEntryInput) {
    sportFishingResultEntries(filter: $filter) {
      id
      weight
      location
      points
      updatedAt
      entry {
        id
        team {
          id
          title
          image
        }
        users {
          user {
            name
            surname
            avatar
            nation
            email
          }
        }
      }
    }
  }
`;

export default SPORTS_FISHING_RESULTS_BY_FILTER;
