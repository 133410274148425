import { gql } from '@apollo/client';

const USERS = gql`
  query users($filter: ListUsersInput, $page: Int, $limit: Int) {
    users(page: $page, limit: $limit, filter: $filter) {
      users {
        name
        surname
        email
        phone
        nation
        gender
        city
        street
        user_club_text
        role
        password
        dob
        avatar
        id
      }
      total
    }
  }
`;

export default USERS;
