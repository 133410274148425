import { gql } from "@apollo/client";

const TOP_FIVE_PLAYERS = gql`
  query tournamentTopFivePlayers($tournamentId: Float, $categoryId: Float) {
    tournamentTopFivePlayers(
      tournamentId: $tournamentId
      categoryId: $categoryId
    )
  }
`;

export default TOP_FIVE_PLAYERS;
