import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, FormHelperText } from '@material-ui/core';
import useMounted from '../../../hooks/useMounted';
import { FORGET } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getPlatformId } from 'src/constants';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import errorToString from '../../../utils/errorToString';

interface PasswordRecoveryAmplifyProps {
  handleClose?: () => void;
}

const PasswordRecoveryAmplify: FC<PasswordRecoveryAmplifyProps> = ({ handleClose }) => {
  const mounted = useMounted();
  const { t } = useTranslation();
  const [Forget] = useMutation(FORGET) as any;

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        const variables = {
          email: values.email,
          platformId: getPlatformId(),
          redirectURL: window?.location?.origin,
        };
        try {
          // await passwordRecovery(values.email);
          const response = await Forget({
            variables,
          });
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(t('Please check your email for reset password link!'));
            handleClose();
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            toast.error(err.message);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              width: '100%',
              height: 'max-content',
              maxHeight: '100%',
              minHeight: '300px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <CustomFormInput
              autoFocus
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label={t('Email')}
              name="email"
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            {errors.submit && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{errorToString(errors.submit)}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <CustomButton variant="primary" size="medium" sx={{ width: '100%' }} disabled={isSubmitting} type="submit">
                {t('Send instructions')}
              </CustomButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryAmplify;
