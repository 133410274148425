import { gql } from "@apollo/client";

const ADD_FRIEND = gql`
mutation addFriend($userId:Int! $friendId:Int!) {
    addFriend(userId:$userId,friendId:$friendId){
      id
      friends{
        id
      }
    }
  }
  `;
export default ADD_FRIEND;