import React, { useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Stack, Typography, useMediaQuery } from '@material-ui/core';
import CustomPopover from './CustomPopover';
import CustomButton from './CustomButton';
import { CopyAll, DownloadOutlined, IosShare, MoreVert, ShareOutlined } from '@material-ui/icons';
import { QRCodeCanvas } from 'qrcode.react';
import chroma from 'chroma-js';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';
import ScrollContainer from 'react-indiana-drag-scroll';
import { debounce, throttle } from 'lodash';
import CustomPaperCard from './CustomPaperCard';

type Tab = {
  value: string;
  label: string;
  icon: string;
};

type Props = {
  tabs: Tab[] | undefined;
  hideTabs?: boolean;
  onChange: (value: string) => void;
  currentTab?: string;
  shareColor?: string;
  sx?: SxProps;
  outerSx?: SxProps;
  isShare?: boolean;
};

const CustomTabs = forwardRef<HTMLDivElement, Props>(({ tabs, outerSx, hideTabs, onChange, currentTab, shareColor = process.env.REACT_APP_PRIMARY_COLOR, sx, isShare = true }, ref) => {
  const { t } = useTranslation();
  const tabRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentTabRect, setCurrentTabRect] = useState<Pick<DOMRect, 'width' | 'left'>>();
  const { platformData } = useAuth();
  const [overflowingTabs, setOverflowingTabs] = useState<string[]>([]);
  const [rerenderCount, setRerenderCount] = useState(0);
  const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
  const [scrollSpace, setScrollSpace] = useState({ left: 0, right: 0 });

  useEffect(() => {
    if (containerRef.current && tabRefs.current) {
      const containerElement = containerRef.current;
      const containerRect = containerElement.getBoundingClientRect();
      const newOverflowingTabs = tabs
        ?.filter((tab) => {
          const tabElement = tabRefs.current[tab.value];
          if (tabElement) {
            const tabRect = tabElement.getBoundingClientRect();

            const isTabOverflowingRight = tabRect.width + tabRect.x > containerRect.width + containerRect.x + 20;
            const isTabOverflowingLeft = tabRect.x - containerRect.x <= -20;
            return isTabOverflowingRight || isTabOverflowingLeft;
          }
          return false;
        })
        .map((tab) => tab.value);

      setOverflowingTabs(newOverflowingTabs);
    }
  }, [rerenderCount]);

  useEffect(() => {
    const tabEl = tabRefs.current[currentTab];
    const containerEl = containerRef.current;
    if (tabEl && containerEl) {
      const rect = tabEl.getBoundingClientRect();
      const containerOffset = containerEl.getBoundingClientRect().left;
      setCurrentTabRect({ width: rect.width, left: rect.left - containerOffset });
    } else {
      setCurrentTabRect(undefined);
    }
  }, [overflowingTabs]);

  const callRerender = useCallback(
    () =>
      throttle(() => {
        setRerenderCount((curr) => curr + 1);
      }, 25),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', callRerender);
    return () => window.removeEventListener('resize', callRerender);
  }, [overflowingTabs]);

  useEffect(() => {
    tabRefs.current[currentTab]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
    setRerenderCount((curr) => curr + 1);
  }, [currentTab]);

  useEffect(() => {
    if (!scrollRef) return;
    const handleScroll = debounce(() => {
      const scrollableElement = scrollRef;
      const maxScrollLeft = scrollableElement.scrollWidth - scrollableElement.clientWidth;
      const pixelsToScrollLeft = scrollableElement.scrollLeft;
      const pixelsToScrollRight = maxScrollLeft - pixelsToScrollLeft;

      setScrollSpace({ left: pixelsToScrollLeft, right: pixelsToScrollRight });
    }, 50);

    handleScroll();

    scrollRef.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      scrollRef.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [scrollRef]);

  const tabsInModal = tabs.filter((tab) => overflowingTabs.includes(tab.value));
  const platformLogo = platformData?.lightEmblem ?? platformData?.darkEmblem ?? process.env.REACT_APP_LOGO_ICON;
  const qrRef = useRef<HTMLCanvasElement | null>(null);

  const downloadQRCode = () => {
    if (!qrRef.current) return;
    const dataURI = qrRef.current.toDataURL('image/png');
    const pathnameArray = window.location.pathname.split('/');
    const link = document.createElement('a');
    link.href = dataURI;
    link.download = `${pathnameArray[pathnameArray.length - 1]}-QR.png`;
    link.click();
  };

  const qrCodeLink = window.location.origin + window.location.pathname;
  const renderShareOptions = () => (
    <Stack alignItems="center" gap="15px" width="100%" sx={{ width: { sm: '350px' } }}>
      <Box
        sx={{
          borderRadius: '18px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          border: `1px solid ${shareColor}`,
          padding: '22px',
          background: chroma(shareColor).alpha(0.2).hex(),
          position: 'relative',
        }}
      >
        <QRCodeCanvas
          ref={qrRef}
          imageSettings={{
            src: platformLogo,
            height: 100,
            width: 100,
            opacity: 1,
            excavate: true,
            crossOrigin: 'anonymous',
          }}
          value={qrCodeLink}
          bgColor={chroma(shareColor).alpha(0).hex()}
          size={600}
          style={{ height: '200px', width: '200px' }}
        />
        <CustomButton onClick={downloadQRCode} color={shareColor} size="medium" variant="transparent" square sx={{ position: 'absolute', right: 18, top: 18 }}>
          <DownloadOutlined sx={{ color: shareColor, fontSize: '28px' }} />
        </CustomButton>
      </Box>
      <CustomButton
        onClick={() => navigator.clipboard.writeText(window.location.href).then(() => toast.success('Link copied to clipboard'))}
        size="medium"
        variant="primary"
        color="#F9F9F9"
        sx={{ justifyContent: 'start', width: '100%', gap: '8px', color: '#0A2540' }}
      >
        <CopyAll sx={{ fontSize: '22px' }} />
        <Stack alignItems="start" sx={{ overflow: 'hidden', flex: 1, gap: '2px', textAlign: 'start' }}>
          <Typography lineHeight="100%" variant="font17">
            Copy link
          </Typography>
          <Typography color="#666" lineHeight="100%" variant="font21" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>
            {window.location.href}
          </Typography>
        </Stack>
      </CustomButton>
      <CustomButton
        onClick={() => {
          if (navigator.share) {
            navigator
              .share({
                title: document.title,
                text: 'Check out this page!',
                url: window.location.href,
              })
              .catch((error) => console.error('Error sharing:', error));
          } else {
            void navigator.clipboard.writeText(window.location.href);
            toast.success('Link copied to clipboard');
          }
        }}
        size="medium"
        variant="primary"
        color="#F9F9F9"
        sx={{ justifyContent: 'start', width: '100%', gap: '8px', color: '#0A2540' }}
      >
        <IosShare sx={{ fontSize: '22px' }} />
        <Typography lineHeight="100%" variant="font17">
          More Options
        </Typography>
      </CustomButton>
    </Stack>
  );
  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMoreButtonCompact = overflowingTabs.length > 0 || isCompact;

  return hideTabs ? (
    <Container maxWidth={false} sx={{ maxWidth: '1920px' }}>
      <CustomPaperCard skeleton sx={{ height: '48px', ...sx }} />
    </Container>
  ) : (
    <Box sx={outerSx}>
      <Container ref={ref} maxWidth={false} sx={{ maxWidth: '1920px', display: 'flex', gap: '16px', alignItems: 'center', ...sx }}>
        <Box
          ref={containerRef}
          sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            maskImage: `linear-gradient(to right, transparent, black ${scrollSpace.left > 5 ? 5 : 0}%, black ${scrollSpace.right > 5 ? 95 : 100}%, transparent)`,
            WebkitMaskImage: `linear-gradient(to right, transparent, black ${scrollSpace.left > 5 ? 5 : 0}%, black ${scrollSpace.right > 5 ? 95 : 100}%, transparent)`,
            transition: 'all 0.2s',
          }}
        >
          <ScrollContainer innerRef={(el) => setScrollRef(el)} buttons={[0]} nativeMobileScroll={true} onScroll={callRerender}>
            <Box sx={{ display: 'flex', gap: { xs: '6px', md: 0 }, transition: 'all 0.2s' }}>
              {!hideTabs &&
                tabs?.map((tab) => (
                  <CustomButton
                    variant="transparent"
                    size="large"
                    key={tab.value}
                    value={tab.value}
                    selected={currentTab === tab.value}
                    ref={(el) => (tabRefs.current[tab.value] = el)}
                    onClick={() => {
                      onChange(tab.value);
                    }}
                    sx={{
                      borderRadius: 'none',
                      gap: '5px',
                      color: '#86909F',
                      '.icon': { backgroundColor: '#86909F' },
                      flexShrink: 0,
                      paddingInline: { xs: '4px', md: '10px' },
                    }}
                  >
                    <Box
                      className="icon"
                      sx={{
                        minHeight: '18px',
                        minWidth: '18px',
                        maxHeight: '18px',
                        maxWidth: '18px',
                        WebkitMask: `url(${tab.icon}) no-repeat center`,
                        mask: `url(${tab.icon}) no-repeat center`,
                        maskSize: '18px 18px',
                      }}
                    />
                    <Typography variant="font22">{t(tab.label)}</Typography>
                  </CustomButton>
                ))}
            </Box>
            {currentTabRect && (
              <Box
                sx={{
                  width: currentTabRect.width,
                  height: '1px',
                  left: currentTabRect.left,
                  bottom: 0,
                  position: 'absolute',
                  background: process.env.REACT_APP_PRIMARY_COLOR,
                  transition: 'all 0.2s',
                }}
              />
            )}
          </ScrollContainer>
        </Box>
        {isShare && (
          <Box sx={{ width: { md: '80px', xs: 'fit-content' }, display: 'flex', justifyContent: 'end' }}>
            <CustomPopover
              modalOnMobile
              transformOriginVertical={-20}
              align="right"
              triggerEl={
                <CustomButton
                  square={isMoreButtonCompact}
                  size="small"
                  variant="secondary"
                  selected={overflowingTabs.includes(currentTab)}
                  sx={{ borderColor: '#86909F', background: '#E6E9EC', color: '#86909F', gap: '4px' }}
                >
                  {isMoreButtonCompact ? (
                    tabsInModal.length > 0 ? (
                      <MoreVert />
                    ) : (
                      <ShareOutlined sx={{ fontSize: '18px' }} />
                    )
                  ) : (
                    <>
                      <ShareOutlined sx={{ fontSize: '18px' }} /> Share
                    </>
                  )}
                </CustomButton>
              }
            >
              {(onClose) =>
                overflowingTabs.length > 0 ? (
                  <Stack alignItems="start" gap="12px" sx={{ minWidth: '120px', overflow: 'auto' }}>
                    {tabs.map((tab) => (
                      <CustomButton
                        selected={currentTab === tab.value}
                        onClick={() => {
                          onChange(tab.value);
                          onClose();
                        }}
                        size="small"
                        variant="transparent"
                        sx={{ gap: '4px', width: '100%', justifyContent: 'start' }}
                      >
                        <Box
                          className="icon"
                          sx={{
                            minHeight: '18px',
                            minWidth: '18px',
                            maxHeight: '18px',
                            maxWidth: '18px',
                            WebkitMask: `url(${tab.icon}) no-repeat center`,
                            mask: `url(${tab.icon}) no-repeat center`,
                            maskSize: '18px 18px',
                          }}
                        />
                        {tab.label}
                      </CustomButton>
                    ))}
                    <Box sx={{ minHeight: '1px', width: '100%', background: '#0A2540' }} />
                    <CustomPopover
                      onClose={onClose}
                      modalOnMobile
                      transformOriginVertical={-20}
                      align="right"
                      triggerEl={
                        <CustomButton size="small" variant="transparent" sx={{ gap: '4px', width: '100%', justifyContent: 'start' }}>
                          <ShareOutlined sx={{ fontSize: '18px' }} />
                          Share
                        </CustomButton>
                      }
                    >
                      {renderShareOptions()}
                    </CustomPopover>
                  </Stack>
                ) : (
                  renderShareOptions()
                )
              }
            </CustomPopover>
          </Box>
        )}
      </Container>
    </Box>
  );
});

export default CustomTabs;
