import React from 'react';
import { Box, Typography, IconButton, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';

const SelectedPlayerDisplay = ({ player, onRemove, isFirstPlayer, flow }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CustomChip
      variant="primary"
      size="xs"
      color="#0A25400D"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px',
        height: '25px',
        borderRadius: '6px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', width: '100%' }}>
        <CustomAvatar src={player?.avatar} alt={`${player?.name} ${player?.surname}`} size={18} seed={player?.name + ' ' + player?.surname} />

        <Typography variant="font18" sx={{ lineHeight: '16.98px', color: '#0A2540', whiteSpace: 'nowrap', textTransform: 'capitalize', marginTop: '1px' }}>
          {`${player?.name} ${player?.surname}`}
        </Typography>
      </Box>
      {/* Only show the close icon if it's not the first player when the flow is 'myself' */}
      {!isFirstPlayer || flow !== 'myself' ? (
        <IconButton size="small" onClick={onRemove} sx={{ padding: '0px', marginTop: '2px' }}>
          <CloseIcon fontSize="small" sx={{ color: '#0A2540', fontSize: '12px' }} />
        </IconButton>
      ) : null}
    </CustomChip>
  );
};

export default SelectedPlayerDisplay;
