import React, { Fragment, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { getShortMatchStatus } from 'src/utils/helperFunction';
import MatchDetailsModal from 'src/components/dashboard/TournamentPage/orderofplay/MatchDetailsModal';
import { CheckCircle } from '@material-ui/icons';
import LiveIcon from 'src/components/CustomComponents/LiveIcon';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import chroma from 'chroma-js';
import DownloadOOP from './DownloadOOP';
import NoDataFound from 'src/components/dashboard/NoDataFound';

const BirdsView = ({ oopData, show, tournamentDocuments }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);

  const handleOpenMatchDetails = (match) => {
    setOpenModal(true);
    setSelectedMatch(match);
  };

  const renderMatch = (match, matches, isOdd, index) => {
    const backgroundColor = match?.isMatchInProgress ? chroma('green').alpha(0.7).hex() : match?.tournamentCategory?.color || match?.group?.tournamentCategory?.color || theme.palette.primary.main;
    const isMatchFinished = !!match?.winner?.id;

    return (
      <Box
        sx={{
          position: 'relative',
          width: '140px',
          height: '50px',
          borderRadius: '6px',
          display: 'flex',
          marginBottom: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#0A2540',
          fontWeight: '400',
          fontSize: '12px',
          background: isOdd ? '#FBFBFB' : '#FFFF',
          marginLeft: '10px',
        }}
      >
        <Box
          sx={{ borderRadius: '6px', padding: '5px', backgroundColor: backgroundColor, color: '#FFFF', height: '100%', width: '100%', cursor: 'pointer' }}
          onClick={() => handleOpenMatchDetails(match)}
        >
          <Box
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '140px',
              height: '100%',
              lineHeight: '1.2em',
              maxHeight: '2.4em',
            }}
          >
            {match?.tournamentCategory?.category?.name || match?.group?.tournamentCategory?.category?.name} [{`${getShortMatchStatus(match?.matchStatus, '', match?.time, true) ?? ''}`}] - [
            {match?.draw?.id ? 'D' : 'RR'}]
          </Box>
        </Box>
        {/* {match?.isMatchInProgress && (
          <CustomChip
            variant="primary"
            size="xs"
            color={chroma(process.env.REACT_APP_PRIMARY_COLOR ?? '#FF5733')
              .alpha(0.7)
              .hex()}
            fontSize="8px !important"
            sx={{ gap: '5px', position: 'absolute', bottom: 3, left: 3, border: '1px solid #fff', height: '18px', borderRadius: '6px !important' }}
          >
            <LiveIcon />
            live
          </CustomChip>
        )} */}
        {isMatchFinished && (
          <CheckCircle
            sx={{
              color: '#fff',
              position: 'absolute',
              bottom: 3,
              right: 3,
              fontSize: '1rem',
            }}
          />
        )}
      </Box>
    );
  };

  const maxMatches = oopData ? Math.max(...oopData?.map((item) => item?.matches?.length)) : 0;
  const totalMatches = show
    ? oopData?.reduce((total, court) => {
        return total + (court?.matches ? court?.matches?.length : 0);
      }, 0)
    : 0;

  if (!show || !oopData || oopData?.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          background: '#FFFF',
          borderRadius: '6px',
          padding: '20px',
        }}
      >
        {tournamentDocuments?.length > 0 ? <DownloadOOP tournamentDocuments={tournamentDocuments} /> : <NoDataFound text="There is no available order of play" sx={{ margin: '150px 0' }} />}
      </Box>
    );
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', my: 2, border: '1px solid #0000001A', background: '#FFFF', borderRadius: '6px', padding: '20px' }}>
        <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: '500', color: '#0A25408C', mb: 3 }}>
          {totalMatches} total {totalMatches > 1 ? 'Matches' : 'Match'}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {show &&
            oopData?.map((item) => (
              <Box key={item} sx={{ display: 'flex', width: 'max-content' }}>
                <Box
                  sx={{
                    minWidth: '100px',
                    maxWidth: '100px',
                    height: '50px',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                    display: 'flex',
                    marginBottom: '10px',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: '20px',
                    background: '#F9F9F9',
                    color: '#0A2540',
                    fontWeight: '400',
                    fontSize: '12px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    border: ' 1px solid #eee',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translateY(-50%)',
                      height: '30px',
                      width: '3px',
                      backgroundColor: item?.matches?.[0]?.tournamentCategory?.color || item?.matches?.[0]?.group?.tournamentCategory?.color || theme.palette.primary.main,
                    },
                  }}
                >
                  {item?.court}
                </Box>
                {Array.from({ length: maxMatches }).map((_, index) => {
                  const match = item?.matches[index];
                  const isOdd = index % 2 === 0;

                  return (
                    <div key={match?.id}>
                      {match ? (
                        renderMatch(match, item?.matches, isOdd, index)
                      ) : (
                        <Box
                          sx={{
                            width: '140px',
                            height: '50px',
                            borderRadius: '6px',
                            display: 'flex',
                            marginBottom: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#0A2540',
                            fontWeight: '400',
                            fontSize: '12px',
                            marginLeft: '10px',
                            background: isOdd ? '#FBFBFB' : '#FFFF',
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </Box>
            ))}
        </Box>
      </Box>
      <MatchDetailsModal openModal={openModal} setOpenModal={setOpenModal} match={selectedMatch} />
    </Fragment>
  );
};

export default BirdsView;
