import { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import CustomModal from './CustomModal';

const CustomImagePopup = ({ src, children }: { src: string; children: ReactNode }) => (
  <CustomModal
    contentSx={{ alignItems: 'center' }}
    triggerEl={<Box sx={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'zoom-in' }}>{children}</Box>}
  >
    <Box component="img" src={src} sx={{ height: '100%', width: 'fit-content', maxWidth: '100%', objectFit: 'contain', overflow: 'hidden', borderRadius: '8px', minWidth: '250px' }} />
  </CustomModal>
);

export default CustomImagePopup;
