import { gql } from "@apollo/client";
const PARTNER_SEARCHES = gql`
  query partnerSearches($page: Int, $limit: Int, $filter: ListPartnerSearchInput) {
    partnerSearches(page: $page, limit: $limit, filter: $filter) {
      id
      categoryId {
        id
        category {
          id
          name
        }
      }
      partner {
        id
        name
        surname
        email
        avatar
        citizenship
      }
      user {
        id
        name
        surname
        email
        avatar
        citizenship
      }
      status
      selfInformation
      rank
      partnerInformation
    }
  }
`;
export default PARTNER_SEARCHES;
