import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { getCurrencySymbol, parseToJSON } from 'src/utils/helperFunction';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { ca } from 'date-fns/locale';

const StepAdditionalQuestions = ({ priceUnit }) => {
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();

  const handleAnswerChange = (categoryId, playerType, questionId, event, fieldType) => {
    const updatedCategories = registrationData.selectedCategories.map((categoryEntry) => {
      if (categoryEntry?.category?.id === categoryId) {
        const updatedQuestions = playerType === 'player1' ? [...categoryEntry?.player1additionalQuestions] : [...categoryEntry?.player2additionalQuestions];
        const questionIndex = updatedQuestions?.findIndex((q) => q?.questionId === questionId);

        if (questionIndex !== -1) {
          if (fieldType === 'File') {
            updatedQuestions[questionIndex].answer = event.target.files[0];
            updatedQuestions[questionIndex].file = event.target.files[0];
          } else if (fieldType === 'Select') {
            updatedQuestions[questionIndex].answer = event.target.value;
            updatedQuestions[questionIndex].amount = String(event.target.value);
          } else {
            updatedQuestions[questionIndex].answer = event.target.value;
          }

          if (
            updatedQuestions[questionIndex].isMandatory &&
            (updatedQuestions[questionIndex].answer === null || updatedQuestions[questionIndex].answer === undefined || updatedQuestions[questionIndex].answer === '')
          ) {
            updatedQuestions[questionIndex].hasError = true;
          } else {
            updatedQuestions[questionIndex].hasError = false;
          }
        }

        return {
          ...categoryEntry,
          [playerType === 'player1' ? 'player1additionalQuestions' : 'player2additionalQuestions']: updatedQuestions,
        };
      }
      return categoryEntry;
    });
    updateRegistrationData({ selectedCategories: updatedCategories });
  };
  console.log('player1additionalQuestions', registrationData);
  const calculateTotalAdditionalFee = (questions) => {
    return questions?.reduce((acc, question) => {
      if (question?.fieldType === 'Select' && question?.answer !== null && !isNaN(parseFloat(question?.answer))) {
        return acc + parseFloat(question.answer) || 0;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    const updatedCategories = registrationData.selectedCategories.map((categoryEntry) => {
      const totalAdditionalFeePlayer1 = calculateTotalAdditionalFee(categoryEntry?.player1additionalQuestions || []);
      const totalAdditionalFeePlayer2 = calculateTotalAdditionalFee(categoryEntry?.player2additionalQuestions || []);
      const totalFees = totalAdditionalFeePlayer1 + totalAdditionalFeePlayer2;
      if (categoryEntry?.totalAdditionalFees !== totalFees) {
        return {
          ...categoryEntry,
          totalAdditionalFees: totalFees,
        };
      }
      return categoryEntry;
    });

    if (JSON.stringify(updatedCategories) !== JSON.stringify(registrationData.selectedCategories)) {
      updateRegistrationData({ selectedCategories: updatedCategories });
    }
  }, [registrationData.selectedCategories, updateRegistrationData]);
  return (
    <Box sx={{ height: '400px', overflowY: 'scroll' }} className="my-scroller">
      {registrationData?.selectedCategories?.map((categoryEntry) => {
        const { category, partners, player1additionalQuestions, player2additionalQuestions } = categoryEntry;
        if (!player1additionalQuestions?.length && !player2additionalQuestions?.length) {
          return null;
        }

        return (
          <Box key={category?.id} sx={{ marginBottom: '16px', padding: '12px', border: '1px solid #EFEFF0', borderRadius: '8px' }}>
            <Typography variant="font18" sx={{ lineHeight: '18px', color: '#0A2540' }}>
              {category?.category?.name}
            </Typography>
            {partners?.player1 && (
              <Box sx={{ marginTop: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', marginBottom: '8px' }}>
                  <CustomAvatar src={partners?.player1?.avatar} size={19} seed={partners?.player1?.name + ' ' + partners?.player1?.surname} />
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
                    {partners?.player1?.name} {partners?.player1?.surname}
                  </Typography>
                </Box>
                {player1additionalQuestions.map((question) => (
                  <Box key={question.questionId} sx={{ mt: '5px' }}>
                    <Box sx={{ marginBottom: '5px' }}>
                      <Typography variant="font13" sx={{ color: '#86909F', lineHeight: '18px' }}>
                        {question?.question}
                      </Typography>
                    </Box>
                    {question.fieldType === 'Select' ? (
                      <Box sx={{ width: '100%' }}>
                        <CustomSelect
                          error={question.hasError}
                          helperText={question.hasError ? 'This question is mandatory.' : ''}
                          placeholder="Select answer"
                          matchTriggerWidth
                          options={parseToJSON(question.fieldOptions)?.map((item) => ({
                            value: item.price,
                            title: item.question,
                            rightIcon:
                              parseFloat(item?.price) || item?.price == 0 ? (
                                <CustomChip
                                  color="#FFFF"
                                  variant="primary"
                                  size="small"
                                  sx={{
                                    background: Math.sign(item?.price) === -1 ? '#32B64F' : (theme) => theme.palette.primary.main,
                                    fontSize: '12px',
                                    borderRadius: '4px',
                                    height: '20px',
                                  }}
                                >
                                  {`${Math.sign(item?.price) === -1 ? item?.price : '+' + item?.price}${getCurrencySymbol(priceUnit)}`}
                                </CustomChip>
                              ) : null,
                          }))}
                          selectedValue={question.answer ?? ''}
                          onChange={(value) => handleAnswerChange(category.id, 'player1', question.questionId, { target: { value } }, 'Select')}
                          buttonProps={{
                            sx: {
                              width: '100%',
                              fontFamily: 'Inter',
                              fontSize: '12px!important',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: 'normal',
                              height: '40px',
                            },
                          }}
                        />
                      </Box>
                    ) : (
                      <CustomFormInput
                        size="small"
                        sx={{ maxWidth: '100%', height: '40px' }}
                        fullWidth
                        name={question.questionId}
                        variant="outlined"
                        type={question.fieldType.toLowerCase()}
                        value={question.fieldType === 'File' ? undefined : question.answer || ''}
                        onChange={(e) => handleAnswerChange(category.id, 'player1', question.questionId, e, question.fieldType)}
                        inputProps={question.fieldType === 'File' ? { accept: 'image/*' } : {}}
                        error={question.hasError}
                        helperText={question.hasError ? 'This question is mandatory.' : ''}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}

            {partners?.player2 && player2additionalQuestions?.length > 0 && (
              <Box sx={{ marginTop: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', marginBottom: '8px' }}>
                  <CustomAvatar src={partners?.player2?.avatar} size={19} seed={partners?.player2?.name + ' ' + partners?.player2?.surname} />
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
                    {partners?.player2?.name} {partners?.player2?.surname}
                  </Typography>
                </Box>
                {player2additionalQuestions.map((question) => (
                  <Box key={question.questionId} sx={{ mt: '10px' }}>
                    <Box sx={{ marginBottom: '5px' }}>
                      <Typography variant="font13" sx={{ color: '#86909F', lineHeight: '18px' }}>
                        {question?.question}
                      </Typography>
                    </Box>
                    {question.fieldType === 'Select' ? (
                      <Box sx={{ width: '100%' }}>
                        <CustomSelect
                          error={question.hasError}
                          helperText={question.hasError ? 'This question is mandatory.' : ''}
                          placeholder="Select answer"
                          matchTriggerWidth
                          options={parseToJSON(question.fieldOptions)?.map((item) => ({
                            value: item.price,
                            title: item.question,
                            rightIcon:
                              parseFloat(item?.price) || item?.price == 0 ? (
                                <CustomChip
                                  color="#FFFF"
                                  variant="primary"
                                  size="small"
                                  sx={{
                                    background: Math.sign(item?.price) === -1 ? '#32B64F' : (theme) => theme.palette.primary.main,
                                    fontSize: '12px',
                                    borderRadius: '4px',
                                    height: '20px',
                                  }}
                                >
                                  {`${Math.sign(item?.price) === -1 ? item?.price : '+' + item?.price}${getCurrencySymbol(priceUnit)}`}
                                </CustomChip>
                              ) : null,
                          }))}
                          selectedValue={question.answer ?? ''}
                          onChange={(value) => handleAnswerChange(category.id, 'player2', question.questionId, { target: { value } }, 'Select')}
                          buttonProps={{
                            sx: {
                              fontFamily: 'Inter',
                              fontSize: '12px!important',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: 'normal',
                              width: '100%',
                              height: '40px',
                            },
                          }}
                        />
                      </Box>
                    ) : (
                      <CustomFormInput
                        fullWidth
                        error={question.hasError}
                        helperText={question.hasError ? 'This question is mandatory.' : ''}
                        name={question.questionId}
                        variant="outlined"
                        type={question.fieldType.toLowerCase()}
                        value={question.fieldType === 'File' ? undefined : question.answer || ''}
                        onChange={(e) => handleAnswerChange(category.id, 'player2', question.questionId, e, question.fieldType)}
                        inputProps={question.fieldType === 'File' ? { accept: 'image/*' } : {}}
                        sx={{ maxWidth: '100%', height: '40px' }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default StepAdditionalQuestions;
