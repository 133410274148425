import { useEffect, useRef } from 'react';

const useScrollToTop = ({ deps = [], skipFirstRender, lazy }: { deps?: string[]; skipFirstRender?: boolean; lazy?: boolean }) => {
  const isFirstRender = useRef(true);
  const main = document.getElementById('main');

  useEffect(() => {
    if (lazy || (skipFirstRender && isFirstRender.current)) {
      isFirstRender.current = false;
      return;
    }

    if (main) {
      main.scrollTo({ top: 0, left: 0 });
    }
  }, [...deps]);

  return { handleScroll: () => main.scrollTo({ top: 0, left: 0 }) };
};
export default useScrollToTop;
