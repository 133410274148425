import { gql } from "@apollo/client";

const GET_FEDERATION_BY_EMAIL = gql`
  query findFederation($filter: ListFederationInput) {
    findFederation(filter: $filter) {
      id
    }
  }
`;

export default GET_FEDERATION_BY_EMAIL;
