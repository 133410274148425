import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { getButtonStyles } from 'src/components/CustomComponents/CustomButton';

type CustomInputProps = {
  labelClassName?: string;
} & TextFieldProps;

const CustomFilterBox: React.FC<CustomInputProps> = ({ labelClassName, ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiOutlinedInput-root': {
          ...getButtonStyles('default', 'medium', 'outline'),
          paddingBlock: 0,
          '& fieldset': {
            borderWidth: '0px',
          },
          '&:hover fieldset': {
            borderWidth: '0px',
            transform: 'none',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '0px',
          },
          input: {
            padding: 0,
          },
        },
        flex: 1,
        maxWidth: 'fit-content',
        ...props.sx,
      }}
      InputProps={props.InputProps}
    />
  );
};

export default CustomFilterBox;
