import { gql } from "@apollo/client";
const MATCHCOURTS = gql`
  query courts($filter: ListCourtInput) {
    courts(filter: $filter) {
      id
      name
      isPublish
    }
  }
`;
export default MATCHCOURTS;