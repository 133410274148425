import { Box, Typography } from '@material-ui/core';
import React from 'react';
import GetFlags from 'src/utils/getFlags';

// interface PlayerInfoProps {
//   player: { name: string; country: string };
// }

const PlayerInfo: React.FC<any> = ({ player, entry, item }) => {
  return (
    <Box key={player?.id} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <GetFlags
        country={player?.nation || player?.citizenship}
        style={{
          width: '15px',
          height: '12px',
          //   marginTop: '1px',
          marginRight: '0px',
          borderRadius: '1px',
        }}
      />

      <Typography variant="font18" sx={{ color: entry?.id === item?.winner?.id ? process.env.REACT_APP_PRIMARY_COLOR : '', lineHeight: '14.52px', textAlign: 'justify', textTransform: 'capitalize' }}>

        {`${player?.name?.charAt(0).toUpperCase()}. ${player?.surname}`}
      </Typography>
    </Box>
  );
};

export default PlayerInfo;
