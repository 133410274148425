import { Payments as CashIcon, CreditCard as CreditCardIcon } from '@material-ui/icons';
import { getCurrencySymbol } from 'src/utils/helperFunction';
import React from 'react';
import { height } from '@material-ui/system';

export function calculateFee(feeObj) {
  const { fee, onsiteFee, currency, method } = feeObj;

  const getIcon = (method) => {
    switch (method) {
      case 'online':
        return CreditCardIcon;
      case 'onsite':
        return CashIcon;
      case 'both':
        return fee < onsiteFee ? CreditCardIcon : CashIcon;
      default:
        return CreditCardIcon; // Default to online icon
    }
  };

  const formatFee = (value, Icon) => (
    <>
      <Icon style={{ verticalAlign: 'text-top', marginRight: '3px', height: '15px', width: '15px' }} />
      {getCurrencySymbol(currency)}
      {value}
    </>
  );
  if ((!fee || fee === 0) && (!onsiteFee || onsiteFee === 0)) {
    return 'Free';
  }
  if (fee && onsiteFee) {
    if (fee === onsiteFee) {
      return formatFee(fee, getIcon(method));
    } else {
      return (
        <>
          {formatFee(Math.min(fee, onsiteFee), getIcon(method))}
          {' - '}
          {formatFee(Math.max(fee, onsiteFee), method === 'both' ? CashIcon : getIcon(method))}
        </>
      );
    }
  } else if (fee && !onsiteFee) {
    return formatFee(fee, getIcon(method));
  } else if (onsiteFee && !fee) {
    return formatFee(onsiteFee, getIcon(method));
  } else {
    return '';
  }
}

export function calculateFeeNew(feeObj, additionalFee, isDoubleAmount) {
  const { fee, onsiteFee, currency } = feeObj;
  const updatedFee = isDoubleAmount ? fee * 2 + additionalFee : fee + additionalFee;
  const updatedOnsiteFee = isDoubleAmount ? onsiteFee * 2 + additionalFee : onsiteFee + additionalFee;

  const formatFee = (value, Icon) => (
    <>
      <Icon style={{ verticalAlign: 'middle', marginRight: '4px', height: '15px', width: '15px' }} />
      {getCurrencySymbol(currency)}
      {value}
    </>
  );

  if (updatedFee && updatedOnsiteFee) {
    if (updatedFee === updatedOnsiteFee) {
      return formatFee(updatedFee, CreditCardIcon);
    } else {
      return (
        <>
          {formatFee(Math.min(updatedFee, updatedOnsiteFee), updatedFee < updatedOnsiteFee ? CreditCardIcon : CashIcon)} -{' '}
          {formatFee(Math.max(updatedFee, updatedOnsiteFee), updatedFee < updatedOnsiteFee ? CashIcon : CreditCardIcon)}
        </>
      );
    }
  } else if (updatedFee && !updatedOnsiteFee) {
    return formatFee(updatedFee, CreditCardIcon);
  } else if (updatedOnsiteFee && !updatedFee) {
    return formatFee(updatedOnsiteFee, CashIcon);
  } else {
    return '';
  }
}
