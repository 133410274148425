import { useState, useEffect, Fragment } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { ALL_TOURNAMENT_CATEGORIES, ALL_TOURNAMENT_CATEGORIES_WITH_PARTNERS, PARTICIPANTS, PARTNER_SEARCHES } from 'src/graphql/queries';
import PartnerSearchListView from './PartnerSearchList';
import Loading from 'src/components/Loading';
import NoDataFound from '../../NoDataFound';
import { Helmet } from 'react-helmet';
import gtm from 'src/lib/gtm';
import useAuth from 'src/hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import CreateApplicationModal from './CreateApplicationModal';
import CustomSection from '../../../layout/CustomSection';
import CustomButton from '../../../CustomComponents/CustomButton';
import { Add } from '@material-ui/icons';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import { useTranslation } from 'react-i18next';
import { useTournament } from '../TournamentPage';

const PartnerSearch = () => {
  const { id, tournament } = useTournament();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filtersParams, setFiltersParams] = useSearchParams();

  const [selectedValue, setSelectedValue] = useState(filtersParams.get('category') ? Number(filtersParams.get('category')) : '');
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const { data: allTournamentCategories } = useQuery(ALL_TOURNAMENT_CATEGORIES_WITH_PARTNERS, {
    skip: !id,
    variables: {
      filter: {
        tournament: id,
        registrationStatus: 'confirm',
      },
    },
    onCompleted: (data) => {
      const categories = data.tournamentCategoriesWithPartnersCount?.filter((item) => item?.category?.category?.type === 'doubles');
      setCategories(categories);
      const firstCategoryWithPartners = categories?.find((item) => item?.partnersCount > 0);
      if (categories?.length > 0) {
        setSelectedValue((prev) => categories?.find((item) => item?.category?.id === Number(prev))?.category?.id);
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    if (value === '') {
      filtersParams.delete('category');
      navigate({ search: filtersParams.toString() });
    } else {
      filtersParams.set('category', value);
      navigate({ search: filtersParams.toString() });
    }
  };

  const { loading, data } = useQuery(PARTNER_SEARCHES, {
    variables: {
      filter: {
        tournament: id,
        categoryId: parseInt(selectedValue?.toString()),
      },
    },
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const auth = useAuth();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (loading) {
    return <Loading height="50vh" />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{tournament?.title ?? ''} | Partner Search</title>
      </Helmet>
      <CustomSection
        title={t('Partner search')}
        titleRightSideElement={
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
            {!isMobile && (
              <CustomSelect
                placeholder="All categories"
                options={categories?.map((item) => ({
                  title: `${item?.category?.category?.name} (${item?.partnersCount})`,
                  value: item?.category?.id,
                }))}
                onClear={() => setSelectedValue('')}
                onChange={handleSelectChange}
                selectedValue={selectedValue}
                buttonProps={{ size: 'medium', sx: { minWidth: '200px' } }}
              />
            )}
            {auth?.user && (
              <CustomButton
                variant="outline"
                size="medium"
                sx={{
                  borderRadius: '999px',
                  borderColor: 'black',
                  color: 'black',
                  '& > svg *': { fill: 'black' },
                }}
                onClick={handleModalOpen}
              >
                <Add sx={{ fontSize: '20px' }} /> {t('Create application')}
              </CustomButton>
            )}
          </Box>
        }
        underTitleElement={
          isMobile && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
              <CustomSelect
                placeholder="All categories"
                options={categories?.map((item) => ({
                  title: item?.category?.category?.name,
                  value: item?.category?.id,
                }))}
                onClear={() => setSelectedValue('')}
                onChange={handleSelectChange}
                selectedValue={selectedValue}
                buttonProps={{ size: 'medium', sx: { minWidth: '200px', width: '100%' } }}
                matchTriggerWidth
              />
            </Box>
          )
        }
      >
        <CustomPaperCard sx={{ padding: '16px', minHeight: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          {data?.partnerSearches?.length > 0 ? (
            <PartnerSearchListView PartnerSearch={data?.partnerSearches} selectedValue={selectedValue} categories={categories} tournament={tournament} />
          ) : (
            <NoDataFound text={t('No partner search applications found')} />
          )}
        </CustomPaperCard>
      </CustomSection>
      <CreateApplicationModal categories={categories} openModal={openModal} setOpenModal={setOpenModal} category={selectedValue?.toString()} />
    </Fragment>
  );
};

export default PartnerSearch;
