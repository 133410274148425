import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import useAuth from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import { USER_VALIDATIONS_UPDATE } from 'src/graphql/mutations';
import { GET_USER_BY_EMAIL } from 'src/graphql/queries';

const usePlayerSelection = (tournamentId) => {
  const auth = useAuth();
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();

  const [validateUser, { loading }] = useMutation(USER_VALIDATIONS_UPDATE);
  const [searchUser] = useLazyQuery(GET_USER_BY_EMAIL, {
    fetchPolicy: 'network-only',
  });

  const getPrimaryEmail = useCallback(
    (isSecondPlayer, categoryId) => {
      const selectedCategory = registrationData.selectedCategories.find((c) => c?.category?.category?.id === categoryId);
      if (registrationData.flow !== 'coach') {
        return auth?.user?.email;
      } else {
        if (isSecondPlayer) {
          return selectedCategory?.partners?.player1?.email || '';
        } else {
          return selectedCategory?.partners?.player2?.email || '';
        }
      }
    },
    [auth?.user?.email, registrationData.flow, registrationData.selectedCategories]
  );

  const handlePlayerValidation = useCallback(
    async (email, isSecondPlayer, categoryId) => {
      try {

        const selectedCategory = registrationData?.selectedCategories?.find((c) => c.category?.id === categoryId);
        const { data } = await validateUser({
          variables: {
            email,
            tournament: parseInt(tournamentId),
            category: parseInt(selectedCategory?.category?.id),
            primaryEmail: getPrimaryEmail(isSecondPlayer, categoryId),
          },
        });
        if (data?.checkValidationsToBeDepriciated?.error) {
          toast.error(data?.checkValidationsToBeDepriciated?.error);
          return null;
        }

        return data?.checkValidationsToBeDepriciated?.user || null;
      } catch (err) {
        toast.error(err?.message);
        return null;
      }
    },
    [validateUser, searchUser, tournamentId, registrationData.selectedCategories, getPrimaryEmail]
  );

  return { handlePlayerValidation, getPrimaryEmail, auth, registrationData, updateRegistrationData, loading };
};

export default usePlayerSelection;
