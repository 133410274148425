import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Typography,
  Button,
  makeStyles,
  Grid,
  Box,
  Container,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Slider, { Settings } from "react-slick";

const useStyles = makeStyles((theme) => ({
  label: {
    borderRadius: "5px",
  },
  slickSlide: {
    paddingTop: 0, // Set paddingTop to 0 for each slide
  },
}));

interface WrapperProps {
  children: (ref: Slider | null) => React.ReactNode;
  toShow?: number;
  hasArrow?: boolean;
  setCurrentTab?: Dispatch<SetStateAction<string>>;
  setRef?: ((ref: Slider | null) => void) | undefined;
  length?: number;
  setSlider?: any;
  slickSlider?: any;
  isResponsive?: boolean;
  autoplay?:boolean
}

const UpdatedSlider: React.FC<WrapperProps> = ({
  children,
  toShow,
  setRef,
  setCurrentTab,
  length,
  setSlider,
  slickSlider,
  isResponsive,
  autoplay
}) => {
  const classes = useStyles();
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);

  const handleBeforeChange = (nextSlide) => {
    const maxSlideIndex = toShow ? length - toShow : length - 1;
    setIsFirstSlide(nextSlide === 0);
    setIsLastSlide(nextSlide >= maxSlideIndex);
  };

  const settings: Settings = {
    className: "slider variable-width overview-banner",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: toShow || 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay:autoplay,

    beforeChange: (current, next) => handleBeforeChange(next),
    responsive: isResponsive
      ? [
          {
            breakpoint: 1550,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]
      : [],
  };

  const sliderRef = useRef<Slider | null>(null);
  //   const [slickSlider, setSlider] = useState<Slider | null>(null);
  const [selectedValue, setSelectedValue] = useState("single");
 
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const customStyle = {
    width:"320px"
  };
  useEffect(() => {
    setSlider(sliderRef.current);
  }, []);
  return (
    <Container
      maxWidth={false}
      sx={{ padding: { lg: "0px", md: "0px", sm: "0px", xs: "12px 0", height:'100%' } }}
    >
      <Grid container height="100%">
        <Grid
          item
          xs={12}
           height="100%"
        >
          <Slider
            ref={sliderRef}
            {...settings}
          >
            {children(slickSlider)}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  );
};
export default UpdatedSlider;
const selectOptions = [
  { key: "single", label: "Single" },
  { key: "double", label: "Double" },
  { key: "team", label: "Team" },
];
