import { gql } from "@apollo/client";

const PLATFORM_BY_ID = gql`
  query organizationPlatformByFields($platformId: Int!) {
    organizationPlatformByFields(platformId: $platformId) {
      federation {
        id
        organizationName
        organizationAbbreviation

        about
        contactEmail
        contactPhone
        website
        organizationType
        logo
        user {
          email
          name
          surname
          instagram
        }
      }
      platform {
        id
        title
        platformType
        country

        sports {
          id
          sport {
            id
            title
          }
          createdAt
        }
        signeeRole
        signeeFullName
        language
        timeZone
        currency
        description
        alreadyDomain
        primaryColor
        secondaryColor
        uploadFile
        yourRequest
        coverPhoto
        lightLogo
        darkLogo
        serviceProvider
        liveScoring
        liveStreaming
        onlineBookingCourts
        onlinePayments
        licenseMemberships
        onlinePayment
        needIntegration
        needLanding
        companyEmail
        fullAddress
        registrationNumber
        companyName
        domain
        mobileApp
        smm
        graphicsForSmm
        template {
          id
        }
      }
    }
  }
`;

export default PLATFORM_BY_ID;
