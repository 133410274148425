import { gql } from "@apollo/client";

const CREATE_USER_COACH = gql`
  mutation createUserCoach($user: Int! $coaches: [Int!]) {
    createUserCoach(createUserCoachInput: { user: $user, coaches: $coaches }) {
      id
    }
  }
`;

export default CREATE_USER_COACH;
