import { FC } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import GetFlag from 'src/utils/getFlags';
import { getFideTitle, parseToJSON } from 'src/utils/helperFunction';
import DragScrollbar from 'src/components/DragScrollbar';
import { useTranslation } from 'react-i18next';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import { DUPRBox, getColorByRating } from './Participants';
import { Info } from '@material-ui/icons';
import { Tournament } from 'src/types';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgba(25, 54, 96, 0.70)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  country: {
    color: '#1B3861',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  subText: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
}));

interface participentsTableProps {
  participants?: any;
  selectedValue?: any;
  categories?: any;
  tournament?: Tournament;
}
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);
const ParticipantsListView: FC<participentsTableProps> = ({ participants, selectedValue, categories, tournament }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const displaySettings = tournament?.league?.league?.sports;
  const shouldDisplayDupr = tournament?.isDuprRatingEnabled;
  const shouldDisplayRank = categories?.find((category) => category?.category?.id == selectedValue)?.category?.category?.isDisplayRank;
  function getColorByName(name) {
    const upperCaseName = name?.toUpperCase();
    const colorMap = {
      MD: '#00A100',
      Q: '#1F6BFF',
      QWC: '#FF5733',
      MDWC: '#FFC600',
      LL: '#8C2135',
      QW: '#FF5790',
    };
    return colorMap[upperCaseName] || '#CCCCCC';
  }

  const foundCategory = categories?.find((category) => category?.category?.id == selectedValue);

  const timeCont = parseToJSON(foundCategory?.category?.category?.timeControl);

  const getRatings = (user) => {
    switch (timeCont?.title?.toLowerCase()?.trim()) {
      case 'blitz':
        return user?.blitzElo === 'Notrated' ? '0' : (user?.blitzElo ?? '0');
      case 'rapid':
        return user?.rapidElo === 'Notrated' ? '0' : (user?.rapidElo ?? '0');
      case 'standard':
        return user?.standardElo === 'Notrated' ? '0' : (user?.standardElo ?? '0');
      default:
        return '0';
    }
  };

  return (
    <Box>
      {/* <Card> */}

      <DragScrollbar
        style={{
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell align="left" sx={{ padding: '16px 8px', width: '5%' }} className={classes.tablehead}>
                  {t('NR')}
                </CustomTableCell>
                {participants?.length > 0 &&
                  participants[0]?.users.map((user, index) => (
                    <>
                      <CustomTableCell
                        align="left"
                        sx={{
                          padding: '16px 8px',
                          width: '15%',
                          maxWidth: 'max-content',
                          whiteSpace: 'nowrap',
                        }}
                        className={classes.tablehead}
                      >
                        {t('PLAYER')} {index + 1}
                      </CustomTableCell>

                      {/* {shouldDisplayDupr && (
                        <CustomTableCell
                          align="left"
                          sx={{
                            padding: '16px 8px',
                            width: '5%',
                            maxWidth: 'max-content',
                          }}
                          className={classes.tablehead}
                        ></CustomTableCell>
                      )} */}

                      {foundCategory?.category?.category?.isDisplayCity && (
                        <CustomTableCell
                          align="left"
                          sx={{
                            padding: '16px 8px',
                            width: '10%',
                            maxWidth: 'max-content',
                          }}
                          className={classes.tablehead}
                        >
                          {t('CITY')}
                        </CustomTableCell>
                      )}

                      {tournament?.league?.league?.sports?.resourceTitle === 'Round' && (
                        <CustomTableCell
                          align="left"
                          sx={{
                            padding: '16px 8px',
                            width: '10%',
                            maxWidth: 'max-content',
                          }}
                          className={classes.tablehead}
                        >
                          {t('RATING')}
                        </CustomTableCell>
                      )}
                      {foundCategory?.category?.category?.isDisplayClub && (
                        <CustomTableCell
                          align="left"
                          sx={{
                            padding: '16px 8px',
                            width: '15%',
                            maxWidth: 'max-content',
                            whiteSpace: 'nowrap',
                          }}
                          className={classes.tablehead}
                        >
                          {t('CLUB')}
                        </CustomTableCell>
                      )}
                      {tournament?.league?.league?.sports?.resourceTitle !== 'Round' && shouldDisplayRank && (
                        <CustomTableCell
                          align="left"
                          sx={{
                            padding: '16px 8px',
                            width: '10%',
                            maxWidth: 'max-content',
                          }}
                          className={classes.tablehead}
                        >
                          {t('RANK')}
                        </CustomTableCell>
                      )}
                    </>
                  ))}

                {foundCategory?.category?.category?.type === 'doubles' && shouldDisplayRank && (
                  <CustomTableCell
                    align="left"
                    sx={{
                      padding: '16px 8px',
                      width: '5%',
                      maxWidth: 'max-content',
                    }}
                    className={classes.tablehead}
                  >
                    {t('TOTAL')}
                  </CustomTableCell>
                )}
                {foundCategory?.category?.category?.type === 'doubles' && shouldDisplayDupr && (
                  <CustomTableCell
                    align="left"
                    sx={{
                      padding: '16px 8px',
                      minWidth: '5%',
                      maxWidth: 'max-content',
                    }}
                    className={classes.tablehead}
                  >
                    <Typography className={classes.tablehead} sx={{ display: 'flex' }}>
                      {t('T.DUPR')}
                      <Tooltip title={t('Total DUPR')}>
                        <Info sx={{ fontSize: '16px', marginLeft: '5px', cursor: 'pointer' }} />
                      </Tooltip>
                    </Typography>
                  </CustomTableCell>
                )}
                {displaySettings?.isDisplaySegments && (
                  <CustomTableCell
                    align="left"
                    sx={{
                      padding: '16px 8px',
                      width: '5%',
                      maxWidth: 'max-content',
                    }}
                    className={classes.tablehead}
                  >
                    {t('SEGMENT')}
                  </CustomTableCell>
                )}
                {displaySettings?.isDisplaySeeds && (
                  <CustomTableCell
                    align="left"
                    sx={{
                      padding: '16px 8px',
                      width: '5%',
                      maxWidth: 'max-content',
                    }}
                    className={classes.tablehead}
                  >
                    {t('SEED')}
                  </CustomTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {participants?.length > 0 &&
                participants?.map((entry, index) => (
                  <TableRow
                    hover
                    key={entry?.id}
                    sx={{
                      borderRadius: '6px',
                      padding: '12px',
                      border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                      background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                    }}
                  >
                    <CustomTableCell className={classes.subText} sx={{ width: '5%' }}>
                      {index + 1}
                    </CustomTableCell>
                    {entry?.users?.map((user) => (
                      <>
                        <CustomTableCell
                          sx={{
                            width: '15%',
                            maxWidth: 'max-content',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'pointer',
                              }}
                              onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                            >
                              <GetFlag country={user?.user?.citizenship || user?.user?.nation} style={{ width: '15px', height: '15px', marginTop: '1px', marginRight: '0px' }} />

                              <CustomAvatar src={user?.user?.avatar} size={24} key={user?.user?.id} alt={user?.user?.name} seed={user?.user?.name + ' ' + user?.user?.surname} />
                              {entry?.users?.length > 1 ? (
                                <Typography className={classes.name}>{`${user?.user?.name?.charAt(0).toUpperCase()}. ${user?.user?.surname}`}</Typography>
                              ) : (
                                <Typography className={classes.name}>{`${user?.user?.name} ${user?.user?.surname}`}</Typography>
                              )}

                              {shouldDisplayDupr && user?.duprRating && <DUPRBox rating={user.duprRating} color={getColorByRating(user.duprRating)} />}
                              {displaySettings?.isDisplayAthleteChessTitle && (
                                <Box
                                  sx={{
                                    padding: '3px 5px',
                                    background: getFideTitle(user?.fideTitle)?.color,
                                    color: '#FFFF',
                                    borderRadius: '5px',
                                    marginRight: '10px',
                                    width: 'max-content',
                                  }}
                                >
                                  {getFideTitle(user?.fideTitle)?.abbreviation}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </CustomTableCell>

                        {foundCategory?.category?.category?.isDisplayCity && (
                          <CustomTableCell sx={{ width: '10%', maxWidth: 'max-content' }}>
                            <Typography className={classes.country} sx={{ whiteSpace: 'nowrap' }}>
                              {user.user?.city}
                            </Typography>
                          </CustomTableCell>
                        )}

                        {tournament?.league?.league?.sports?.resourceTitle === 'Round' && (
                          <CustomTableCell sx={{ width: '10%', maxWidth: 'max-content' }}>
                            <Typography className={classes.country} sx={{ whiteSpace: 'nowrap' }}>
                              {getRatings(user)}
                            </Typography>
                          </CustomTableCell>
                        )}
                        {foundCategory?.category?.category?.isDisplayClub && (
                          <CustomTableCell sx={{ width: '15%', maxWidth: 'max-content' }}>
                            <Typography className={classes.country} sx={{ whiteSpace: 'nowrap' }}>
                              {user.user?.user_club_id?.clubName}
                            </Typography>
                          </CustomTableCell>
                        )}
                        {tournament?.league?.league?.sports?.resourceTitle !== 'Round' && shouldDisplayRank && (
                          <CustomTableCell sx={{ width: '10%', maxWidth: 'max-content' }}>
                            <Typography className={classes.country}>#{user?.rank || 0}</Typography>
                          </CustomTableCell>
                        )}
                      </>
                    ))}
                    {entry?.users?.length > 1 && shouldDisplayRank && (
                      <CustomTableCell sx={{ width: '5%', maxWidth: 'max-content' }}>
                        <Typography className={classes.country}>#{entry?.users[0]?.rank + entry?.users[1]?.rank}</Typography>
                      </CustomTableCell>
                    )}
                    {entry?.users?.length > 1 && shouldDisplayDupr && (
                      <CustomTableCell sx={{ width: '5%', maxWidth: 'max-content' }}>
                        <Typography className={classes.country}>{(entry?.users[0]?.duprRating + entry?.users[1]?.duprRating)?.toFixed(3)}</Typography>
                      </CustomTableCell>
                    )}
                    {displaySettings?.isDisplaySegments && (
                      <CustomTableCell sx={{ width: '5%', maxWidth: 'max-content' }}>
                        {(entry?.assignedSegment || entry?.additionalSegment) && (
                          <Typography
                            sx={{
                              width: 'max-content',
                              fontWeight: 500,
                              border: `1px solid ${getColorByName(entry?.assignedSegment)}`,
                              padding: '4px 12px',
                              borderRadius: '4px',
                              fontSize: { lg: '10px', xs: '8px' },
                              fontFamily: 'Inter',
                              color: getColorByName(entry?.assignedSegment) === '#FFC600' ? '#0A2540' : getColorByName(entry?.assignedSegment) === '#CCCCCC' ? '#0A2540' : '#0A2540',
                            }}
                          >
                            {entry?.assignedSegment === 'Q' && entry?.additionalSegment ? 'QW' : entry?.assignedSegment}
                          </Typography>
                        )}
                      </CustomTableCell>
                    )}
                    {displaySettings?.isDisplaySeeds && (
                      <CustomTableCell sx={{ width: '5%', maxWidth: 'max-content' }}>
                        <Typography className={classes.country}>{entry?.seed}</Typography>
                      </CustomTableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </DragScrollbar>
    </Box>
  );
};

export default ParticipantsListView;
