import { gql } from '@apollo/client';
const MATCHESDATES = gql`
  query groupMatches($filter: ListGroupMatchInput) {
    groupMatches(filter: $filter) {
      id
      date
    }
  }
`;

export default MATCHESDATES;
