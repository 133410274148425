import { gql } from '@apollo/client';

const RENEW_LICENSE = gql`
  mutation renewUserLicense(
    $user: Int!
    $license: Int!
    $promo: String!
    $currency: String!
    $payment_status: LicensePaymentStatus
    $additionalQuestion: [JSONObject!]
    $startDate: DateTime!
    $endDate: DateTime!
    $basePrice: Float
    $type: String
  ) {
    renewUserLicense(
      createPurchasedLicenseInput: {
        user: $user
        license: $license
        promo: $promo
        currency: $currency
        payment_status: $payment_status
        additionalQuestion: $additionalQuestion
        startDate: $startDate
        endDate: $endDate
        basePrice: $basePrice
        type: $type
      }
    ) {
      id
      promo
      message
    }
  }
`;

export default RENEW_LICENSE;
