import { gql } from '@apollo/client';

const getTournamentById = gql`
  query getTournamentById($id: ID!, $userId: Float, $tournamentId: Float) {
    tournament(id: $id, userId: $userId, tournamentId: $tournamentId) {
      id
      title
      club {
        id
      }
      tour
      coverPhoto
      startDate
      endDate
      entryDeadline
      description
      license
      timeZone
      termsAndConditions
      closeRegistration
      partnerSearch
      country
      address
      city
      entryFee
      registerLink
      withdrawlDeadline
      currency
      createdAt
      termsAndConditions
      type
      registerLink
      regulations
      paymentMethod
      logo
      timeControl
      showOOP
      poster
      tournamentRankings {
        id
      }
      organizer {
        organizationName
        organizationEmail
        organizationLogo
        organizationPhone
        isOrganization
        member {
          email
          name
          id
          avatar
          surname
          instagram
          facebook
          phone
        }
      }
      league {
        id
        league {
          id
          enableLicense
          owner {
            id
            federation {
              id
            }
          }
          sports {
            id
            icon
            title
            resourceTitle
            displayLiveStandings
            displayOrderPlay
            displayListResults
            displayPendingResults
            displayTournamentResults
            displayGroups
            displayDraws
            enableStages
            enableCourts
            enableDiscipline
            isEnablePairings
            isEnablePairingStandings
            isDisplaySeeds
            isDisplaySegments
            isDisplayAthleteChessTitle
          }
          title
        }
      }
      tournamentCategory {
        additionalQuestions {
          id
          question
          fieldType
          fieldOptions
          answer
          askEachAthlete
        }
        status
        isRegistered
        paymentStatus
        onlinePayments
        category {
          isDisplayClub
          isDisplayCity
          timeControl
          name
          type
          gender
          status
          id
          ageLimit
          ageType
          ageTo
          ageFrom
          skillsLevel
          ageGroup
        }
        id
        fee
        onsiteFee
        currency
        method
        time
        timePerMove
        timeAfterMove
        extraTime
        pairing
      }
      draws {
        id
      }
      groups {
        id
      }
      liveStandings {
        id
      }
      facts {
        id
        answer
        factSheet {
          id
          fieldTitle
          fieldType
          options
        }
      }
    }
  }
`;

export default getTournamentById;
