import { Box, Stack } from '@material-ui/core';
import CustomPaperCard from '../CustomComponents/CustomPaperCard';
import React from 'react';
import { getPlatformId } from '../../constants';
import { useQuery } from '@apollo/client';
import { SPORTSWITHCOUNT } from '../../graphql/queries';
import useAuth from '../../hooks/useAuth';
import NavSection from '../NavSection';
import { filteredSections } from '../dashboard/DashboardSidebar';
import CustomButton from '../CustomComponents/CustomButton';
import CustomChip from '../CustomComponents/CustomChip';
import { CheckRounded, PaidOutlined } from '@material-ui/icons';
import toast from 'react-hot-toast';
import chroma from 'chroma-js';
import SportIcon from '../CustomComponents/SportIcon';

const MobileMenu = ({ open }) => {
  const platformId = getPlatformId();
  const { data } = useQuery(SPORTSWITHCOUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      platform: parseInt(process.env.REACT_APP_PLATFORM_ID),
    },
  });
  const { selectedSports, setSelectedSports, platformData } = useAuth();

  const sports = data?.sportsWithTournamentCount?.map((sport) => ({ ...sport, selected: selectedSports.includes(sport.id) })) ?? [];

  const handleSportSelect = (sport) => {
    if (sport.selected) {
      if (selectedSports.length === 1) {
        return toast.error('Please select at least one sport');
      } else {
        setSelectedSports(selectedSports.filter((id) => id !== sport.id));
        toast.error(sport.title + ' removed', { icon: <SportIcon sport={sport.title} /> });
      }
    } else {
      setSelectedSports([...selectedSports, sport.id]);
      toast.success(sport.title + ' added', { icon: <SportIcon sport={sport.title} color="green" /> });
    }
  };

  return (
    open && (
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          animation: 'slideUp 0.2s ease-out',
          zIndex: 999,
          '@keyframes slideUp': {
            '0%': {
              opacity: 0,
              transform: 'translateY(10%)',
            },
            '40%': {
              opacity: 0,
              transform: 'translateY(10%)',
            },
            '100%': {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },
        }}
      >
        <Box sx={{ height: '100%', flex: 1, background: '#fff', borderRight: '1px solid #EFEFF0' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: '8px',
              padding: '10px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {filteredSections(Number(platformId))?.map((section) => <NavSection key={section.title} openSideBarState={true} pathname={location.pathname} {...section} />)}
            {platformData?.isEnablePrice && <NavSection openSideBarState={true} path="/pricing?plan=manager" title="pricing" highlighted icon={<PaidOutlined sx={{ fontSize: '20px' }} />} />}
          </Box>
        </Box>
        <Stack
          sx={{
            height: '100%',
            width: '60px',
            background: chroma('white').darken(0.1).alpha(0.95).hex(),
            backdropFilter: 'blur(10px)',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
          }}
        >
          {sports.map((sport) => (
            <CustomButton onClick={() => handleSportSelect(sport)} size="auto" variant="text" sx={{ height: 'fit-content', width: 'fit-content' }}>
              <CustomPaperCard
                sx={{
                  position: 'relative',
                  borderRadius: '999px',
                  height: '40px',
                  width: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.2s',
                  ...(sport.selected ? { borderColor: process.env.REACT_APP_PRIMARY_COLOR } : {}),
                }}
              >
                <Box
                  sx={{
                    minHeight: '22px',
                    minWidth: '22px',
                    transition: 'all 0.2s',
                    backgroundColor: sport.selected ? process.env.REACT_APP_PRIMARY_COLOR : '#0A2540',
                    WebkitMask: `url(${sport?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                    mask: `url(${sport?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                    maskSize: '20px 20px',
                  }}
                />
              </CustomPaperCard>
              {sport.selected && (
                <CustomChip
                  size="xs"
                  variant="primary"
                  square
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    maxHeight: '14px',
                    maxWidth: '14px',
                    minHeight: '14px',
                    minWidth: '14px',
                  }}
                >
                  <CheckRounded sx={{ fontSize: '9px' }} />
                </CustomChip>
              )}
            </CustomButton>
          ))}
        </Stack>
      </Box>
    )
  );
};

export default MobileMenu;
