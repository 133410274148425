import { gql } from '@apollo/client';

const CREATE_TOURNAMENT_REG_PAYMENT = gql`
  mutation createTournamentRegistrationPayment(
    $email: String
    $name: String
    $amount: Float
    $entryFee: Float
    $platformProcessingFee: Float!
    $successUrl: String
    $currency: String
    $registrationRequestIds: [Int!]
    $tournamentName: String
    $tournamentId: Int
    $VATFee: Float!
    $userId: Int
    $totalTournatedServiceFee: Float!
    $totalPartnerServiceFee: Float!
  ) {
    createTournamentRegistrationPayment(
      createRegistrationPaymentInput: {
        email: $email
        name: $name
        amount: $amount
        successUrl: $successUrl
        currency: $currency
        entryFee: $entryFee
        platformProcessingFee: $platformProcessingFee
        registrationRequestIds: $registrationRequestIds
        tournamentName: $tournamentName
        tournamentId: $tournamentId
        VATFee: $VATFee
        userId: $userId
        totalTournatedServiceFee: $totalTournatedServiceFee
        totalPartnerServiceFee: $totalPartnerServiceFee
      }
    )
  }
`;

export default CREATE_TOURNAMENT_REG_PAYMENT;
