import React, { ReactElement, ReactNode } from 'react';
import { Box, Fade, Typography } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type Props = {
  children: ReactNode;
  title: string;
  titleRightSideElement?: ReactElement;
  icon?: ReactElement;
  underTitleElement?: ReactElement;
  gap?: number;
  sx?: SxProps;
};
const CustomSection = ({ children, title, icon, titleRightSideElement, underTitleElement, sx, gap = 15 }: Props) => (
  <Fade in={true} timeout={700}>
    <Box display="flex" flexDirection="column" gap={`${gap}px`} sx={{ mt: '15px', ...sx }} width="100%">
      <Box display="flex" height="40px" alignItems="center" justifyContent="space-between" gap="32px">
        <Box color="#0A2540" sx={{ alignItems: 'center', display: 'flex', gap: '6px' }}>
          {icon}
          <Typography variant="heading14" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {titleRightSideElement && <Box flex="1">{titleRightSideElement}</Box>}
      </Box>
      {underTitleElement}
      {children}
    </Box>
  </Fade>
);

export default CustomSection;
