import { gql } from '@apollo/client';

const SEARCH_TOURNAMENTS = gql`
  query searchTournamentsByTitle($title: String, $sport: String, $country: String, $ageGroup: String, $skills: String, $leagueId: Float) {
    searchTournamentsByTitle(title: $title, sport: $sport, country: $country, ageGroup: $ageGroup, skills: $skills, leagueId: $leagueId) {
      id
      tour
      title
      address
      startDate
      endDate
      country
      city
      coverPhoto
      logo
      withdrawlDeadline
      type
      status
      entryDeadline
      createdAt
      closeRegistration
      paymentMethod
      additionalQuestion {
        id
        question
      }
      tournamentCategory {
        id
        status
        category {
          name
        }
        fee
      }
      organizer {
        organizationName
        organizationEmail
        isOrganization
        member {
          name
        }
      }
      referee {
        referee {
          name
        }
      }
      league {
        id
        league {
          id
          owner {
            id
            federation {
              id
              organizationName
              organizationType
              organizationAbbreviation
              logo
            }
          }
          title
          enableLicense
          sports {
            id
            icon
            title
          }
          abbreviation
        }
      }
      tournamentCategory {
        isRegistered
        category {
          name
          type
          gender
          status
          id
          ageLimit
          ageType
          ageTo
          ageFrom
          skillsLevel
          ageGroup
        }
        id
        fee
        currency
      }
      organizer {
        organizationName
        organizationEmail
        isOrganization
        member {
          name
          id
          surname
          avatar
        }
      }
      referee {
        referee {
          name
        }
      }
      registrationRequests {
        id
      }
      additionalReferee {
        referee {
          name
        }
      }
    }
  }
`;

export default SEARCH_TOURNAMENTS;
