import { Box, Button, Theme, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    gap: '10px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fbe3dd',
    borderRadius: '50px',
    padding: '10px',
  },
  Btn: {
    width: '100%',
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '12px',
    height: '35px',
    borderRadius: '6px',
  },
}));
interface NoDataFoundProps {
  btnText?: string;
  text?: string;
  description?: string;
  sx?: object;
  handleNavigate?: any; // Optional sx prop for custom styles
}
const NoDataFound: React.FC<NoDataFoundProps> = ({ btnText, text, sx, description, handleNavigate }) => {
  const classes = useStyles();
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        ...sx,
      }}
      className={classes.container}
    >
      <Box className={classes.imageContainer}>
        {/* <img src="/images/Nosvg.svg" height={52} width={52} /> */}
        <img src={auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON} height={20} width={20} />
      </Box>
      <Typography variant="font17" sx={{ textAlign: 'center' }}>
        {t(text)}
      </Typography>
      {description && (
        <Typography variant="font17" sx={{ textAlign: 'center' }}>
          {description}
        </Typography>
      )}
      {btnText && (
        <Box sx={{ mt: 1 }}>
          <Button className={classes.Btn} onClick={handleNavigate}>
            {btnText || 'View'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NoDataFound;
