import React from 'react';
import { useTranslation } from 'react-i18next';
import Description from './Description';
import CustomSection from '../../../../layout/CustomSection';
import CustomPaperCard from '../../../../CustomComponents/CustomPaperCard';
import Format from './Format';
import FactSheet from './Facts';
import { EmojiEvents } from '@material-ui/icons';

const Overview = () => {
  const { t } = useTranslation();

  return (
    <CustomSection gap={6} title={t('Tournament Overview')} icon={<EmojiEvents />} sx={{ mt: 0 }}>
      <CustomPaperCard sx={{ padding: { xs: '12px', md: '22px' }, display: 'flex', flexDirection: 'column', gap: '22px' }}>
        <Format />
        <Description />
        <FactSheet />
      </CustomPaperCard>
    </CustomSection>
  );
};

export default Overview;
