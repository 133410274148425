import { gql } from '@apollo/client';

const MyENTERIES = gql`
  query GetTournamentTeamMembers($filter: ListTournamentTeamMembersInput, $status: String, $isUnique: Boolean) {
    tournamentTeamMembers(filter: $filter, status: $status, isUnique: $isUnique) {
      id
      status
      isDelete
      registrationRequest {
        id
        status
        type
        createdAt
      }
      user {
        id
        name
        surname
        avatar
        nation
        citizenship
      }
      tournament {
        id
        title
        withdrawlDeadline
        logo
        coverPhoto
        startDate
        country
        additionalQuestion {
          id
          question
        }
      }
      categoryId {
        id
      }
    }
  }
`;

export default MyENTERIES;
