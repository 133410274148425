import { gql } from '@apollo/client';

const USERTOURNAMENTS = gql`
  query userTournaments($id: Int) {
    userTournaments(user: $id) {
      id
      title
      tour
      address
      startDate
      endDate
      entryDeadline
      createdAt
      country
      city
      coverPhoto
      paymentMethod
      closeRegistration
      logo
      league {
        id
        league {
          id
          title
          enableLicense
          sports {
            id
            icon
            title
          }
          abbreviation
        }
      }
      tournamentCategory {
        id
        status
        category {
          name
        }
        fee
      }
      organizer {
        organizationName
        organizationEmail
        isOrganization
        member {
          name
          id
          surname
          avatar
        }
      }
      referee {
        referee {
          name
        }
      }
      registrationRequests {
        id
      }
    }
  }
`;

export default USERTOURNAMENTS;
