import { gql } from '@apollo/client';

const RESET = gql`
  mutation updateUserpassword($email: String, $password: String, $oldPassword: String) {
    updateUserPassword(updateUserpassword: { email: $email, password: $password, oldPassword: $oldPassword }) {
      id
      name
      password
      email
    }
  }
`;

export default RESET;
