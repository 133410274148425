import { Box } from '@material-ui/core';
import data from '../assets/data-loader-orange.json';
import Lottie from 'lottie-react';

interface LoadingProps {
  height?: string;
  responsive?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ height, responsive }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: height || '100dvh',
        width: '100%',
      }}
    >
      {/* <video autoPlay loop muted style={{ width: '80px', height: '80px' }}>
        <source src="/images/loading-light.webm" type="video/mp4" />
      </video> */}
      <Lottie
        animationData={data}
        loop={true}
        style={{
          width: responsive ? '50px' : '130px',
          height: responsive ? '50px' : '130px',
        }}
      />
    </Box>
  );
};

export default Loading;
