import { useState, useEffect } from "react";

const useDebouncedTerm = (term: string, delay: number) => {
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(term);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [term, delay]);

  return debouncedTerm;
};

export default useDebouncedTerm;
