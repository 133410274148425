import { Box } from '@material-ui/core';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import ChevronRight from '../icons/ChevronRight';
import React from 'react';
import { ReactSVG } from 'react-svg';

type Props = {
  text: string | String;
  isTournament?: boolean;
  onClick: () => void;
};

const ViewAllButton = ({ text, isTournament, onClick }: Props) => (
  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
    {isTournament ? (
      <ReactSVG
        src="/images/updated/download.svg"
        style={{
          paddingRight: '5px',
          paddingTop: '5px',
        }}
      />
    ) : (
      ''
    )}
    <CustomButton variant="text" size="auto" onClick={onClick}>
      {text}
    </CustomButton>
    <CustomButton shape="circle" size="small" variant="primary" onClick={onClick}>
      <ChevronRight sx={{ fontSize: 16 }} />
    </CustomButton>
  </Box>
);

export default ViewAllButton;
