import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomButton from './CustomButton';
import ChevronDown from '../../icons/ChevronDown';

interface CustomAccordionProps {
  summaryText: React.ReactNode | string;
  detailsText: React.ReactNode | string;
  iconSrc?: string;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ summaryText, detailsText, iconSrc = '/images/alert.svg' }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#0A25400D',
        borderRadius: '6px',
        padding: '10px',
        gap: '10px',
        transition: 'all 0.3s ease',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={handleToggle}
    >
      <img src={iconSrc} style={{ height: '28px', width: '28px' }} alt="alert" />
      <Box>
        <Typography variant="font18" sx={{ color: '#0A254059', width: '100%', display: 'inline', lineHeight: '14px' }}>
          {summaryText}
          {!expanded && '...'} {expanded && detailsText}
        </Typography>
      </Box>
      <CustomButton variant="secondary" shape="circle" size="small">
        <ChevronDown sx={{ fontSize: 18 }} />
      </CustomButton>
    </Box>
  );
};

export default CustomAccordion;
