import { gql } from "src/__generated__";


const MyENTERIESUPDATED = gql(`
  query tournamentRegistrations($filter: ListTournamentRegistrationInput) {
    tournamentRegistrations(filter: $filter) {
      id
      status
      type
      additionalFee
      createdAt
      payForMyself
      amount
      users {
        id
        status
        paymentStatus
        user {
          id
          name
          surname
          avatar
          nation
          
        }
      }
      tournament {
        id
        title
        withdrawlDeadline
        additionalQuestion {
          id
          question
        }
      }
      categoryId {
        id
        fee
        onsiteFee
        onlinePayments
        currency
        category {
          name
          id
          type
        }
      }
    }
  }
`);

export default MyENTERIESUPDATED;
