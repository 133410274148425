import { gql } from '@apollo/client';

const TOURNAMENTS_COUNTRIES = gql`
  query tournamentCountries($filter: ListTournamentInput) {
    tournamentCountries(filter: $filter) {
      country
    }
  }
`;

export default TOURNAMENTS_COUNTRIES;
