import { gql } from "@apollo/client";

const CREATE_USER_SPORT = gql`
  mutation createUserSport($user: Int, $sport: Int) {
    createUserSport(createUserSportInput: { user: $user, sport: $sport }) {
      id
    }
  }
`;

export default CREATE_USER_SPORT;
