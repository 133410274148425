import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import CloseIcon from "@material-ui/icons/Close";
import useAuth from "src/hooks/useAuth";
import BlockIcon from "@material-ui/icons/Block";
import FileDropzone from "src/components/FileDropzone";
const useStyles = makeStyles((theme) => ({
  selectInput: {
    "& .MuiInputBase-root": {
      height: "36px!important",
    },
  },
  textfield: {
    flexShrink: 0,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
  labelfield: {
    color: "#1B3861",
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
  },
  modalTitle: {
    fontFamily: "Eudoxus Sans",
    fontSize: "18px",
    fontWeight: 700,
  },
}));
interface AddResultsProps {
  openAddResults: boolean;
  setOpenAddResults: Dispatch<SetStateAction<boolean>>;
}
const AddResultsModal: FC<AddResultsProps> = ({
  openAddResults,
  setOpenAddResults,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClose = () => {
    setOpenAddResults(false);
  };

  return (
    <React.Fragment>
      <Modal
        open={openAddResults}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: "912px",
            borderRadius: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            zIndex: 10,
            outline: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <Paper
            elevation={12}
            sx={{ width: "100%", background: "#FFF", padding: "30px" }}
          >
            <Box
              sx={{
                // padding: "30px 20px 0px 20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.modalTitle}>Pike Fish</Typography>
              <CloseIcon
                sx={{ color: "black", cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#0A2540",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    paddingTop: "10px",
                  }}
                >
                  Result
                </Typography>

                <TextField
                  fullWidth
                  placeholder="513|"
                  name="email"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: "1px solid #EFEFF0",
                      background: "#FBFBFB",
                      "& fieldset": {
                        borderWidth: "0px",
                      },
                      "&:hover fieldset": {
                        borderWidth: "0px",
                      },
                      "&.Mui-focused fieldset": {
                        borderWidth: "0px",
                      },
                    },
                    mt: 1,
                    color: "rgba(25, 54, 96, 0.40)",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                  className={classes.textfield}
                  disabled
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#0A2540",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    paddingTop: "10px",
                  }}
                >
                  Identify Unit (cm)
                </Typography>

                <TextField
                  fullWidth
                  placeholder="Līdaka, 63.00cm2"
                  name="email"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: "1px solid #EFEFF0",
                      background: "#FBFBFB",
                      "& fieldset": {
                        borderWidth: "0px",
                      },
                      "&:hover fieldset": {
                        borderWidth: "0px",
                      },
                      "&.Mui-focused fieldset": {
                        borderWidth: "0px",
                      },
                    },
                    mt: 1,
                    color: "rgba(25, 54, 96, 0.40)",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                  className={classes.textfield}
                  disabled
                />
              </Box>
            </Box>

            <Box sx={{ paddingTop: "20px" }}>
              <Typography
                sx={{
                  marginBottom: "10px",
                  color: "#0A2540",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Attachments
              </Typography>
              <FileDropzone />
            </Box>
            <Box sx={{ paddingTop: "20px" }}>
              <Button
                variant="outlined"
                sx={{
                  display: "inline-flex",
                  height: "38px",
                  maxWidth:"119px",
                  width:"100%",
                  padding: "10px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: 0,
                  borderRadius: "8px",
                  // background: "#29ABE2",
                  color: "#FFF",
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddResultsModal;
