import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

const DragScrollbar = ({ children, ...props }) => {
  return (
    <ScrollContainer {...props}>
      {children}
    </ScrollContainer>
  );
};

export default DragScrollbar;
