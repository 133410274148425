import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import type { ListProps } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { useNavigate } from 'react-router';
import { keyframes } from '@emotion/react';
import { ReactElement } from 'react';
import CustomTooltip from './CustomComponents/CustomTooltip';

interface Item {
  path: string;
  title: string;
}

interface Props extends ListProps {
  subItems?: Item[];
  pathname?: string;
  title: string;
  icon?: string | ReactElement;
  path?: string;
  openSideBarState: boolean;
  revert?: boolean;
  highlighted?: boolean;
}

const NavSection = (props: Props) => {
  const { path, title, openSideBarState, icon, revert, highlighted } = props;
  const { t } = useTranslation();

  const location = useLocation();
  const isActiveItem = path === location.pathname + location.search || path === location.pathname;

  const navigate = useNavigate();

  const animateFromLeft = keyframes`
    from {
      opacity: 0;
      transform: translate(-14px, -50%) scaleY(0.8);
    }
    to {
      opacity: 1;
      transform: translate(-11px,-50%) scaleY(1);
    }
  `;

  const animateFromRight = keyframes`
    from {
      opacity: 0;
      transform: translate(14px, -50%) scaleY(0.8);
    }
    to {
      opacity: 1;
      transform: translate(6px,-50%) scaleY(1);
    }
  `;

  const handleClick = (e) => {
    if (path) navigate(path);
    props.onClick?.(e);
  };

  return (
    <CustomTooltip mobileFriendly={false} disabled={revert || openSideBarState} title={t(title)} placement={revert ? 'left' : 'right'} disableInteractive hidden>
      <CustomButton
        onClick={handleClick}
        variant="menu"
        selected={isActiveItem || highlighted}
        size="medium"
        square={!openSideBarState}
        sx={{ position: 'relative', ...(openSideBarState ? { width: { xs: '100%', sm: '230px' }, justifyContent: 'start', alignItems: 'center', gap: '12px', overflow: 'visible !important' } : {}) }}
      >
        {typeof icon === 'string' ? <ReactSVG src={icon} style={{ marginTop: '2px', width: '20px', transition: 'all 0.8s' }} /> : icon}
        {openSideBarState && t(title)}
        {isActiveItem && (
          <Box
            sx={{
              width: '3px',
              height: '20px',
              background: process.env.REACT_APP_PRIMARY_COLOR,
              position: 'absolute',
              left: revert ? '100%' : 0,
              top: '50%',
              transform: revert ? 'translate(50%, -50%)' : 'translateY(-50%)',
              animation: `${revert ? animateFromRight : animateFromLeft} 0.2s forwards`,
              borderBottomRightRadius: revert ? 0 : '8px',
              borderTopRightRadius: revert ? 0 : '8px',
              borderBottomLeftRadius: revert ? '8px' : 0,
              borderTopLeftRadius: revert ? '8px' : 0,
            }}
          />
        )}
      </CustomButton>
    </CustomTooltip>
  );
};

export default NavSection;
