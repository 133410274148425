import { gql } from "@apollo/client";

const DELETE_PLATEFORM_SPORT = gql`
  mutation removePlateformSport($id: Int!) {
    removePlateformSport(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_PLATEFORM_SPORT;
