import { gql } from '@apollo/client';

const GET_PLATFORM = gql`
  query platform($id: Int!, $isClient: Boolean) {
    platform(id: $id, isClient: $isClient) {
      id
      title
      country
      platformType
      primaryColor
      secondaryColor
      darkLogo
      lightLogo
      darkEmblem
      lightEmblem
      coverPhoto
      isEnablePrice
      language
      companyName
      companyEmail
      companyPhone
      platformType
      landingLink
      thirdPartyLicenses {
        id
        title
      }
      sports {
        id
        sport {
          id
          title
        }
      }
    }
  }
`;

export default GET_PLATFORM;
