import { Fragment, useEffect, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  Avatar,
  makeStyles,
  Tooltip,
  withStyles,
  IconButton,
} from '@material-ui/core';
import type { ListResults, ListResultsTableProps } from 'src/types/listResults';
import Scrollbar from 'src/components/Scrollbar';
import { ReactSVG } from 'react-svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { ArrowDownward, ArrowUpward, Create, List } from '@material-ui/icons';
import useDebounce from 'src/utils/debounce';
import GetFlag from 'src/utils/getFlags';
import { useNavigate } from 'react-router';
import MetricListModal from './MetricListModal';

const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const useStyles = makeStyles((theme) => ({
  athlete: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '5px',
  },
  athleteAvatar: {
    height: 24,
    width: 24,
    marginRight: '10px',
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "10px",
    // },
  },
}));

const applyFilters = (listAndResults: ListResults[], query: string, filters: any): ListResults[] =>
  listAndResults?.filter((ListResults) => {
    let matches = true;

    if (query) {
      const searchWords = query.toLowerCase().split(' ');
      const properties = ['name', 'surname'];
      let containsQuery = false;

      properties.forEach((property) => {
        // if (searchWords.every((word) =>listAndResults.some((user) => user.user[property]?.toLowerCase().includes(word)))) {
        //   containsQuery = true;
        // }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && ListResults[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (customers: ListResults[], page: number, limit: number): ListResults[] => customers?.slice(page * limit, page * limit + limit);

const descendingComparator = (a: ListResults, b: ListResults, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: ListResults, b: ListResults) => descendingComparator(a, b, orderBy) : (a: ListResults, b: ListResults) => -descendingComparator(a, b, orderBy);

const applySort = (customers: ListResults[], sort): ListResults[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const RankingsListTable: FC<ListResultsTableProps> = (props) => {
  const { listAndResults, title, category, stages, matrics, isCustomMetrics, isStage, ...other } = props;
  const [selectedLiveStandings, setSelectedLiveStandings] = useState<number[]>([]);

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('location|asc,weight|desc');
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });

  useEffect(() => {
    setSort('');
  }, [listAndResults]);

  const enableBulkActions = selectedLiveStandings.length > 0;
  const selectedSomeLiveStandings = selectedLiveStandings.length > 0 && selectedLiveStandings.length < listAndResults?.length;
  const selectedAllLiveStandings = selectedLiveStandings.length === listAndResults?.length;

  const handleSelectAllLiveStandings = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedLiveStandings(event.target.checked ? listAndResults?.map((ListResults) => ListResults.id) : []);
  };

  const handleSelectOneDocument = (event: ChangeEvent<HTMLInputElement>, customerId: number): void => {
    if (!selectedLiveStandings.includes(customerId)) {
      setSelectedLiveStandings((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedLiveStandings((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value);
  };

  const handleSortRequest = (property: string) => {
    if (property === 'location') {
      setSort('location|asc,weight|desc');
    } else if (property === 'weight') {
      setSort('weight|desc');
    }
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredDocuments = applyFilters(listAndResults, query, filters);
  const sortedDocuments = applySort(filteredDocuments, sort);
  const paginatedDocuments = applyPagination(sortedDocuments, page, limit);
  const [listModal, setListModal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const handleOpenListModal = (item, metric) => {
    setSelectedMetric({ entryId: item?.id, metric });
    setListModal(true);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'end',
          position: 'relative',
          gap: 2,
          m: -1,
          pb: 4,
          // pt: 2.5,
          '@media (max-width: 768px)': {
            py: 3,
          },
        }}
      ></Box>
      {listAndResults?.length > 0 && (
        <Card
          {...other}
          sx={{
            boxShadow: 'none',
            border: '1px solid #EDF1F6',
            borderRadius: '6px',
          }}
        >
          <Scrollbar>
            <Box sx={{ minWidth: 700, padding: '0 12px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <CustomTableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllLiveStandings}
                        sx={{ color: "#D3D3D3" }}
                        indeterminate={selectedSomeLiveStandings}
                        onChange={handleSelectAllLiveStandings}
                      />
                    </CustomTableCell> */}
                    <CustomTableCell>
                      <Typography
                        className={classes.tablehead}
                        sx={{
                          whiteSpace: 'pre',
                        }}
                      >
                        NR
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        className={classes.tablehead}
                        sx={{
                          whiteSpace: 'pre',
                        }}
                      >
                        Team
                      </Typography>
                    </CustomTableCell>

                    {listAndResults?.some((league) => league?.entry) && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                          }}
                        >
                          Athlete
                        </Typography>
                      </CustomTableCell>
                    )}

                    {stages?.map(
                      (stage) =>
                        stage?.isPublish && (
                          <Fragment key={stage?.id}>
                            <CustomTableCell>
                              <Typography
                                className={classes.tablehead}
                                sx={{
                                  whiteSpace: 'pre',
                                }}
                              >
                                {stage?.name}
                              </Typography>
                            </CustomTableCell>
                            <CustomTableCell>
                              <Typography
                                className={classes.tablehead}
                                sx={{
                                  whiteSpace: 'pre',
                                }}
                              >
                                Points
                              </Typography>
                            </CustomTableCell>
                          </Fragment>
                        )
                    )}

                    {!isStage && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                          }}
                        >
                          Total Weight
                        </Typography>
                      </CustomTableCell>
                    )}

                    {stages?.length > 0 && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                          }}
                        >
                          Total Points
                        </Typography>
                      </CustomTableCell>
                    )}

                    {isStage && !isCustomMetrics && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                            paddingLeft: '10px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => handleSortRequest('location')}
                        >
                          Zone
                          {sort?.includes('location|asc') ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                        </Typography>
                      </CustomTableCell>
                    )}

                    {isStage && !isCustomMetrics && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                            paddingLeft: '5px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => handleSortRequest('weight')}
                        >
                          Weight
                          {sort?.includes('weight|asc') ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                        </Typography>
                      </CustomTableCell>
                    )}

                    {isStage &&
                      isCustomMetrics &&
                      matrics &&
                      matrics?.map((matric) => (
                        <TableCell key={matric?.id}>
                          <Typography className={classes.tablehead}>{`${matric?.title} (${matric?.unit})`}</Typography>
                        </TableCell>
                      ))}

                    {isStage && (
                      <CustomTableCell>
                        <Typography
                          className={classes.tablehead}
                          sx={{
                            whiteSpace: 'pre',
                          }}
                        >
                          Points
                        </Typography>
                      </CustomTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDocuments?.map((item: any, index) => {
                    const isDocumentsSelected = selectedLiveStandings?.includes(item.id);

                    return (
                      <TableRow
                        hover
                        key={item?.id}
                        selected={isDocumentsSelected}
                        className="entry-row"
                        sx={{
                          border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                          background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                        }}
                      >
                        {/* <CustomTableCell padding="checkbox">
                          <Checkbox
                            checked={isDocumentsSelected}
                            sx={{ color: "#D3D3D3" }}
                            onChange={(event) =>
                              handleSelectOneDocument(event, item?.id)
                            }
                            value={isDocumentsSelected}
                          />
                        </CustomTableCell> */}
                        <CustomTableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#193660',
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={item?.entry?.team?.image || item?.team?.country}
                              sx={{
                                height: 24,
                                width: 24,
                                marginRight: 0.5,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              {item?.entry?.team?.title || item?.team?.title}
                            </Typography>
                          </Box>
                        </CustomTableCell>
                        {item?.entry && (
                          <CustomTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box className={classes.athlete}>
                                <Avatar src={item?.entry?.users[0]?.user?.avatar} className={classes.athleteAvatar} />
                                {/* <Avatar src={item?.entry?.registrationRequest?.users[0]?.user?.profileImage} className={classes.athleteAvatar} /> */}
                              </Box>
                              <Box className={classes.athlete} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                  // variant="body1"
                                  className={classes.name}
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    window.open(`/athlete/${item?.entry?.users[0]?.user?.id}/${item?.entry?.users[0]?.user?.name}-${item?.entry?.user[0]?.user?.surname}?tab=overview`, '_blank')
                                  }
                                >
                                  {item?.entry?.users[0]?.user?.name + ' ' + item?.entry?.users[0]?.user?.surname}
                                  <GetFlag
                                    country={item?.entry?.users[0]?.user?.nation}
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      marginBottom: '-4px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                </Typography>
                              </Box>
                            </Box>
                          </CustomTableCell>
                        )}

                        {stages?.map((stage) => {
                          // compare stage id with item stage id
                          const comparedStage = item?.stages?.find((s) => s?.id === stage?.id);
                          return (
                            stage?.isPublish && (
                              <Fragment key={stage?.id}>
                                <CustomTableCell>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: '#193660',
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 500,
                                    }}
                                  >
                                    {comparedStage?.stageweight}
                                  </Typography>
                                </CustomTableCell>
                                <CustomTableCell>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: '#193660',
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 500,
                                    }}
                                  >
                                    {comparedStage?.stagePoints}
                                  </Typography>
                                </CustomTableCell>
                              </Fragment>
                            )
                          );
                        })}

                        {!isStage && (
                          <CustomTableCell>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              {item?.stages?.reduce((sum, stage) => {
                                const isPublishedStage = stages?.find((stag) => stag.id === stage.id);
                                if (isPublishedStage.isPublish) {
                                  return sum + (stage.stageweight || 0);
                                } else {
                                  return sum;
                                }
                              }, 0)}
                            </Typography>
                          </CustomTableCell>
                        )}

                        {stages?.length > 0 && (
                          <CustomTableCell>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              {item?.stages?.reduce((sum, stage) => {
                                const isPublishedStage = stages?.find((stag) => stag.id === stage.id);

                                if (isPublishedStage.isPublish) {
                                  return sum + (stage.stagePoints || 0);
                                } else {
                                  return sum;
                                }
                              }, 0)}
                            </Typography>
                          </CustomTableCell>
                        )}

                        {isStage && !isCustomMetrics && (
                          <CustomTableCell>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              <Box
                                component="img"
                                src="/images/updated/location.svg"
                                alt="location"
                                sx={{
                                  width: '15px',
                                  height: '15px',
                                  marginBottom: '-3px',
                                  marginRight: '4px',
                                }}
                              />
                              {item?.location}
                            </Typography>
                          </CustomTableCell>
                        )}

                        {isStage && !isCustomMetrics && (
                          <CustomTableCell>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              <Box
                                component="img"
                                src="/images/updated/weight.svg"
                                alt="location"
                                sx={{
                                  width: '15px',
                                  height: '15px',
                                  marginBottom: '-3px',
                                  marginRight: '4px',
                                }}
                              />
                              {item?.weight}
                            </Typography>
                          </CustomTableCell>
                        )}

                        {isStage &&
                          isCustomMetrics &&
                          matrics &&
                          matrics?.map((matric) => {
                            const findMetric = item?.matrics?.find((m) => m?.sportFishingMatric?.id === matric?.id);
                            return (
                              <TableCell key={matric?.id}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <Box>{findMetric?.points || 0}</Box>

                                  <IconButton size="small" sx={{ color: '#193660' }} onClick={() => handleOpenListModal(item, findMetric)}>
                                    <List />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          })}

                        {isStage && (
                          <CustomTableCell>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#193660',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                              }}
                            >
                              {item?.points}
                            </Typography>
                          </CustomTableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Scrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: '10px',
            }}
          >
            <Box
              sx={{
                marginLeft: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                '@media (max-width: 525px)': {
                  mt: '10px',
                },
              }}
            >
              {/* <Box
                sx={{
                  mr: '8px',
                  '@media (max-width: 525px)': {
                    mt: '8px',
                  },
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  startIcon={
                    <ReactSVG
                      src="/images/download-list.svg"
                      style={{
                        marginTop: '4px',
                        paddingLeft: '5px',
                      }}
                    />
                  }
                  sx={{ height: '36px', textTransform: 'uppercase', mt: -0.3 }}
                  variant="contained"
                  onClick={downloadExcel}
                >
                  Download List
                </Button>
              </Box> */}
            </Box>
            <TablePagination
              sx={{
                '.MuiToolbar-gutters': { paddingRight: '0px !important', paddingLeft: '0px !important' },
              }}
              component="div"
              count={filteredDocuments?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Card>
      )}
      <MetricListModal openModal={listModal} setOpenModal={setListModal} metric={selectedMetric?.metric} />
    </React.Fragment>
  );
};

RankingsListTable.propTypes = {
  listAndResults: PropTypes.array.isRequired,
};

export default RankingsListTable;
