import React, { Dispatch, FC, SetStateAction } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Divider, Modal, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface TermsConditionsModalProps {
  termsModalOpen: boolean;
  setTermsModalOpen: Dispatch<SetStateAction<boolean>>;
  terms?: string;
}
const TermsConditionsModal: FC<TermsConditionsModalProps> = ({ termsModalOpen, setTermsModalOpen, terms }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    setTermsModalOpen(false);
  };
  return (
    <React.Fragment>
      <Modal
        open={termsModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '512px',
            borderRadius: '16px',
            border: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 100,
            outline: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: '100%',
              height: 379,
            }}
          >
            <Box
              sx={{
                padding: '20px 20px 0px 20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="h2">
                {t('Terms And Conditions')}
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>
            <Box sx={{ padding: '20px 20px 0px 20px' }}>
              <Divider />
            </Box>
            <Box
              sx={{
                padding: '0px 20px',
                maxHeight: 'max-content',
                height: '80%',
                background: '#FFF',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '7px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#aaa',
                  borderRadius: '6px',
                  pl: 3,
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#a2a2a2',
                },
              }}
            >
              <Typography sx={{ fontFamily: 'Inter' }}>
                <Box dangerouslySetInnerHTML={{ __html: terms }} />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default TermsConditionsModal;
