import { gql } from "@apollo/client";
const ALLATHLETES = gql`
  query allAthletes($page: Int!, $limit: Int!, $filter: ListUsersInput) {
    users(page: $page, limit: $limit, filter: $filter) {
      users {
        id
        name
        email
        nation
        gender
        role
        surname
      }
    }
  }
`;
export default ALLATHLETES;
