import { gql } from '@apollo/client';
const LEAGUES_FOR_RANKINGS = gql`
  query leagueForRankings($platform: Int) {
    leagueForRankings(platform: $platform) {
      title
      abbreviation
      id
      logo
    }
  }
`;

export default LEAGUES_FOR_RANKINGS;
