import { gql } from "@apollo/client";
const ALLTEAMMEMBERS = gql`
  query teamMembers($filter: ListTeamMemberInput) {
    findAllTeamMembers(filter: $filter) {
      id
      team {
        id
        title
      }
      member {
        id
        email
        gender
        surname
        name
        role
        avatar
        dob
        country
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export default ALLTEAMMEMBERS;
