import { gql } from "@apollo/client";
const CAPTAINSTEAM = gql`
query captainsTeam($id:Int! ) {
    captainTeams(
      id:$id
    ) {
      id
      title
      
      members{
          member{
              id
              email
          }
          team{
              title
          }
          role
      }
    }
  }
  `;
export default CAPTAINSTEAM;