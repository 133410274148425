import { gql } from '@apollo/client';

const GET_ALL_GALLERIES = gql`
  query findAllAlbum($filter: ListAlbumInput, $platform: Int, $page: Int, $limit: Int, $tournament: Int, $league: Int) {
    findAllAlbum(filter: $filter, platform: $platform, page: $page, limit: $limit, tournament: $tournament, league: $league) {
      albums {
        id
        title
        thumbnail
        isSpecificTournament
        albumCustomEvent
        albumTags
        date
        createdAt
        media {
          id
          photoLink
          photoCaption
        }
        league {
          id
          title
        }
        tournament {
          id
          title
        }
      }
      total
    }
  }
`;

export default GET_ALL_GALLERIES;
