import { Box, Container, useMediaQuery } from '@material-ui/core';
import React, { ComponentProps, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import chroma from 'chroma-js';
import { Theme } from '@material-ui/core/styles';
import pattern from '../../assets/images/pattern.svg';
import CustomPaperCard from './CustomPaperCard';
import CustomTabs from './CustomTabs';

type Props = {
  tabsConfig?: ComponentProps<typeof CustomTabs>;
  children: ReactNode;
  color: string;
  banner?: string;
};

const CustomHeader = ({ children, tabsConfig, color, banner }: Props) => {
  const [isSticky, setIsSticky] = useState(false);
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const getRect = useCallback(() => {
    const navbarRect = document.getElementById('platform-navbar')?.getBoundingClientRect();
    if (tabsRef.current && navbarRect) {
      const rect = tabsRef.current.getBoundingClientRect();
      setIsSticky(rect.y <= navbarRect.y + navbarRect.height);
    }
  }, [isCompact]);

  useEffect(() => {
    const main = document.getElementById('main');
    if (main) {
      main.addEventListener('scroll', getRect);
      return () => main.removeEventListener('scroll', getRect);
    }
  }, [getRect]);

  return (
    <>
      <Container maxWidth={false} sx={{ maxWidth: '1920px', marginTop: '24px' }}>
        <CustomPaperCard sx={{ borderRadius: '8px', overflow: 'hidden', background: '#1D1D1D' }}>
          {banner ? (
            <Box
              component="img"
              src={banner}
              sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                objectFit: 'cover',
                right: 0,
                top: 0,
                opacity: 0.04,
              }}
            />
          ) : (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                WebkitMask: `url(${pattern}) no-repeat right`,
                mask: `url(${pattern}) no-repeat right`,
                maskSize: `auto 100%`,
                backgroundColor: color,
                right: 0,
                top: 0,
                opacity: 0.08,
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              padding: { md: '22px', xs: '12px' },
              overflow: 'hidden',
              background: `linear-gradient(to right, ${chroma(color).alpha(0.2).hex()}, transparent 55%)`,
              color: '#FBFEFF',
              position: 'relative',
            }}
          >
            {children}
          </Box>
        </CustomPaperCard>
      </Container>
      {tabsConfig && (
        <CustomTabs
          shareColor={color}
          ref={tabsRef}
          outerSx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            '&:before': {
              content: '""',
              height: '100%',
              width: '100%',
              position: 'absolute',
              background: isSticky ? 'white' : 'transparent',
              borderBottom: isSticky ? '1px solid #EDF1F6' : '1px solid transparent',
              left: 0,
              top: 0,
              transition: 'all 0.2s',
            },
          }}
          sx={{
            width: '100%',
            marginTop: '20px',
          }}
          {...tabsConfig}
        />
      )}
    </>
  );
};

export default CustomHeader;
