import { gql } from '@apollo/client';

const LEAGUE_CATEGORIES = gql`
  query leagueCategories($filter: ListLeagueCategoryInput!) {
    leagueCategories(filter: $filter) {
      id
      category {
        id
        name
        type
        gender
        uniqueCode
        maxTournaments
        tournaments {
          id
        }
        activeFrom
        activeTo
        sort
        ageLimit
        ageLimitType
        selectStage
        countries
        timePeriod
        countAllTournament
        tournamentCatgories {
          id
          tournamentCategory {
            name
            type
            gender
            order
            discipline {
              id
            }
            enableMetrics
            team
            isAllowed
            moveToSingles
            categoryEntries
            enableLicense
            status
            ageLimit
            ageType
            id
          }
          rankingCategory {
            name
          }
        }
        isDisplayClub
        isDisplayCity
        isDisplayBirthYear
      }
    }
  }
`;

export default LEAGUE_CATEGORIES;
