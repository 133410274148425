import { Fragment, useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, makeStyles, TextField } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import gtm from 'src/lib/gtm';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_RANKINGS, GET_TOURNAMENT_DETAILS } from 'src/graphql/queries';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Loading from 'src/components/Loading';
import Points from './Points';
import NoDataFound from '../../NoDataFound';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'src/utils/debounce';
import { useTranslation } from 'react-i18next';
import { useTournament } from '../TournamentPage';

const useStyles = makeStyles((theme: any) => ({
  topBarButton: {
    height: '42px',
    mt: -0.3,
    backgroundColor: '#FFFF',
    color: '#425466',
    // border: `1px solid ${theme.custom.borderColor}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  dropDown: {
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid #EDF1F6',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '42px',
    width: '250px',
    backgroundColor: '#FFFF',
  },
  topBarDropdown: {
    // backgroundColor: "#FFFF",
    // height: "42px",
    color: '#425466',
    '& .MuiSelect-select': {
      padding: '5px 14px 5px 5px',
    },
    '& .MuiNativeSelect-select': {
      padding: '0 14px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  topBarSearch: {
    backgroundColor: '#FFFF',
    '& .MuiInputBase-input': {
      paddingLeft: '8px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-root': {
      height: '42px',
      borderRadius: '6px',
      border: '1px solid #EFEFF0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  container: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 9,
    width: '100%',
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  select: {
    display: 'flex',
    width: '248px',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectedText: {
    paddingLeft: '10px',
    color: 'var(--bright-blue, #182C47)',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineheight: 'normal',
  },
  text: {
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  downloadBtn: {
    display: 'flex',
    padding: '10px 24px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #EFEFEF',
    fontSize: '14px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textfield: {
    // width: "100%",
    height: '40px',
    flexShrink: 0,
    borderRadius: '8px',
    // marginTop: "20px",
    border: 0,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  formControl: {
    height: '40px',
    border: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        borderWidth: '0px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '0px',
      },
    },
  },
  active: {
    color: '#FF5733',
    border: '1px solid #FF5733',
    '&:hover': {
      background: 'transparent',
      color: '#FF5733',
    },
  },
  inActive: {
    color: '#1B3861',
    border: '1px solid #EFEFEF',
    '&:hover': {
      background: 'transparent',
      color: '#1B3861',
    },
  },
  button: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    display: 'flex',
    padding: '10px 24px',
    gap: '10px',
    borderRadius: '8px',
    alignItems: 'center',
    background: '#fff',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  subTitle: {
    marginTop: '2px',
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
  },
}));

const Rankings = () => {
  const { id, tournament } = useTournament();
  const { t } = useTranslation();
  const [filtersParams, setFiltersParams] = useSearchParams();
  const [getTournament] = useLazyQuery(GET_TOURNAMENT_DETAILS);
  const [tournamentData, setTournamentData] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  // const [segment, setSegment] = useState<any>(null);
  const [category, setCategory] = useState<any>(parseInt(filtersParams.get('category')) || null);
  const { settings } = useSettings();
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    // setSelectedValue(event.target.value);
  };
  //   const { displayTournamentResults, isLoaded } = useTournamentPreferences();

  //   useEffect(() => {
  //     isLoaded && !displayTournamentResults && navigate('/dashboard');
  //   }, [isLoaded]);
  const classes = useStyles();

  const { loading, data: rankingsTableData } = useQuery(GET_RANKINGS, {
    variables: {
      page: page + 1,
      limit: limit,
      filter: {
        tournament: id,
        // ...(segment ? { segment: segment } : {}),
        ...(category ? { tournamentCategory: category } : {}),
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getTournament({
      variables: {
        id: id,
      },
      onCompleted: (data) => {
        setTournamentData(data?.tournamentDetailById);
      },
    });
  }, [getTournament, id]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };
  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(category ? { category: category } : {}),
      };

      // Remove parameters with empty values
      if (!category) {
        delete newParams.category;
      }

      setFiltersParams(newParams);
    },
    [category],
    500
  );
  if (loading) {
    return <Loading height="55vh" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title> Rankings Data</title>
      </Helmet>
      <Box>
        {/* <Container
          maxWidth={false}
          sx={{ padding:0 }}
        > */}
        <Box
          sx={{
            justifyContent: 'space-between',
          }}
          className={classes.container}
        >
          <Box>
            <Typography variant="heading17" className={classes.title}>
              {t('Tournament Results')}
            </Typography>
            {/* {category==null &&<Typography
              className={classes.subTitle}
              sx={{
                width: "max-content",
                borderBottom: "2px solid #edeaea",
                paddingBottom: "5px",
              }}
            >
              {rankingsTableData?.tournamentRankings[0]?.tournamentCategory?.category?.name}
            </Typography>} */}
          </Box>
          <Box sx={{ width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' } }} className={classes.buttonContainer}>
            <TextField
              fullWidth
              name="role"
              // label="Select a category"
              onChange={(e) => setCategory(e.target.value === 'null' ? null : parseInt(e.target.value))}
              select
              SelectProps={{ native: true }}
              value={category}
              sx={{
                width: { lg: '253px', md: '253px', sm: '253px', xs: '75%' },
                height: '20px',
                border: 0,
                background: '#ffff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '0px',
                  },
                  '&:hover fieldset': {
                    borderWidth: '0px',
                    transform: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '0px',
                  },
                },
              }}
              className={classes.textfield}
              // sx={{ mt: 3 }}
            >
              {/* <option value="">Select a category</option> */}
              {tournament?.tournamentCategory?.length ? (
                <Fragment>
                  <option value="null">All Categories</option>
                  {tournament?.tournamentCategory?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category.name}
                    </option>
                  ))}
                </Fragment>
              ) : (
                <option disabled>No category found</option>
              )}
            </TextField>
          </Box>
        </Box>

        <Box sx={{ position: 'relative', mb: 3 }}>
          {rankingsTableData?.tournamentRankings?.tournamentRankings?.length > 0 ? (
            rankingsTableData?.tournamentRankings?.tournamentRankings?.map((item: any, index) => (
              <Box sx={{ mt: index !== 0 && 3 }} key={item.id}>
                <Points
                  leagues={item?.rankings}
                  title={item?.name}
                  category={item?.tournamentCategory?.category?.name}
                  tableId={item?.id}
                  categoryId={item?.tournamentCategory?.id}
                  selectedCategory={category}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  count={rankingsTableData?.tournamentRankings?.total}
                />
              </Box>
            ))
          ) : (
            <NoDataFound text="There is no available Tournament results" sx={{ margin: '150px 0' }} />
          )}
        </Box>
        {/* </Container> */}
      </Box>
    </React.Fragment>
  );
};

export default Rankings;
