import { useState, useEffect, Fragment, ChangeEvent } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { ALL_TOURNAMENT_CATEGORIES, PARTICIPANTS } from 'src/graphql/queries';
import ParticipantsListView from './listView';
import Loading from 'src/components/Loading';
import NoDataFound from '../../NoDataFound';
import { Helmet } from 'react-helmet';
import gtm from 'src/lib/gtm';
import ParticipantsResponsive from './ParticipentsResponsive';
import useDebounce from 'src/utils/debounce';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomSection from '../../../layout/CustomSection';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import CustomFormInput from '../../../CustomComponents/CustomFormInput';
import { SearchOutlined } from '@material-ui/icons';
import CustomPaperCard from 'src/components/CustomComponents/CustomPaperCard';
import { useTournament } from '../TournamentPage';

const Participants = () => {
  const { id, tournament } = useTournament();
  const { t } = useTranslation();
  const [filtersParams, setFiltersParams] = useSearchParams();
  const [query, setQuery] = useState<string>(filtersParams.get('name') || '');
  const [categories, setCategories] = useState(null);
  const [selectedValue, setSelectedValue] = useState(filtersParams.get('category') || null);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { loading: tourLoading, data: allTournamentCategories } = useQuery(ALL_TOURNAMENT_CATEGORIES, {
    skip:!id,
    variables: {
      filter: {
        tournament: id,
        registrationStatus: 'confirm',
      },
    },
    onCompleted: (data) => {
      const categories = data?.allTournamentCategories?.filter((item) => item?.count > 0);
      setCategories(data.allTournamentCategories?.filter((item) => item?.count > 0));
      setSelectedValue((prev) => categories?.find((item) => item?.category?.id === Number(prev))?.category?.id ?? categories[0]?.category?.id);
    },
    fetchPolicy: 'network-only',
  });
  const { loading, error, data } = useQuery(PARTICIPANTS, {
    skip: !selectedValue,
    variables: {
      filter: {
        tournament: id,
        categoryId: selectedValue,
        status: 'confirm',
      },
      // ...(debouncedSearchValue && { keyword: debouncedSearchValue }),
    },
  });

  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(selectedValue ? { category: selectedValue } : {}),
        ...(query ? { name: query } : {}),
      };

      // Remove parameters with empty values
      if (!selectedValue) {
        delete newParams.category;
      }
      if (!query) {
        delete newParams.name;
      }

      setFiltersParams(newParams);
    },
    [selectedValue, query],
    100
  );
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  // if (loading) {
  //   return <Loading height="50vh" />;
  // }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const applyFilters = (entriesListData: any, query: string): any =>
    entriesListData?.filter((entries) => {
      let matches = true;

      if (query) {
        const searchWords = query.toLowerCase().split(' ');
        const properties = ['name', 'surname'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (searchWords.every((word) => entries?.users.some((user) => user.user[property]?.toLowerCase().includes(word)))) {
            containsQuery = true;
          }
        });
        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    });

  const filteredEntries = applyFilters(data?.tournamentRegistrations, query);
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };
  const filters = (
    <Box sx={{ display: 'flex', gap: '8px', maxWidth: '100%', width: '100%', justifyContent: 'end' }}>
      <CustomFormInput
        placeholder={t('Search')}
        name="Search"
        value={query}
        onChange={handleQueryChange}
        size="small"
        InputProps={{
          endAdornment: <SearchOutlined sx={{ fontSize: '20px' }} />,
        }}
      />
      {categories?.length > 0 && (
        <CustomSelect
          placeholder={t('Select category')}
          options={categories?.map((item) => ({ title: item?.category?.category?.name, value: item?.category?.id }))}
          onChange={(value) => setSelectedValue(value)}
          selectedValue={selectedValue}
          buttonProps={{ sx: { flex: { xs: 1, sm: 'none' } } }}
        />
      )}
    </Box>
  );
  return (
    <Fragment>
      <Helmet>
        <title>Participants</title>
      </Helmet>
      <CustomSection title={t('Participants')} titleRightSideElement={!isMobile ? filters : undefined} underTitleElement={isMobile ? filters : undefined}>
        <CustomPaperCard sx={{ padding: '0px 16px 16px 16px', minHeight: '250px', width: '100%' }}>
          {loading || tourLoading ? (
            <Loading height="50vh" />
          ) : filteredEntries?.length > 0 ? (
            smDown ? (
              <ParticipantsResponsive participants={filteredEntries} selectedValue={selectedValue} categories={categories} tournament={tournament} />
            ) : (
              <ParticipantsListView participants={filteredEntries} selectedValue={selectedValue} categories={categories} tournament={tournament} />
            )
          ) : (
            <NoDataFound text={t('No participants found')} sx={{ height: '50vh' }} />
          )}
        </CustomPaperCard>
      </CustomSection>
    </Fragment>
  );
};

export default Participants;

export const getColorByRating = (value) => {
  if (value >= 5) {
    return '#00A100';
  } else if (value >= 4) {
    return '#1F6BFF';
  } else if (value >= 3) {
    return '#FF5733';
  } else {
    return '#8C2135';
  }
};

export const DUPRBox = ({ rating, color }: { rating: string; color: string }) => (
  <Box
    sx={{
      backgroundColor: color,
      color: '#ffff',
      cursor: 'pointer',
      fontSize: '10px',
      fontWeight: 700,
      fontFamily: 'Inter',
      padding: '4px 6px',
      lineHeight: '100%',
      borderRadius: '4px',
      textAlign: 'center',
      width: 'fit-content',
      display: 'flex',
      gap: '2px',
      alignItems: 'center',
    }}
  >
    <Box component="img" height="8px" sx={{ pointerEvents: 'none' }} src="https://i.imgur.com/XorD1mC.png" />
    {rating}
  </Box>
);
