import { createContext, SetStateAction, useEffect, useReducer } from 'react';
import type { Dispatch, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { Platform, User } from '../types/user';
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/client';
import { CHECKMANAGER, GET_PLATFORM, ME } from 'src/graphql/queries';
import { getPlatformId } from 'src/constants';
import { boolean } from 'yup';
import useLocalStorageState from '../utils/useLocalStorageState';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  platformData: Platform | null;
  platformLoading: boolean;
  isDrawerOpen: boolean;
  isManager: boolean;
  lang: any;
  selectedSports: string[];
  loginModalOpen: boolean;
  regSuccessModalOpen: boolean;
  signupModalOpen: boolean;
  forgetModalOpen: boolean;
  resetModalOpen: boolean;
  welcomeModalOpen: boolean;
  recoverModalOpen: boolean;
  isServerCrashModalOpen: boolean;
  isTournamentRegModalOpen: boolean;
  tournamentId: number | null; 

}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (user: any) => Promise<void>;
  logout: () => Promise<void>;
  register: (user: any) => Promise<void>;
  language: (lang: any) => Promise<void>;
  setSelectedSports: (sportsIds: string[]) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
  setLoginModalOpen: (open: boolean) => void;
  setSignupModalOpen: (open: boolean) => void;
  setForgetModalOpen: (open: boolean) => void;
  setResetModalOpen: (open: boolean) => void;
  setWelcomeModalOpen: (open: boolean) => void;
  setRecoverModalOpen: (open: boolean) => void;
  setServerCrashModalOpen: (open: boolean) => void;
  setTournamentRegistrationModalOpen: (open: boolean) => void;
  setRegSuccessModalOpen: (open: boolean) => void;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  dispatch: Dispatch<Action>;
  setTournamentId: (tournamentId: number | null) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};
type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};
type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};
type LanguageAction = {
  type: 'LANGUAGE';
  payload: {
    lang: any;
  };
};

type OpenDrawerAction = {
  type: 'OPEN_DRAWER';
};

type CloseDrawerAction = {
  type: 'CLOSE_DRAWER';
};
type SetManagerAction = {
  type: 'SET_MANAGER';
  payload: {
    isManager: boolean;
  };
};
type SetPlatformAction = {
  type: 'SET_PLATFORM';
  payload: {
    platformData: Platform;
  };
};
type SetSelectedSportsAction = {
  type: 'SET_SELECTED_SPORTS';
  payload: {
    sportsIds: string[];
  };
};

type SetLoginModalOpenAction = {
  type: 'SET_LOGIN_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetRegSuccessModalOpenAction = {
  type: 'SET_REG_SUCCESS_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetSignupModalOpenAction = {
  type: 'SET_SIGNUP_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};

type SetForgetModalOpenAction = {
  type: 'SET_FORGET_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetResetModalOpenAction = {
  type: 'SET_RESET_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetWelcomeModalOpenAction = {
  type: 'SET_WELCOME_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetRecoverModalOpenAction = {
  type: 'SET_RECOVER_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type setServerCrashModalOpenAction = {
  type: 'SET_CRASH_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type setTournamentRegistrationModalOpenAction = {
  type: 'SET_TOURNAMENT_REGISTRATION_MODAL_OPEN';
  payload: {
    open: boolean;
  };
};
type SetPlatformLoadingAction = {
  type: 'SET_PLATFORM_LOADING';
  payload: {
    platformLoading: boolean;
  };
};
type SetTournamentIdAction = {
  type: 'SET_TOURNAMENT_ID';
  payload: {
    tournamentId: number | null;  // Allow null in case we want to clear it
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | LanguageAction
  | OpenDrawerAction
  | CloseDrawerAction
  | SetManagerAction
  | SetPlatformAction
  | SetSelectedSportsAction
  | SetLoginModalOpenAction
  | SetRegSuccessModalOpenAction
  | SetSignupModalOpenAction
  | SetForgetModalOpenAction
  | SetResetModalOpenAction
  | SetWelcomeModalOpenAction
  | SetRecoverModalOpenAction
  | setServerCrashModalOpenAction
  | UpdateUserAction
  | SetPlatformLoadingAction
  | setTournamentRegistrationModalOpenAction
  | SetTournamentIdAction;

const initialState: State = {
  isAuthenticated: Cookies.get('isAuthenticated') ? JSON.parse(Cookies.get('isAuthenticated') as any) : false,
  user: Cookies.get('user') ? JSON.parse(Cookies.get('user') as any) : null,
  platformData: Cookies.get('platform') ? JSON.parse(Cookies.get('platform') as any) : null,
  platformLoading: false,
  lang: Cookies.get('lang') ? (Cookies.get('lang') as any) : null,
  isInitialized: false,
  isDrawerOpen: false,
  isManager: false,
  loginModalOpen: false,
  regSuccessModalOpen: false,
  signupModalOpen: false,
  forgetModalOpen: false,
  resetModalOpen: false,
  welcomeModalOpen: false,
  recoverModalOpen: false,
  isServerCrashModalOpen: false,
  isTournamentRegModalOpen: false,
  selectedSports: Cookies.get('selectedSports') ? JSON.parse(Cookies.get('selectedSports') as any) : [],
  tournamentId: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LANGUAGE: (state: any, action: any): State => {
    const { lang } = action.payload;

    return {
      ...state,
      lang,
    };
  },
  OPEN_DRAWER: (state: State): State => ({
    ...state,
    isDrawerOpen: true,
  }),
  CLOSE_DRAWER: (state: State): State => ({
    ...state,
    isDrawerOpen: false,
  }),
  SET_MANAGER: (state: State, action: SetManagerAction): State => {
    const { isManager } = action.payload;

    return {
      ...state,
      isManager,
    };
  },
  SET_PLATFORM: (state: State, action: SetPlatformAction): State => {
    const { platformData } = action.payload;

    return {
      ...state,
      platformData,
    };
  },
  SET_PLATFORM_LOADING: (state: State, action: SetPlatformLoadingAction): State => {
    const { platformLoading } = action.payload;

    return {
      ...state,
      platformLoading,
    };
  },
  SET_SELECTED_SPORTS: (state: State, action: SetSelectedSportsAction): State => {
    const { sportsIds } = action.payload;
    return {
      ...state,
      selectedSports: sportsIds,
    };
  },
  SET_LOGIN_MODAL_OPEN: (state: State, action: SetLoginModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      loginModalOpen: open,
    };
  },
  SET_REG_SUCCESS_MODAL_OPEN: (state: State, action: SetRegSuccessModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      regSuccessModalOpen: open, //dddddd
    };
  },
  SET_SIGNUP_MODAL_OPEN: (state: State, action: SetSignupModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      signupModalOpen: open,
    };
  },
  SET_FORGET_MODAL_OPEN: (state: State, action: SetForgetModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      forgetModalOpen: open,
    };
  },
  SET_RESET_MODAL_OPEN: (state: State, action: SetResetModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      resetModalOpen: open,
    };
  },
  SET_WELCOME_MODAL_OPEN: (state: State, action: SetWelcomeModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      welcomeModalOpen: open,
    };
  },
  SET_RECOVER_MODAL_OPEN: (state: State, action: SetRecoverModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      recoverModalOpen: open,
    };
  },
  SET_CRASH_MODAL_OPEN: (state: State, action: setServerCrashModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      isServerCrashModalOpen: open,
    };
  },
  SET_TOURNAMENT_REGISTRATION_MODAL_OPEN: (state: State, action: setTournamentRegistrationModalOpenAction): State => {
    const { open } = action.payload;
    return {
      ...state,
      isTournamentRegModalOpen: open,
    };
  },
  UPDATE_USER: (state: State, action: UpdateUserAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
  SET_TOURNAMENT_ID: (state: State, action: SetTournamentIdAction): State => {
    const { tournamentId } = action.payload;

    return {
      ...state,
      tournamentId,
    };
  },

};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  language: () => Promise.resolve(),
  openDrawer: () => {},
  closeDrawer: () => {},
  setSelectedSports: () => {},
  setLoginModalOpen: () => {},
  setRegSuccessModalOpen: () => {},
  setSignupModalOpen: () => {},
  setForgetModalOpen: () => {},
  setResetModalOpen: () => {},
  setWelcomeModalOpen: () => {},
  setRecoverModalOpen: () => {},
  setServerCrashModalOpen: () => {},
  setTournamentRegistrationModalOpen: () => {},
  sidebarOpen: localStorage.getItem('sidebarOpen') === 'true',
  setSidebarOpen: () => {},
  dispatch: () => {},
  setTournamentId: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useQuery(ME, {
    onCompleted: (data) => {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: data.getProfile,
        },
      });
    },
    onError: () => {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    },
  });

  const accessToken = Cookies.get('token') as string;

  useQuery(CHECKMANAGER, {
    skip: !accessToken,
    onCompleted: (data) => {
      dispatch({
        type: 'SET_MANAGER',
        payload: {
          isManager: data?.checkUserRole?.isManager,
        },
      });
    },
    onError: () => {
      dispatch({
        type: 'SET_MANAGER',
        payload: {
          isManager: false,
        },
      });
    },
    fetchPolicy: 'network-only',
  });
  const platformId = getPlatformId();
  const { loading: platformLoading } = useQuery(GET_PLATFORM, {
    // skip: !accessToken,
    variables: {
      id: platformId,
      isClient: true,
    },
    onCompleted: (data) => {
      dispatch({
        type: 'SET_PLATFORM',
        payload: {
          platformData: data?.platform,
        },
      });
      dispatch({
        type: 'SET_PLATFORM_LOADING',
        payload: {
          platformLoading: false,
        },
      });
    },
    onError: () => {
      dispatch({
        type: 'SET_PLATFORM',
        payload: {
          platformData: null,
        },
      });
      dispatch({
        type: 'SET_PLATFORM_LOADING',
        payload: {
          platformLoading: false,
        },
      });
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (platformLoading) {
      dispatch({
        type: 'SET_PLATFORM_LOADING',
        payload: {
          platformLoading: true,
        },
      });
    }
  }, [platformLoading]);
  useEffect(() => {
    if (!accessToken) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      dispatch({
        type: 'SET_MANAGER',
        payload: {
          isManager: false,
        },
      });
    }
  }, [accessToken]);

  const login = async (user: any): Promise<void> => {
    Cookies.set('isAuthenticated', JSON.stringify(true));
    Cookies.set('token', user.accessToken);
    Cookies.set('user', JSON.stringify(user));

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    Cookies.remove('user');
    Cookies.set('isAuthenticated', JSON.stringify(false));
    Cookies.remove('token');
    dispatch({ type: 'LOGOUT' });
    dispatch({
      type: 'SET_MANAGER',
      payload: {
        isManager: null,
      },
    });
  };

  const register = async (user: any): Promise<void> => {
    Cookies.set('isAuthenticated', JSON.stringify(true));
    Cookies.set('token', user.accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const language = async (lang: any): Promise<void> => {
    Cookies.set('lang', lang);

    dispatch({
      type: 'LANGUAGE',
      payload: {
        lang,
      },
    });
  };

  const openDrawer = (): void => {
    dispatch({ type: 'OPEN_DRAWER' });
  };

  const closeDrawer = (): void => {
    dispatch({ type: 'CLOSE_DRAWER' });
  };
  const setSelectedSports = (sportsIds: string[]) => {
    dispatch({
      type: 'SET_SELECTED_SPORTS',
      payload: {
        sportsIds,
      },
    });
  };
  const setLoginModalOpen = (open: boolean) => {
    dispatch({
      type: 'SET_LOGIN_MODAL_OPEN',
      payload: { open },
    });
  };
  const setRegSuccessModalOpen = (open: boolean) => {
    dispatch({
      type: 'SET_REG_SUCCESS_MODAL_OPEN',
      payload: { open },
    });
  };
  const setSignupModalOpen = (open: boolean) => {
    dispatch({
      type: 'SET_SIGNUP_MODAL_OPEN',
      payload: { open },
    });
  };

  const setForgetModalOpen = (open: boolean) => {
    dispatch({
      type: 'SET_FORGET_MODAL_OPEN',
      payload: { open },
    });
  };
  const setResetModalOpen = (open: boolean) => {
    dispatch({
      type: 'SET_RESET_MODAL_OPEN',
      payload: { open },
    });
  };
  const setWelcomeModalOpen = (open: boolean) => {
    //new
    dispatch({
      type: 'SET_WELCOME_MODAL_OPEN',
      payload: { open },
    });
  };
  const setRecoverModalOpen = (open: boolean) => {
    //new
    dispatch({
      type: 'SET_RECOVER_MODAL_OPEN',
      payload: { open },
    });
  };
  const setServerCrashModalOpen = (open: boolean) => {
    //new
    dispatch({
      type: 'SET_CRASH_MODAL_OPEN',
      payload: { open },
    });
  };
  const setTournamentRegistrationModalOpen = (open: boolean) => {
    //new
    dispatch({
      type: 'SET_TOURNAMENT_REGISTRATION_MODAL_OPEN',
      payload: { open },
    });
  };
  const setTournamentId = (tournamentId: number | null) => {
    dispatch({
      type: 'SET_TOURNAMENT_ID',
      payload: { tournamentId },
    });
  };
  
  useEffect(() => {
    // Update cookies whenever selectedSports change
    Cookies.set('selectedSports', JSON.stringify(state.selectedSports));
  }, [state.selectedSports]);

  const [sidebarOpen, setSidebarOpen] = useLocalStorageState({ key: 'sidebarOpen', defaultValue: true });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        language,
        openDrawer,
        closeDrawer,
        setSelectedSports,
        setLoginModalOpen,
        setRegSuccessModalOpen,
        setSignupModalOpen,
        setForgetModalOpen,
        setResetModalOpen,
        setWelcomeModalOpen,
        setRecoverModalOpen,
        setServerCrashModalOpen,
        setTournamentRegistrationModalOpen,
        dispatch,
        sidebarOpen,
        setSidebarOpen,
        setTournamentId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
