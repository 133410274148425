import { gql } from '@apollo/client';

const CREATE_SPORT_FISHING_ENTRIES_MATRIC = gql`
  mutation createSportFishingEntriesMatric($tournament: Int!, $sportFishingResult: Int, $points: Int, $sportFishingResultEntry: Int, $sportFishingMatric: Int, $categoryId: Int, $media: [Upload!]) {
    createSportFishingEntriesMatric(
      createSportFishingEntriesMatricInput: {
        tournament: $tournament
        sportFishingResult: $sportFishingResult
        points: $points
        sportFishingResultEntry: $sportFishingResultEntry
        sportFishingMatric: $sportFishingMatric
        categoryId: $categoryId
        media: $media
      }
    ) {
      points
      media
    }
  }
`;

export default CREATE_SPORT_FISHING_ENTRIES_MATRIC;
