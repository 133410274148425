import React from 'react';
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { PasswordResetAmplify } from 'src/components/authentication/password-reset';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '15px 20px 25px 20px',
    maxWidth: '461px',
    height: 'max-content',
    maxHeight: '100%',
    minHeight: '431px',
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 9999,
    border: 0,
    background: '#FFF',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    height: '100%',
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '100%',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type Props = {
  handleClose: () => void;
};

const ResetContent = ({ handleClose }: Props) => {
  const classes = useStyles();
  const { platform } = useAuth() as any;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: '100%',
        width: '100%',
      }}
    >
      <Box className={classes.loginContainer}>
        <Box className={classes.socialContainer}>
          <Box>
            <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
              Enter your new password
            </Typography>
            <Typography
              variant="font21"
              sx={{
                color: '#0A2540',
                lineHeight: '20px',
                opacity: '35%',
              }}
            >
              {t('Your password must be different to previous passwords')}
            </Typography>
            <Box sx={{ mt: 1.5 }}>
              <Divider />
            </Box>
          </Box>
        </Box>
        <Box className={classes.loginActionContainer}>
          <Box
            sx={{
              width: '100%',
              height: 'fit-content',
              maxHeight: '100%',
            }}
          >
            {platform === 'JWT' && <PasswordResetAmplify handleClose={handleClose} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetContent;
