import type { FC } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import type { DropzoneOptions } from "react-dropzone";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DuplicateIcon from "../icons/Duplicate";
import XIcon from "../icons/X";
import bytesToSize from "../utils/bytesToSize";
import { ReactSVG } from "react-svg";
interface FileDropzoneProps extends DropzoneOptions {
  files?: any[];
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}
const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    accept,
    disabled,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props;
  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
  });
  const allowedExtensions = ["pdf", "docx", "doc"];
  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: "center",
          border: 1,
          borderRadius: 1,
          borderColor: "#FAFAFA",
          textAlign: "center",
          // display: "flex",
          // flexWrap: "wrap",
          justifyContent: "center",
          outline: "none",
          pt: 4,
          pb:1,
          background:"#fff",
          ...(isDragActive && {
            backgroundColor: "action.active",
            opacity: 0.5,
          }),
          "&:hover": {
            backgroundColor: "action.hover",
            cursor: "pointer",
            opacity: 0.5,
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box
        // sx={{
        //   "& img": {
        //     width: 100,
        //   },
        // }}
        >
          <ReactSVG src="/images/upload-icon.svg" />
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Typography color="textPrimary" variant="h6">
            {`Select file${maxFiles && maxFiles === 1 ? "" : "s"}`}
          </Typography> */}
          <Box>
            <Typography color="var(--bright-blue-40, rgba(25, 54, 96, 0.40))" variant="body1">
              {`Drag and Drop some file${
                maxFiles && maxFiles === 1 ? "" : "s"
              }`}{" "}
              here, or click to Select file
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ mt: 2, width: "120px" }}
            // onClick={() => navigate("/general/under-construction")}
          >
            UPLOAD
          </Button>
        </Box>
      </Box>
      {files?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  "& + &": {
                    mt: 1,
                  },
                }}
              >
                {allowedExtensions?.includes(
                  file?.type?.split("/").pop().toLowerCase()
                ) ? (
                  <ListItemIcon>
                    <DuplicateIcon fontSize="small" />
                  </ListItemIcon>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    height="40px"
                    width="50px"
                    style={{
                      objectFit: "cover",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
                <ListItemText
                  primary={
                    file?.name?.split("-")[file?.name?.split("-")?.length - 1]
                  }
                  primaryTypographyProps={{
                    color: "textPrimary",
                    variant: "subtitle2",
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={() => onRemove && onRemove(file)}
                  >
                    <XIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};
FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
};
FileDropzone.defaultProps = {
  files: [],
};
export default FileDropzone;