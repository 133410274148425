import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },
  blinkIcon: {
    animationName: '$blink',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    fontSize: '10px',
  },
});

const LiveIcon = () => {
  const classes = useStyles();

  return (
    // <Box  sx={{width: '20px', height: '20px'}}>
    <SvgIcon className={classes.blinkIcon} viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="3" fill="#FF0000" />
    </SvgIcon>
  );
};

export default LiveIcon;
