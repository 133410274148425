import { Box, Typography } from '@material-ui/core';
import React from 'react';

const ScoreDisplay = ({ scores1, scores2, winner }) => {
  const renderScore = (score1, score2) => {
    const mainScore1Int = parseInt(score1?.mainScore, 10);
    const mainScore2Int = parseInt(score2?.mainScore, 10);

    return (
      <Box sx={{ paddingLeft: '1px', width: '15px', textAlign: 'center', position: 'relative' }}>
        {score1?.tieScore && (
          <sup>
            <Typography
              sx={{
                position: 'absolute',
                color: '#425466',
                fontFamily: 'Inter',
                fontSize: '7px',
                fontWeight: 500,
                lineHeight: 'normal',
                right: 1,
                top: -5,
              }}
            >
              {score1?.tieScore ?? ''}
            </Typography>
          </sup>
        )}
        <Typography
          sx={{
            borderLeft: '1px solid #EDF1F6',
            color: (theme) => (mainScore1Int > mainScore2Int ? theme.palette.primary.main : '#425466'),
            fontFamily: 'Inter',
            fontSize: '11px',
            fontWeight: 500,
            lineHeight: 'normal',
          }}
        >
          {score1?.mainScore}
        </Typography>
      </Box>
    );
  };

  return (
    <Box display="flex">
      {scores1?.map((score1, index) => (
        <Box key={index} display="flex">
          {renderScore(score1, scores2[index])}
        </Box>
      ))}
    </Box>
  );
};

export default ScoreDisplay;
