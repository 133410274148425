import { gql } from "@apollo/client";
const ATHLETES = gql`
  query athletes($page:Int! $limit:Int! $filter: ListAthleteInput) {
    athletes(page: $page, limit: $limit, filter: $filter) {
      id
      athlete {
        id
        name
        surname
        email
        phone
        nation
        gender
        city
        user_address
        user_join_club
        national_fisher_id
        user_coach {
          id
          user {
            id
          }
          coaches {
            id
            user {
              id
              email
            }
          }
        }
        role
        password
        dob
        avatar
      }
    }
  }
`;
export default ATHLETES;