import { useEffect, useRef, useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LiveChatWidget, useWidgetIsReady, useWidgetState } from '@livechat/widget-react';

const CustomLiveChat = () => {
  const [isMaximized, setIsMaximized] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const classes = useStyles();
  const boxRef = useRef<HTMLDivElement>(null);

  const state = useWidgetState();
  const isWidgetReady = useWidgetIsReady();

  const isOnline = state?.availability === 'online';

  const texts = [...(isOnline ? ['Live chat', 'We are online!', 'Contact us!'] : ['We are offline']), 'F.A.Q.', 'Create ticket', 'And more!'];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMaximized) {
        const chatWidgetContainer = document.getElementById('chat-widget-container');
        if (chatWidgetContainer && !chatWidgetContainer.contains(event.target as Node)) {
          setIsMaximized(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMaximized]);

  useEffect(() => {
    if (state) {
      setIsMaximized(state.visibility !== 'hidden');
    }
  }, [state]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      const timeout = setTimeout(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsFading(false);
      }, 100);

      return () => clearTimeout(timeout);
    }, 3000);

    return () => clearInterval(interval);
  }, [texts.length]);

  useEffect(() => {
    setTextIndex(0);
  }, [isHovered]);

  const shouldExpand = isHovered && !isMaximized;

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={boxRef}
      sx={{
        transition: 'all 0.3s',
        zIndex: 10,
        borderRadius: '1000px',
        position: 'absolute',
        right: '25px',
        bottom: isMaximized ? '-50px' : '15px',
        transform: isMaximized ? 'scale(0.5)' : 'scale(1)',
        opacity: isMaximized ? '0' : '1',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
      }}
    >
      <LiveChatWidget visibility={isMaximized ? 'maximized' : 'hidden'} license="18398904" />
      {isWidgetReady && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              transition: 'all 0.2s',
              backgroundColor: 'rgba(0, 0, 0, 1)',
              borderRadius: '1000px',
              opacity: shouldExpand ? 1 : 0,
              padding: shouldExpand ? 0 : '8px',
              width: shouldExpand ? '210px' : '40px',
              height: shouldExpand ? 'calc(100% + (6px * 2))' : '50%',
              right: shouldExpand ? '-6px' : '0',
            }}
          >
            {shouldExpand && (
              <p className={`${classes.textAnimation} ${isFading ? classes.fadeOut : classes.fadeIn}`} style={{ color: '#86909F', paddingInline: '24px' }}>
                {texts[textIndex]}
              </p>
            )}
          </Box>
          <ButtonBase
            onClick={() => {
              setIsMaximized(true);
              setIsHovered(false);
            }}
            onTouchStart={() => setIsHovered(true)}
            onTouchEnd={() => setIsHovered(false)}
            sx={{
              transition: 'all 1s',
              background: isOnline ? process.env.REACT_APP_PRIMARY_COLOR : '#555555',
              aspectRatio: '1/1',
              borderRadius: '1000px',
              padding: '13px',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#fff" className={isHovered ? classes.animation : ''}>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 3a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-4.724l-4.762 2.857a1 1 0 0 1 -1.508 -.743l-.006 -.114v-2h-1a4 4 0 0 1 -3.995 -3.8l-.005 -.2v-8a4 4 0 0 1 4 -4zm-4 9h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0 -2m2 -4h-8a1 1 0 1 0 0 2h8a1 1 0 0 0 0 -2" />
            </svg>
          </ButtonBase>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  animation: { animation: `$springRotate 1000ms` },
  textAnimation: {
    animation: `$fadeIn 1s`,
  },
  fadeIn: {
    animation: `$fadeIn 1s`,
  },
  fadeOut: {
    animation: `$fadeOut 0.5s`,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes springRotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '20%': {
      transform: 'rotate(30deg)',
    },
    '40%': {
      transform: 'rotate(-20deg)',
    },
    '60%': {
      transform: 'rotate(10deg)',
    },
    '80%': {
      transform: 'rotate(-5deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));

export default CustomLiveChat;
