import { Dispatch, FC, Fragment, SetStateAction, memo } from "react";
import {
  Box,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Scrollbar from "src/components/Scrollbar";
import { parseToJSON } from "src/utils/helperFunction";
import MediaPopup from "src/components/shared/MediaPopup";

const useStyles = makeStyles((theme) => ({
  attachmants: {position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",}
}));

interface ModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  metric: {
    id: number;
    points: number;
    media: string;
    sportFishingMatric: {
      id: number;
      unit: string;
      title: string;
    };
  };
}

const UpdateTeam: FC<ModalProps> = ({ openModal, setOpenModal, metric }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const attachments = parseToJSON(metric?.media);
  const classes = useStyles();
  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: "512px",
            borderRadius: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            zIndex: 10,
            height: "100%",
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: "100%",
              maxHeight: "90vh",
              background: "#FFF",
              p: 0.5,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "7px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#aaa",
                borderRadius: "6px",
                pl: 3,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#a2a2a2",
              },
            }}
          >
            <Scrollbar>
              <Box
                sx={{
                  padding: "20px 20px 0px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="textPrimary" variant="h2">
                  Metric Results
                </Typography>
                <CloseIcon
                  sx={{ color: "black", cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
              <Box sx={{ padding: "20px 20px 0px 20px" }}>
                <Divider />
              </Box>
              <Box sx={{ padding: "20px 20px 20px 20px" }}>
                {metric?.points ? (
                  <>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Results
                    </Typography>
                    <Box>
                      <ul>
                        <li>{`${metric?.points} [${metric?.sportFishingMatric?.title}]`}</li>
                      </ul>
                    </Box>
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    No results yet
                  </Typography>
                )}
                {attachments?.length > 0 && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "bold" }}
                    >
                      Uploaded attachments
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: "0px" }}>
                      {attachments?.map((file, index) => {
                        // Extract the file extension from the URL
                        const fileExtension = file
                          ?.split(".")
                          ?.pop()
                          ?.toLowerCase();

                        // Define arrays of image extensions
                        const imageExtensions = [
                          "jpg",
                          "jpeg",
                          "png",
                          "gif",
                          "bmp",
                          "tiff",
                          "tif",
                          "webp",
                          "svg",
                          "ico",
                          "psd",
                          "ai",
                          "eps",
                          "raw",
                          "indd",
                          "cdr",
                          "svgz",
                        ];
                        const isImage = imageExtensions.includes(fileExtension);

                        return (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                              sx={{
                                position: "relative",
                                paddingBottom: "56.25%",
                                height: 0,
                                overflow: "hidden",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                              }}
                            >
                              {isImage ? (
                                <MediaPopup type="image"  src={file} className={classes.attachmants} />
                              ) : (
                                <MediaPopup type="video"  src={file} className={classes.attachmants} />
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Box>
            </Scrollbar>
          </Paper>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default memo(UpdateTeam);
