import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React from 'react';

interface DeleteWarningProps {
  openDeleteModal: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  warningText: string;
  warningTitle?: string;
  confirmText?: string;
}

const DeleteWarning = ({ openDeleteModal, handleClose, handleDelete, warningText, warningTitle, confirmText }: DeleteWarningProps) => {
  return (
    <Dialog open={openDeleteModal} onClose={handleClose}>
      <DialogTitle sx={{ '& .MuiTypography-root': { display: 'flex', alignItems: 'center', gap: '10px' } }}>
        <Warning color="action" sx={{ mt: -0.2 }} />
        {warningTitle ? warningTitle : 'Confirm Deletion'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} sx={{ color: 'red' }}>
          {confirmText ? confirmText : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWarning;
