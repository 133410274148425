import { gql } from "@apollo/client";

const COACH_BY_ID = gql`
  query platformCoach($id: Int!) {
    platformCoach(id: $id) {
      id
      userRole
      user {
        id 
        email
        phone
        name
        surname
        id
        citizenship
        nation
        avatar
      }
      students {
        id
        user {
          id
          name
          surname
          email
          avatar
          citizenship
          role
        }
      }
      sport {
        id
        icon
      }
    }
  }
`;

export default COACH_BY_ID;
