import { gql } from "@apollo/client";

const CREATEPLAN = gql`
mutation creaUserPlan($userId:Int $planId:String ) {
  createUserPlan(
    createUserPlanInput: {
        user:$userId,
        plan:$planId
    })
   {
        id
        plan
    }
}
`;

export default CREATEPLAN;
