import { gql } from '@apollo/client';

const USER_LEAGUES = gql`
  query userAllLeagues($userId: Int, $page: Int, $limit: Int, $filter: ListLeagueInput) {
    userAllLeagues(page: $page, limit: $limit, userId: $userId, filter: $filter) {
      league {
        title
        id
        type
        status
        sports {
          id
          title
          icon
        }
        logo
        leagueCategory {
          id
        }
        tournaments {
          id
        }
        owner {
          federation {
            id
            organizationName
            organizationAbbreviation
            logo
          }
        }
      }
      total
    }
  }
`;

export default USER_LEAGUES;
