import { useEffect } from 'react'

export const useClickOutside = (ref: any, callback: any) => {
  useEffect(() => {
    const handler = (e: any) => {
      if (!ref?.current?.contains(e?.target)) {
        callback(e)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])
}