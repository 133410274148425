import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme: any) => ({
  dropDown: {
    display: "flex",
    borderRadius: "6px",
    border: "1px solid #EDF1F6",
    alignItems: "center",
    paddingLeft: "8px",
    backgroundColor: "#FFFF",
    "& .MuiOutlinedInput-root": {
      border: "none !important",
      paddingRight: "32px !important",
    },
  },
  topBarDropdown: {
    height: "42px",
    flexDirection: "row",
    color: "#425466",
    "& .MuiSelect-select": {
      padding: "5px 14px 5px 5px",
    },
    "& .MuiNativeSelect-select": {
      padding: "0 14px",
      backgroundColor: "#FFFF",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

type CustomDropDownProps = TextFieldProps & {
  labelText?: string;
};

const CustomDropDown: FC<React.PropsWithChildren<CustomDropDownProps>> = ({
  children,
  labelText,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.dropDown}>
      {labelText && (
        <Typography
          sx={{ color: "#19366066", display: { md: "block", xs: "none" } }}
        >
          {labelText}
        </Typography>
      )}
      <TextField className={classes.topBarDropdown} {...props}>
        {children}
      </TextField>
    </Box>
  );
};

export default CustomDropDown;
