import { useState, useEffect, Fragment } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GETLIVESTANDINGS } from 'src/graphql/queries';
import { useQuery } from '@apollo/client';
import LiveStandingsListTable from 'src/components/widgets/tables/liveStandingsTable/LiveStandingsTable';
import Loading from 'src/components/Loading';
import NoDataFound from '../../NoDataFound';
import gtm from 'src/lib/gtm';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'src/utils/debounce';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useTournament } from '../TournamentPage';

const useStyles = makeStyles((theme) => ({
  LivestandingsContainer: {
    // minHeight: "550px",
    borderRadius: '12px',
    // border: "1px solid #EFEFEF",
    // background: "#FFF",
    // padding: "9px",
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  subTitle: {
    marginTop: '2px',
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  container: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%',
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
    marginBottom: '5px',
  },
  select: {
    display: 'flex',
    width: '248px',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectedText: {
    paddingLeft: '10px',
    color: 'var(--bright-blue, #182C47)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineheight: 'normal',
  },
  text: {
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  downloadBtn: {
    display: 'flex',
    padding: '10px 24px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #EFEFEF',
    fontSize: '12px',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  textfield: {
    background: '#fff',
    height: '40px',
    flexShrink: 0,
    borderRadius: '8px',
    // marginTop: "20px",
    border: 0,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  label: {
    color: '#1B3861',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
  },
}));

const LiveStandings = () => {
  const { id, tournament, setRefetch } = useTournament();
  const classes = useStyles();
  const { t } = useTranslation();
  const [filtersParams, setFiltersParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState<string>(filtersParams.get('category') || '');
  const [filteredSettings, setFilteredSettings] = useState([]);
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const {
    loading,
    data: lsTableData,
    refetch: refetchLiveStandingsTableData,
  } = useQuery(GETLIVESTANDINGS, {
    variables: {
      filter: {
        tournament: id,
        ...(selectedValue ? { tournamentCategory: parseInt(selectedValue) } : {}),
      },
    },
    pollInterval: 1000 * 30,
  });
  const {
    loading: lsLoading,
    data: lsData,
    refetch: refetchLsData,
  } = useQuery(GETLIVESTANDINGS, {
    variables: {
      filter: {
        tournament: id,
      },
    },
    pollInterval: 1000 * 30,
  });
  useEffect(() => {
    const filterCategories = () => {
      // Check if both groupsTableData and categories exist
      if (lsData?.liveStandings?.length && tournament?.tournamentCategory) {
        const drawCat = lsData?.liveStandings?.map((ls) => ls?.tournamentCategory?.id);

        const filteredCategorySettings = tournament?.tournamentCategory?.filter((setting) => drawCat?.includes(setting?.id));

        return filteredCategorySettings;
      }
      // If data is not available yet, return null or an empty array
      return null; // or return []
    };

    const filteredData = filterCategories();

    // Update the state only if filteredData is not null or an empty array
    if (filteredData !== null && filteredData.length > 0) {
      setFilteredSettings(filteredData);
    }
  }, [lsData, tournament]);
  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(selectedValue ? { category: selectedValue } : {}),
      };

      // Remove parameters with empty values
      if (!selectedValue) {
        delete newParams.category;
      }

      setFiltersParams(newParams);
    },
    [selectedValue],
    500
  );

  useEffect(() => {
    const refetchData = () => {
      void refetchLiveStandingsTableData();
      void refetchLsData();
      toast.success('Live standings data has been successfully refreshed.');
    };

    setRefetch(refetchData);

    return () => setRefetch(undefined);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{tournament?.title} | Live Standings</title>
      </Helmet>
      <Grid container className={classes.LivestandingsContainer}>
        {/* <Container
        maxWidth={false}
        sx={{ padding: 0 }}
      > */}
        <Box
          sx={{
            justifyContent: 'space-between',
          }}
          className={classes.container}
        >
          <Box>
            <Typography variant="heading17" className={classes.title}>
              {t('Live Standings')}
            </Typography>
          </Box>
          <Box sx={{ width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' } }} className={classes.buttonContainer}>
            <TextField
              fullWidth
              name="role"
              placeholder="Select a category"
              onChange={handleSelectChange}
              select
              SelectProps={{ native: true }}
              value={selectedValue}
              sx={{
                width: { lg: '253px', md: '253px', sm: '253px', xs: '100%' },
                height: '43px',
                border: 0,

                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '0px',
                  },
                  '&:hover fieldset': {
                    borderWidth: '0px',
                    transform: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '0px',
                  },
                },
              }}
              className={classes.textfield}
              // sx={{ mt: 3 }}
            >
              <option value="">{t('All Categories')}</option>
              {filteredSettings?.map((type) => (
                <option key={type?.id} value={type?.id}>
                  {type.category?.name}
                </option>
              ))}
            </TextField>
            {/* <Button
              sx={{
                border: "1px solid #E0E0E0",
                color: "#1B3861",
                height: "40px",
              }}
              variant="outlined"
              size="medium"
              className={classes.downloadBtn}
            >
              <img
                src="/images/print.svg"
                // style={{ paddingRight: "7px", paddingTop: "7px" }}
              />
            </Button> */}
          </Box>
        </Box>
        {!loading ? (
          lsTableData?.liveStandings?.length > 0 ? (
            lsTableData?.liveStandings?.map(
              (item: any) =>
                item?.standings?.length > 0 && (
                  <Box key={item.id} sx={{ width: '100%' }}>
                    <LiveStandingsListTable leagues={item?.standings} title={item?.title} category={item?.tournamentCategory?.category?.name} segment={item?.segment} />
                  </Box>
                )
            )
          ) : (
            <NoDataFound text="There is no available live standings" sx={{ margin: '150px 0' }} />
          )
        ) : (
          <Loading height="50vh" />
        )}
        {/* </Container> */}
      </Grid>
    </Fragment>
  );
};

export default LiveStandings;
