import {
  Box,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import React, { FC, PropsWithChildren, useState } from "react";

const useStyles = makeStyles((theme) => ({
  popover: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))",
      gap: "10px",
      padding: "24px 30px 24px 24px",
      position: "absolute",
      top: 45,
      right: "0px",
      zIndex: 9,
      borderRadius: "6px",
      border: "1px solid #F6F7F9",
      background: "#FFF",
      boxShadow: "20px 20px 52px 0px rgba(0, 0, 0, 0.10)",
    },
  },
}));

interface IResponsiveFiltersPopoverProps {
  position?: "start" | "end" | "space-between";
  startElements?: number;
}

const ResponsiveFiltersPopover: FC<
  PropsWithChildren<IResponsiveFiltersPopoverProps>
> = ({ position, startElements, children }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  return (
    <Box>
      <Box
        sx={{
          display: { md: "flex", xs: openPopover ? "flex" : "none" },
          gap: React.Children.toArray(children)?.length > 1 ? "20px" : "0px",
          justifyContent: position || "end",
        }}
        className={openPopover ? classes.popover : ""}
      >
        {position !== "space-between" || !isMdUp ? (
          children
        ) : (
          <>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {React.Children.toArray(children).slice(0, startElements || 1)}
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {React.Children.toArray(children).slice(startElements || 1)}
            </Box>
          </>
        )}
        <Button
          sx={{
            borderRadius: "6px",
            display: { md: "none", xs: "block" },
            border: "1px solid #EFEFF0",
            height: "40px",
            cursor: "pointer",
            color: "white",
          }}
          variant="outlined"
          size="medium"
          onClick={() => setOpenPopover(false)}
        >
          Close
        </Button>
      </Box>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          textAlign: "right",
          cursor: "pointer",
        }}
        onClick={() => setOpenPopover(!openPopover)}
      >
        <FilterList />
      </Box>
    </Box>
  );
};

export default ResponsiveFiltersPopover;
