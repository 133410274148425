import { gql } from '@apollo/client';
const ALLNEWS = gql`
  query allNews($filter: ListNewsInput, $page: Int, $limit: Int) {
    allNews(filter: $filter, page: $page, limit: $limit) {
      news {
        id
        title
        slug
        image
        postText
        date
        author {
          id
          email
          name
          surname
        }
        federationNews {
          id
          news {
            id
            title
          }
          federation {
            id
            organizationName
          }
        }
      }
      total
    }
  }
`;
export default ALLNEWS;
