import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dot: {
    height: '6px',
    width: '6px',
    margin: '0 3px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
    transition: 'background-color 0.3s ease',
  },
  active: {
    backgroundColor: '#0A2540',
  },
}));

function Dot({ active, onClick }) {
  const classes = useStyles();
  return (
    <span
      className={`${classes.dot} ${active ? classes.active : ''}`}
      onClick={onClick}
    ></span>
  );
}

export default Dot;
