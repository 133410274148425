import { gql } from "@apollo/client";

const LEAGUESWITHATHLETES = gql`
  query allLeaguesWithCount(
    $page: Int
    $limit: Int
    $sports: [Int!]
    $filter: ListLeagueInput
    $federationId: Float
  ) {
    allLeaguesWithCount(
      page: $page
      limit: $limit
      filter: $filter
      federationId: $federationId
      sports: $sports
    )
  }
`;

export default LEAGUESWITHATHLETES;
