import React, { useState, useRef, useEffect, memo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@material-ui/core';
import CustomSection from '../../../layout/CustomSection';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import CustomButton from '../../../CustomComponents/CustomButton';
import TablerIcon from '../../../CustomComponents/TablerIcon';
import { ALL_TOURNAMENT_CATEGORIES, USER_TOURNAMENT_CATEGORIES } from '../../../../graphql/queries';
import { useNavigate } from 'react-router';
import useAuth from '../../../../hooks/useAuth';
import CustomTooltip from '../../../CustomComponents/CustomTooltip';
import { useTournament } from '../TournamentPage';

const Categories = memo(() => {
  const { id } = useTournament();
  const { user } = useAuth();
  const { data: categoriesData, loading: categoriesDataLoading, error: categoriesError } = useQuery(ALL_TOURNAMENT_CATEGORIES, { skip: !id, variables: { filter: { tournament: id } } });
  const { data: userCategories } = useQuery(USER_TOURNAMENT_CATEGORIES, { skip: !user || !id, variables: { email: user?.email, filter: { tournament: id } } });
  const userSupportedCategoriesArray = userCategories?.userTournamentCategories.map((cat) => cat.id) ?? [];

  const [viewAll, setViewAll] = useState(false);
  const { t } = useTranslation();
  const categories = categoriesData?.allTournamentCategories;

  const stackRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkOverflow = () => {
      if (stackRef.current) {
        setIsOverflowing(stackRef.current.scrollHeight > stackRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [categories, viewAll]);

  const feeRange = (cat: any) => {
    if (!cat.category.fee && !cat.category.onsiteFee) {
      return t('Free');
    } else if (cat.category.fee === cat.category.onsiteFee) {
      return `${cat.category.currency} ${cat.category.fee}`;
    } else if (cat.category.fee && cat.category.onsiteFee) {
      return `${cat.category.currency} ${Math.min(cat.category.fee, cat.category.onsiteFee)} - ${Math.max(cat.category.fee, cat.category.onsiteFee)}`;
    } else {
      return `${cat.category.currency} ${cat.category.fee || cat.category.onsiteFee}`;
    }
  };

  const enrichedCategories = categories?.map((cat) => {
    const isFull = !!cat.category.registrationLimit && cat.count >= cat.category.registrationLimit;
    const isSupported = userSupportedCategoriesArray.includes(cat.category.id);

    return { ...cat, isFull, isSupported, feeRange: feeRange(cat) };
  });

  const sortedCategories = enrichedCategories?.slice().sort((a, b) => {
    if (!a.isFull && a.isSupported && (b.isFull || !b.isSupported)) return -1;
    if (!b.isFull && b.isSupported && (a.isFull || !a.isSupported)) return 1;

    const aAvailableSpaces = a.category.registrationLimit ? a.category.registrationLimit - a.count : Infinity;
    const bAvailableSpaces = b.category.registrationLimit ? b.category.registrationLimit - b.count : Infinity;

    if (aAvailableSpaces !== bAvailableSpaces) {
      return bAvailableSpaces - aAvailableSpaces;
    }

    return a.category.category.name.localeCompare(b.category.category.name);
  });

  if (!categoriesDataLoading && !categoriesData?.allTournamentCategories.length) {
    return null;
  }

  return (
    <CustomSection gap={6} icon={<TablerIcon icon="category" fontSize="24px" />} title={t('Categories') + (categories ? ` (${categories.length})` : '')} sx={{ mt: 0 }}>
      {' '}
      <CustomPaperCard
        sx={{
          padding: { xs: '12px', md: '22px' },
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          flexDirection: 'column',
          maxHeight: viewAll ? 'none' : '320px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Stack
          ref={stackRef}
          gap="6px"
          sx={{
            width: '100%',
            overflow: 'hidden',
            ...(isOverflowing && !viewAll
              ? {
                  WebkitMaskImage: 'linear-gradient(to bottom, black 75%, transparent)',
                  maskImage: 'linear-gradient(to bottom, black 75%, transparent)',
                }
              : {}),
          }}
        >
          {categoriesDataLoading
            ? Array.from({ length: 4 }).map((_, index) => <CustomPaperCard key={`skeleton-${index}`} skeleton sx={{ height: '60px', width: '100%' }} />)
            : sortedCategories?.map((cat) => {
                const percentage = !cat.category.registrationLimit ? 0 : (100 / cat.category.registrationLimit) * cat.count;
                const color = percentage >= 100 ? '#ffe5e5' : percentage > 65 ? '#fff6f6' : percentage > 50 ? '#f3fef2' : '#f7fff6';

                return (
                  <CustomButton
                    key={cat.category.id}
                    size="medium"
                    variant="secondary"
                    onClick={() => {
                      const currentUrl = new URL(window.location.href);
                      currentUrl.searchParams.set('tab', 'participants');
                      currentUrl.searchParams.set('category', cat.category.id);
                      navigate(currentUrl.pathname + currentUrl.search);
                    }}
                    sx={{
                      minHeight: '60px',
                      borderColor: 'rgba(10, 37, 64, 0.15)',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        maskImage: `linear-gradient(to right, black ${percentage}%, transparent ${percentage}%)`,
                        background: `linear-gradient(to right, #fafafa, ${color})`,
                      }}
                    ></Box>
                    <Stack textAlign="start" width="100%" gap="4px" position="relative">
                      <Typography variant="font22" sx={{ color: '#0A2540', maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {cat.category.category.name}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#666' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <TablerIcon icon="tag" />
                          <Typography variant="font21">{cat.feeRange}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <TablerIcon icon="users-group" />
                          <Typography variant="font21">
                            <span style={{ color: cat.isFull ? '#de0a26' : undefined }}>{cat.count}</span>
                            {!!cat.category.registrationLimit && <>/{cat.category.registrationLimit}</>}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                    {cat.isSupported && !cat.isFull && (
                      <CustomTooltip title="Athlete satisfies the category entry requirements">
                        <Box>
                          <TablerIcon
                            icon="rosette-discount-check"
                            fontSize="24px"
                            color={process.env.REACT_APP_PRIMARY_COLOR}
                            style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
                          />
                        </Box>
                      </CustomTooltip>
                    )}
                  </CustomButton>
                );
              })}
        </Stack>
        {(viewAll || isOverflowing) && (
          <CustomButton
            size="small"
            shape="chip"
            variant="secondary"
            onClick={() => setViewAll((curr) => !curr)}
            sx={{
              borderColor: '#86909F',
              background: '#E6E9EC',
              color: '#86909F',
              gap: '4px',
              paddingInline: '16px',
              width: 'fit-content',
              position: viewAll ? 'relative' : 'absolute',
              left: viewAll ? undefined : '50%',
              bottom: viewAll ? undefined : { xs: '12px', md: '22px' },
              transform: viewAll ? undefined : 'translateX(-50%)',
            }}
          >
            {t(viewAll ? 'Hide' : 'View all')}
          </CustomButton>
        )}
      </CustomPaperCard>
    </CustomSection>
  );
});

export default Categories;
