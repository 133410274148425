import { gql } from "@apollo/client";

const CREATE_PARTNER_REQUEST = gql`
  mutation createPartnerRequest(
    $user: Int!
    $tournament: Int!
    $categoryId: Int!
    $status: String
    $selfInformation: String
    $partnerInformation: String
    $rank: Int
    $userEmail: String
    $partnerId: Int!
    $acceptUrl: String
    $declineUrl: String
    $partner: Int!
  ) {
    createPartnerRequest(
      partnerId: $partnerId
      createPartnerSearchInput: {
        user: $user
        tournament: $tournament
        categoryId: $categoryId
        status: $status
        selfInformation: $selfInformation
        partnerInformation: $partnerInformation
        rank: $rank
        userEmail: $userEmail
        partner: $partner
        acceptUrl: $acceptUrl
        declineUrl: $declineUrl
      }
    )
  }
`;
export default CREATE_PARTNER_REQUEST;
