import { gql } from '@apollo/client';
const RANKINGS_GENERIC = gql`
  query homePageLeagueRanking($platform: Int) {
    homePageLeagueRanking(platform: $platform) {
      id
      place
      points
      createdAt
      date
      tournaments
      thirdPartyPoints
      league {
        id
        title
        logo
      }
      user {
        id
        name
        surname
        country
        nation
        avatar
        dob
        user_club_id {
          clubName
          city
        }
      }
      rankingCategory {
        id
        category {
          name
        }
      }
    }
  }
`;
export default RANKINGS_GENERIC;
