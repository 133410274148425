import React, { FC, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Modal, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { WITHDRAWENTRY } from 'src/graphql/mutations';
import toast from 'react-hot-toast';
import { MyENTERIES, USER_TOURNAMENT_CATEGORIES } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  textfield: {
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FBFBFB',
    '& .MuiOutlinedInput-root': {
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#1B3861',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  label: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    margin: '8px 0',
  },
}));
const WithdrawModal: FC<any> = ({ openWithdrawModal, setOpenWithdrawModal, entryName, entryId, refetchTournament, setWithdrawLoading }) => {
  const classes = useStyles();
  const auth = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const handleClose = () => {
    setOpenWithdrawModal(false);
  };
  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const [withdraw, { data: check, loading }] = useMutation(WITHDRAWENTRY, {
    refetchQueries: [
      {
        query: MyENTERIES,
        variables: {
          isUnique: true,
          filter: { user: parseInt(auth?.user?.id), tournament: parseInt(id) },
        },
      },
      {
        query: USER_TOURNAMENT_CATEGORIES,
        variables: {
          email: auth?.user?.email,
          filter: { tournament: parseInt(id) },
        },
      },
      // {
      //   query: getTournamentById,
      //   variables: {
      //     id: parseInt(id),
      //     userId: auth?.user?.id,
      //     tournamentId: parseInt(id),
      //   },
      // },
    ],
    onCompleted: (data) => {
      refetchTournament();
    },
  }) as any;
  useEffect(() => {
    setWithdrawLoading(loading);
  }, [loading]);
  async function withdrawRequest() {
    try {
      const res = await withdraw({
        variables: {
          id: entryId,
          withdrawReason: reason,
        },
      });
      toast.success(t('Request Withdrawn'));
      setReason('');
      handleClose();
    } catch (error) {
      toast.error(t('Something went wrong!'));
    }
  }
  return (
    <React.Fragment>
      <Modal
        open={openWithdrawModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '802px',
            borderRadius: '16px',
            border: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 100,
            outline: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Paper sx={{ width: '100%', background: '#FFF' }}>
            <Box
              sx={{
                padding: '30px 30px 0px 30px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="h2">
                {t('Withdrawal')}
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>
            <Box sx={{ padding: '10px 30px 30px 30px' }}>
              {/* <Box sx={{ padding: "20px", height: "300px" }}> */}
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('Category')}</Typography>
                  <TextField
                    fullWidth
                    name="tour"
                    placeholder={t('Entry to withdraw')}
                    onChange={(e) => handleChange(e)}
                    disabled
                    value={entryName}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        border: '1px solid #EFEFF0',
                        '& fieldset': {
                          borderWidth: '0px',
                        },
                        '&:hover fieldset': {
                          borderWidth: '0px',
                          transform: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          borderWidth: '0px',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '9px 14px',
                        paddingRight: '0px!important',
                      },
                    }}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('Withdrawal Reason')}</Typography>
                  <TextField
                    fullWidth
                    name="tour"
                    placeholder={t('Write your reason here')}
                    onChange={(e) => handleChange(e)}
                    value={reason}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        border: '1px solid #EFEFF0',
                        '& fieldset': {
                          borderWidth: '0px',
                        },
                        '&:hover fieldset': {
                          borderWidth: '0px',
                          transform: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          borderWidth: '0px',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '9px 14px',
                        paddingRight: '0px!important',
                      },
                    }}
                    className={classes.textfield}
                  />
                </Grid>
              </Grid>
              <Button
                sx={{
                  display: 'flex',
                  padding: '10px 24px',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '6px',
                  color: '#FFF',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  mt: 2,
                }}
                onClick={() => {
                  !reason ? toast.error(t('Please specify reason')) : withdrawRequest();
                }}
              >
                {loading ? <CircularProgress size={20} color="inherit" /> : t('Confirm')}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawModal;
