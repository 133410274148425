import { gql } from '@apollo/client';

const TournamentRegistrations = gql`
  mutation createTournamentRegistrations($partnerSearchId: Int, $redirectURL: String, $platformId: Float, $createTournamentRegistrationInputs: [CreateTournamentRegistrationInput!]) {
    createTournamentRegistrations(partnerSearchId: $partnerSearchId, redirectURL: $redirectURL, platformId: $platformId, createTournamentRegistrationInputs: $createTournamentRegistrationInputs) {
      id
      status
      tournament {
        club {
          id
        }
      }
      additionalFee
      # user {
      #   id
      #   city
      #   user_poster
      # }
    }
  }
`;

export default TournamentRegistrations;
