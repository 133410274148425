import { gql } from '@apollo/client';
const ALLFEDERATIONS = gql`
  query federations($page: Int, $limit: Int, $filter: ListFederationInput, $platform: Int) {
    federations(page: $page, limit: $limit, filter: $filter, platform: $platform) {
      federations {
        id
        organizationName
        organizationType
        logo
        platforms {
          platform {
            sports {
              sport {
                title
                icon
              }
            }
          }
        }
        user {
          email
          nation
        }
        leagues {
          league {
            id
            title
            logo
            sports {
              id
              icon
              title
            }
            tournaments {
              id
            }
          }
        }
      }
      total
    }
  }
`;
export default ALLFEDERATIONS;
