import { gql } from '@apollo/client';
const GETROLES = gql`
  query roles {
    roles {
      id
      name
    }
  }
`;
export default GETROLES;
