import CustomSection from '../../../layout/CustomSection';
import { ContentCopy, LocationOn } from '@material-ui/icons';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomButton from '../../../CustomComponents/CustomButton';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import TablerIcon from '../../../CustomComponents/TablerIcon';
import useEventLocation from '../../../../utils/useEventLocation';
import { useTournament } from '../TournamentPage';

const Location = () => {
  const { t } = useTranslation();
  const { tournament, loading } = useTournament();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  const addressParts = [tournament?.address, tournament?.country, tournament?.city].filter(Boolean);
  const address = addressParts.join(', ');

  const markerPosition = useEventLocation({ address });

  const copyAddressToClipboard = () => {
    if (address) {
      navigator.clipboard
        .writeText(address)
        .then(() => {
          toast.success('Address copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy address: ', err);
        });
    }
  };

  return (
    <CustomSection icon={<LocationOn />} title={t('Location')} sx={{ mt: 0 }} gap={6}>
      <CustomPaperCard id={`tournament-${tournament?.id}-map`} sx={{ padding: { xs: '12px', md: '22px' }, display: 'flex', gap: '16px', flexDirection: 'column' }}>
        {loading ? (
          <CustomPaperCard skeleton sx={{ height: '24px', width: '160px' }} />
        ) : (
          <CustomButton size="auto" variant="text" sx={{ gap: '6px', justifyContent: 'space-between', overflow: 'hidden' }} onClick={copyAddressToClipboard}>
            <Typography variant="font22" sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'start' }}>
              {address}
            </Typography>
            <ContentCopy sx={{ fontSize: '20px' }} />
          </CustomButton>
        )}
        <CustomPaperCard skeleton={!isLoaded || loading} sx={{ position: 'relative', width: '100%', height: '300px' }}>
          <GoogleMap mapContainerStyle={{ height: '100%', width: '100%' }} center={markerPosition || { lat: 0, lng: 0 }} zoom={14}>
            {markerPosition && <Marker position={markerPosition} />}
          </GoogleMap>
        </CustomPaperCard>
        <Box display="flex" gap="6px">
          {address && tournament?.startDate && tournament?.endDate && (
            <CustomButton
              onClick={() =>
                window.open(
                  tournament?.id === 6592
                    ? 'https://docs.google.com/spreadsheets/d/1lW3eUuXQoEHuVtTcWsVMEoXPjm1gly95YihLiQCiR1A/edit?gid=0#gid=0'
                    : `https://www.booking.com/searchresults.html?ss=${encodeURIComponent(address)}`
                )
              }
              size="medium"
              variant="outline"
              sx={{ gap: '4px', background: 'rgba(12, 59, 124, 0.10)', color: '#0C3B7C', borderColor: 'currentcolor', flex: 1 }}
            >
              <TablerIcon icon={tournament?.id === 6592 ? 'world-www' : 'brand-booking'} style={{ fontSize: '20px' }} />
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Explore Hotels</span>
            </CustomButton>
          )}
          {markerPosition && (
            <CustomButton
              onClick={() => window.open(`https://waze.com/ul?ll=${markerPosition.lat},${markerPosition.lng}&navigate=yes`)}
              size="medium"
              variant="outline"
              sx={{ gap: '4px', background: '#fff', color: '#05c8f7', borderColor: 'currentcolor' }}
              square
            >
              <i className="ti ti-brand-waze" style={{ fontSize: '20px' }}></i>
            </CustomButton>
          )}
          {markerPosition && (
            <CustomButton
              onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${markerPosition.lat},${markerPosition.lng}`)}
              size="medium"
              variant="outline"
              sx={{ gap: '4px', background: '#fff', color: '#1EA362', borderColor: 'currentcolor' }}
              square
            >
              <i className="ti ti-brand-google-maps" style={{ fontSize: '20px' }}></i>
            </CustomButton>
          )}
          {markerPosition && (
            <CustomButton
              onClick={() => window.open(`https://maps.apple.com/?daddr=${markerPosition.lat},${markerPosition.lng}&dirflg=d`)}
              size="medium"
              variant="outline"
              sx={{ gap: '4px', background: '#fff', color: '#007aff', borderColor: 'currentcolor' }}
              square
            >
              <i className="ti ti-brand-apple" style={{ fontSize: '20px' }}></i>
            </CustomButton>
          )}
        </Box>
      </CustomPaperCard>
    </CustomSection>
  );
};

export default Location;
