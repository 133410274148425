import { gql } from "@apollo/client";

const REMOVE_USER_PARENT = gql`
  mutation removeUserParent($id: Int!) {
    removeUserParent(id: $id) {
      user {
        id
      }
    }
  }
`;

export default REMOVE_USER_PARENT;
