import { gql } from "@apollo/client";
const ALL_PARTNERS = gql`
  query partners($filter: ListPartnerInput) {
    partners(filter: $filter) {
      id
      title
      gender
      link
      image
    }
  }
`;
export default ALL_PARTNERS;
