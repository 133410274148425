import { gql } from "@apollo/client";
const GETFEDERATIONBYID = gql`
  query federationbyid($id: Int!) {
    federation(id: $id) {
      user {
        surname
        email
        phone
      }
      logo
      organizationAbbreviation
      organizationName
      organizationType
      leagues {
        id
        league {
          id
        }
      }
    }
  }
`;
export default GETFEDERATIONBYID;
