import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Checkbox, Divider, CircularProgress } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { UserRolesInfo } from 'src/constants';
import { useRegisterContext } from 'src/contexts/RegisterContext';
import toast from 'react-hot-toast';
import AuthContext from 'src/contexts/JWTContext';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';

const SelectRole: FC<any> = (props) => {
  const { registerObject, updateRegisterObject, registeration, response, isLoading } = useRegisterContext();
  const { t } = useTranslation();

  const [selectedRoles, setSelectedRoles] = useState<any[]>(registerObject?.additionalUserRole || []);
  const { setSelectedSports, selectedSports } = useContext(AuthContext);

  useEffect(() => {
    const variables = {
      additionalUserRole: selectedRoles,
    };
    updateRegisterObject({ ...registerObject, ...variables });
  }, [selectedRoles]);

  const removeObjItems = (obj) => {
    delete obj?.has_fisher_id;
    delete obj?.no_fisher_id_doc;
    delete obj?.no_fisher_id_reason;
    delete obj?.submit;
    delete obj?.reEnter;
    delete obj?.national_fisher_id;
  };

  const handleRoleToggle = (role) => {
    setSelectedRoles((prevSelectedRoles) => (prevSelectedRoles?.includes(role) ? prevSelectedRoles?.filter((r) => r !== role) : [...prevSelectedRoles, role]));
  };

  const handleSubmit = async () => {
    try {
      removeObjItems(registerObject);
      await registeration(registerObject);
      setSelectedSports(registerObject?.user_sports);
      props.handleClick(0);
      if (response) {
        toast.success(t('User registered successfully'));
        props?.handleClose?.();
      }
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: 'max-content',
        maxHeight: '100%',
        minHeight: '474px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
            {t('Tell us more about you')}
          </Typography>
          <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '20px', opacity: '35%' }}>
            {t('What is your primary role in the world of sports?')}
          </Typography>
          <Box sx={{ mt: 1.5 }}>
            <Divider />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            minHeight: '378px',
          }}
        >
          <Grid container spacing={1}>
            {UserRolesInfo?.map((item) => (
              <Grid item lg={6} md={6} xs={6} key={item.value}>
                <CustomButton
                  variant="secondary"
                  size="auto"
                  selected={selectedRoles?.includes(item.value)}
                  onClick={() => handleRoleToggle(item.value)}
                  sx={{ width: '100%', justifyContent: 'space-between', paddingInline: '8px' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', whiteSpace: 'normal', height: '70px', textAlign: 'start' }}>
                    <ReactSVG src={item?.icon} style={{ height: '40px', width: '40px' }} className="svg-current-signup" />
                    <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '12px' }}>
                      {t(item?.title)}
                    </Typography>
                  </Box>
                  <Checkbox
                    checked={selectedRoles.includes(item.value)}
                    value={item.value}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRoleToggle(item.value);
                    }}
                  />
                </CustomButton>
              </Grid>
            ))}
          </Grid>
          <Box>
            <CustomButton size="medium" type="submit" variant="primary" onClick={() => handleSubmit()} disabled={isLoading} sx={{ mt: '8px', width: '100%' }}>
              {isLoading ? <CircularProgress sx={{ color: '#fff' }} /> : t('Register & Go to profile')}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectRole;
