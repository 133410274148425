import { gql } from '@apollo/client';

const FEATURED_TOURNAMENTS = gql`
  query featuredTournaments($filter: ListTournamentInput, $sports: [Int!], $platform: Int) {
    featuredTournaments(filter: $filter, sports: $sports, platform: $platform) {
      id
      title
      tour
      address
      startDate
      endDate
      entryDeadline
      createdAt
      country
      city
      logo
      coverPhoto
      closeRegistration
      paymentMethod
      status
      league {
        id
        league {
          logo
          id
          owner {
            id
            federation {
              id
              organizationName
              organizationType
              organizationAbbreviation
              logo
            }
          }
          title
          enableLicense
          sports {
            id
            icon
            title
          }
          abbreviation
        }
      }
      tournamentCategory {
        isRegistered
        category {
          name
          type
          gender
          status
          id
          ageLimit
          ageType
          ageTo
          ageFrom
          skillsLevel
          ageGroup
        }
        id
        fee
        currency
      }
      organizer {
        organizationName
        organizationEmail
        isOrganization
        member {
          name
          id
          surname
          avatar
        }
      }
      referee {
        referee {
          name
        }
      }
      registrationRequests {
        id
      }
    }
  }
`;

export default FEATURED_TOURNAMENTS;
