import { Avatar, Box, Divider, Grid, makeStyles, TableCell, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { getFideTitle, getRating } from 'src/utils/helperFunction';
import { ReactComponent as BlackHorse } from 'src/assets/images/horse-black.svg';
import { ReactComponent as WhiteHorse } from 'src/assets/images/hourse-white.svg';
import GetFlag from 'src/utils/getFlags';
import { margin } from '@material-ui/system';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '12px',
    color: '#193660',
    fontWeight: 500,
    fontFamily: 'Inter',
  },
  pts: {
    padding: '2px 3px',
    fontSize: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: '3px 5px',
      fontSize: '12px',
    },
    background: 'rgba(0, 0, 0, 0.15)',
    color: '#000',
    borderRadius: '5px',
    marginRight: '10px',
    minWidth: '20px',
    maxWidth: 'max-content',
    textAlign: 'center',
  },
  ptsBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PairingsCard = ({ entries, timeControl }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const FormatScore = ({ score }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {score?.split(':')?.map((s, index) => (
          <>
            <Box
              key={score}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: { sm: '3px 5px', xs: '2px 3px' },
                border: '1px solid rgba(223, 223, 223, 1)',
                borderRadius: '5px',
              }}
            >
              {s}
            </Box>
            {index === 0 && <Box mx="2px">:</Box>}
          </>
        ))}
      </Box>
    );
  };

  const getScores = (pts: string) => {
    switch (pts) {
      case '1:0':
        return <FormatScore score="1:0" />;
      case '0.5:0.5':
        return <FormatScore score="1/2:1/2" />;
      case '0:1':
        return <FormatScore score="0:1" />;
      case '0:0':
        return <FormatScore score="0:0" />;
      case '0.5:0':
        return <FormatScore score="1/2:0" />;
      case '0:0.5':
        return <FormatScore score="0:1/2" />;
      default:
        return '';
    }
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          borderTop: '1px solid #E0E0E0',
          borderBottom: '1px solid #E0E0E0',
          padding: '10px',
          mb: 1,
        }}
      >
        <Grid item xs={1} className={classes.heading}>
          Nr
        </Grid>
        <Grid item xs={5} className={classes.heading}>
          Name
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          Rating
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          Pts
        </Grid>
        <Grid item xs={2} className={classes.heading} sx={{ paddingLeft: '10px' }}>
          Score
        </Grid>
      </Grid>
      {entries.map((entry, index) => {
        const user1 = entry?.entry1?.users?.[0]?.user;
        const user2 = entry?.entry2?.users?.[0]?.user;
        const pts1 = entry?.entry1?.pts;
        const pts2 = entry?.entry2?.pts;
        const fideTitle = entry?.entry1?.users?.[0]?.fideTitle;
        const fideTitle2 = entry?.entry2?.users?.[0]?.fideTitle;

        return (
          <Box mb={3} p="0 10px">
            <Grid container alignItems="center">
              <Grid
                item
                xs={1}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              ></Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': { color: '#29ABE2' },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        position: 'relative',
                        width: '20px',
                        height: '20px',
                        mr: 1,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: '20px',
                          height: '20px',
                        }}
                        src={user1?.avatar}
                      />
                      <GetFlag
                        country={user1?.citizenship ? user1?.citizenship : user1?.nation}
                        style={{
                          width: '.7em',
                          height: '.7em',
                          marginTop: '5px',
                          borderRadius: '1px',
                          position: 'absolute',
                          bottom: -3,
                          left: 0,
                          right: 0,
                          margin: 'auto',
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: 'flex',
                        color: '#0A2540',
                        fontWeight: '500',
                        fontFamily: 'Inter',
                        lineHeight: '12px',
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        fontSize: {
                          xs: '10px',
                          sm: '12px',
                        },
                      }}
                      onClick={() => {
                        if (user1?.id) {
                          window.open(`/athlete/${user1?.id}?tab=overview`, '_blank');
                        }
                      }}
                    >
                      {user1?.name ? user1?.name : ''} {user1?.surname ? user1?.surname : ''}{' '}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      padding: { sm: '3px 5px', xs: '2px 3px' },
                      background: getFideTitle(fideTitle)?.color,
                      color: '#FFFF',
                      borderRadius: '5px',
                      marginRight: '10px',
                      width: 'max-content',
                      fontSize: {
                        xs: '10px',
                        sm: '12px',
                      },
                    }}
                  >
                    {getFideTitle(fideTitle)?.abbreviation}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              >
                {getRating(entry?.entry1?.users?.[0], timeControl)}
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.ptsBox}>
                  <Typography className={classes.pts}>{pts1}</Typography>
                  <WhiteHorse className="svg-current-color" style={{ color: 'white' }} />
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              ></Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid
                item
                xs={1}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                  paddingLeft: '5px',
                }}
              >
                {index + 1}
              </Grid>
              <Grid item xs={9}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                  paddingLeft: '10px',
                }}
              >
                {getScores(entry?.score)}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                padding: '0px',
              }}
              alignItems="center"
            >
              <Grid
                item
                xs={1}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              ></Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': { color: '#29ABE2' },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        position: 'relative',
                        width: '20px',
                        height: '20px',
                        mr: 1,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: '20px',
                          height: '20px',
                        }}
                        src={user2?.avatar}
                      />
                      <GetFlag
                        country={user2?.citizenship ? user2?.citizenship : user2?.nation}
                        style={{
                          width: '.7em',
                          height: '.7em',
                          marginTop: '5px',
                          borderRadius: '1px',
                          position: 'absolute',
                          bottom: -3,
                          left: 0,
                          right: 0,
                          margin: 'auto',
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        display: 'flex',
                        color: '#193660',
                        fontWeight: '500',
                        mr: 0.5,
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: {
                          xs: '10px',
                          sm: '12px',
                        },
                      }}
                      onClick={() => {
                        if (user2?.id) {
                          window.open(`/athlete/${user2?.id}?tab=overview`, '_blank');
                        }
                      }}
                    >
                      {user2?.name ? user2?.name : ''} {user2?.surname ? user2?.surname : ''}{' '}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      padding: { sm: '3px 5px', xs: '2px 3px' },
                      background: getFideTitle(fideTitle2)?.color,
                      color: '#FFFF',
                      borderRadius: '5px',
                      marginRight: '10px',
                      width: 'max-content',
                      marginLeft: '10px',
                      fontSize: {
                        xs: '10px',
                        sm: '12px',
                      },
                    }}
                  >
                    {getFideTitle(fideTitle2)?.abbreviation}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              >
                {getRating(entry?.entry2?.users?.[0], timeControl)}
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.ptsBox}>
                  <Typography className={classes.pts}>{pts2}</Typography>
                  <BlackHorse className="svg-current-color" style={{ color: 'black' }} />
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                  },
                }}
              ></Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default PairingsCard;
