import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import WithdrawModal from 'src/components/widgets/modals/WithdrawModal';
import GetFlag from 'src/utils/getFlags';
import Loading from 'src/components/Loading';
import DragScrollbar from 'src/components/DragScrollbar';

import { CREATE_TOURNAMENT_REG_PAYMENT } from 'src/graphql/mutations';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import NoDataFound from '../../NoDataFound';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { GET_PAYMENT_CALCULATIONS, MyENTERIESUPDATED } from 'src/graphql/queries';
import { getPlatformId } from 'src/constants';
import CustomChip from 'src/components/CustomComponents/CustomChip';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
  },
  head: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  withdraw: {
    padding: '8px 16px',
    borderRadius: '6px',
    textAlign: 'center',
    border: '1px solid #EDF1F6',
    background: '#FFF',
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);
const UserPendingPayments: FC<any> = ({ refetchTournament, Enteries, loading }) => {
  const auth = useAuth();
  const FilteredEntries = Enteries?.filter((entry) => {
    if (entry.status === 'partial_payment') {
      return entry?.users?.some((user) => user?.user?.id == auth?.user?.id && user?.paymentStatus === 'pending');
    }

    return entry?.status === 'payment_pending';
  });
  // const FilteredEntries = Enteries?.filter((entry) => entry?.status == 'payment_pending' || entry?.status == 'partial_payment');
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();

  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [entryName, setEntryName] = useState(null);
  const [entryId, setEntryId] = useState(null);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [fetchPayments, { data, loading: calculationsLoading }] = useLazyQuery(GET_PAYMENT_CALCULATIONS, {});
  const [tournamentPayment, { data: paymentData, loading: paymentLoading }] = useMutation(CREATE_TOURNAMENT_REG_PAYMENT);

  const handlePayment = async (entry) => {
    const payments = await fetchPayments({
      variables: {
        categoryPaymentInput: [
          {
            categoryId: parseInt(entry?.categoryId?.category?.id),
            additionalFee: entry?.additionalFee,
            onSiteAmount: parseInt(entry?.categoryId?.onsiteFee),
            currency: entry?.categoryId?.currency,
            amount: parseInt(entry?.categoryId?.fee),
            partnerId: parseInt(entry?.users[0]?.user?.id),
          },
        ],
        calculatePaymentInput: {
          payForMyself: entry?.payForMyself,
          tournamentId: parseInt(entry?.tournament?.id),
          userId: parseInt(auth?.user?.id),
          platformId: getPlatformId(),
        },
      },
    });
    const isDoubleAmount = entry?.categoryId?.category?.type === 'doubles' && entry?.categoryId?.onlinePayments?.toLowerCase() === 'per athlete';
    if (payments?.data?.calculatePayments && parseFloat(entry?.categoryId?.fee) + entry?.additionalFee) {
      const paymentResponse = await tournamentPayment({
        variables: {
          email: auth?.user?.email,
          name: auth?.user?.name,
          userId: parseInt(auth?.user?.id),
          amount: parseFloat(payments?.data?.calculatePayments?.totalPayable),
          platformProcessingFee: payments?.data?.calculatePayments?.totalProcessingFee,
          entryFee: payments?.data?.calculatePayments?.totalEntryFee,
          VATFee: payments?.data?.calculatePayments?.totalVatFee,
          totalPartnerServiceFee: payments?.data?.calculatePayments?.totalPartnerServiceFee,
          totalTournatedServiceFee: payments?.data?.calculatePayments?.totalTournatedServiceFee,
          successUrl: window.location.href,
          currency: entry?.categoryId?.currency,
          registrationRequestIds: [entry?.id],
          tournamentName: entry?.tournament?.title,
          tournamentId: entry?.tournament?.id,
        },
      });
      if (paymentResponse?.data?.createTournamentRegistrationPayment?.url) {
        window.open(`${paymentResponse?.data?.createTournamentRegistrationPayment?.url}`, '_self');
      }
    }
  };

  function withdrawEntry(cat) {
    setOpenWithdrawModal(true);
    setEntryName(cat?.categoryId?.category?.name);
    setEntryId(cat?.id);
  }
  return (
    <>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {
          <Box sx={{ width: '100%' }}>
            {loading ? (
              <Loading height="30vh" />
            ) : FilteredEntries?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell align="left" className={classes.heading}>
                      {t('Description')}
                    </CustomTableCell>
                    <CustomTableCell align="left" className={classes.heading} sx={{ whiteSpace: 'nowrap' }}>
                      {t('Deadline to pay')}
                    </CustomTableCell>
                    <CustomTableCell align="left" className={classes.heading}>
                      {t('Amount')}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FilteredEntries?.map((entry, index) => (
                    <>
                      <TableRow
                        hover
                        key={entry?.id}
                        sx={{
                          borderRadius: '6px',
                          padding: '12px',
                          border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                          background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                        }}
                      >
                        <CustomTableCell align="left">
                          <CustomChip size="xs" variant="secondary" sx={{ gap: '4px', height: '26px', paddingInline: '10px' }}>
                            {entry?.categoryId?.category?.name}
                          </CustomChip>
                        </CustomTableCell>
                        <CustomTableCell align="left" className={classes.text}>
                          <Typography className={classes.text}>{moment(entry?.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Typography>
                        </CustomTableCell>
                        <CustomTableCell align="left">
                          <Box sx={{ display: 'flex', alignItems: 'Center', gap: '5px' }}>
                            <Typography className={classes.text}>{entry?.amount}</Typography>
                            {new Date(entry?.tournament?.withdrawlDeadline) >= new Date() &&
                              (entry?.status === 'payment_pending' ||
                                (entry?.status === 'partial_payment' && entry?.users.some((user) => user?.user?.id == auth.user?.id && user?.paymentStatus === 'pending'))) && (
                                <CustomButton
                                  size="small"
                                  variant="primary"
                                  sx={{ height: '25px', fontSize: '10px' }}
                                  disabled={(paymentLoading || calculationsLoading) && entryId == entry?.id}
                                  onClick={() => {
                                    handlePayment(entry);
                                    setEntryId(entry?.id);
                                  }}
                                >
                                  {(paymentLoading || calculationsLoading) && entryId == entry?.id ? <CircularProgress size={20} sx={{ color: '#ffff' }} /> : 'PAY'}
                                </CustomButton>
                              )}
                            {new Date(entry?.tournament?.withdrawlDeadline) >= new Date() || auth?.user?.role?.toLowerCase() === 'referee' ? (
                              <CustomButton
                                key={entry?.id}
                                size="small"
                                variant="primary"
                                sx={{ cursor: 'pointer', whiteSpace: 'nowrap', height: '25px', fontSize: '10px' }}
                                // onClick={() => withdrawRequest(entry?.id)}
                                onClick={() => withdrawEntry(entry)}
                                disabled={entry?.status == 'withdrawn' || (withdrawLoading && entryId == entry?.id)}
                              >
                                {withdrawLoading && entryId == entry?.id ? <CircularProgress size={20} sx={{ color: '#ffff' }} /> : entry?.status == 'withdrawn' ? t('WITHDRAWN') : t('WITHDRAW')}
                              </CustomButton>
                            ) : (
                              ''
                            )}
                          </Box>
                        </CustomTableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoDataFound text="No entries found" sx={{ height: '35vh' }} />
            )}
          </Box>
        }
      </Box>
      <WithdrawModal
        openWithdrawModal={openWithdrawModal}
        setOpenWithdrawModal={setOpenWithdrawModal}
        entryId={entryId}
        refetchTournament={refetchTournament}
        setWithdrawLoading={setWithdrawLoading}
        entryName={entryName}
      />
    </>
  );
};

export default UserPendingPayments;
