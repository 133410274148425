import { Agenda, Day, Inject, Month, ScheduleComponent, ViewDirective, ViewsDirective, Week, WorkWeek } from '@syncfusion/ej2-react-schedule';
import { Box, Stack, Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Theme } from '@material-ui/core/styles';

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-react-schedule/styles/material.css';
import chroma from 'chroma-js';

const CustomSchedule = ({ selectedDate, data, startDate, endDate, height }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box sx={{ '& .e-schedule': { border: 'none', borderRadius: '8px' } }}>
      <ScheduleComponent
        height={height}
        selectedDate={selectedDate}
        timeFormat="HH:mm"
        firstDayOfWeek={1}
        eventSettings={{
          dataSource: data,
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          template: (data) => (
            <Stack sx={{ overflow: 'hidden', padding: '4px' }}>
              <Typography component="p" variant="font22" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {data.Subject}
              </Typography>
              <Typography component="p" variant="font21" color="rgba(255, 255, 255, 0.75)">
                {moment(data.StartTime).format('HH:mm')} - {moment(data.EndTime).format('HH:mm')}
              </Typography>
            </Stack>
          ),
        }}
        timeScale={{
          interval: 60, // Set the interval for each slot in minutes
          slotCount: 2, // Number of slots per interval
          majorSlotTemplate: (props) => (
            <Typography component="p" variant="font22" sx={{ color: '#A6A6A6', paddingRight: '8px', width: '100%', textAlign: 'end' }}>
              {moment(props.date).format('HH:mm')}
            </Typography>
          ),
        }}
        // minDate={startDate ? new Date(startDate.toISOString()) : undefined}
        // maxDate={endDate ? new Date(endDate.toISOString()) : undefined}
        eventRendered={(args) => {
          const eventColor = args.data.CategoryColor; // Assuming 'CategoryColor' is the field with color value
          if (eventColor) {
            args.element.style.backgroundColor = eventColor;
            args.element.style.borderRadius = '12px';
            args.element.style.border = 'none';
          }
          args.element.style.minHeight = '45px';
        }}
        dateHeaderTemplate={({ date }) => {
          const weekdayName = moment(date).format('ddd');
          const dayNumber = moment(date).format('D');
          const isToday = moment(date).isSame(moment(), 'day');

          return (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Stack
                sx={{
                  height: '45px',
                  minWidth: '45px',
                  alignItems: 'center',
                  gap: '2px',
                  padding: '8px',
                  borderRadius: '4px',
                  backgroundColor: isToday ? chroma(process.env.REACT_APP_PRIMARY_COLOR).alpha(0.1).hex() : 'transparent',
                }}
              >
                <Typography variant="heading12" color={isToday ? process.env.REACT_APP_PRIMARY_COLOR : '#0A2540'} sx={{ lineHeight: '100%' }}>
                  {weekdayName}
                </Typography>
                <Typography variant="heading13" color={isToday ? process.env.REACT_APP_PRIMARY_COLOR : '#0A2540'} sx={{ lineHeight: '100%' }}>
                  {dayNumber}
                </Typography>
              </Stack>
            </Box>
          );
        }}
        currentView={isMobile ? 'Day' : 'Week'}
        renderCell={(args) => {
          if (args.element.classList.contains('e-disable-dates') && args.element.classList.contains('e-work-cells')) {
            args.element.style.backgroundColor = 'rgb(250, 250, 250)'; // Set your desired color for out-of-range cells
          }
          if (
            args.element.classList.contains('e-time-cells') ||
            args.element.classList.contains('e-time-slots') ||
            args.element.classList.contains('e-header-cells') ||
            args.element.classList.contains('e-all-day-cells')
          ) {
            args.element.style.border = 'none';
          }
          args.element.style.height = '45px';
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" startHour="09:00" endHour="22:00" />
          <ViewDirective option="Week" startHour="09:00" endHour="22:00" />
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
      </ScheduleComponent>
    </Box>
  );
};

export default CustomSchedule;
