import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { getCurrencySymbol } from 'src/utils/helperFunction';
import { Payments as CashIcon, CheckBox, CreditCard as CreditCardIcon } from '@material-ui/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import CustomAccordion from 'src/components/CustomComponents/CustomAccordian';
import { GETUSERBYID, RANKINGS_BY_DUPRID } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import InfoIcon from '@material-ui/icons/Info';
import PlayerRatings from './DuprRankings';
import TermsConditionsModal from 'src/components/widgets/modals/TermsConditionModal';
import CustomCheckbox from 'src/components/CustomComponents/CustomCheckbox';
import DuprConnection from './DUPRConnect';

interface StepNavigationProps {
  activeStep: number;
  steps: string[];
  handleNext: () => void;
  handleBack: () => void;
  backButtonClickCounter: any;
  setShowBackBtn: any;
  setBackButtonClickCounter: any;
  setActiveStep: any;
  loading: boolean;
  handlePayClick: (method: 'online' | 'onsite') => void;
  tournament: any;
  priceUnit?: String;
  fetchPayments?: any;
  calculationsLoading?: any;
  data?: any;
  termAccepted?: boolean;
  handleCheckboxChange?: any;
  isEmptyContent?: any;
}

export const StepNavigation: React.FC<StepNavigationProps> = ({
  activeStep,
  steps,
  handleNext,
  handleBack,
  loading,
  handlePayClick,
  setBackButtonClickCounter,
  setShowBackBtn,
  backButtonClickCounter,
  setActiveStep,
  tournament,
  priceUnit,
  fetchPayments,
  calculationsLoading,
  data,
  termAccepted,
  handleCheckboxChange,
  isEmptyContent,
}) => {
  const { user, dispatch } = useAuth();
  const navigate = useNavigate();
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();
  const [openTerms, setOpenTerms] = useState(false);
  const [clickedButton, setClickedButton] = useState<'online' | 'onsite' | null>(null);
  const [skipAdditionalQuestions, setSkipAdditionalQuestions] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [duprData, setDuprData] = useState<any>();

  // const [checkDupr, { loading: duprLoading, data: duprData }] = useLazyQuery(GETUSERBYID, {
  //   fetchPolicy: 'no-cache',
  // });
  const { loading: duprRankLoading, data: duprRankings } = useQuery(RANKINGS_BY_DUPRID, {
    fetchPolicy: 'network-only',
    skip: !user?.duprId,
    variables: {
      duprId: user?.duprId,
    },
  });

  const calculateFinalFees = () => {
    let totalFee = 0;
    let totalOnsiteFee = 0;
    let totalAdditionalFees = 0;
    let currency = null;

    registrationData?.selectedCategories?.forEach((categoryEntry) => {
      const { category, totalAdditionalFees: additionalFees, currency: categoryCurrency } = categoryEntry;
      const isDoubleAmount = category?.category?.type === 'doubles' && category?.onlinePayments?.toLowerCase() === 'per athlete';

      const categoryFee = isDoubleAmount ? category?.fee * 2 + additionalFees : category?.fee + additionalFees;
      const categoryOnsiteFee = isDoubleAmount ? category?.onsiteFee * 2 + additionalFees : category?.onsiteFee + additionalFees;

      totalFee += categoryFee;
      totalOnsiteFee += categoryOnsiteFee;
      totalAdditionalFees += additionalFees;

      currency = categoryCurrency;
    });

    return { totalFee, totalOnsiteFee, currency };
  };

  const { totalFee, totalOnsiteFee, currency } = calculateFinalFees();

  const handlePayClickWrapper = (method: 'online' | 'onsite') => {
    setClickedButton(method);
    handlePayClick(method);
  };
  const formatFee = (value, IconComponent) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
      <IconComponent style={{ verticalAlign: 'middle', marginRight: '2px', height: '15px', width: '15px' }} />
      <Typography variant="font15" sx={{ lineHeight: '18px', color: '#0A2540', whiteSpace: 'nowrap' }}>
        {getCurrencySymbol(priceUnit)}
        {value.toFixed(2)}
      </Typography>
    </Box>
  );
  const renderPaymentButton = (method: 'online' | 'onsite', fee: number, label: string) => (
    <CustomButton
      shape="default"
      size="medium"
      sx={{ fontWeight: 500 }}
      variant={method === 'online' ? 'primary' : 'secondary'}
      onClick={() => handlePayClickWrapper(method)}
      disabled={loading || calculationsLoading}
    >
      {loading && clickedButton === method ? <CircularProgress size={25} sx={{ color: 'white' }} /> : `${label}`}
    </CustomButton>
  );

  const validateMandatoryQuestions = () => {
    let isValid = true;

    registrationData?.selectedCategories?.forEach((categoryEntry) => {
      const { player1additionalQuestions, player2additionalQuestions } = categoryEntry;
      const player1Questions = [...(player1additionalQuestions || [])];
      const player2Questions = [...(player2additionalQuestions || [])];

      player1Questions.forEach((question) => {
        if (question.isMandatory && (question.answer === null || question.answer === undefined || question.answer === '')) {
          question.hasError = true;
          isValid = false;
        } else {
          question.hasError = false;
        }
      });

      player2Questions.forEach((question) => {
        if (question.isMandatory && (question.answer === null || question.answer === undefined || question.answer === '')) {
          question.hasError = true;
          isValid = false;
        } else {
          question.hasError = false;
        }
      });

      updateRegistrationData({
        selectedCategories: registrationData?.selectedCategories?.map((catEntry) =>
          catEntry?.category?.category?.id === categoryEntry?.category?.category?.id
            ? {
                ...catEntry,

                player1additionalQuestions: player1Questions,
                player2additionalQuestions: player2Questions,
              }
            : catEntry,
        ),
      });
    });

    return isValid;
  };
  const validateAndProceed = () => {
    const { selectedCategories } = registrationData;
    if (activeStep === 0 && !selectedCategories?.length) {
      toast.error('Please select a category to proceed.');
      return;
    }

    if (activeStep === 1) {
      let hasAdditionalQuestions = false;

      for (const categoryEntry of selectedCategories) {
        const { category, partners } = categoryEntry;
        const { type } = category?.category || {};

        if (type === 'single' && !partners?.player1) {
          toast.error(`Please select a player for the single category (${category?.category?.name}).`);
          return;
        }

        if (type === 'doubles' && (!partners?.player1 || !partners?.player2)) {
          toast.error(`Please select partner for doubles category (${category?.category?.name}).`);
          return;
        }
        if (category?.additionalQuestions?.length > 0) {
          hasAdditionalQuestions = true;
        }
      }
      if (!hasAdditionalQuestions) {
        fetchPayments();
        setSkipAdditionalQuestions(true);
        handleNext();
        // handleNext();
      } else {
        setSkipAdditionalQuestions(false);
      }
    }

    if (activeStep === 2) {
      fetchPayments();
      if (!validateMandatoryQuestions()) {
        toast.error('Please fill out all mandatory questions.');
        return;
      }
    }

    handleNext();
  };
  const formatDateToGoogleCalendarUTC = (date) => {
    const utcDate = new Date(date)?.toISOString();
    return utcDate?.replace(/-|:|\.\d+/g, '');
  };
  useEffect(() => {
    if (backButtonClickCounter > 0) {
      setActiveStep((prev) => {
        let newStep = Math.max(0, prev - 1);

        const hasAdditionalQuestions = registrationData.selectedCategories.some((categoryEntry) => categoryEntry?.category?.additionalQuestions?.length > 0);

        if (newStep === 2 && hasAdditionalQuestions) {
          setSkipAdditionalQuestions(false);
        } else if (newStep === 2 && !hasAdditionalQuestions) {
          newStep = newStep - 1;
        }

        return newStep;
      });
    }
    return () => setBackButtonClickCounter(0);
  }, [backButtonClickCounter]);
  const allFeesZeroOrNull = registrationData?.selectedCategories?.every(
    (categoryEntry) => (categoryEntry?.category?.fee === 0 || categoryEntry?.category?.fee == null) && (categoryEntry?.category?.onsiteFee === 0 || categoryEntry?.category?.onsiteFee == null),
  );

  useEffect(() => {
    if (activeStep > 0 && activeStep !== steps.length - 1) setShowBackBtn(true);
    return () => setShowBackBtn(false);
  }, [activeStep]);

  const renderStepButtons = () => {
    const paymentMethod = registrationData?.paymentMethod;
    if (activeStep === 0 && tournament.isDuprRequired && user?.duprId) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
          <Typography variant="font13" sx={{ lineHeight: '14px', textAlign: 'start' }}>
            Your DUPR is connected
          </Typography>
          {duprRankings?.getRatingByDUPRId?.result && <PlayerRatings player={duprRankings?.getRatingByDUPRId?.result} />}
          <CustomButton shape="default" size="medium" variant="primary" onClick={validateAndProceed} sx={{ width: '100%', fontWeight: 500 }}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </CustomButton>{' '}
        </Box>
      );
    }
    if (activeStep === 0 && tournament?.isDuprRequired && !user?.duprId) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography variant="font13" sx={{ lineHeight: '14px', textAlign: 'start' }}>
              The tournament requires participants to have a DUPR rating. It looks like you haven't provided yours yet.To ensure a successful connection, make sure your Tournated account email matches
              your DUPR account email. You may need to log out of the DUPR website first, then reconnect for the authorization to work.
            </Typography>
            <InfoIcon
              style={{
                color: '#86909F',
                cursor: 'pointer',
                // fontSize: '10.5px',
                height: '15px',
                width: '15px',
              }}
              onClick={() => {
                window.open('https://dupr.zendesk.com/hc/en-us/articles/26125452528404-How-to-create-an-account-on-DUPR');
              }}
            />
          </Box>
          <DuprConnection duprData={duprData} setDuprData={setDuprData} isResponsive={false} />
        </Box>
      );
    }

    if (activeStep === steps.length - 2) {
      const showOnlineButton = paymentMethod === 'online' || paymentMethod === 'both';
      const showOnsiteButton = paymentMethod === 'onsite' || paymentMethod === 'both';

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            '@media (max-width: 560px)': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tournament?.termsAndConditions && !isEmptyContent(tournament.termsAndConditions) && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={<CustomCheckbox sx={{ fontSize: '20px' }} name="isMatchInProgress" checked={termAccepted} onChange={handleCheckboxChange} />}
                sx={{ '& .MuiTypography-root': { fontSize: '11px' } }}
                label="By registering for this event, you agree to the terms and conditions set forth by the tournament organizer."
              />
              <InfoIcon sx={{ fontSize: '20px', color: '#86909F', cursor: 'pointer' }} onClick={() => setOpenTerms(true)} />
            </Box>
          )}
          <TermsConditionsModal termsModalOpen={openTerms} setTermsModalOpen={setOpenTerms} terms={tournament?.termsAndConditions} />
          {!allFeesZeroOrNull && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {showOnlineButton && !tournament?.isManagerPayingServiceFee && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mt: 0.3 }}>
                  <Typography variant="font18" sx={{ lineHeight: '12px', color: '#0A25408C' }}>
                    Processing fee:
                  </Typography>
                  {calculationsLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Typography variant="font15" sx={{ lineHeight: '12px', color: '#0A2540', whiteSpace: 'nowrap' }}>
                        {getCurrencySymbol(tournament?.currency)}
                        {data?.calculatePayments?.totalProcessingFee?.toFixed(2)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2, mt: 0.3 }}>
                <Typography variant="font18" sx={{ lineHeight: '12px', color: '#0A25408C' }}>
                  Total:
                </Typography>
                {calculationsLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {data?.calculatePayments?.totalPayable === data?.calculatePayments?.totalOnSite && data?.calculatePayments?.totalPayable > 0 ? (
                      formatFee(data?.calculatePayments?.totalPayable, CreditCardIcon)
                    ) : (
                      <>
                        {showOnlineButton && data?.calculatePayments?.totalPayable ? formatFee(data?.calculatePayments?.totalPayable, CreditCardIcon) : ''}
                        {showOnsiteButton && data?.calculatePayments?.totalOnSite && data?.calculatePayments?.totalPayable !== data?.calculatePayments?.totalOnSite
                          ? formatFee(data?.calculatePayments?.totalOnSite, CashIcon)
                          : ''}
                      </>
                    )}
                  </Box>
                )}
              </Box>

              {!tournament?.isManagerPayingServiceFee && (
                <CustomAccordion
                  summaryText="Service and processing fees apply to all non-free registrations. If these fees are not included in the"
                  detailsText="entry fee, they must be paid separately by the tournament participant(s)."
                />
              )}
            </Box>
          )}
          {allFeesZeroOrNull ? (
            renderPaymentButton('onsite', totalOnsiteFee || totalFee, 'Register')
          ) : (
            <>
              {showOnlineButton && renderPaymentButton('online', totalFee, 'Register & Pay')}
              {showOnsiteButton && renderPaymentButton('onsite', totalOnsiteFee || totalFee, 'Register')}
            </>
          )}
        </Box>
      );
    }

    if (activeStep === steps.length - 1) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            '@media (max-width: 560px)': {
              flexWrap: 'wrap',
            },
          }}
        >
          <CustomButton
            shape="default"
            size="medium"
            variant="transparent"
            sx={{ fontWeight: 500 }}
            onClick={() => {
              const eventTitle = tournament?.title;
              const startDate = formatDateToGoogleCalendarUTC(tournament?.startDate);
              const endDate = formatDateToGoogleCalendarUTC(tournament?.endDate);
              const description = tournament?.description;
              const location = tournament?.address;
              const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventTitle)}&dates=${startDate}/${endDate}&details=${encodeURIComponent(
                description,
              )}&location=${encodeURIComponent(location)}&sf=true&output=xml`;

              window.open(googleCalendarUrl, '_blank');
            }}
          >
            <img src="/images/googleCalendar.svg" style={{ width: '24px', height: '24px' }} alt="Google Calendar" />
            <Typography
              sx={{
                color: '#0A2540',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '20px',
              }}
            >
              Add to Google Calendar
            </Typography>
          </CustomButton>
          <CustomButton
            shape="default"
            size="medium"
            variant="primary"
            sx={{ fontWeight: 500 }}
            onClick={() => {
              navigate('/mycareer?tab=entries');
            }}
          >
            Go to My Entries
          </CustomButton>
        </Box>
      );
    }

    return (
      <CustomButton shape="default" size="medium" variant="primary" onClick={validateAndProceed} sx={{ width: '100%', fontWeight: 500 }}>
        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
      </CustomButton>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: activeStep === steps.length - 2 ? 'column-reverse' : 'row',
        justifyContent: 'space-between',
        gap: '10px',
        mt: 2,
        mb: 2,
      }}
    >
      {renderStepButtons()}
    </Box>
  );
};
