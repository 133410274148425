import { gql } from "@apollo/client";

const UPDATE_PLAYER_ROLE = gql`
  mutation updateTeamMember($id: Int!, $role: String) {
    updateTeamMember(updateTeamMemberInput: { id: $id, role: $role }) {
      id
    }
  }
`;

export default UPDATE_PLAYER_ROLE;
