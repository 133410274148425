import React from 'react';
import { Box, Stack, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import { useQuery } from '@apollo/client';
import { ALL_TOURNAMENTS } from 'src/graphql/queries';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Overview from './Overview/Overview';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import CustomSchedule from '../../../CustomComponents/CustomSchedule';
import CustomSection from '../../../layout/CustomSection';
import Timeline from './Timeline';
import Participants from './Participants';
import Categories from './Categories';
import Location from './Location';
import FeaturedAthletes from './FeaturedAthletes';
import { useTournament } from '../TournamentPage';
import Contacts from './Contacts';

const General = () => {
  const { tournament } = useTournament();
  const { t } = useTranslation();
  useQuery(ALL_TOURNAMENTS, {
    variables: {
      page: 1,
      limit: 5,
      federationId: tournament?.league?.league?.owner?.federation?.id,
      filter: {
        isUpcomingAndProgress: true,
        status: 'active',
      },
      platform: parseInt(process.env.REACT_APP_PLATFORM_ID),
    },
  });

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const events = (tournament?.events ? JSON.parse(tournament.events) : []).map((event) => ({
    Id: event.id,
    Subject: event.title,
    StartTime: moment(event.start).toDate(),
    EndTime: moment(event.end).toDate(),
    IsAllDay: false,
    CategoryColor: event.color,
  }));
  const startDate = tournament?.startDate ? moment(tournament.startDate, 'YYYY-MM-DD') : null;
  const endDate = tournament?.endDate ? moment(tournament.endDate, 'YYYY-MM-DD') : null;
  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const componentsMap = {
    overview: <Overview />,
    timeline: <Timeline />,
    participants: <Participants />,
    categories: <Categories />,
    location: <Location />,
    featuredAthletes: <FeaturedAthletes />,
    contacts: <Contacts />,
  };

  const layout: Record<'desktop' | 'mobile' | 'sidebar', (keyof typeof componentsMap)[]> = {
    desktop: ['timeline', 'overview'],
    mobile: ['timeline', 'participants', 'overview', 'categories', 'featuredAthletes', 'contacts', 'location'],
    sidebar: ['participants', 'categories', 'contacts', 'location', 'featuredAthletes'],
  };

  return (
    <Box sx={{ paddingTop: '5px' }}>
      <Stack gap="12px">
        <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden', gap: '22px', flexDirection: { xs: 'column', md: 'row' } }}>
          <Stack sx={{ flex: 1, overflow: 'hidden', gap: '12px' }}>
            {layout[isCompact ? 'mobile' : 'desktop'].map((item) => componentsMap[item])}
            {tournament?.events && (
              <CustomSection title={t('Schedule')}>
                <CustomPaperCard>
                  <CustomSchedule
                    data={events}
                    height={isMobile ? '400px' : '750px'}
                    startDate={startDate ? new Date(startDate.toISOString()) : undefined}
                    endDate={endDate ? new Date(endDate.toISOString()) : undefined}
                    selectedDate={tournament?.startDate ? new Date(tournament.startDate.date()) : new Date()}
                  />
                </CustomPaperCard>
              </CustomSection>
            )}
          </Stack>
          {isCompact ? null : (
            <Stack gap="22px" sx={{ width: { md: '30%' }, maxWidth: { md: '360px' } }}>
              {layout.sidebar.map((item) => componentsMap[item])}
            </Stack>
          )}
        </Box>
        {/*<Box sx={{ marginTop: '15px' }}>*/}
        {/*  /!*<SliderWrapper*!/*/}
        {/*  /!*  length={featuredTournaments?.allTournamentsList?.tournaments?.filter((item) => item?.id != id)?.length}*!/*/}
        {/*  /!*  heading={t('Other tournaments')}*!/*/}
        {/*  /!*  button={t('viewAll')}*!/*/}
        {/*  /!*  toShow={auth.isDrawerOpen ? 3.2 : 4}*!/*/}
        {/*  /!*  link="/tournaments"*!/*/}
        {/*  /!*  isFramed={false}*!/*/}
        {/*  /!*>*!/*/}
        {/*  /!*  {() => {*!/*/}
        {/*  /!*    return featuredTournaments?.allTournamentsList?.tournaments?.filter((item) => item?.id != id)?.length > 0 ? (*!/*/}
        {/*  /!*      featuredTournaments?.allTournamentsList?.tournaments?.filter((item) => item?.id != id)?.map((tournament) => <FeaturedTournamentCard {...tournament} isFramed={false} />)*!/*/}
        {/*  /!*    ) : featuredLoading ? (*!/*/}
        {/*  /!*      TournamentSkeletonLimit.map((count) => <FeaturedTournamentSkeleton key={count?.id} />)*!/*/}
        {/*  /!*    ) : (*!/*/}
        {/*  /!*      <NoDataFound text={t('No other tournament found')} sx={{ margin: '70px' }} />*!/*/}
        {/*  /!*    );*!/*/}
        {/*  /!*  }}*!/*/}
        {/*  /!*</SliderWrapper>*!/*/}
        {/*</Box>*/}
      </Stack>
    </Box>
  );
};

export default General;
