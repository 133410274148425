import { gql } from "@apollo/client";

const CREATE_STRIPE_SESSION = gql`
  mutation createStripeSession(
    $userId:Int
    $name: String 
    $email: String 
    $productId: String!
    $priceId: String!
    $successUrl: String!
    $quantity:Int
    ) {
    createStripeSession(createStripeSessionInput: { 
      userId:$userId, 
      name:$name,
      email: $email, 
      productId: $productId, 
      priceId: $priceId, 
      successUrl:$successUrl,
      quantity: $quantity
     }) 
  }
`;

export default CREATE_STRIPE_SESSION;
