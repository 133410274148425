import { gql } from '@apollo/client';

const LEAGUES = gql`
  query leagues($filter: ListLeagueInput, $page: Int, $limit: Int) {
    leagues(page: $page, limit: $limit, filter: $filter) {
      title
      enableLicense
      id
      type
      status
      sports {
        id
        title
      }
      email
      website
      logo
      tournaments {
        id
      }
      athlete {
        id
      }
      owner {
        federation {
          id
          user {
            id
            name
            surname
            avatar
          }
        }
      }
    }
  }
`;

export default LEAGUES;
