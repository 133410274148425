import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../../../CustomComponents/CustomModal';
import CustomButton from '../../../../CustomComponents/CustomButton';
import { useTournament } from '../../TournamentPage';
import CustomPaperCard from '../../../../CustomComponents/CustomPaperCard';

const Description = () => {
  const { t } = useTranslation();
  const { tournament, loading } = useTournament();
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(false);

  const doesDescriptionExist = !!tournament?.description?.replace(/<\/?[^>]+(>|$)/g, '') && !tournament.description.toLowerCase().includes('null');
  const description = doesDescriptionExist ? tournament?.description?.replaceAll('<p><br></p>', '<br>') : undefined;
  const descriptionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (descriptionRef.current) {
        setIsDescriptionOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsDescriptionOverflowing, description, descriptionRef]);

  if (loading) return <CustomPaperCard skeleton sx={{ width: '100%', height: '300px' }} />;

  if (!description) return null;

  return (
    <>
      <Box sx={{ height: '1px', width: '100%', background: '#DFDFDF' }} />
      <Stack gap="14px" sx={{ position: 'relative' }}>
        <Typography variant="font20">{t('Description')}</Typography>
        <Box
          ref={descriptionRef}
          sx={{
            overflow: 'hidden',
            maxHeight: '200px',
            '& > *': {
              padding: 0,
              margin: 0,
              width: '100%',
              overflow: 'hidden',
              fontSize: '13px',
              '& p': {
                margin: '0px',
                padding: '0px',
                lineHeight: '22.68px',
              },
              '& li': {
                margin: '0px',
                padding: '0px',
                lineHeight: '22.68px',
              },
              'h1, h2, h3': {
                margin: '0px',
              },
            },
            WebkitMaskImage: isDescriptionOverflowing ? 'linear-gradient(to top, transparent, black 90% )' : 'none',
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {isDescriptionOverflowing && (
          <CustomModal
            triggerEl={
              <CustomButton
                size="medium"
                shape="chip"
                variant="secondary"
                sx={{
                  borderColor: '#86909F',
                  background: '#E6E9EC',
                  color: '#86909F',
                  gap: '4px',
                  paddingInline: '16px',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {t('read_more')}
              </CustomButton>
            }
          >
            <Box
              sx={{
                maxWidth: '700px',
                overflow: 'auto',
                '& p': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                '& li': {
                  margin: '0px',
                  padding: '0px',
                  lineHeight: '22.68px',
                },
                'h1, h2, h3': {
                  margin: '0px',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: doesDescriptionExist ? description : t('Description is missing'),
              }}
            />
          </CustomModal>
        )}
      </Stack>
    </>
  );
};

export default Description;
