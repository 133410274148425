import { gql } from '@apollo/client';

const REGISTER = gql`
  mutation signUp(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $user_sports: [Int!]
    $role: String!
    $nation: String
    $phone: String
    $dob: String
    $gender: String
    $national_fisher_id: String
    $has_fisher_id: Boolean
    $no_fisher_id_doc: Upload
    $no_fisher_id_reason: String
    $city: String
    $citizenship: String
    $fideId: String
    $ipinId: String
    $duprId: String
    $teId: String
    $atpId: String
    $wtaId: String
    $ltsU10Id: String
    $pdlId: String
    $additionalUserRole: String
    $redirectURL: String
    $platformId: Float
  ) {
    signUp(
      redirectURL: $redirectURL
      platformId: $platformId
      createUserInput: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        user_sports: $user_sports
        role: $role
        nation: $nation
        phone: $phone
        dob: $dob
        gender: $gender
        national_fisher_id: $national_fisher_id
        has_fisher_id: $has_fisher_id
        no_fisher_id_doc: $no_fisher_id_doc
        no_fisher_id_reason: $no_fisher_id_reason
        city: $city
        fideId: $fideId
        citizenship: $citizenship
        ipinId: $ipinId
        duprId: $duprId
        teId: $teId
        atpId: $atpId
        wtaId: $wtaId
        ltsU10Id: $ltsU10Id
        pdlId: $pdlId
        additionalUserRole: $additionalUserRole
      }
    ) {
      email
      accessToken
      name
      surname
      role
      gender
      dob
      id
      nation
      avatar
      phone
      street
      city
      facebook
      instagram
      website
      gtw_points
      preferred_language
      user_coach {
        id
        user {
          id
        }
        coaches {
          id
          user {
            id
            email
          }
        }
      }
      about
      createdAt
      user_address
      linkedIn
      youtube
      tiktok
      citizenship
      fideId
      ltsU10Id
      duprId
      ipinId
      teId
      atpId
      wtaId
      pdlId
      additionalUserRole
      user_sports {
        id
        sport {
          id
          title
        }
      }
    }
  }
`;

export default REGISTER;
