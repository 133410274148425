import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';

interface CustomCheckboxProps extends CheckboxProps {
  rounded?: boolean;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({ rounded, ...props }) => {
  return (
    <Checkbox
      {...props}
      icon={rounded ? <RadioButtonUnchecked sx={{ fontSize: props.sx['fontSize'] }} /> : <CheckBoxOutlineBlank sx={{ fontSize: props.sx['fontSize'] }} />}
      checkedIcon={rounded ? <CheckCircle sx={{ fontSize: props.sx['fontSize'] }} /> : <CheckBox sx={{ fontSize: props.sx['fontSize'] }} />}
    />
  );
};

export default CustomCheckbox;
