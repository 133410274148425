import { gql } from '@apollo/client';

const CREATEPLATFORM = gql`
  mutation CreatePlatform(
    $email: String
    $organizationName: String
    $organizationType: String
    $organizationAbbreviation: String
    $contactEmail: String
    $contactPhone: String
    $website: String
    $logo: Upload
    $title: String!
    $platformType: String!
    $country: String!
    $sports: [Int!]
    $language: String
    $timeZone: String
    $currency: String
    $description: String
    $alreadyDomain: Boolean
    $domain: String
    $companyName: String
    $registrationNumber: String
    $fullAddress: String
    $companyEmail: String
    $needLanding: Boolean
    $needIntegration: Boolean
    $onlinePayment: Boolean
    $licenseMemberships: Boolean
    $onlinePayments: Boolean
    $onlineBookingCourts: Boolean
    $liveStreaming: Boolean
    $liveScoring: Boolean
    $serviceProvider: String
    $primaryColor: String
    $secondaryColor: String
    $darkLogo: Upload
    $lightLogo: Upload
    $coverPhoto: Upload
    $yourRequest: String
    $uploadFile: Upload
    $instagram: String
    $signeeFullName: String!
    $signeeRole: String!
  ) {
    createPlatform(
      createPlatformInput: {
        title: $title
        platformType: $platformType
        country: $country
        sports: $sports
        language: $language
        timeZone: $timeZone
        currency: $currency
        description: $description
        alreadyDomain: $alreadyDomain
        primaryColor: $primaryColor
        secondaryColor: $secondaryColor
        uploadFile: $uploadFile
        yourRequest: $yourRequest
        coverPhoto: $coverPhoto
        lightLogo: $lightLogo
        darkLogo: $darkLogo
        serviceProvider: $serviceProvider
        liveScoring: $liveScoring
        liveStreaming: $liveStreaming
        onlineBookingCourts: $onlineBookingCourts
        onlinePayments: $onlinePayments
        licenseMemberships: $licenseMemberships
        onlinePayment: $onlinePayment
        needIntegration: $needIntegration
        needLanding: $needLanding
        companyEmail: $companyEmail
        fullAddress: $fullAddress
        registrationNumber: $registrationNumber
        companyName: $companyName
        domain: $domain
        signeeFullName: $signeeFullName
        signeeRole: $signeeRole
      }
      createFederationInput: {
        email: $email
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        website: $website
        organizationType: $organizationType
        logo: $logo
        instagram: $instagram
      }
    ) {
      id
      title
      currency
      description
    }
  }
`;

export default CREATEPLATFORM;
