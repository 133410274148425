import { getTimeZones } from '@vvo/tzdb';

export const getTimezoneName = (timeZoneString: string): string | null => {
  const regex = /(?:\([^)]+\)\s*)?([A-Za-z]+(?:\/[A-Za-z_]+)+)/;
  const match = timeZoneString.match(regex);

  return match ? match[1].trim() : null;
};

export const getTimezone = (timeZoneString?: string) => {
  const tz = timeZoneString ? getTimezoneName(timeZoneString) : 'UTC';
  const fallbackTz = getTimeZones({ includeUtc: true }).find((zone) => zone.group.includes('UTC'));
  const foundTz = getTimeZones({ includeUtc: true }).find((zone) => zone.group.includes(tz));
  return foundTz ?? fallbackTz;
};
