import { gql } from "@apollo/client";

const CREATE_TEAM_REG = gql`
  mutation createTeamReg(
    $tournament: Int
    $status: String
    $categoryId: Int
    $user: [String!]
    $type: String
    $team:Int
  ) {
    createTeamRegistrationRequest(
      createTournamentRegistrationInput: {
        tournament: $tournament
        status: $status
        type:$type
        categoryId: $categoryId
        user: $user
        team:$team
      }
    ) {
      id
      status
    }
  }
`;

export default CREATE_TEAM_REG;
