import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import type { ChangeEvent, Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import Scrollbar from 'src/components/Scrollbar';
import UserNameCell from './pairingsComponents/UserNameCell';
import { Pairings } from 'src/types/pairings';
import { ReactComponent as Knight } from 'src/assets/images/knight.svg';
import CustomDropDown from 'src/components/shared/CustomDropDown';
import ResponsiveFiltersPopover from 'src/components/shared/ResponsiveFiltersPopover';
import Loading from 'src/components/Loading';
import NoDataFound from '../NoDataFound';
import { BlackButton, WhiteButton } from 'src/components/shared/Buttons';
import { downloadFile, getRating, getTimeControl } from 'src/utils/helperFunction';
import PairingsCard from './pairingsComponents/PairingsCard';
import toast from 'react-hot-toast';
import { Print } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { PDF_GENERATION_FOR_PAIRING } from 'src/graphql/queries';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

interface EntrieListTableProps {
  entriesListData: Pairings[];
  refreshTournamentData?: any;
  setType: Dispatch<SetStateAction<string>>;
  type: string;
  tournamentData: any;
  allTournamentCategories: any;
  rounds: { id: number; title: string }[];
  categoryID: number;
  setCategoryID: Dispatch<SetStateAction<number>>;
  round: number;
  roundTitle: string;
  setRound: Dispatch<SetStateAction<number>>;
  timeControl: string;
  setTimeControl: Dispatch<SetStateAction<string>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isHide?: boolean;
}

type Sort = 'all' | 'updatedAt|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'All',
    value: 'all',
  },
];

const useStyles = makeStyles((theme: any) => ({
  topBarButton: {
    height: '42px',
    mt: -0.3,
    backgroundColor: '#FFFF',
    color: '#425466',
    border: `1px solid #EDF1F6`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  dropDown: {
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid #EDF1F6',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '42px',
    width: '250px',
    backgroundColor: '#FFFF',
  },
  topBarDropdown: {
    // backgroundColor: "#FFFF",
    // height: "42px",
    color: '#425466',
    '& .MuiSelect-select': {
      padding: '5px 14px 5px 5px',
    },
    '& .MuiNativeSelect-select': {
      padding: '0 14px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  topBarSearch: {
    backgroundColor: '#FFFF',
    '& .MuiInputBase-input': {
      paddingLeft: '8px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-root': {
      height: '42px',
      borderRadius: '6px',
      border: '1px solid #EFEFF0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  moreIcon: {
    color: '#425466',
  },
  pts: {
    width: 'max-content',
    fontWeight: 500,
    padding: '3px 5px',
    background: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    color: '#7B7B7B',
  },
  body1: {
    width: 'max-content',
    color: '#193660',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    display: 'flex !important',
    alignItems: 'center',
  },
  body1Head: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 'normal',
    whiteSpace: 'pre',
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: '23.35px',
  },
}));

const applyFilters = (entriesListData: Pairings[], query: string, filters: any): Pairings[] =>
  entriesListData?.filter((entries) => {
    let matches = true;

    if (query) {
      const searchWords = query.toLowerCase().split(' ');
      const properties = ['name', 'surname'];
      let containsQuery = false;

      // Iterate over each entry in the array
      ['entry1', 'entry2'].forEach((entryKey) => {
        properties?.forEach((property) => {
          if (searchWords?.every((word) => entries[entryKey]?.users?.some((user) => user?.user[property]?.toLowerCase()?.includes(word)))) {
            containsQuery = true;
          }
        });
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && entries[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const descendingComparator = (a: Pairings, b: Pairings, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: Pairings, b: Pairings) => descendingComparator(a, b, orderBy) : (a: Pairings, b: Pairings) => -descendingComparator(a, b, orderBy);

const applySort = (customers: Pairings[], sort: Sort): Pairings[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const PairingsListTable: FC<EntrieListTableProps> = (props) => {
  const {
    entriesListData,
    setType,
    type,
    refreshTournamentData,
    tournamentData,
    allTournamentCategories,
    rounds,
    categoryID,
    setCategoryID,
    timeControl,
    setTimeControl,
    round,
    roundTitle,
    setRound,
    isHide,
    loading,
    setLoading,
    ...other
  } = props;
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [query, setQuery] = useState<string>('');
  const [piece, setPiece] = useState(0);
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);

    if (newLimit === -1) {
      setLimit(filteredEntries.length);
    } else {
      setLimit(newLimit);
    }
    setPage(0);
    setQuery('');
  };
  const filteredEntries = applyFilters(entriesListData, query, filters);
  const sortedEntries = applySort(filteredEntries, sort);

  const getScores = (pts: string) => {
    switch (pts) {
      case '1:0':
        return '1 : 0';
      case '0.5:0.5':
        return '1/2 : 1/2';
      case '0:1':
        return '0 : 1';
      case '0:0':
        return '0 : 0';
      case '0.5:0':
        return '1/2 : 0';
      case '0:0.5':
        return '0 : 1/2';
      default:
        return '';
    }
  };

  const { id } = useParams();

  const [downloadPDF] = useLazyQuery(PDF_GENERATION_FOR_PAIRING, {
    variables: {
      id: String(id),
      tournamentCategory: String(categoryID),
      timeControl: getTimeControl(timeControl),
      pairingId: String(round),
    },
    fetchPolicy: 'network-only',
  });

  const handleDownload = async (url) => {
    toast.promise(
      downloadFile(url, `${roundTitle}_pairings`),

      {
        loading: t('Downloading...'),
        success: (res) => {
          return t(`PDF Downloaded Successfully!`);
        },
        error: (err) => {
          return err.message;
        },
      }
    );
  };

  const handleDownloadPrint = (event: MouseEvent<HTMLButtonElement>) => {
    toast.promise(downloadPDF(), {
      loading: t('Generating PDF...'),
      success: (res: any) => {
        handleDownload(res?.data?.pdfGenerationForPairing);
        return t(`PDF Generated Successfully!`);
      },
      error: (err) => {
        return err.message;
      },
    });
  };

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  if (loading) {
    return <Loading height="50vh" />;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          my: '10px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="heading17" className={classes.title}>
          {t('Pairings')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            mt: { xs: '10px', sm: '0px' },
          }}
        >
          <WhiteButton
            sx={{
              border: '1px solid #E0E0E0',
              textTransform: 'uppercase',
              height: { xs: '35px !important', sm: 'auto' },
            }}
            variant="contained"
            onClick={handleDownloadPrint}
          >
            <Print fontSize="small" />
          </WhiteButton>
          <CustomDropDown
            fullWidth
            labelText={t('Category:')}
            name="category"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            sx={{
              height: { xs: '35px !important', sm: '42px' },
              '& .MuiOutlinedInput-root': {
                fontSize: { xs: '12px', sm: '14px' },
              },
            }}
            onChange={(e) => {
              setLoading(true);
              setCategoryID(parseInt(e.target.value));
              const findSelectedCategory = allTournamentCategories.find((category) => category.category.id === parseInt(e.target.value));
              if (findSelectedCategory) {
                setType(findSelectedCategory.category.category.type);
                setTimeControl(findSelectedCategory.category.category.timeControl);
              }
            }}
            value={categoryID}
          >
            {allTournamentCategories?.length ? (
              <Fragment>
                {allTournamentCategories?.map((category) => (
                  <option key={category.category.id} value={category.category.id}>
                    {category.category.category.name + ' ' + `(${category.count})`}
                  </option>
                ))}
              </Fragment>
            ) : (
              <option disabled>{t('No category found')}</option>
            )}
          </CustomDropDown>

          <CustomDropDown
            sx={{
              height: { xs: '35px !important', sm: '42px' },
              '& .MuiOutlinedInput-root': {
                fontSize: { xs: '12px', sm: '14px' },
              },
            }}
            fullWidth
            name="round"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            onChange={(e) => {
              setRound(parseInt(e.target.value));
            }}
            value={round}
          >
            {rounds?.length > 0 ? (
              <Fragment>
                {rounds.map((round) => (
                  <option key={round.id} value={round.id}>
                    {round.title}
                  </option>
                ))}
              </Fragment>
            ) : (
              <option disabled>{t('No Rounds found')}</option>
            )}
          </CustomDropDown>
          <ResponsiveFiltersPopover position="space-between" startElements={1}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <ReactSVG
                      src="/images/search-new.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('style', 'margin-top: 5px;');
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t('Search')}
              value={query}
              variant="outlined"
              className={classes.topBarSearch}
            />
            {/* <CustomDropDown
          fullWidth
          // labelText="Discipline: "
          name="discipline"
          select
          SelectProps={{ native: true }}
          variant="outlined"
          onChange={(e) => {
            setCategoryID(e.target.value);
            const findSelectedCategory = allTournamentCategories.find(
              (category) => category.category.id === Number(e.target.value)
            );
            if (findSelectedCategory) {
              setType(findSelectedCategory.category.category.type);
            }
          }}
          value={categoryID}
        >
          {allTournamentCategories?.length ? (
            <Fragment>
              {allTournamentCategories?.map((category) => (
                <option key={category.category.id} value={category.category.id}>
                  {category.category.category.name +
                    " " +
                    `(${category.count})`}
                </option>
              ))}
            </Fragment>
          ) : (
            <option disabled>No category found</option>
          )}
        </CustomDropDown> */}

            {/* <CustomDropDown
          fullWidth
          // labelText="Classification: "
          name="classification"
          select
          SelectProps={{ native: true }}
          variant="outlined"
          onChange={(e) => {
            setCategoryID(e.target.value);
            const findSelectedCategory = allTournamentCategories.find(
              (category) => category.category.id === Number(e.target.value)
            );
            if (findSelectedCategory) {
              setType(findSelectedCategory.category.category.type);
            }
          }}
          value={categoryID}
        >
          {allTournamentCategories?.length ? (
            <Fragment>
              {allTournamentCategories?.map((category) => (
                <option key={category.category.id} value={category.category.id}>
                  {category.category.category.name +
                    " " +
                    `(${category.count})`}
                </option>
              ))}
            </Fragment>
          ) : (
            <option disabled>No category found</option>
          )}
        </CustomDropDown> */}
          </ResponsiveFiltersPopover>
        </Box>
      </Box>

      {sortedEntries?.length ? (
        <Card
          {...other}
          sx={{
            boxShadow: 'none',
            border: '1px solid #EDF1F6',
            borderRadius: '6px',
          }}
        >
          {!isSM ? (
            <Box
              sx={{
                padding: '12px',
                height: 'calc(100vh - 180px)',
                overflow: 'auto',
              }}
            >
              <Grid container padding="20px 20px" sx={{ width: { xs: '700px', md: '100%' } }}>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    background: '#F6F6F6',
                    color: '#0A2540',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                >
                  <Knight className="svg-current-color" style={{ marginBottom: '3px' }} />
                  {t('White')}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="body1" sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    {roundTitle}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#fff',
                    backgroundColor: '#000',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                >
                  <Knight className="svg-current-color" style={{ marginBottom: '3px' }} />
                  {t('Black')}
                </Grid>
              </Grid>
              <Table>
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: -15,
                    background: '#fff',
                    zIndex: 1,
                    borderBottom: '1px solid #EDF1F6',
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.body1Head}>{t('NR')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Name')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Rating')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Pts')}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography className={classes.body1Head}>{t('Score')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Pts')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Name')}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={classes.body1Head}>{t('Rating')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedEntries?.map((item, index) => {
                    const sortingNumber = page * limit + index + 1;
                    return (
                      <TableRow hover key={item.id} className="entry-row">
                        <TableCell>
                          <Typography className={classes.body1}>{sortingNumber}</Typography>
                        </TableCell>
                        <TableCell>
                          <UserNameCell user={item?.entry1?.users?.[0]?.user} fideTitle={item?.entry1?.users?.[0]?.fideTitle} />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className={classes.body1}>
                            {getRating(item?.entry1?.users?.[0], timeControl)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className={classes.body1}>
                            {item?.entry1?.pts}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '-webkit-fill-available' }}>
                          {getScores(item?.score)}
                        </TableCell>

                        <TableCell>
                          <Typography variant="body1" className={classes.body1}>
                            {item?.entry2?.pts}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <UserNameCell user={item?.entry2?.users?.[0]?.user} fideTitle={item?.entry2?.users?.[0]?.fideTitle} />
                        </TableCell>

                        <TableCell>
                          <Typography variant="body1" className={classes.body1}>
                            {getRating(item?.entry2?.users?.[0], timeControl)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <PairingsCard entries={sortedEntries} timeControl={timeControl} />
          )}
        </Card>
      ) : (
        <Box sx={{ mt: 2 }}>
          <NoDataFound text={t('There is no available pairings!')} sx={{ margin: '50px 0' }} />
        </Box>
      )}
    </React.Fragment>
  );
};

PairingsListTable.propTypes = {
  entriesListData: PropTypes.array.isRequired,
};

export default PairingsListTable;
