import React from 'react';
import { Box, Typography, Stack } from '@material-ui/core';
import GetFlag from 'src/utils/getFlags';
import { useNavigate } from 'react-router';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';

const Top5AthletesList = ({ athletes, loading }) => {
  const navigate = useNavigate();

  function getUserRank(item) {
    if (item?.tournament?.league?.league?.sports?.id === 7) {
      switch (JSON.parse(item?.categoryId?.category?.timeControl || '{}')?.title) {
        case 'Rapid':
          return item?.rapidElo != null ? `#${item.rapidElo}` : null;
        case 'Standard':
          return item?.standardElo != null ? `#${item.standardElo}` : null;
        case 'Blitz':
          return item?.blitzElo != null ? `#${item.blitzElo}` : null;
        default:
          return null;
      }
    } else if (item?.tournament?.league?.league?.sports?.id === 6) {
      return item?.duprRating != null ? `#${item.duprRating}` : null;
    } else {
      return item?.rank != null ? `#${item.rank}` : null;
    }
  }

  return (
    <Stack gap="12px">
      {(athletes?.tournamentTopFivePlayers ?? Array.from({ length: 5 }))?.map((rank, i) => (
        <CustomPaperCard
          skeleton={loading}
          key={i + 1}
          sx={{
            height: '60px',
            display: 'flex',
            width: '100%',
            background: '#F9F9F9',
            color: i === 0 ? '#FF9D01' : i === 1 ? '#656565' : i === 2 ? '#FF6401' : '#86909f',
            borderColor: 'currentcolor',
            padding: '12px',
            alignItems: 'center',
            gap: '8px',
            opacity: i > 2 ? 0.3 : 1,
            transition: '0.2s all',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Box
            sx={{
              width: '18px',
              height: '18px',
              aspectRatio: '1/1',
              borderRadius: '999px',
              border: '1px solid',
              borderColor: 'currentcolor',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 500,
              }}
            >
              {i + 1}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Box sx={{ position: 'relative', height: '32px', width: '32px' }}>
              <CustomAvatar src={rank?.user?.avatar} size={32} seed={`${rank?.user?.name} ${rank?.user?.surname}`} />
              <GetFlag
                country={rank?.user?.nation}
                style={{
                  width: '15px',
                  height: '12px',
                  position: 'absolute',
                  left: '50%',
                  bottom: 0,
                  borderRadius: '3px',
                  transform: 'translate(-50%, 25%)',
                  border: '1px solid white',
                }}
              />
            </Box>
            <Stack sx={{ justifyContent: 'center', gap: '2px' }}>
              <Typography
                sx={{ color: '#0A2540', lineHeight: '100%', cursor: 'pointer' }}
                variant="font22"
                onClick={() => navigate(`/athlete/${rank?.user?.id}/${rank?.user?.name}-${rank?.user?.surname}?tab=overview`)}
              >
                {`${rank?.user?.name?.charAt(0).toUpperCase()}. ${rank?.user?.surname}`}
              </Typography>
              {getUserRank(rank) && (
                <Typography variant="font21" sx={{ color: '#666', lineHeight: '100%' }}>
                  {`${getUserRank(rank)} ${rank?.fideTitle ? rank?.fideTitle : ''}`}
                </Typography>
              )}
            </Stack>
          </Box>
        </CustomPaperCard>
      ))}
    </Stack>
  );
};

export default Top5AthletesList;
