import { gql } from '@apollo/client';

const EDITUSER = gql`
  mutation updateUser(
    $id: Float
    $name: String
    $surname: String
    $email: String
    $phone: String
    $gender: String
    $dob: String
    $nation: String
    $city: String
    $user_address: String
    $role: String
    $facebook: String
    $instagram: String
    $website: String
    $gtw_points: Float
    $preferred_language: String
    $avatar: Upload
    $about: String
    $citizenship: String
    $tiktok: String
    $youtube: String
    $linkedIn: String
    $fideId: String
    $ipinId: String
    $duprId: String
    $teId: String
    $atpId: String
    $wtaId: String
    $ltsU10Id: String
    $user_club_id: Float
    $coach_access: Boolean
    $club_access: Boolean
    $parent_access: Boolean
    $user_sports: [Int!]
    $pdlId: String
    $additionalUserRole: String
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        gender: $gender
        dob: $dob
        nation: $nation
        city: $city
        user_address: $user_address
        role: $role
        facebook: $facebook
        instagram: $instagram
        website: $website
        gtw_points: $gtw_points
        preferred_language: $preferred_language
        avatar: $avatar
        about: $about
        citizenship: $citizenship
        tiktok: $tiktok
        youtube: $youtube
        linkedIn: $linkedIn
        ipinId: $ipinId
        duprId: $duprId
        teId: $teId
        atpId: $atpId
        wtaId: $wtaId
        ltsU10Id: $ltsU10Id
        fideId: $fideId
        user_club_id: $user_club_id
        coach_access: $coach_access
        club_access: $club_access
        parent_access: $parent_access
        user_sports: $user_sports
        pdlId: $pdlId
        additionalUserRole: $additionalUserRole
      }
    ) {
      email
      accessToken
      name
      surname
      role
      user_sports {
        id
        sport {
          id
          title
        }
      }
      gender
      dob
      id
      nation
      avatar
      phone
      street
      city
      facebook
      instagram
      website
      gtw_points
      preferred_language
      user_parents {
        id
        parent {
          id
          name
          email
        }
      }
      user_club_id {
        id
        clubName
      }
      coach_access
      club_access
      parent_access
      user_coach {
        id
        user {
          id
        }
        coaches {
          id
          user {
            id
            email
          }
        }
      }
      about
      createdAt
      user_address
      linkedIn
      youtube
      tiktok
      citizenship
      ipinId
      fideId
      teId
      atpId
      wtaId
      ltsU10Id
      duprId
      pdlId
      additionalUserRole
      plans {
        id
        plan
      }
      stripe_payments {
        productId
        status
      }
    }
  }
`;

export default EDITUSER;
