import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

const useStyles = makeStyles((theme) => ({
  pill: {
    padding: '8px 16px',
    height: '34px',
    border: '1px solid #EFEFF0',
    borderRadius: '30px',
    background: '#FFF',
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  selectedPill: {
    padding: theme.spacing(0.3, 1.7),
    borderRadius: '30px',
    border: '1px solid #00D010',
    background: 'rgba(0, 208, 16, 0.08)',
  },
  text: {
    color: '#1B3861',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    // lineHeight:0.5
  },
  selectedText: {
    color: '#00D010',
    fontSize: '12px',
    fontFamily: 'Inter',
    // lineHeight:0.5
  },
  clockIcon: {
    padding: '0px 0 0 0',
  },
  activeText: {
    color: '#00D010',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  activePill: {
    display: 'flex',
    // padding: "4px 16px",
    alignItems: 'flex-start',
    borderRadius: '23px',
    border: '1px solid #00D010',
  },
  inActiveText: {
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  inActivePill: {
    display: 'flex',
    padding: '4px 16px',
    alignItems: 'flex-start',
    borderRadius: '23px',
    border: '1px solid var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
  },
}));

type PillProps = {
  text: string;
  isCourt?: boolean;
  isHistory?: boolean;
};

const Pill = ({ text, isCourt, isHistory }: PillProps): JSX.Element => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(!selected);
  };

  const getWinner = (text) => {
    const [num1, num2] = text.split(':').map(Number);
    if (num1 > num2) {
      return true;
    } else if (num1 < num2) {
      return false;
    }
  };
  return (
    <React.Fragment>
      {!isHistory ? (
        <Box className={`${classes.pill} ${selected && !isCourt ? classes.selectedPill : ''}`} onClick={handleClick} sx={{ alignItems: 'center', padding: isCourt && '5px 16px !important' }}>
          {text == 'Ongoing' && !isCourt ? (
            <Typography
              className={`${classes.text} ${selected ? classes.selectedText : ''}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                padding: '1.5px 0',
                lineHeight: 0.9,
              }}
            >
              {selected ? <ReactSVG src="/images/clockg 1.svg" className={classes.clockIcon} /> : <ReactSVG src="/images/clock.svg" className={classes.clockIcon} />}
              {text}
            </Typography>
          ) : !isCourt ? (
            <Typography variant="body1" className={`${classes.text} ${selected ? classes.selectedText : ''}`}>
              {text || ''}
            </Typography>
          ) : isCourt ? (
            <Box>
              {getWinner(text) ? (
                <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  {' '}
                  <ReactSVG src="/images/winCup.svg" style={{ padding: '1px 0 0 0' }} />{' '}
                  <Typography variant="body1" className={classes.text} sx={{ display: 'flex', gap: '4px' }}>
                    {text}
                  </Typography>{' '}
                </span>
              ) : (
                <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  {' '}
                  <Typography variant="body1" className={classes.text} sx={{ display: 'flex', gap: '4px' }}>
                    {text}
                  </Typography>
                  <ReactSVG src="/images/winCup.svg" style={{ padding: '1px 0 0 0' }} />
                </span>
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        <Box className={`${classes.pill} ${text == 'active' || text == 'confirm' ? classes.activePill : classes.inActivePill} `} onClick={handleClick} sx={{ alignItems: 'center' }}>
          <Typography className={`${classes.activeText} ${text == 'active' || text == 'confirm' ? classes.activeText : classes.inActiveText}`}>{text || ''}</Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Pill;
