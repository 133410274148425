import { Box } from '@material-ui/core';
import React from 'react';
import MatchesScores from './MatchesScores'; // Assuming MatchesScores is imported

const getFormattedScore = (inputScore, match, scoreType) => {
  const scoresByRound =
    Array.isArray(inputScore) &&
    inputScore.reduce((acc, score) => {
      const round = score?.round;
      if (!acc[round]) {
        acc[round] = [];
      }
      acc[round].push(score);
      return acc;
    }, {});

  let formattedScoresJSX = [];

  if (typeof scoresByRound === 'object' && scoresByRound !== null) {
    Object.keys(scoresByRound).forEach((round, index) => {
      const scoresForRound = scoresByRound[round];

      const entry1 = scoresForRound[0];
      const entry2 = scoresForRound[1];

      if (entry1 && entry2) {
        const parsedScores = {
          firstArray: [entry1.score],
          secondArray: [entry2.score],
        };

        // Determine which entry is match.entry1 and match.entry2
        const isEntry1Match1 = entry1?.entry.id === match?.entry1?.id;
        const isEntry2Match2 = entry2?.entry?.id === match?.entry2?.id;

        // Render scores accordingly, displaying both scores
        formattedScoresJSX.push(
          <Box>
            {isEntry1Match1 ?
            <MatchesScores key={index} scores1={parsedScores?.firstArray} scores2={parsedScores?.secondArray} winner={true} />:
            <MatchesScores key={index} scores1={parsedScores?.secondArray} scores2={parsedScores?.firstArray} winner={false} />}
            {isEntry2Match2 ?
            <MatchesScores key={index} scores1={parsedScores?.secondArray} scores2={parsedScores?.firstArray} winner={true} />:
            <MatchesScores key={index} scores1={parsedScores?.firstArray} scores2={parsedScores?.secondArray} winner={false} />}
          </Box>
        );
      }

      // Add space or separator if needed between rounds
      if (index < Object.keys(scoresByRound)?.length - 1) {
        formattedScoresJSX.push(' '); // Add a separator between rounds
      }
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        // padding: '12px',
        color: '#0A2540',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '12px',
        textTransform: 'uppercase',
        // border: '1px solid #EDF1F6',
        // borderRadius: '6px',
        // width: '60%',
      }}
    >
      {formattedScoresJSX}
      {`${scoreType ? '  ' + scoreType : ''}`}
    </Box>
  );
};

export default getFormattedScore;
