import type { FC } from 'react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, FormHelperText, Checkbox, Typography, InputAdornment } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { LOGIN } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import errorToString from '../../../utils/errorToString';

const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const { t } = useTranslation();
  const { login, setForgetModalOpen, setLoginModalOpen } = useAuth();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginUser] = useMutation(LOGIN);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
        password: Yup.string().max(255).required(t('Password is required')),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        const variables = {
          email: values.email,
          password: values.password,
        };
        try {
          const response = await loginUser({
            variables,
          });
          await login(response.data.login);

          if (mounted.current) {
            // navigate("/");
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (error) {
          const err = error?.graphQLErrors;
          err.forEach((item) => {
            if (item?.extensions?.originalError?.statusCode === 403) {
              setLoginModalOpen(false);
              setForgetModalOpen(true);
            }
          });
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
            }}
          >
            <CustomFormInput
              autoFocus
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              placeholder={t('Email')}
              name="email"
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            <CustomFormInput
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              placeholder={t('Password')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isPasswordVisible ? 'text' : 'password'}
              value={values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomButton variant="transparent" shape="circle" size="small" onClick={togglePasswordVisibility}>
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </CustomButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox id="rememberMeCheckbox" size="small" />
            <label htmlFor="rememberMeCheckbox">
              <Typography variant="font21" sx={{ color: '#0A2540', cursor: 'pointer' }}>
                {t('Remember me')}
              </Typography>
            </label>
          </Box>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errorToString(errors.submit)}</FormHelperText>
            </Box>
          )}
          <CustomButton disabled={isSubmitting} size="medium" type="submit" variant="primary" sx={{ width: '100%', mt: '8px' }}>
            {t('Log In')}
          </CustomButton>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
