import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import gtm from 'src/lib/gtm';
import { useQuery } from '@apollo/client';
import { ALL_TOURNAMENT_CATEGORIES, PAIRING_RESULTS, PAIRINGS } from 'src/graphql/queries';
import React from 'react';
import PairingsListTable from 'src/components/dashboard/pairingsStandings/PairingsListTable';
import { useTournament } from '../../components/dashboard/TournamentPage/TournamentPage';

const PairingsStandings = () => {
  const { id } = useTournament();
  const { settings } = useSettings();
  const [type, setType] = useState('single');
  const [categoryID, setCategoryID] = useState(null);
  const [timeControl, setTimeControl] = useState('');
  const [round, setRound] = useState(null);
  const [loading, setLoading] = useState(true);

  // const [getTournament, {loading: tourLoading}] = useLazyQuery(TOURNAMENT_BY_ID);

  const { data: allTournamentCategories } = useQuery(ALL_TOURNAMENT_CATEGORIES, {
    skip:!id,
    variables: {
      filter: {
        tournament: id,
        registrationStatus: 'confirm',
      },
    },
    onCompleted: (data) => {
      if (!categoryID || !type) {
        setTimeControl(data.allTournamentCategories[0]?.category?.category?.timeControl);
        setType(data.allTournamentCategories[0]?.category?.category.type);
        setCategoryID(data.allTournamentCategories[0]?.category?.id);
      }
      if (data?.allTournamentCategories?.length === 0) {
        setLoading(false);
      }
    },
    onError: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
  });

  const { data: pairingRounds } = useQuery(PAIRINGS, {
    skip: !categoryID,
    variables: {
      filter: {
        tournament: id,
        // status: 'confirm',
        tournamentCategory: parseInt(categoryID),
        // type: type,
      },
    },
    onCompleted(data) {
      if (pairingRounds?.pairings?.filter((round) => !round.hideStandings)?.length === 0) {
        setLoading(false);
      }
    },
    onError(error) {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (pairingRounds?.pairings) {
      const visiblePairings = pairingRounds?.pairings?.filter((pairing) => !pairing.hideStandings);
      setRound(visiblePairings?.[0]?.id);
    }
  }, [pairingRounds]);

  const { loading: pairingsLoading, data: pairing } = useQuery(PAIRING_RESULTS, {
    skip: !round,
    variables: {
      id: round,
      filter: {
        tournament: id,
        tournamentCategory: parseInt(categoryID),
      },
    },
    onCompleted(data) {
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setType('single');
    setCategoryID(null);
  }, [id]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> Pairings Standings</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          minHeight: '100%',
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Box sx={{ position: 'relative', mb: '15px' }}>
            <PairingsListTable
              allTournamentCategories={allTournamentCategories?.allTournamentCategories}
              type={type}
              categoryID={categoryID}
              setCategoryID={setCategoryID}
              setType={setType}
              entriesListData={pairing?.pairingResults?.pairingEntries}
              loading={loading || pairingsLoading}
              setLoading={setLoading}
              rounds={pairingRounds?.pairings?.filter((round) => !round.hideStandings)}
              round={round}
              setRound={setRound}
              timeControl={timeControl}
              setTimeControl={setTimeControl}
              isHide={pairing?.pairing?.hideStandings}
            />
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default PairingsStandings;
