import React, { useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SliderWrapper from 'src/components/SliderWrapper';
import CourtCard from 'src/components/dashboard/TournamentPage/groups/CourtCard';
import AllTournamentSkeleton from '../../skeletons/AllTournamentsSkeleton';
import { TournamentSkeletonLimit } from 'src/constants';
import UpdatedSlider from 'src/components/UpdatedSlider';
import NoDataFound from 'src/components/dashboard/NoDataFound';
import { DownloadSharp } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { downloadFile } from 'src/utils/helperFunction';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import DownloadOOP from './DownloadOOP';

const useStyles = makeStyles((theme) => ({
  scheduleContainer: {
    width: '100%',
    height: 'max-content',
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FFF',
    padding: '9px',
  },
}));

type GroupGridVieProps = {
  setCurrentTab?: Dispatch<SetStateAction<string>>;
  oopData?: any;
  setSlider?: any;
  slickSlider?: any;
  show: any;
  tournamentDocuments?: Record<string, any>[];
};
const GroupGridView: FC<GroupGridVieProps> = ({ setCurrentTab, oopData, setSlider, slickSlider, show, tournamentDocuments }) => {
  const classes = useStyles();
  return (
    <Box className={classes.scheduleContainer}>
      {!show || !oopData || oopData?.length === 0 ? (
        tournamentDocuments?.length > 0 ? (
          <DownloadOOP tournamentDocuments={tournamentDocuments} />
        ) : (
          <NoDataFound text="There is no available order of play" sx={{ margin: '150px 0' }} />
        )
      ) : (
        <UpdatedSlider length={5} slickSlider={slickSlider} setSlider={setSlider} toShow={4} isResponsive={true}>
          {() => {
            return oopData?.map((data) => {
              if (show && data?.court) {
                return (
                  <React.Fragment key={data.id}>
                    <CourtCard data={data} show={show} />
                  </React.Fragment>
                );
              }
              return null;
            });
          }}
        </UpdatedSlider>
      )}
    </Box>
  );
};

export default GroupGridView;
