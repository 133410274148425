import { useQuery } from '@apollo/client';
import React from 'react';
import { SPORTSWITHCOUNT } from '../../graphql/queries';
import { HelpOutline } from '@material-ui/icons';
import { SxProps } from '@material-ui/system';
import { Box } from '@material-ui/core';

type Props = {
  sport: string | undefined;
  size?: number;
  sx?: SxProps;
  color?: string;
};

const SportIcon = ({ sport, size = 20, color, sx }: Props) => {
  const { data } = useQuery(SPORTSWITHCOUNT, {
    fetchPolicy:"no-cache",
    variables: {
      platform: 1,
    },
  });

  const icon = data?.sportsWithTournamentCount?.find((item) => item.title?.toLowerCase() === sport?.toLowerCase())?.icon;

  return sport && icon ? (
    <Box
      className="icon"
      sx={{
        minHeight: `${size}px`,
        minWidth: `${size}px`,
        maxHeight: `${size}px`,
        maxWidth: `${size}px`,
        backgroundColor: color ?? process.env.REACT_APP_PRIMARY_COLOR,
        WebkitMask: `url(${icon}) no-repeat center`,
        mask: `url(${icon}) no-repeat center`,
        maskSize: `${size}px ${size}px`,
        ...sx,
      }}
    />
  ) : (
    <HelpOutline sx={{ height: `${size}px`, width: `${size}px` }} />
  );
};

export default SportIcon;
