const scrollToAndHighlight = (el: string | HTMLElement) => {
  const element = typeof el === 'string' ? document.querySelector(el) : el;

  if (!element || !(element instanceof HTMLElement)) {
    return;
  }

  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100dvw';
  overlay.style.height = '100dvh';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  overlay.style.opacity = '0';
  overlay.style.transition = 'opacity 0.3s ease-in-out';
  overlay.style.zIndex = '9998';
  overlay.style.pointerEvents = 'none';
  overlay.style.overflow = 'hidden';

  document.body.appendChild(overlay);

  requestAnimationFrame(() => {
    overlay.style.opacity = '0.2';
  });

  const mainElement = document.getElementById('main');
  if (mainElement) {
    const elementRect = element.getBoundingClientRect();
    const mainRect = mainElement.getBoundingClientRect();
    const scrollTop = mainElement.scrollTop;
    const scrollLeft = mainElement.scrollLeft;
    const elementTop = elementRect.top - mainRect.top + scrollTop;
    const elementLeft = elementRect.left - mainRect.left + scrollLeft;

    mainElement.scrollTo({
      top: elementTop - mainElement.clientHeight / 2 + elementRect.height / 2,
      left: elementLeft - mainElement.clientWidth / 2 + elementRect.width / 2,
      behavior: 'smooth',
    });
  }

  const originalZIndex = element.style.zIndex;
  element.style.position = 'relative';
  element.style.zIndex = '9999';

  const timeoutId = setTimeout(() => {
    element.style.zIndex = originalZIndex;
    overlay.style.opacity = '0';
    setTimeout(() => {
      if (overlay.parentNode) {
        overlay.parentNode.removeChild(overlay);
      }
    }, 300);
  }, 2000);

  return () => {
    clearTimeout(timeoutId);
    if (overlay.parentNode) {
      overlay.parentNode.removeChild(overlay);
    }
  };
};

export default scrollToAndHighlight;
