import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, FormHelperText, IconButton, InputAdornment, Stack } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { RESET } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import toast from 'react-hot-toast';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import errorToString from '../../../utils/errorToString';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '30px',
    right: '18px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
}));

const PasswordResetAmplify = ({ handleClose }: { handleClose: () => void }) => {
  const mounted = useMounted();
  const { setLoginModalOpen, setResetModalOpen } = useAuth() as any;
  const location = useLocation() as any;
  const itemsRef = useRef([]);
  const [Reset] = useMutation(RESET) as any;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isReVisible, setIsReVisible] = useState(false);

  const { t } = useTranslation();

  // Get the query parameter string
  const queryString = location.search;

  // Find the token using a regular expression
  const tokenMatch = queryString.match(/\/([^/]+)$/);
  let token = null;
  if (tokenMatch && tokenMatch[1]) {
    token = tokenMatch[1];
  }
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  function toggleReVisibility() {
    setIsReVisible((prevState) => !prevState);
  }

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
  }, []);

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        // code: Yup
        //   .array()
        //   .of(Yup.string().required('Code is required')),
        // email: Yup
        //   .string()
        //   .email('Must be a valid email')
        //   .max(255)
        //   .required('Email is required'),
        password: Yup.string().min(7, 'Must be at least 7 characters').max(255).required('Required'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        const variables = {
          email: token,
          password: values.password,
        };
        try {
          const response = await Reset({
            variables,
          });
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            // navigate("/");
          }
          toast?.success('Password reset successfully');
          setResetModalOpen(false);
          setLoginModalOpen(true);
          handleClose();
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            toast?.error(err.message);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          {/* {
            !location.state?.username
              ? (
                <TextField
                  autoFocus
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"

                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
              )
              : (
                <TextField
                  disabled
                  fullWidth
                  margin="normal"
                  value={location.state.username}
                  variant="outlined"
                />
              )
          } */}
          {/* <Typography
            color="textSecondary"
            sx={{
              mb: 2,
              mt: 3
            }}
            variant="subtitle2"
          >
            Verification code
          </Typography> */}
          {/* <Box
            sx={{
              columnGap: '16px',
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              py: 1
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((ref, i) => (
              <TextField
                error={Boolean(
                  Array.isArray(touched.code)
                  && touched.code.length === 6
                  && errors.code
                )}
                fullWidth
                inputRef={(el) => itemsRef.current[i] = el}
                // eslint-disable-next-line react/no-array-index-key
                key={`codeNumber-${i}`}
                name={`code[${i}]`}

                onKeyDown={(event) => {
                  if (event.code === 'ENTER') {
                    if (values.code[i]) {
                      setFieldValue(`code[${i}]`, '');
                      return;
                    }

                    if (i > 0) {
                      setFieldValue(`code[${i}]`, '');
                      itemsRef.current[i - 1].focus();
                      return;
                    }
                  }

                  if (Number.isInteger(parseInt(event.key, 10))) {
                    setFieldValue(`code[${i}]`, event.key);

                    if (i < 5) {
                      itemsRef.current[i + 1].focus();
                    }
                  }
                }}
                onPaste={(event) => {
                  const paste = event.clipboardData.getData('text');
                  const pasteArray = paste.split('');

                  if (pasteArray.length !== 6) {
                    return;
                  }

                  let valid = true;

                  pasteArray.forEach((x) => {
                    if (!Number.isInteger(parseInt(x, 10))) {
                      valid = false;
                    }
                  });

                  if (valid) {
                    setFieldValue('code', pasteArray);
                    itemsRef.current[5].focus();
                  }
                }}
                value={values.code[i]}
                sx={{
                  display: 'inline-block',
                  textAlign: 'center',
                  '& .MuiInputBase-input': {
                    textAlign: 'center'
                  }
                }}
                variant="outlined"
              />
            ))}
          </Box> */}
          {/* {
            Boolean(Array.isArray(touched.code) && touched.code.length === 6 && errors.code) && (
              <FormHelperText
                error
                sx={{ mx: '14px' }}
              >
                {Array.isArray(errors.code) && errors.code.find((x) => x !== undefined)}
              </FormHelperText>
            )
          } */}
          <Stack gap="14px">
            <CustomFormInput
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              label={t('Password')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isPasswordVisible ? 'text' : 'password'}
              value={values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ marginRight: 0, zIndex: 1 }} aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomFormInput
              error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
              helperText={touched.passwordConfirm && errors.passwordConfirm}
              label={t('Re-Enter')}
              name="passwordConfirm"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isReVisible ? 'text' : 'password'}
              value={values.passwordConfirm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ marginRight: 0, zIndex: 1 }} aria-label="toggle password visibility" onClick={toggleReVisibility} edge="end">
                      {isReVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errorToString(errors.submit)}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 14 }}>
            <CustomButton variant="primary" disabled={isSubmitting} size="medium" sx={{ width: '100%' }} type="submit">
              Reset Password
            </CustomButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PasswordResetAmplify;
