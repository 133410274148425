import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import { Box, Stack, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { throttle } from 'lodash';
import CustomButton from '../../../CustomComponents/CustomButton';
import TablerIcon from '../../../CustomComponents/TablerIcon';
import { useTournament } from '../TournamentPage';

const Timeline = () => {
  const { tournament, loading } = useTournament();
  const steps = [
    {
      start: null,
      end: tournament?.entryDeadline,
      title: 'Entry Deadline',
      keyDate: tournament?.entryDeadline,
      icon: 'login',
      action: (
        <CustomButton size="medium" variant="primary" sx={{ width: '150px', mt: '10px' }}>
          Register
        </CustomButton>
      ),
    },
    { start: tournament?.entryDeadline, end: tournament?.withdrawlDeadline, title: 'Withdrawal Deadline', keyDate: tournament?.withdrawlDeadline, icon: 'ticket-off' },
    { start: tournament?.withdrawlDeadline, end: tournament?.startDate, title: 'Confirmation', keyDate: 'Until start date', icon: 'clock-hour-2' },
    { start: tournament?.startDate, end: tournament?.endDate, title: 'Start Date', keyDate: tournament?.startDate, icon: 'calendar-star' },
    { start: tournament?.endDate, end: null, title: 'End Date', keyDate: tournament?.endDate, icon: 'flag' },
  ].filter((step) => !!step.start || !!step.end);

  const determineCurrentStepIndex = (now) => {
    const index = steps.findIndex((step) => {
      if (step.end) {
        return step.start ? now.isBetween(step.start, step.end, null, '[)') : now.isBefore(step.end);
      } else {
        return step.start ? now.isSameOrAfter(step.start) : true;
      }
    });
    return index !== -1 ? index : steps.length - 1;
  };

  const determinePastSteps = (now) => {
    return steps.map((step) => (step.end ? now.isAfter(step.end) : false));
  };

  const [currentStepIndex, setCurrentStepIndex] = useState(() => determineCurrentStepIndex(tournament?.localTime()));
  const [pastSteps, setPastSteps] = useState(() => determinePastSteps(tournament?.localTime()));

  useEffect(() => {
    const interval = setInterval(() => {
      const now = tournament?.localTime();
      setCurrentStepIndex(determineCurrentStepIndex(now));
      setPastSteps(determinePastSteps(now));
    }, 1000);

    return () => clearInterval(interval);
  }, [steps]);

  const STEP_WIDTH = 300;
  const MIN_WIDTH = STEP_WIDTH * steps.length;
  const [scrollRef, setScrollRef] = useState(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const handleScroll = throttle((el?: HTMLElement) => {
    const target = el ?? scrollRef;
    const scrollLeft = target?.scrollLeft;
    const scrollWidth = target?.scrollWidth;
    const clientWidth = target?.clientWidth;

    if (scrollLeft > 0) {
      setShowLeft(true);
    } else {
      setShowLeft(false);
    }

    if (scrollLeft + clientWidth < scrollWidth) {
      setShowRight(true);
    } else {
      setShowRight(false);
    }
  }, 100);

  useEffect(() => {
    const handleResize = () => handleScroll();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const target = document.getElementById(steps[currentStepIndex]?.title);
    target?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
  }, [currentStepIndex]);

  return (
    <CustomPaperCard skeleton={loading} sx={{ height: '120px' }}>
      <ScrollContainer
        innerRef={(ref) => {
          setScrollRef(ref);
          handleScroll(ref);
        }}
        onScroll={() => handleScroll()}
        style={{
          maskImage: `linear-gradient(to right, transparent, black ${showLeft ? 10 : 0}%, black ${showRight ? 90 : 100}%, transparent)`,
        }}
      >
        <Stack
          sx={{
            minWidth: MIN_WIDTH + 'px',
            gap: '24px',
            padding: { xs: '12px', md: '22px' },
          }}
        >
          <Box sx={{ position: 'relative', mt: '8px' }}>
            <Box
              sx={{
                height: '4px',
                width: '100%',
                background: `linear-gradient(to right, ${process.env.REACT_APP_PRIMARY_COLOR} calc((100% / ${steps.length}) * ${currentStepIndex}), #84929F calc((100% / ${steps.length}) * ${currentStepIndex} + 5%))`,
                maskImage: 'linear-gradient(to right, black 90%, transparent)',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '4px',
                width: `calc((100% / ${steps.length}) * ${currentStepIndex})`,
                transition: '1s all',
                backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
              }}
            />
            {steps.map((_, i) => (
              <Box
                key={i}
                sx={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '999px',
                  background: i === currentStepIndex || pastSteps[i] ? process.env.REACT_APP_PRIMARY_COLOR : 'gray',
                  position: 'absolute',
                  top: '50%',
                  left: `calc((100% / ${steps.length}) * ${i})`,
                  transform: `translateY(-50%) scale(${i === currentStepIndex ? 1.2 : 1})`,
                  transformOrigin: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ height: '50%', width: '50%', borderRadius: '999px', background: 'white' }} />
              </Box>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {steps.map((step, i) => (
              <Stack key={i} sx={{ flex: 1, gap: '4px' }} id={step.title}>
                {<TablerIcon icon={step.icon} style={{ fontSize: '28px', color: i === currentStepIndex || pastSteps[i] ? process.env.REACT_APP_PRIMARY_COLOR : 'gray' }} />}
                <Box>
                  <Typography variant="font22" sx={{ fontSize: '13px' }}>
                    {step.title}
                  </Typography>{' '}
                  <Typography variant="font21" sx={{ color: 'rgba(10, 37, 64, 0.50)', fontSize: '11px' }}>
                    &#8226;{' '}
                    {typeof step.keyDate === 'string' ? (
                      step.keyDate
                    ) : (
                      <>
                        {step.keyDate.format('DD.MM HH:mm')} {tournament?.timeZone.abbreviation}
                      </>
                    )}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Box>
        </Stack>
      </ScrollContainer>
    </CustomPaperCard>
  );
};

export default Timeline;
