import { gql } from '@apollo/client';

const ALL_TOURNAMENTS = gql`
  query allTournamentsList(
    $filter: ListTournamentInput
    $page: Int
    $limit: Int
    $sports: [Int!]
    $federationId: Int
    $skills: String
    $ageGroup: String
    $inProgress: Boolean
    $platform: Int
    $isLast7or30Days: Boolean
    $categoryGroup: Int
  ) {
    allTournamentsList(
      filter: $filter
      page: $page
      limit: $limit
      sports: $sports
      federationId: $federationId
      skills: $skills
      ageGroup: $ageGroup
      inProgress: $inProgress
      platform: $platform
      isLast7or30Days: $isLast7or30Days
      categoryGroup: $categoryGroup
    )
  }
`;

export default ALL_TOURNAMENTS;
