import { getCurrencySymbol } from 'src/utils/helperFunction';

interface Category {
  fee?: number;
  onsiteFee?: number;
  currency?: string;
}

export const getFeeRange = (categories: Category[]): string => {
  if (!categories?.length) return '';

  const isFeeValid = (fee: number | undefined | null) => fee !== undefined && fee !== null && fee !== 0;
  const allSameOrZeroFee = categories.every((category) => {
    const { fee, onsiteFee } = category;
    return ((fee === categories[0].fee || fee === null) && (onsiteFee === categories[0].onsiteFee || onsiteFee === null)) || (!isFeeValid(fee) && !isFeeValid(onsiteFee));
  });

  if (allSameOrZeroFee) {
    const { fee, onsiteFee, currency } = categories[0];
    const validFee = isFeeValid(fee) ? fee : onsiteFee;
    return isFeeValid(validFee) ? `${validFee} ${getCurrencySymbol(currency)}` : '';
  }

  const fees = categories.flatMap((category) => {
    const { fee, onsiteFee } = category;
    return [fee, onsiteFee].filter(isFeeValid);
  });

  const minFee = Math.min(...fees);
  const maxFee = Math.max(...fees);

  if (fees.length === 0) return '';

  const currency = categories[0].currency;
  return minFee === maxFee ? `${minFee} ${getCurrencySymbol(currency)}` : `${minFee}-${maxFee} ${getCurrencySymbol(currency)}`;
};
