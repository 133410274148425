import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '16px',
    border: ' 1px solid #EDF1F6',
    background: '#FFF',
    '&:hover': {
      boxShadow: '7px 7px 15px 0px rgba(0, 0, 0, 0.10)',
      transition: 'boxShadow 0.3s ease',
    },
  },
  pillContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  imageContainer: {
    minHeight: '96px',
  },
  heading: {
    color: '#1B3861',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  description: {
    color: '#1B3861',
    fontWeight: 'medium',
    marginTop: theme.spacing(1),
  },
  authorContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  //
  mainwrapper: {
    width: '100%',
    minHeight: '12rem',
    height: '100%',
    maxHeight: '238px',
    cursor: 'pointer',
    position: 'relative',
  },
  mediaCount: {
    position: 'absolute',
    top: '5px',
    left: '10px',
    padding: '5px 10px 5px 10px',
    borderRadius: '20px',
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: 'white',
    fontWeight: '500',
    fontSize: '12px',
  },
}));

type CardProps = {
  isFramed?: boolean;
  label: string;
  description: string;
  image?: string;
};

const GalleryCard = ({ src }: any): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Box onClick={() => navigate(`/album/${src?.id}?${location.pathname.split('/')[1]}=${id}`)} className={classes.card}>
      <Box className={classes.mainwrapper}>
        <img
          src={src?.thumbnail}
          style={{
            width: '100%',
            objectFit: 'cover',
            height: '100%',
            maxHeight: '180px',
            maxWidth: '100%',
            borderRadius: '12px',
          }}
        />
        <Box className={classes.mediaCount}>{src?.media?.length} photos</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '6px 12px',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '30px',
            border: '1px solid #EDF1F6',
            background: '#FBFBFB',
          }}
        >
          <Typography
            variant="font11"
            sx={{
              color: '#86909F',
              fontStyle: 'normal',
              lineHeight: 'normal',
            }}
          >
            {moment(src?.date).format('DD MMMM YYYY')}
          </Typography>
        </Box>
        <Typography
          variant="heading4"
          sx={{
            color: '#0A2540',
            lineHeight: '28px',
            wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            cursor: 'pointer',
          }}
        >
          {src?.title || '-'}
        </Typography>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '6px 12px',
              alignItems: 'center',
              gap: '10px',
              borderRadius: '30px',
              border: '1px solid #EDF1F6',
              background: '#F6F7F9',
            }}
          >
            <Typography
              sx={{
                color: '#425466',
                fontFamily: 'Inter',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                textTransform: 'uppercase',
              }}
            >
              LALIGA EA SPORTS
            </Typography>
          </Box>
          <Button
            sx={{
              color: '#425466',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '16px',
              background: 'transparent',
              '&:hover': {
                background: 'transparent',
                color: '#0A2540',
              },
            }}
          >
            <img src="/images/updated/shared.svg" style={{ marginRight: '5px' }} />
            Share
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default GalleryCard;
