import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Checkbox, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/client';
import { SPORTS } from 'src/graphql/queries';
import { useRegisterContext } from 'src/contexts/RegisterContext';
import { getPlatformId } from 'src/constants';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '29px',
    right: '18px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
  textfield: {
    // height: "40px",
    flexShrink: 0,
    borderRadius: '8px',

    background: '#FFF',

    '& .MuiOutlinedInput-root': {
      // height: "37px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#86909F',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  sportItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '15px',
    alignItems: 'center',
    borderRadius: '4px',
    height: '70px',
    border: '1px solid #0000001A',
    padding: '0px 14px 0px 16px',
    cursor: 'pointer',
    '&:hover': {
      background: '#0A25400D',
    },
  },
  sportItemActive: {
    border: `2px solid ${process.env.REACT_APP_PRIMARY_COLOR}`,
    background: 'rgba(255, 87, 51, 0.05)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '15px',
    alignItems: 'center',
    borderRadius: '4px',
    height: '70px',
    padding: '0px 14px 0px 16px',
  },
  submitBtn: {
    borderRadius: '4px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
}));

const SportPicker: FC<any> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { registerObject, updateRegisterObject } = useRegisterContext();
  const [array, setArray] = useState<any>(registerObject?.user_sports);
  const platformId = getPlatformId();
  const { loading: loader, data: sports } = useQuery(SPORTS, {
    variables: {
      platform: platformId,
      filter: {},
    },
  });
  const [activeItems, setActiveItems] = useState(registerObject?.user_sports);

  const toggleActive = (itemId) => {
    if (activeItems.includes(itemId)) {
      setActiveItems(activeItems.filter((id) => id !== itemId));
    } else {
      setActiveItems([...activeItems, itemId]);
    }
  };
  const handlePush = (item: any) => {
    if (array.includes(item.id)) {
      let dummyArr = [...array];
      dummyArr = dummyArr.filter((arr) => arr !== item.id);

      setArray(dummyArr);
    } else {
      setArray([...array, item.id]);
    }
  };
  useEffect(() => {
    const updatedVales = { ['user_sports']: array };
    updateRegisterObject({ ...registerObject, ...updatedVales });
  }, [array]);
  const handleSubmit = async () => {
    props.handleClick(2);
    try {
      // if (array.includes(5)) {
      //   props.handleClick(4);
      // }
      // else {
      //   removeObjItems(registerObject);
      //   await registeration(registerObject);
      //   if (response) {
      //     props.setOpen(false);
      //   }
      //   props.handleClick(0);
      // }
    } catch (error) {
      console.log('In error >>>', error);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        maxHeight: '100%',
        minHeight: '474px',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
            {t('Pick a sport')}
          </Typography>
          <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '20px', opacity: '35%' }}>
            {t('Pick a sport that you play')}
          </Typography>
          <Box sx={{ mt: 1.5 }}>
            <Divider />
          </Box>
        </Box>
        {!loader ? (
          <Grid container spacing={1} width="100%">
            {sports?.sports?.map((item) => (
              <Grid item lg={6} md={6} xs={6}>
                <CustomButton
                  selected={array.includes(item?.id)}
                  size="large"
                  variant="secondary"
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    height: '70px',
                    whiteSpace: 'normal',
                    textAlign: 'start',
                  }}
                  onClick={() => {
                    handlePush(item);
                    toggleActive(item.id);
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                    <div
                      style={{
                        height: '28px',
                        width: '28px',
                        mask: `url(${item?.icon}) no-repeat center / contain`,
                        WebkitMask: `url(${item?.icon}) no-repeat center / contain`,
                      }}
                      className="icon"
                    />
                    {item?.title}
                  </Box>
                  <Checkbox size="small" checked={activeItems.includes(item.id)} onChange={() => toggleActive(item.id)} />
                </CustomButton>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ height: '300px' }}></Box>
        )}
        <CustomButton onClick={() => handleSubmit()} variant="primary" size="medium" type="submit" className={classes.submitBtn} sx={{ mt: '16px', width: '100%' }}>
          {t('Continue')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default SportPicker;
