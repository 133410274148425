import { gql } from '@apollo/client';

const FIND_COACH = gql`
  query searchCoachByEmail($email: String!) {
    searchCoachByEmail(email: $email) {
      id
      userRole
      user {
        id
        name
        email
        fideId
      }
      students {
        id
        user {
          id
          name
          surname
          email
          avatar
        }
      }
    }
  }
`;

export default FIND_COACH;
