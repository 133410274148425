import { gql } from "@apollo/client";

const SPORTSWITHCOUNT = gql`
  query sportsWithTournamentCount($platform: Int) {
    sportsWithTournamentCount(platform: $platform) {
      id
      title
      icon
      tournamentCount
    }
  }
`;

export default SPORTSWITHCOUNT;
