import moment from 'moment/moment';

export default function prettifyDates(startDate: string | moment.Moment, endDate: string | moment.Moment, timeZone?: string | moment.Moment) {
  const start = typeof startDate === 'string' ? moment(startDate) : startDate;
  const end = typeof endDate === 'string' ? moment(endDate) : endDate;

  if (start.isSame(end, 'day')) {
    return start.format(`MMM D, YYYY${timeZone ? ` HH:mm - ${end.format('HH:mm')}` : ''}`) + (timeZone ? ` ${timeZone}` : '');
  } else if (start.year() !== end.year()) {
    return `${start.format(`MMM D, YYYY${timeZone ? ` HH:mm` : ''}`)} - ${end.format(`MMM D, YYYY${timeZone ? ` HH:mm` : ''}`)} ${timeZone ?? ''}`;
  } else if (start.month() !== end.month()) {
    return `${start.format(`MMM D${timeZone ? ` HH:mm` : ''}`)} - ${end.format(`MMM D, YYYY${timeZone ? ` HH:mm` : ''}`)} ${timeZone ?? ''}`;
  } else {
    return `${start.format(`MMM D${timeZone ? ` HH:mm` : ''}`)} - ${end.format(`D, YYYY${timeZone ? ` HH:mm` : ''}`)} ${timeZone ?? ''}`;
  }
}
