import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, ListItemText, MenuItem, Typography, Stack } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { CustomWellComeMsg, getPlatformId, WellComeMsg } from 'src/constants';
import { useTranslation } from 'react-i18next';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomTooltip from '../../../CustomComponents/CustomTooltip';
import { DescriptionOutlined, Instagram, LanguageOutlined } from '@material-ui/icons';
interface LoginModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const WelcomeModal: FC<LoginModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { setForgetModalOpen, setLoginModalOpen, setSignupModalOpen, platformData } = useAuth();

  const platformId = getPlatformId();
  const handleClose = () => {
    localStorage.setItem('hasVisitedBefore', 'true');
    setOpen(false);
  };

  const onLoginClick = () => {
    setLoginModalOpen(true);
    handleClose();
  };

  const onRegisterClick = () => {
    setSignupModalOpen(true);
    handleClose();
  };

  const onResetClick = () => {
    setForgetModalOpen(true);
    handleClose();
  };

  const platformLogo = platformData?.darkLogo ?? platformData?.lightLogo ?? process.env.REACT_APP_LOGO_ICON;

  return (
    <React.Fragment>
      <CustomModal openOvveride={open} onClose={handleClose} width="min(100%, 450px)">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <Stack gap="35px" alignItems="center" sx={{ maxWidth: '360px' }}>
            <Box component="img" sx={{ maxHeight: '100px', height: 'fit-content', objectFit: 'contain', width: 'fit-content', maxWidth: '200px' }} src={platformLogo} />
            <Stack sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
              {/*  @ts-ignore-next-line */}
              <Typography variant="heading2" sx={{ textAlign: 'center', textWrap: 'pretty' }}>
                Welcome to <span style={{ whiteSpace: 'nowrap' }}>{platformData?.title}</span>
              </Typography>
              <Typography variant="font21" sx={{ textAlign: 'center' }}>
                {[1, 3].includes(platformId) ? platformData?.title.trim() + ', ' + t(WellComeMsg) : platformData?.title.trim() + ' ' + t(CustomWellComeMsg)}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#0A2540',
                lineHeight: '20px',
                textAlign: 'center',
                paddingBlock: '10px',
              }}
              variant="font15"
            >
              {t('Did you have an account in one of ')}
              <CustomTooltip
                title={
                  <Stack gap="6px">
                    {MenuItems.map((item) => (
                      <MenuItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                color: '#0A2540',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '14px',
                              }}
                            >
                              {item.title}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Stack>
                }
              >
                <span
                  style={{
                    color: process.env.REACT_APP_PRIMARY_COLOR,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  {t('those')}
                </span>
              </CustomTooltip>{' '}
              {t('platforms?')}
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', gap: '5px', alignItems: 'center', flexWrap: 'wrap' }}>
              <CustomButton
                size="medium"
                variant="primary"
                color="#0000000D"
                shape="default"
                sx={{
                  flex: 1,
                  color: '#0A2540',
                }}
                onClick={onLoginClick}
              >
                {t('Login')}
              </CustomButton>
              <CustomButton
                size="medium"
                variant="primary"
                color="#0000000D"
                shape="default"
                sx={{
                  flex: 1,
                  color: '#0A2540',
                }}
                onClick={onResetClick}
              >
                {t('Recover password')}
              </CustomButton>
            </Box>
            <CustomButton
              size="medium"
              variant="primary"
              shape="default"
              sx={{
                width: '100%',
              }}
              onClick={onRegisterClick}
            >
              {t(`newUser`)}
            </CustomButton>
            <CustomButton
              size="medium"
              variant="transparent"
              shape="default"
              sx={{
                width: '100%',
                color: 'rgba(10, 37, 64, 0.35)',
                fontWeight: '600',
              }}
              onClick={() => {
                handleClose();
              }}
            >
              {t('Continue as a guest')}
            </CustomButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '3px',
              alignItems: 'center',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <CustomButton
              size="small"
              variant="outline"
              shape="chip"
              sx={{
                width: 'fit-content',
                height: '35px',
                flexGrow: 1,
                justifyContent: 'center',
                gap: '6px',
              }}
              onClick={() => window.open('https://www.instagram.com/tournated')}
            >
              <Instagram sx={{ fontSize: '20px' }} />
              {t('Instagram')}
            </CustomButton>

            <CustomButton
              size="small"
              variant="outline"
              shape="chip"
              sx={{
                width: 'fit-content',
                height: '35px',
                flexGrow: 1,
                justifyContent: 'center',
                gap: '6px',
              }}
              onClick={() => window.open('https://tournated.gitbook.io/tournated')}
            >
              <DescriptionOutlined sx={{ fontSize: '20px' }} />
              {t('Documentation')}
            </CustomButton>
            <CustomButton
              size="small"
              variant="outline"
              shape="chip"
              sx={{
                width: 'fit-content',
                height: '35px',
                flexGrow: 1,
                justifyContent: 'center',
                gap: '6px',
              }}
              onClick={() => window.open('https://tournated.com/')}
            >
              <LanguageOutlined sx={{ fontSize: '20px' }} />
              {t('Landing')}
            </CustomButton>
          </Box>
        </Box>
      </CustomModal>
    </React.Fragment>
  );
};

const MenuItems = [
  {
    id: 0,
    title: 'beachtennis.io',
    desc: 'In-Platform management',
    path: '/pricing?plan=manager',
  },
  {
    id: 1,
    title: 'rankiaopr.com',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'teniss.lat',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'geotennis.ge',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'sportfishing.live',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'armarkseri.lv',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
];
export default WelcomeModal;
