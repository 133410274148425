import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Divider, FormHelperText, ListItemText, MenuItem, Modal, Paper, Popover, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import { CustomWellComeMsg, getPlatformId, WellComeMsg } from 'src/constants';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FORGET } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import useMounted from 'src/hooks/useMounted';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import errorToString from '../../../../utils/errorToString';
import { useTranslation } from 'react-i18next';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFilterBox from 'src/components/CustomComponents/CustomFilterBox';

interface LoginModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '15px 20px 25px 20px',
    maxWidth: '530px',
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 9999,
    border: 0,
    background: '#FFF',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    // height: 379,
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textfield: {
    // height: "40px",
    flexShrink: 0,
    borderRadius: '8px',

    // background: "#FBFBFB",
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    '& .MuiOutlinedInput-root': {
      // height: "37px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#86909F',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

const RecoverModal: FC<LoginModalProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, platformData } = useAuth();
  const mounted = useMounted();
  const { platform } = useAuth() as any;
  const platformId = getPlatformId();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openList, setOpenList] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [Forget, { data, loading, error }] = useMutation(FORGET) as any;

  const isRestrictedPath = location.pathname.startsWith('/userSettings') || location.pathname === '/mycareer' || location.pathname === '/get-licence';
  const handleClose = () => {
    if (!user && isRestrictedPath) {
      toast.error('Unauthorized , please sign in ');
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [user]);
  useEffect(() => {
    if (!user && isRestrictedPath) {
      navigate('/');
    }
  }, [user, isRestrictedPath]);
  const handleMenuItemClick = (item) => {
    // navigate(item.path);
    handleClose();
  };
  return (
    <React.Fragment>
      <CustomModal openOvveride={open} onClose={handleClose} allowClosing={isRestrictedPath ? false : true}>
        <Paper className={classes.paperStyle}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box className={classes.loginContainer}>
              <Box className={classes.socialContainer}>
                <Box
                  sx={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <img
                      src={platformData?.lightLogo}
                      style={{
                        cursor: 'pointer',
                        width: '110px',
                        height: '98px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#0A2540',
                        lineHeight: '24px',
                      }}
                      variant="heading2"
                    >
                      {` ${t('Welcome to the')} ${platformData?.title ? platformData?.title : ''} ${t('platform')}`}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ color: '#0A2540BF', lineHeight: '14.4px' }} variant="font18">
                        {t('Powered by')}
                      </Typography>
                      <img
                        src={platformData?.lightLogo}
                        style={{
                          maxWidth: '79px',
                          width: 'max-content',
                          height: '13px',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#666666',
                        lineHeight: '16.8px',
                        maxWidth: '358px',
                        width: '100%',
                      }}
                      variant="font21"
                    >
                      {`${platformData?.title ? platformData?.title : ''}, ${platformId == 1 || platformId == 3 ? t(WellComeMsg) : t(CustomWellComeMsg)}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.loginActionContainer}>
                <Formik
                  initialValues={{
                    email: '',
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
                    const variables = {
                      email: values.email,
                      platformId: getPlatformId(),
                      redirectURL: window?.location?.origin,
                    };
                    try {
                      // await passwordRecovery(values.email);
                      const response = await Forget({
                        variables,
                      });
                      if (mounted.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        //   setIsShow(true);
                        toast.success('Please check your email for reset password link!');
                        setOpen(false);
                      }
                    } catch (err) {
                      console.error(err);
                      if (mounted.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                        toast.error(err.message);
                      }
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          gap: '8px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#0A2540',
                            lineHeight: '20px',
                            textAlign: 'center',
                            maxWidth: '358px',
                            width: '100%',
                          }}
                          variant="font15"
                        >
                          {t('If you have an account on one of ')}
                          <span
                            style={{
                              color: process.env.REACT_APP_PRIMARY_COLOR,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            ref={anchorRef}
                            onClick={() => setOpenList(true)}
                          >
                            {t('these')}
                          </span>{' '}
                          {t('platforms, log in with your e-mail or create a new profile')}
                        </Typography>
                        <CustomFilterBox
                          autoFocus
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          placeholder={t('Email')}
                          margin="normal"
                          name="email"
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          // variant="outlined"
                          sx={{ maxWidth: '100%', marginBottom: '0px' }}
                        />
                        {errors.submit && (
                          <Box sx={{ mt: 1 }}>
                            <FormHelperText error>{errorToString(errors.submit)}</FormHelperText>
                          </Box>
                        )}
                        <CustomButton
                          size="medium"
                          variant="primary"
                          shape="default"
                          sx={{
                            width: '100%',
                            color: '#fff',
                            borderRadius: '6px',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '20px',
                          }}
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {t('Reset password')}
                        </CustomButton>
                        <CustomButton
                          size="medium"
                          variant="transparent"
                          shape="default"
                          sx={{
                            width: '100%',
                            background: '#0000000D',
                            color: '#0A254059',
                            borderRadius: '6px',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '20px',
                          }}
                        >
                          {t('Continue as a guest')}
                        </CustomButton>
                      </Box>
                    </form>
                  )}
                </Formik>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '3px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://www.instagram.com/tournated')}
                  >
                    <img src="/images/newInat.svg" />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Instagram')}
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://tournated.gitbook.io/tournated')}
                  >
                    <img src="/images/updated/cat.svg" style={{ width: '15px', height: '15px' }} />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Documentation')}
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://tournated.gitbook.io/tournated')}
                  >
                    <HelpOutlineIcon sx={{ height: '20px', width: '20px' }} />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Support')}
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          keepMounted
          onClose={() => setOpenList(false)}
          open={openList}
          PaperProps={{
            sx: {
              width: 250,
              boxShadow: '0px 6px 9px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.16)',
              borderRadius: '8px',
              padding: '10px 0px',
            },
          }}
        >
          {MenuItems.map((item) => (
            <MenuItem onClick={() => handleMenuItemClick(item)} key={item.id} sx={{ padding: '0 20px' }}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: '#0A2540',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '14px',
                    }}
                  >
                    {item.title}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </Popover>
      </CustomModal>
    </React.Fragment>
  );
};
const MenuItems = [
  {
    id: 0,
    title: 'beachtennis.io',
    desc: 'In-Platform management',
    path: '/pricing?plan=manager',
  },
  {
    id: 1,
    title: 'rankiaopr.com',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'teniss.lat',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'geotennis.ge',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'sportfishing.live',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'armarkseri.lv',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
];
export default RecoverModal;
