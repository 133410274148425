import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import type { Direction, Theme, ThemeOptions } from '@material-ui/core';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}
declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    heading0: true;
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    heading6: true;
    heading7: true;
    heading8: true;
    heading9: true;
    heading10: true;
    heading11: true;
    heading12: true;
    heading13: true;
    font11: true;
    font12: true;
    font13: true;
    font14: true;
    font15: true;
    font16: true;
    font17: true;
    font18: true;
    font19: true;
    font20: true;
    font21: true;
    font22: true;
    font25: true;
    heading14: true;
    heading15: true;
    heading16: true;
    heading17: true;
    heading18: true;
    heading22: true;
    heading19: true;
    font23?: true;
    heading20?: true;
    heading21?: true;
    font24?: true;
  }
}
const customTypography: { [key: string]: TypographyStyleOptions } = {
  heading0: {
    fontWeight: 700,
    fontSize: '28px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading1: {
    fontWeight: 700,
    fontSize: '22px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading2: {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading3: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading4: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'Eudoxus Sans, sans-serif',
    fontStyle: 'normal',
    lineHeight: '35.28px',
  },
  heading5: {
    fontWeight: 500,
    fontSize: '20px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading6: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading7: {
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading8: {
    fontWeight: 500,
    fontSize: '15px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading9: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading10: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading11: {
    fontWeight: 700,
    fontSize: '12px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading12: {
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading13: {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading14: {
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading15: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading16: {
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Eudoxus Sans, sans-serif',
  },
  heading17: {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Eudoxus Sans, sans-serif',
    '@media (max-width:600px)': {
      fontSize: '18px',
    },
  },
  heading18: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Eudoxus Sans ,  sans-serif',
  },
  heading19: {
    fontWeight: 700,
    fontSize: '24px',
    fontFamily: 'Eudoxus Sans ,  sans-serif',
  },
  heading20: {
    fontWeight: 500,
    fontSize: '24px',
    fontFamily: 'Eudoxus Sans ,  sans-serif',
  },
  heading22: {
    fontWeight: 500,
    fontSize: '10px',
    fontFamily: 'Eudoxus Sans ,  sans-serif',
  },
  font11: {
    fontWeight: 400,
    fontSize: '11px',
    fontFamily: 'Inter, sans-serif',
  },
  font12: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
  },
  font13: {
    fontWeight: 400,
    fontSize: '10px',
    fontFamily: 'Inter, sans-serif',
  },
  font14: {
    fontWeight: 700,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  font15: {
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  font16: {
    fontWeight: 500,
    fontSize: '10px',
    fontFamily: 'Inter, sans-serif',
  },
  font17: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
  },
  font18: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  font19: {
    fontWeight: 700,
    fontSize: '56px',
  },
  font20: {
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
  },
  font21: {
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  font22: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
  },
  font23: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
  },
  font24: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Inter, sans-serif',
  },
  font25: {
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
  },
  heading21: {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Eudoxus Sans',
  },
};

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width:961px)': {
            paddingInline: '10px',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          height: '44px',
          transition: 'background 0.3s ease',
          background: `linear-gradient(100deg, ${process.env.REACT_APP_PRIMARY_COLOR} 0%, ${process.env.REACT_APP_SECONDARY_COLOR} 100%)`,
          '&:hover': {
            boxShadow: 'none',
            background: `linear-gradient(100deg, ${process.env.REACT_APP_PRIMARY_COLOR} 0%, ${process.env.REACT_APP_SECONDARY_COLOR} 100%)`,
            transition: `background 0.3s ease`,
            color: '#fff',
          },
          '&.Mui-disabled': {
            color: '#fff',
            opacity: 0.6,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  // typography: {
  //   button: {
  //     fontWeight: 500,
  //     fontFamily: "Inter, sans-serif",
  //     fontSize: "14px",
  //   },
  //   // fontFamily: "Inter, sans-serif",

  // },
  typography: {
    button: {
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },
    fontFamily: 'Inter, sans-serif',
    ...customTypography,
    h1: {
      fontWeight: 700,
      fontSize: '36px',
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 700,
      fontSize: '21px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '17px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '16px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '28px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '20px',
      fontFamily: 'Inter, sans-serif',
    },
    overline: {
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },

    body2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '13px',
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: `${process.env.REACT_APP_PRIMARY_COLOR}`,
      },
      background: {
        default: '#FAFAFA',
        paper: '#ffffff',
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: `${process.env.REACT_APP_PRIMARY_COLOR}`,
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#193660',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36',
      },
      divider: '#D3D3D3',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};
const INITIAL_BREAKPOINTS = { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 };

export const createCustomTheme = (config: ThemeConfig = {}, sidebarOpen): Theme => {
  const themeOptions = themesOptions[config.theme] || themesOptions[THEMES.LIGHT];

  if (!themesOptions[config.theme]) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
  }

  const adjustedBreakpoints = Object.fromEntries(Object.entries(INITIAL_BREAKPOINTS).map(([key, value]) => [key, key === 'xs' || key === 'sm' ? value : value + (sidebarOpen ? 250 : 0)]));

  return createTheme(
    merge(
      {},
      { breakpoints: { values: adjustedBreakpoints } },
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 8,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );
};
