import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupsListView from 'src/components/widgets/tables/groupsTable/GroupsTable';
import MatchesListTable from 'src/components/widgets/tables/groupsTable/MatchesListTable';
import { useParams } from 'react-router';
import Standings from './Standings';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'src/utils/debounce';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  group: {
    display: 'inline-flex',
    paddingBottom: '16px',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0px 6px 6px 0px',
    width: '85%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tabs: {
    display: 'flex',
    width: '100%',
    padding: '6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '0px 6px 0px 0px',
    border: '1px solid #EDF1F6',
    background: '#F5F5F5',
  },
  tab: {
    display: 'flex',
    maxWidth: '440px',
    width: '100%',
    padding: '6px 10px',
    height: '35px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '5px',
  },
}));

const GroupTabs: FC<any> = ({ selectedGroupArray, matchesLoading, groupsLoading, selectedCat, selectedTab, setSelectedTab }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const [filtersParams, setFilterParams] = useSearchParams();
  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(selectedTab && { view: selectedTab }),
      };

      // Remove parameters with empty values
      if (!selectedTab) {
        delete newParams.view;
      }

      setFilterParams(newParams);
    },
    [selectedTab],
    100
  );

  return (
    <Box className={classes.group}>
      <Box className={classes.tabs}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', gap: '10px' }}>
          <Button
            className={classes.tab}
            sx={{
              background: selectedTab == 'standard' ? `linear-gradient(100deg, ${process.env.REACT_APP_PRIMARY_COLOR} 0%, ${process.env.REACT_APP_SECONDARY_COLOR} 100%)` : '#F5F5F5',
              color: selectedTab == 'standard' ? '#FFF' : '#425466',
              whiteSpace: 'nowrap',
            }}
            onClick={() => setSelectedTab('standard')}
          >
            {t('Full view')}
          </Button>
          <Button
            className={classes.tab}
            sx={{
              background: selectedTab == 'liveStanding' ? `linear-gradient(100deg, ${process.env.REACT_APP_PRIMARY_COLOR} 0%, ${process.env.REACT_APP_SECONDARY_COLOR} 100%)` : '#F5F5F5',
              color: selectedTab == 'liveStanding' ? '#FFF' : '#425466',
            }}
            onClick={() => setSelectedTab('liveStanding')}
          >
            {t('Standings')}
          </Button>
          <Button
            className={classes.tab}
            sx={{
              background: selectedTab == 'matches' ? `linear-gradient(100deg, ${process.env.REACT_APP_PRIMARY_COLOR} 0%, ${process.env.REACT_APP_SECONDARY_COLOR} 100%)` : '#F5F5F5',
              color: selectedTab == 'matches' ? '#FFF' : '#425466',
            }}
            onClick={() => setSelectedTab('matches')}
          >
            {t('Matches')}
          </Button>
        </Box>
      </Box>
      {selectedTab == 'standard' && (
        <GroupsListView
          groupsTableData={selectedGroupArray}
          loading={groupsLoading || matchesLoading}
          selectedCat={selectedCat}
          // highlightText={highlightText}
          // groupRefs={groupRefs}
          // userRefs={userRefs}
        />
      )}
      {selectedTab == 'liveStanding' && <Standings groupsTableData={selectedGroupArray} loading={groupsLoading || matchesLoading} selectedCat={selectedCat} />}
      {selectedTab == 'matches' &&
        selectedGroupArray?.map((group) => (
          <MatchesListTable
            groupMatchesData={group.matches}
            groupID={group?.id}
            name={group?.name}
            selectedCat={selectedCat}
            catName={group?.tournamentCategory?.category?.name}
            segment={group?.segment}
            // highlightText={highlightText}
            // groupRefs={groupRefs}
            // userRefs={userRefs}
          />
        ))}
    </Box>
  );
};

export default GroupTabs;
