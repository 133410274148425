import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';
import  AuthLeftSection  from './AuthLeftSection';

interface AuthLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftSection: {
    width: '69%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  rightSection: {
    width: '31%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSection}>
        <AuthLeftSection />
      </div>
      <div className={classes.rightSection}>{children || <Outlet />}</div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
